import { format } from "date-fns";
import { isEmptyVal } from "./utils_types";

/**
 * 'initialData': is a mock version of the 'data' passed to each 'XXXXTemplate' class
 * - Includes the "currentUser"
 * - Includes the "currentFacility"
 * - Includes the "emailContent" (eg message, reference url etc.)
 */
const initialData = {
	currentUser: {
		firstName: null,
		lastName: null,
		username: null,
		password: null,
		token: null,
		userID: null,
		profileID: null,
		title: null,
		isAdmin: false,
		isSuperUser: false,
		facilityID: null,
	},
	currentFacility: {
		communityName: null,
		facilityID: null,
		parentID: null,
		address: {
			street: "",
			city: "",
			state: "",
			zip: "",
		},
	},
	emailContent: {
		pageTitle: "",
		referenceURL: "",
		appLoginURL: "",
		// reset vals
		otp: "",
		// feedback vals
		feedbackType: "",
		issueMsg: "",
		ideaMsg: "",
		otherMsg: "",
	},
};
/**
 * "FeedbackXXXXTemplate": custom html email template for use w/ <FeedbackWidget/>
 * - "Issues": eg bug reports, errors.
 * - "Ideas": eg feature requests, improvements.
 * - "Other": anything, suggestions, criticism etc.
 */

class FeedbackIssueTemplate {
	constructor(data = {}) {
		const { currentUser, currentFacility, emailContent } = data;
		// handle facility info, if empty
		const { facilityName, addressTxt } = this._getFacilityInfo(currentFacility);

		this._pageTitle = emailContent?.pageTitle ?? "ALA Feedback - Issue";
		this._usersName = `${currentUser?.firstName} ${currentUser?.lastName}`;
		this._usersEmail = currentUser?.email ?? currentUser?.username;
		this._facilityName = facilityName;
		this._facilityAddress = addressTxt;
		// content
		this._msg = emailContent?.issueMsg;
		this._referenceURL = emailContent?.referenceURL;
		this._date =
			emailContent?.date ?? format(new Date(), "MMM Do, YYYY h:mm A");

		this._template = `
    <!DOCTYPE html>
    <html lang="en">

    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="ie=edge">
      <meta name="author" content="AL Advantage LLC.">
      <title>${this._pageTitle}</title>
      <style>
        *,
        *::after,
        *::before {
          box-sizing: border-box;
          margin: 0;
          padding: 0;
        }
      
        body {
          box-sizing: border-box;
          margin: 0;
          padding: 2rem;
          background-color: #eaecef;
          font-family: "Raleway", "Open Sans", Arial, sans-serif;
        }
      
        html {
          font-size: 62.5%;
        }
        #container {
          width: 40rem;
        }
        #msg {
          width: 80%;
        }
        @media only screen and (max-width: 450px) {
          #container {
            width: 100%;
          }
          #msg {
            width: 100%;
          }
        }
      </style>
    </head>
      
    <body>
      <div style="display: block;height: auto;padding: 2rem 3rem;margin-left: auto;margin-right: auto;background-color: #ffffff;border-radius: 0.5rem;font-family: 'Raleway', 'Open Sans' , sans-serif;border-top: 5px solid #e84855;" id="container">
        <h1 style="font-size: 3rem;">
          Feedback: <b style="color: #e84855;">"Issue"</b>
        </h1>
        <h4 style="font-size: 1.3rem;font-weight: 500;margin-bottom: 2rem;color: rgb(148, 163, 184);">
          ${this._date}
        </h4>
        <div style="width: 100%;display: block;">
          <div style="font-size: 1.7rem;font-weight: 700;color: rgb(30, 41, 59);">
            From: ${this._usersName}
          </div>
          <div style="font-size: 1.5rem;font-weight: 500;color: rgb(100, 116, 139);">
            ${this._usersEmail}
          </div>
        </div>
        <div style="width: 100%;margin-top: 2rem;">
          <div style="font-size: 1.7rem;font-weight: 700;color: rgb(30, 41, 59);">
            Facility: ${this._facilityName}
          </div>
          <div style="font-size: 1.5rem;font-weight: 500;color: rgb(100, 116, 139);">
            ${this._facilityAddress}
          </div>
        </div>
        <div
          style="text-overflow: clip;word-wrap: wrap;word-break: break-all;font-size: 1.4rem;font-weight: 500;color: rgb(71, 85, 105);margin-top: 4rem;margin-bottom: 0.5rem;" id="msg">
          <div style="font-size: 1.7rem;font-weight: 600;color: rgb(51, 65, 85);margin-bottom: 0.5rem;">
            Describe the issue:
          </div>
          <p style="position: relative;font-size: 1.4rem;font-weight: 600;color: rgb(109, 40, 217);">
            ${this._msg}
          </p>
        </div>
        <div style="width: 100%;margin-top: 2rem;">
          <div style="font-size: 1.2rem;font-weight: 500;color: rgb(100, 116, 139);">
            Reference URL: ${this._referenceURL}
          </div>
        </div>
      
        <div style="width: 100%;margin-top: 4rem;">
          <p style="font-size: 1.4rem;font-weight: 500;color: rgb(100, 116, 139);font-style: italic;">
            This message was sent via the AL Advantage "Feedback Widget".
          </p>
        </div>
      </div>
      
    </body>
      
    </html>
    `;
	}
	getEmailTemplate() {
		return this._template;
	}
	_getFacilityInfo(currentFacility = {}) {
		const { facilityID } = currentFacility;
		if (!facilityID || isEmptyVal(facilityID)) {
			return {
				facilityName: `Unknown Facility`,
				addressTxt: `Unknown Location`,
			};
		} else {
			const { address } = currentFacility;

			return {
				facilityName: currentFacility?.communityName,
				addressTxt: `${address?.street} ${address?.city}, ${address?.state} ${address?.zip}`,
			};
		}
	}
}
class FeedbackIdeaTemplate {
	constructor(data = {}) {
		const { currentUser, currentFacility, emailContent } = data;
		// handle facility info, if empty
		const { facilityName, addressTxt } = this._getFacilityInfo(currentFacility);

		this._pageTitle = emailContent?.pageTitle ?? "ALA Feedback - Idea";
		this._usersName = `${currentUser?.firstName} ${currentUser?.lastName}`;
		this._usersEmail = currentUser?.email ?? currentUser?.username;
		this._facilityName = facilityName;
		this._facilityAddress = addressTxt;
		// content
		this._msg = emailContent?.ideaMsg;
		this._referenceURL = emailContent?.referenceURL;
		this._date =
			emailContent?.date ?? format(new Date(), "MMM Do, YYYY h:mm A");

		this._template = `
    <!DOCTYPE html>
    <html lang="en">

    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="ie=edge">
      <meta name="author" content="AL Advantage LLC.">
      <title>${this._pageTitle}</title>
      <style>
        *,
        *::after,
        *::before {
          box-sizing: border-box;
          margin: 0;
          padding: 0;
        }
      
        body {
          box-sizing: border-box;
          margin: 0;
          padding: 2rem;
          background-color: #eaecef;
          font-family: "Raleway", "Open Sans", Arial, sans-serif;
        }
      
        html {
          font-size: 62.5%;
        }
      </style>
    </head>
      
    <body>
      <div style="display: block;width: 40rem;height: auto;padding: 2rem 3rem;margin-left: auto;margin-right: auto;border-top: 5px solid rgb(16, 185, 129);background-color: #ffffff;border-radius: 0.5rem;font-family: 'Raleway', 'Open Sans' , sans-serif;">
        <h1 style="font-size: 3rem;">
          Feedback: <b style="color:rgb(16, 185, 129);">"Idea"</b>
        </h1>
        <h4 style="font-size: 1.3rem;font-weight: 500;margin-bottom: 2rem;color: rgb(148, 163, 184);">
          ${this._date}
        </h4>
        <div style="width: 100%;display: block;">
          <div style="font-size: 1.7rem;font-weight: 700;color: rgb(30, 41, 59);">
            From: ${this._usersName}
          </div>
          <div style="font-size: 1.5rem;font-weight: 500;color: rgb(100, 116, 139);">
            ${this._usersEmail}
          </div>
        </div>
        <div style="width: 100%;margin-top: 2rem;">
          <div style="font-size: 1.7rem;font-weight: 700;color: rgb(30, 41, 59);">
            Facility: ${this._facilityName}
          </div>
          <div style="font-size: 1.5rem;font-weight: 500;color: rgb(100, 116, 139);">
            ${this._facilityAddress}
          </div>
        </div>
        <div
          style="width: 70%;text-overflow: clip;word-wrap: wrap;word-break: break-all;font-size: 1.4rem;font-weight: 500;color: rgb(71, 85, 105);margin-top: 4rem;margin-bottom: 0.5rem;">
          <div style="font-size: 1.7rem;font-weight: 600;color: rgb(51, 65, 85);margin-bottom: 0.5rem;">
            Message: 
          </div>
          <p style="position: relative;font-size: 1.4rem;font-weight: 600;color: rgb(109, 40, 217);">
            ${this._msg}
          </p>
        </div>
        <div style="width: 100%;margin-top: 2rem;">
          <div style="font-size: 1.2rem;font-weight: 500;color: rgb(100, 116, 139);">
            Reference URL: ${this._referenceURL}
          </div>
        </div>
      
        <div style="width: 100%;margin-top: 4rem;">
          <p style="font-size: 1.4rem;font-weight: 500;color: rgb(100, 116, 139);font-style: italic;">
            This message was sent via the AL Advantage "Feedback Widget".
          </p>
        </div>
      </div>
      
    </body>
      
    </html>
    `;
	}
	getEmailTemplate() {
		return this._template;
	}
	_getFacilityInfo(currentFacility = {}) {
		const { facilityID } = currentFacility;
		if (!facilityID || isEmptyVal(facilityID)) {
			return {
				facilityName: `Unknown Facility`,
				addressTxt: `Unknown Location`,
			};
		} else {
			const { address } = currentFacility;

			return {
				facilityName: currentFacility?.communityName,
				addressTxt: `${address?.street} ${address?.city}, ${address?.state} ${address?.zip}`,
			};
		}
	}
}
class FeedbackOtherTemplate {
	constructor(data = {}) {
		const { currentUser, currentFacility, emailContent } = data;
		// handle facility info, if empty
		const { facilityName, addressTxt } = this._getFacilityInfo(currentFacility);

		this._pageTitle = emailContent?.pageTitle ?? "ALA Feedback - Other";
		this._usersName = `${currentUser?.firstName} ${currentUser?.lastName}`;
		this._usersEmail = currentUser?.email ?? currentUser?.username;
		this._facilityName = facilityName;
		this._facilityAddress = addressTxt;
		// content
		this._msg = emailContent?.otherMsg;
		this._referenceURL = emailContent?.referenceURL;
		// Aug. 18th, 2021 6:32 PM
		this._date =
			emailContent?.date ?? format(new Date(), "MMM Do, YYYY h:mm A");

		this._template = `
    <!DOCTYPE html>
    <html lang="en">

    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="ie=edge">
      <meta name="author" content="AL Advantage LLC.">
      <title>${this._pageTitle}</title>
      <style>
        *,
        *::after,
        *::before {
          box-sizing: border-box;
          margin: 0;
          padding: 0;
        }
      
        body {
          box-sizing: border-box;
          margin: 0;
          padding: 2rem;
          background-color: #eaecef;
          font-family: "Raleway", "Open Sans", Arial, sans-serif;
        }
      
        html {
          font-size: 62.5%;
        }
      </style>
    </head>
      
    <body>
      <div style="display: block;width: 40rem;height: auto;padding: 2rem 3rem;margin-left: auto;margin-right: auto;border-top: 5px solid rgb(37, 99, 235);background-color: #ffffff;border-radius: 0.5rem;font-family: 'Raleway', 'Open Sans' , sans-serif;">
        <h1 style="font-size: 3rem;">
          Feedback: <b style="color: rgb(37, 99, 235);">"Other"</b>
        </h1>
        <h4 style="font-size: 1.3rem;font-weight: 500;margin-bottom: 2rem;color: rgb(148, 163, 184);">
          ${this._date}
        </h4>
        <div style="width: 100%;display: block;">
          <div style="font-size: 1.7rem;font-weight: 700;color: rgb(30, 41, 59);">
            From: ${this._usersName}
          </div>
          <div style="font-size: 1.5rem;font-weight: 500;color: rgb(100, 116, 139);">
            ${this._usersEmail}
          </div>
        </div>
        <div style="width: 100%;margin-top: 2rem;">
          <div style="font-size: 1.7rem;font-weight: 700;color: rgb(30, 41, 59);">
            Facility: ${this._facilityName}
          </div>
          <div style="font-size: 1.5rem;font-weight: 500;color: rgb(100, 116, 139);">
            ${this._facilityAddress}
          </div>
        </div>
        <div
          style="width: 70%;text-overflow: clip;word-wrap: wrap;word-break: break-all;font-size: 1.4rem;font-weight: 500;color: rgb(71, 85, 105);margin-top: 4rem;margin-bottom: 0.5rem;">
          <div style="font-size: 1.7rem;font-weight: 600;color: rgb(51, 65, 85);margin-bottom: 0.5rem;">
            Message:
          </div>
          <p style="position: relative;font-size: 1.4rem;font-weight: 600;color: rgb(109, 40, 217);">
            ${this._msg}
          </p>
        </div>
        <div style="width: 100%;margin-top: 2rem;">
          <div style="font-size: 1.2rem;font-weight: 500;color: rgb(100, 116, 139);">
            Reference URL: ${this._referenceURL}
          </div>
        </div>
      
        <div style="width: 100%;margin-top: 4rem;">
          <p style="font-size: 1.4rem;font-weight: 500;color: rgb(100, 116, 139);font-style: italic;">
            This message was sent via the AL Advantage "Feedback Widget".
          </p>
        </div>
      </div>
      
    </body>
      
    </html>
    `;
	}
	getEmailTemplate() {
		return this._template;
	}
	_getFacilityInfo(currentFacility = {}) {
		const { facilityID } = currentFacility;
		if (!facilityID || isEmptyVal(facilityID)) {
			return {
				facilityName: `Unknown Facility`,
				addressTxt: `Unknown Location`,
			};
		} else {
			const { address } = currentFacility;

			return {
				facilityName: currentFacility?.communityName,
				addressTxt: `${address?.street} ${address?.city}, ${address?.state} ${address?.zip}`,
			};
		}
	}
}

// Confirm Email & Reset Password
class ResetPasswordTemplate {
	constructor(data = {}) {
		const { currentUser, emailContent } = data;

		this._pageTitle = emailContent?.pageTitle ?? `AL Advantage: Password Reset`;
		this._usersName = `${currentUser?.firstName ?? "AL Advantage User"}`;
		this._usersEmail = currentUser?.email ?? currentUser?.username;
		// content
		this._appLogin = emailContent?.appLoginURL;
		this._otp = emailContent?.otp;
		this._template = `
    <!DOCTYPE html>
<html xmlns="http://www.w3.org/1999/xhtml" style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">

<head>
	<meta name="viewport" content="width=device-width" />
	<meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
	<title>Reset Password - OTP</title>
	<style type="text/css">
	img {
		max-width: 100%;
	}
	
	body {
		-webkit-font-smoothing: antialiased;
		-webkit-text-size-adjust: none;
		width: 100% !important;
		height: 100%;
		line-height: 1.6em;
	}
	
	body {
		background-color: #f6f6f6;
	}
	
	@media only screen and (max-width: 640px) {
		body {
			padding: 0 !important;
		}
		h1 {
			font-weight: 800 !important;
			margin: 20px 0 5px !important;
		}
		h2 {
			font-weight: 800 !important;
			margin: 20px 0 5px !important;
		}
		h3 {
			font-weight: 800 !important;
			margin: 20px 0 5px !important;
		}
		h4 {
			font-weight: 800 !important;
			margin: 20px 0 5px !important;
		}
		h1 {
			font-size: 22px !important;
		}
		h2 {
			font-size: 18px !important;
		}
		h3 {
			font-size: 16px !important;
		}
		.container {
			padding: 0 !important;
			width: 100% !important;
		}
		.content {
			padding: 0 !important;
		}
		.content-wrap {
			padding: 10px !important;
		}
		.invoice {
			width: 100% !important;
		}
	}
	</style>
</head>

<body itemscope itemtype="http://schema.org/EmailMessage" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; -webkit-font-smoothing: antialiased; -webkit-text-size-adjust: none; width: 100% !important; height: 100%; line-height: 1.6em; background-color: #f6f6f6; margin: 0;">
	<div class="content" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; max-width:
    600px; display: block; margin: 0 auto; padding: 20px;border-radius: 10px;">
		<table class="main" width="100%" cellpadding="0" cellspacing="0" itemprop="action" itemscope itemtype="http://schema.org/ConfirmAction" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px;
      border-radius: 10px; background-color: #fff; margin: 0; border: 1px solid #e9e9e9; border-top: 10px solid #e84855;">
			
			<tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
				<td class="content-wrap" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size:
          18px;font-weight: 800; vertical-align: top; margin: 0; padding: 20px;color: #e84855;" valign="top"> Password Reset </td>
			</tr>
			<tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
				<td class="content-wrap" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 20px;" valign="top">
					<meta itemprop="name" content="Confirm Email" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;" />
					<table width="100%" cellpadding="0" cellspacing="0" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
						<tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
							<td class="content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top"> Hi, ${this._usersName} </td>
						</tr>
						<tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
							<td class="content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top"> Please copy the code and login to reset your password. </td>
						</tr>
						<tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
							<td class="content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top"> The OTP code below can be used to reset your password. Use it to login and you will be prompted to change your password. </td>
						</tr>
						<tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
							<td class="content-block" itemprop="handler" itemscope itemtype="http://schema.org/HttpActionHandler" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif;font-weight: 700;font-size: 16px;
                box-sizing: border-box; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top"> ${this._otp} </td>
						</tr>
						<tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
							<td class="content-block" itemprop="handler" itemscope itemtype="http://schema.org/HttpActionHandler" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top">
                
                <a href="${this._appLogin}" class="btn-primary" itemprop="url" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size:
                  14px; color: #FFF; text-decoration: none; line-height: 2em; font-weight: bold; text-align: center;
                  cursor: pointer; display: inline-block; border-radius: 5px; text-transform: capitalize;
                  background-color: #e84855; margin: 0; border-color: #e84855; border-style: solid; border-width: 10px
                  20px;">Change Password</a> </td>
						</tr>
						<tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
							<td class="content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top"> &mdash; AL Advantage LLC. </td>
						</tr>
						<tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin-top: 30px;">
							<td class="content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size:
                14px; vertical-align: top; margin: 0; padding: 0 0 10px; color: #999;font-style: italic;" valign="top"> Didn't request this code? Ignore this message. </td>
						</tr>
					</table>
				</td>
			</tr>
		</table>
		
		<div class="footer" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; width: 100%; clear: both; color: #999; margin: 0; padding: 20px;">
			<table width="100%" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
				<tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
					<td class="aligncenter content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 12px; vertical-align: top; color: #999; text-align: center; margin: 0; padding: 0 0 20px;"> Have questions? Visit us at <a target="_blank" rel="noreferrer noopener" href="https://www.aladvantage.com" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size:
                  12px; color: #348eda; text-decoration: underline; margin: 0;">AL
              Advantage LLC.</a> </td>
				</tr>
				<tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
					<td class="aligncenter content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 12px; vertical-align: top; color: #999; text-align: center; margin: 0; padding: 0 0 20px;"> Or email us at us at <a href="mailto:support@aladvantage.com" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size:
                  12px; color: #348eda; text-decoration: underline; margin: 0;">support@aladvantage.com</a> </td>
				</tr>
			</table>
		</div>
	</div>
</body>

</html>
    `;
	}
	getEmailTemplate() {
		return this._template;
	}
}

class TempPasswordTemplate {
	constructor(data = {}) {
		const { currentUser, emailContent } = data;

		this._pageTitle =
			emailContent?.pageTitle ?? `AL Advantage: Temporary Password`;
		this._usersName = `Welcome, ${
			currentUser?.firstName ?? "AL Advantage User"
		}`;
		this._usersEmail = currentUser?.email ?? currentUser?.username;
		// content
		this._appLogin = emailContent?.appLoginURL;
		this._otp = emailContent?.otp;
		this._template = `
    <!DOCTYPE html>
<html xmlns="http://www.w3.org/1999/xhtml" style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">

<head>
	<meta name="viewport" content="width=device-width" />
	<meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
	<title>Reset Password - OTP</title>
	<style type="text/css">
	img {
		max-width: 100%;
	}
	
	body {
		-webkit-font-smoothing: antialiased;
		-webkit-text-size-adjust: none;
		width: 100% !important;
		height: 100%;
		line-height: 1.6em;
	}
	
	body {
		background-color: #f6f6f6;
	}
	
	@media only screen and (max-width: 640px) {
		body {
			padding: 0 !important;
		}
		h1 {
			font-weight: 800 !important;
			margin: 20px 0 5px !important;
		}
		h2 {
			font-weight: 800 !important;
			margin: 20px 0 5px !important;
		}
		h3 {
			font-weight: 800 !important;
			margin: 20px 0 5px !important;
		}
		h4 {
			font-weight: 800 !important;
			margin: 20px 0 5px !important;
		}
		h1 {
			font-size: 22px !important;
		}
		h2 {
			font-size: 18px !important;
		}
		h3 {
			font-size: 16px !important;
		}
		.container {
			padding: 0 !important;
			width: 100% !important;
		}
		.content {
			padding: 0 !important;
		}
		.content-wrap {
			padding: 10px !important;
		}
		.invoice {
			width: 100% !important;
		}
	}
	</style>
</head>

<body itemscope itemtype="http://schema.org/EmailMessage" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; -webkit-font-smoothing: antialiased; -webkit-text-size-adjust: none; width: 100% !important; height: 100%; line-height: 1.6em; background-color: #f6f6f6; margin: 0;">
	<div class="content" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; max-width:
    600px; display: block; margin: 0 auto; padding: 20px;border-radius: 10px;">
		<table class="main" width="100%" cellpadding="0" cellspacing="0" itemprop="action" itemscope itemtype="http://schema.org/ConfirmAction" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px;
      border-radius: 10px; background-color: #fff; margin: 0; border: 1px solid #e9e9e9; border-top: 10px solid #e84855;">
			
			<tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
				<td class="content-wrap" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size:
          18px;font-weight: 800; vertical-align: top; margin: 0; padding: 20px;color: #e84855;" valign="top"> Temporary Password (OTP) </td>
			</tr>
			<tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
				<td class="content-wrap" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 20px;" valign="top">
					<meta itemprop="name" content="Confirm Email" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;" />
					<table width="100%" cellpadding="0" cellspacing="0" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
						<tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
							<td class="content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top"> Hi, ${this._usersName} </td>
						</tr>
						<tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
							<td class="content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top"> Please copy this temp password and login. You will be prompted to immediately change your password. </td>
						</tr>
						<tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
							<td class="content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top"> The OTP code below can be used to set your permanent password. Use it to login and you will be prompted to set a new password. </td>
						</tr>
						<tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
							<td class="content-block" itemprop="handler" itemscope itemtype="http://schema.org/HttpActionHandler" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif;font-weight: 700;font-size: 16px;
                box-sizing: border-box; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top"> ${this._otp} </td>
						</tr>
						<tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
							<td class="content-block" itemprop="handler" itemscope itemtype="http://schema.org/HttpActionHandler" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top">
                
                <a href="${this._appLogin}" class="btn-primary" itemprop="url" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size:
                  14px; color: #FFF; text-decoration: none; line-height: 2em; font-weight: bold; text-align: center;
                  cursor: pointer; display: inline-block; border-radius: 5px; text-transform: capitalize;
                  background-color: #e84855; margin: 0; border-color: #e84855; border-style: solid; border-width: 10px
                  20px;">Change Password</a> </td>
						</tr>
						<tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
							<td class="content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top"> &mdash; AL Advantage LLC. </td>
						</tr>
						<tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin-top: 30px;">
							<td class="content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size:
                14px; vertical-align: top; margin: 0; padding: 0 0 10px; color: #999;font-style: italic;" valign="top"> Didn't request this code? Ignore this message. </td>
						</tr>
					</table>
				</td>
			</tr>
		</table>
		
		<div class="footer" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; width: 100%; clear: both; color: #999; margin: 0; padding: 20px;">
			<table width="100%" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
				<tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
					<td class="aligncenter content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 12px; vertical-align: top; color: #999; text-align: center; margin: 0; padding: 0 0 20px;"> Have questions? Visit us at <a target="_blank" rel="noreferrer noopener" href="https://www.aladvantage.com" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size:
                  12px; color: #348eda; text-decoration: underline; margin: 0;">AL
              Advantage LLC.</a> </td>
				</tr>
				<tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
					<td class="aligncenter content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 12px; vertical-align: top; color: #999; text-align: center; margin: 0; padding: 0 0 20px;"> Or email us at us at <a href="mailto:support@aladvantage.com" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size:
                  12px; color: #348eda; text-decoration: underline; margin: 0;">support@aladvantage.com</a> </td>
				</tr>
			</table>
		</div>
	</div>
</body>

</html>
    `;
	}
	getEmailTemplate() {
		return this._template;
	}
}

class ConfirmEmailTemplate {
	constructor(data = {}) {
		const { currentUser, emailContent } = data;

		this._pageTitle = emailContent?.pageTitle ?? "Confirm Email";
		this._usersName = `${currentUser?.firstName}`;
		this._usersEmail = currentUser?.email ?? currentUser?.username;
		// content
		this._appLogin = emailContent?.appLoginURL;
		this._template = `
    <!DOCTYPE html>
<html xmlns="http://www.w3.org/1999/xhtml"
  style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">

<head>
  <meta name="viewport" content="width=device-width" />
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
  <title>Confirm Email Address</title>


  <style type="text/css">
    img {
      max-width: 100%;
    }

    body {
      -webkit-font-smoothing: antialiased;
      -webkit-text-size-adjust: none;
      width: 100% !important;
      height: 100%;
      line-height: 1.6em;
    }

    body {
      background-color: #f6f6f6;
    }

    @media only screen and (max-width: 640px) {
      body {
        padding: 0 !important;
      }

      h1 {
        font-weight: 800 !important;
        margin: 20px 0 5px !important;
      }

      h2 {
        font-weight: 800 !important;
        margin: 20px 0 5px !important;
      }

      h3 {
        font-weight: 800 !important;
        margin: 20px 0 5px !important;
      }

      h4 {
        font-weight: 800 !important;
        margin: 20px 0 5px !important;
      }

      h1 {
        font-size: 22px !important;
      }

      h2 {
        font-size: 18px !important;
      }

      h3 {
        font-size: 16px !important;
      }

      .container {
        padding: 0 !important;
        width: 100% !important;
      }

      .content {
        padding: 0 !important;
      }

      .content-wrap {
        padding: 10px !important;
      }

      .invoice {
        width: 100% !important;
      }
    }
  </style>
</head>

<body itemscope itemtype="http://schema.org/EmailMessage"
  style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; -webkit-font-smoothing: antialiased; -webkit-text-size-adjust: none; width: 100% !important; height: 100%; line-height: 1.6em; background-color: #f6f6f6; margin: 0;">
  <div class="content" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; max-width:
    600px; display: block; margin: 0 auto; padding: 20px;border-radius: 10px;">
    <table class="main" width="100%" cellpadding="0" cellspacing="0" itemprop="action" itemscope
      itemtype="http://schema.org/ConfirmAction"
      style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px;
      border-radius: 10px; background-color: #fff; margin: 0; border: 1px solid #e9e9e9; border-top: 10px solid #348eda;">

      <tr
        style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
        <td class="content-wrap" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size:
          18px;font-weight: 800; vertical-align: top; margin: 0; padding: 20px;color: #348eda;" valign="top">
          Confirm Email Address
        </td>
      </tr>


      <tr
        style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
        <td class="content-wrap"
          style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 20px;"
          valign="top">
          <meta itemprop="name" content="Confirm Email"
            style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;" />
          <table width="100%" cellpadding="0" cellspacing="0"
            style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
            <tr
              style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
              <td class="content-block"
                style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;"
                valign="top">
                Hi, ${this._usersName}
              </td>
            </tr>
            <tr
              style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
              <td class="content-block"
                style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;"
                valign="top">
                Please confirm your email address by clicking the button below.
              </td>
            </tr>
            <tr
              style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
              <td class="content-block"
                style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;"
                valign="top">
                We may need to send you critical information about our service and it is important that we have an
                accurate email address.
              </td>
            </tr>
            <tr
              style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
              <td class="content-block" itemprop="handler" itemscope itemtype="http://schema.org/HttpActionHandler"
                style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;"
                valign="top">
                <!-- CONFIRM EMAIL BUTTON -->
                <a href="https://apitest.aladvantage.com/alaservices/v1/VerifyEmail?=EMAIL-ADDRESS-GOES-HERE"
                  class="btn-primary" itemprop="url"
                  style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; color: #FFF; text-decoration: none; line-height: 2em; font-weight: bold; text-align: center; cursor: pointer; display: inline-block; border-radius: 5px; text-transform: capitalize; background-color: #348eda; margin: 0; border-color: #348eda; border-style: solid; border-width: 10px 20px;">Confirm
                  email address</a>
              </td>
            </tr>
            <tr
              style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
              <td class="content-block"
                style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;"
                valign="top">
                &mdash; AL Advantage LLC.
              </td>
            </tr>
            <tr
              style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin-top: 30px;">
              <td class="content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size:
                14px; vertical-align: top; margin: 0; padding: 0 0 10px; color: #999;font-style: italic;" valign="top">
                Already confirmed your email address? Ignore this message.
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <!-- FOOTER -->
    <div class="footer"
      style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; width: 100%; clear: both; color: #999; margin: 0; padding: 20px;">
      <table width="100%"
        style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
        <tr
          style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
          <td class="aligncenter content-block"
            style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 12px; vertical-align: top; color: #999; text-align: center; margin: 0; padding: 0 0 20px;">

            Have questions? Visit
            us at <a target="_blank" rel="noreferrer noopener" href="https://www.aladvantage.com" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size:
                    12px; color: #348eda; text-decoration: underline; margin: 0;">AL
              Advantage LLC.</a>
          </td>
        </tr>
        <tr
          style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
          <td class="aligncenter content-block"
            style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 12px; vertical-align: top; color: #999; text-align: center; margin: 0; padding: 0 0 20px;">
            Or email us at
            us at
            <a href="mailto:support@aladvantage.com"
              style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 12px; color: #348eda; text-decoration: underline; margin: 0;">support@aladvantage.com</a>
          </td>

        </tr>
      </table>
    </div>
  </div>
</body>

</html>
    `;
	}
	getEmailTemplate() {
		return this._template;
	}
}

// EMAIL TEMPLATE UTILS //

const generateFeedbackEmail = (feedbackType = "Issue", data = {}) => {
	switch (feedbackType) {
		case "Issue": {
			const issueTemplate = new FeedbackIssueTemplate(data);
			return issueTemplate;
		}
		case "Idea": {
			const ideaTemplate = new FeedbackIdeaTemplate(data);
			return ideaTemplate;
		}
		case "Other": {
			const otherTemplate = new FeedbackOtherTemplate(data);
			return otherTemplate;
		}
		default:
			throw new Error(`❌ Ooops! Not a valid feedback 'type':`, feedbackType);
	}
};
const generateResetEmail = (data = {}) => {
	const resetTemplate = new ResetPasswordTemplate(data);
	const template = resetTemplate.getEmailTemplate();
	return template;
};
const generateConfirmEmail = (data = {}) => {
	const confirmTemplate = new ConfirmEmailTemplate(data);
	return confirmTemplate;
};
const generateTempPasswordEmail = (data = {}) => {
	const pwdModel = new TempPasswordTemplate(data);
	const pwdTemplae = pwdModel.getEmailTemplate();
	return pwdTemplae;
};

// SWITCH TO DETERMINE EMAIL TYPE //
const determineEmailType = (vals = {}) => {
	const { otp, feedbackType } = vals;
	if (!isEmptyVal(otp)) {
		return `Reset_Password`;
	} else if (!isEmptyVal(feedbackType)) {
		return `Feedback_${feedbackType}`;
	} else {
		return `Confirm_Email`;
	}
};

/**
 * Determines what 'type' of email to send and generates the Html template w/ the populated dynamic content.
 * @param {Object} currentUser - Current user object
 * @param {Object} currentFacility - Current facility object.
 * @param {Object} vals - Object of user values, including email content and more.
 * @returns {HTMLDocument} - Returns a populated, styled & prepared HTMLDocument, ready to send via email.
 */
const generateHtmlEmail = (
	currentUser = {},
	currentFacility = {},
	vals = {}
) => {
	const type = determineEmailType(vals);
	// merged email content/data
	const content = {
		currentUser,
		currentFacility,
		emailContent: { ...vals },
	};

	switch (type) {
		case `Reset_Password`: {
			const emailModel = generateResetEmail(content);
			return emailModel;
		}
		case `Confirm_Email`: {
			const emailModel = generateConfirmEmail(content);
			return emailModel;
		}
		case `Feedback_Issue`: {
			const emailModel = generateFeedbackEmail("Issue", content);
			return emailModel;
		}
		case `Feedback_Idea`: {
			const emailModel = generateFeedbackEmail("Idea", content);
			return emailModel;
		}
		case `Feedback_Other`: {
			const emailModel = generateFeedbackEmail("Other", content);
			return emailModel;
		}
		default:
			throw new Error(`❌ Ooops! Invalid email type:`, type);
	}
};

// feedback templates
export { FeedbackIssueTemplate, FeedbackIdeaTemplate, FeedbackOtherTemplate };
// email & password templates
export { ResetPasswordTemplate, ConfirmEmailTemplate, TempPasswordTemplate };

export {
	generateConfirmEmail,
	generateFeedbackEmail,
	generateResetEmail,
	generateTempPasswordEmail,
};

export { generateHtmlEmail };
