import React from "react";
import styles from "../../css/forms/SkipButton.module.scss";
import { PropTypes } from "prop-types";

const SkipButton = ({ children, handleSkip, isDisabled = false }) => {
	return (
		<button
			type="button"
			onClick={handleSkip}
			className={styles.SkipButton}
			disabled={isDisabled}
		>
			{children}
		</button>
	);
};

export default SkipButton;

SkipButton.defaultProps = {};

SkipButton.propTypes = {};
