import { templates } from "./utils_endpoints";
import { currentEnv } from "./utils_env";
import {
	getFacilityLevelsOfCare,
	processLOC,
	validateAllLOC,
	validateLOC,
} from "./utils_loc";
import { groupBy } from "./utils_processing";
import { isEmptyVal } from "./utils_types";

// LOC TEMPLATE REQUESTS //

/**
 * Creates a NEW LevelsOfCare template.
 * @param {String} token - Auth toen
 * @param {Object} template - An object containing all new template values & levels.
 * @param {String} template.facilityID - Template's target guid facility
 * @param {String} template.templateName - Template's 'name' field.
 * @param {String} template.templateDesc - Template's 'desc' field.
 * @param {Boolean} template.isDefault - Set whether this template isDefault
 * @param {Object[]} template.templateLevels - Array of template level objects.
 * @returns {Object} - Returns object w/ response status & validation errors, if applicable.
 */
const createLevelsOfCareTemplate = async (token, template = {}) => {
	const {
		facilityID,
		templateName,
		templateDesc,
		isDefault = false,
		templateLevels,
	} = template;
	let url = currentEnv.base + templates.create.newTemplate;
	url += "?" + new URLSearchParams({ facilityId: facilityID });
	url += "&" + new URLSearchParams({ name: templateName });
	url += "&" + new URLSearchParams({ description: templateDesc });
	url += "&" + new URLSearchParams({ isDefault: isDefault });

	// return;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(templateLevels),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Fetches a facility's LOC templates.
 * @param {String} token - Auth token
 * @param {String} facilityID - Facility string guid
 * @returns {Object[]} - Returns an array of facility template objects.
 */
const getFacilityLOCTemplates = async (token, facilityID) => {
	let url = currentEnv.base + templates.get.facilityTemplates;
	url += "?" + new URLSearchParams({ facilityId: facilityID });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// fetches LOC template names ONLY
const getFacilityLOCTemplatesList = async (token, facilityID) => {
	let url = currentEnv.base + templates.get.facilityTemplateNames;
	url += "?" + new URLSearchParams({ facilityId: facilityID });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
// fetches LOC template names ONLY
const getFacilityLOCTemplatesAndLists = async (token, facilityID) => {
	let url = currentEnv.base + templates.get.facilityTemplates;
	url += "?" + new URLSearchParams({ facilityId: facilityID });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Updates an existing LevelsOfCare template.
 * @param {String} token - Auth toen
 * @param {Object} template - An object containing all new template values & levels.
 * @param {String} template.templateID - Target template's 'templateID'.
 * @param {String} template.facilityID - Template's target guid facility
 * @param {String} template.templateName - Template's 'name' field.
 * @param {String} template.templateDesc - Template's 'desc' field.
 * @param {Boolean} template.isDefault - Set whether this template isDefault
 * @param {Object[]} template.templateLevels - Array of template level objects.
 * @returns {Object} - Returns object w/ response status & validation errors, if applicable.
 */
const updateFacilityLOCTemplate = async (token, template = {}) => {
	const {
		templateID: templateId,
		templateName: name,
		templateDesc: description,
		isDefault,
		templateLevels,
	} = template;

	let url = currentEnv.base + templates.update.facilityTemplate;
	url += "?" + new URLSearchParams({ templateId });
	url += "&" + new URLSearchParams({ name });
	url += "&" + new URLSearchParams({ description });
	url += "&" + new URLSearchParams({ isDefault });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(templateLevels),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Saves changes to an existing LevelsOfCare template.
 * @param {String} token - Auth toen
 * @param {Object} template - An object containing all new template values & levels.
 * @param {String} template.templateID - Target template's 'templateID'.
 * @param {String} template.facilityID - Template's target guid facility
 * @param {String} template.templateName - Template's 'name' field.
 * @param {String} template.templateDesc - Template's 'desc' field.
 * @param {Boolean} template.isDefault - Set whether this template isDefault
 * @param {Object[]} template.templateLevels - Array of template level objects.
 * @returns {Object} - Returns object w/ response status & validation errors, if applicable.
 */
const saveFacilityLOCTemplate = async (token, template = {}) => {
	const {
		templateID: templateId,
		templateName: name,
		templateDesc: description,
		isDefault,
		templateLevels,
	} = template;

	let url = currentEnv.base + templates.save.facilityTemplate;
	url += "?" + new URLSearchParams({ templateId });
	url += "&" + new URLSearchParams({ name });
	url += "&" + new URLSearchParams({ description });
	url += "&" + new URLSearchParams({ isDefault });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(templateLevels),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Deletes a facility's LOC template by 'templateID'
 * @param {String} token - Auth token.
 * @param {Number} templateId - Numeric template id.
 * @returns {Boolean}  - Returns whether deletion was successful.
 */
const deleteFacilityLOCTemplate = async (token, templateId) => {
	let url = currentEnv.base + templates.delete.facilityTemplate;
	url += "?" + new URLSearchParams({ templateId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Sets the levels of care for a given facility by template id.
 * @param {String} token - Auth token
 * @param {String} facilityId - Facility string guid
 * @param {Number} templateId - Template number id.
 * @returns {Boolean} - Returns status
 */
const saveFacilityLOCByTemplate = async (token, facilityId, templateId) => {
	let url = currentEnv.base + templates.saveBy.facilityTemplate;
	url += "?" + new URLSearchParams({ facilityId, templateId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// FETCHING UTILS //

/**
 * Fetches a facility's Levels of Care AND their LOC templates.
 * @param {String} token - Auth token
 * @param {String} facilityID - Facility guid string
 * @returns {Object} - Returns object w/ templates & care levels.
 */
const getLevelsAndTemplates = async (token, facilityID) => {
	const [rawLevels, rawTemplates] = await Promise.all([
		getFacilityLevelsOfCare(token, facilityID),
		getFacilityLOCTemplates(token, facilityID),
	]);

	const facilityLevels = processLOC(rawLevels?.CareLevels ?? []);
	const facilityTemplates = processFacilityTemplates(rawTemplates);

	return {
		facilityLevels: {
			...rawLevels,
			levels: { ...facilityLevels },
		},
		facilityTemplates: { ...facilityTemplates },
	};
};

// LOC TEMPLATE UTILS //

/**
 * Extracts ONLY the template names from an array of existing LOC templates.
 * @param {Object[]} templates - Array of template objects.
 * @returns {String[]} - Array of string template names.
 */
const getLOCTemplateNames = (templates = []) => {
	const names = templates.map(({ TemplateName }) => TemplateName);

	return names;
};

/**
 * Finds matching template by template name.
 * @param {String} selection - String template name selection.
 * @param {Object[]} templates - Array of template objects.
 * @returns {Object} - Returns matching template object
 */
const matchLOCTemplateByName = (selection, templates = []) => {
	return templates.reduce((match, cur) => {
		if (cur?.templateName === selection) {
			match = { ...cur };
			return match;
		}
		return match;
	}, {});
};

// TEMPLATE CREATOR UTILS //

/**
 * Injects 'LOCType' & 'FacilityID' into each loc object.
 * @param {String} facilityID - Facility string guid
 * @param {Object[]} newLevels - An array of care level objects.
 * @returns {Object[]} - REturns levels w/ included facilityID and loc type.
 */
const prepareLOCTemplateLevels = (facilityID, newLevels = []) => {
	const type = "Custom";
	return newLevels.map((level, idx) => {
		const newLevel = {
			...level,
			LOCType: type,
			FacilityID: isEmptyVal(level?.FacilityID) ? facilityID : level.FacilityID,
			LevelNumber: idx + 1, // TO FIX LEVELNUMBER BUG //
			// LevelNumber: level?.LevelNunber===
		};
		return newLevel;
	});
};
/**
 * Injects 'FacilityID' & 'LOCType' into all levels, then returns a single array of LOC levels.
 * @param {String} facilityID - Facility guid string
 * @param {Object} template - An object of care levels' arrays
 * @returns {Object[]} - Returns array of formatted care levels
 */
const prepareNewLOCTemplate = (facilityID, template = {}) => {
	const rawIND = template["Independent"];
	const rawAL = template["Assisted Living"];
	const rawMC = template["Memory Care"];
	const rawPC = template["Personal Care"];
	// inject 'LOCType' & 'FacilityID'
	const levelIND = prepareLOCTemplateLevels(facilityID, rawIND);
	const levelAL = prepareLOCTemplateLevels(facilityID, rawAL);
	const levelMC = prepareLOCTemplateLevels(facilityID, rawMC);
	const levelPC = prepareLOCTemplateLevels(facilityID, rawPC);
	// merge all levels into one array
	const allLevels = [...levelIND, ...levelAL, ...levelMC, ...levelPC];

	return allLevels;
};

// TEMPLATE HEADER/STRUCTURE PROCESSING UTILS //

/**
 * Formats a template header/structure data object for the client.
 * @param {Object} facilityTemplate - An object containing headers & structures
 * @returns {Object} - Returns client-formatted object w/ levels & template info.
 */
const processFacilityTemplate = (facilityTemplate = {}) => {
	const { Template: templateInfo, TemplateStructures: templateLevels } =
		facilityTemplate;
	const {
		FacilityID: facilityID,
		Name: templateName,
		Description: templateDesc,
		TemplateID: templateID,
		TemplateCategoryTypeID: categoryID,
		TemplateTypeID: typeID,
		IsActive: isActive,
		IsDefault: isDefault,
		IsValidated: isValid,
	} = templateInfo;

	const clientTemplate = {
		facilityID,
		templateName,
		templateDesc,
		templateID,
		categoryID,
		typeID,
		isActive,
		isDefault,
		isValid,
	};
	const byUnitType = groupBy(templateLevels, (x) => x.FloorUnit);

	// probably need to group/sort the 'templateLevels' by floor unit first

	return {
		...clientTemplate,
		template: {
			...byUnitType,
		},
	};
};

const processFacilityTemplates = (templateData = []) => {
	const facilityTemplates = templateData.reduce(
		(namesAndTemplates, template) => {
			const formatted = processFacilityTemplate(template);

			namesAndTemplates = {
				names: [...namesAndTemplates.names, formatted?.templateName],
				templates: [...namesAndTemplates.templates, formatted],
			};

			return namesAndTemplates;
		},
		{ names: [], templates: [] }
	);

	return facilityTemplates;
};

// REGEX: ALLOWED CELL CHARS IN LOC TEMPLATE TABLES //

const allowedCharsByCell = {
	LevelDescription: {
		allowed: /.*/gim,
		mode: "text",
	},
	LevelNumber: {
		allowed: /.*/gim,
		mode: "numeric",
	},
	PointsMin: {
		allowed: /^\d+$/gim,
		mode: "numeric",
	},
	PointsMax: {
		allowed: /^\d+$/gim,
		mode: "numeric",
	},
	Pricing: {
		// allowed: /[\d+\.]/gim,
		allowed: /^\d*\.?\d*$/gim,
		mode: "decimal",
	},
};

// TEMPLATE VALIDATOR UTILS //

// checks that ALL floor unit's validation is ok
const checkAllFloorUnitsValidation = (validation = {}) => {
	const units = Object.keys(validation);
	const results = units.every((unitKey) => {
		const unitResults = validation[unitKey];
		const state = checkUnitsValidation(unitResults);

		return state;
	});

	return results;
};

// checks that a single floor unit's validation is ok
const checkUnitsValidation = (unitValidation = {}) => {
	const { hasGap, hasOverlap, isBaseValid, isLastValid } = unitValidation;
	const noGapsOrOverlaps = !hasGap && !hasOverlap;
	const firstAndLast = isBaseValid && isLastValid;

	return noGapsOrOverlaps && firstAndLast;
};

/**
 * Validates ALL template fields & care levels & returns a boolean, valid/invalid
 * @param {Object} vals - Template field values object
 * @param {Object} validation - Floor units-separated validation object
 * @returns {Boolean} - Returns whether template is valid.
 */
const validateLOCTemplate = (vals = {}, validation = {}) => {
	const { templateName, templateDesc } = vals;
	const hasInfo = !isEmptyVal(templateName) && !isEmptyVal(templateDesc);
	const hasValidLevels = checkAllFloorUnitsValidation(validation);

	const isValidTemplate = hasInfo && hasValidLevels;

	return isValidTemplate;
};

export {
	createLevelsOfCareTemplate,
	getFacilityLOCTemplates,
	getFacilityLOCTemplatesAndLists,
	getFacilityLOCTemplatesList,
	updateFacilityLOCTemplate,
	saveFacilityLOCTemplate,
	saveFacilityLOCByTemplate,
	deleteFacilityLOCTemplate,
	// other request utils
	getLevelsAndTemplates,
};

export { getLOCTemplateNames, matchLOCTemplateByName };

export { prepareLOCTemplateLevels, prepareNewLOCTemplate };

export { allowedCharsByCell };

export { processFacilityTemplates };

// validator utils
export { validateLOCTemplate };
