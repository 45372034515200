import React from "react";
import styles from "../../css/app/SidebarHelp.module.scss";
import sprite from "../../assets/icons/settings.svg";
import { PropTypes } from "prop-types";

const activeStyles = {
	fill: "#ffffff",
	color: "#ffffff",
};

const SidebarHelp = ({
	path = null,
	name,
	icon,
	history,
	isActive = false,
	isCollapsed = false,
	openHelpWidget,
}) => {
	if (isCollapsed) {
		return (
			<li className={styles.CollapsedSidebarHelp}>
				<div
					className={styles.CollapsedSidebarHelp_btn}
					onClick={openHelpWidget}
				>
					<svg
						className={styles.CollapsedSidebarHelp_btn_icon}
						style={isActive ? activeStyles : null}
					>
						<use xlinkHref={`${sprite}#icon-${icon}`}></use>
					</svg>
				</div>
			</li>
		);
	}
	return (
		<li className={styles.SidebarHelp}>
			<div className={styles.SidebarHelp_btn} onClick={openHelpWidget}>
				<svg
					className={styles.SidebarHelp_btn_icon}
					style={isActive ? activeStyles : null}
				>
					<use xlinkHref={`${sprite}#icon-${icon}`}></use>
				</svg>
				<span
					className={styles.SidebarHelp_btn_text}
					style={isActive ? activeStyles : null}
				>
					{name}
				</span>
			</div>
		</li>
	);
};

export default SidebarHelp;

SidebarHelp.defaultProps = {
	path: null,
	isActive: false,
	isCollapsed: false,
};

SidebarHelp.propTypes = {
	name: PropTypes.string,
	icon: PropTypes.string,
	history: PropTypes.object,
	isActive: PropTypes.bool,
	isCollapsed: PropTypes.bool,
	openHelpWidget: PropTypes.func,
};
