import React, { useRef, useState } from "react";
import styles from "../../css/shared/PhoneInput.module.scss";
import sprite from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";
// utils
import { isEmptyVal } from "../../helpers/utils_types";

const formatPhone = (val) => {
	if (!val) return val;
	// sanitize non-numbers
	const cleanVal = val.replace(/[^\d]/g, "");
	// get length
	const length = val.length;
	// return unaltered if less than 4 chars
	if (length < 4) {
		return cleanVal;
	}
	// if less than 7 format
	if (length < 7) {
		return `(${cleanVal.slice(0, 3)}) ${cleanVal.slice(3)}`;
	}
	return `(${cleanVal.slice(0, 3)}) ${cleanVal.slice(3, 6)}-${cleanVal.slice(
		6,
		10
	)}`;
};

const PhoneInput = ({
	label,
	name,
	id,
	val,
	handlePhone,
	placeholder = "Numbers only...",
	autoComplete = "off",
	isDisabled = false,
	readOnly = false,
	required = false,
	inputMode = "tel",
	customStyles = {},
}) => {
	const inputRef = useRef();
	const [formatted, setFormatted] = useState(val);

	const handleChange = (e) => {
		// only pass 'name' & 'value' to 'handlePhone'
		const { name, value } = e.target;
		const newVal = formatPhone(value);
		setFormatted(newVal);
		if (!handlePhone) return;
		handlePhone(name, newVal);
	};

	const clearInput = () => {
		setFormatted("");
		inputRef.current.focus();
	};

	return (
		<div className={styles.PhoneInput}>
			<label htmlFor={id} className={styles.PhoneInput_label}>
				{label}
			</label>
			<div className={styles.PhoneInput_wrapper}>
				<input
					type="text"
					name={name}
					id={id}
					value={formatted}
					onChange={(e) => handleChange(e)}
					className={styles.PhoneInput_wrapper_input}
					ref={inputRef}
					placeholder={
						isDisabled || readOnly ? "Read only input..." : placeholder
					}
					autoComplete={autoComplete}
					readOnly={readOnly}
					disabled={isDisabled}
					required={required}
					style={customStyles}
					inputMode={inputMode}
				/>
				{!isEmptyVal(formatted) && (
					<div
						className={styles.PhoneInput_wrapper_close}
						onClick={isDisabled || readOnly ? null : clearInput}
					>
						<svg className={styles.PhoneInput_wrapper_close_icon}>
							<use xlinkHref={`${sprite}#icon-clearclose`}></use>
						</svg>
					</div>
				)}
			</div>
		</div>
	);
};

export default PhoneInput;

PhoneInput.defaultProps = {};

PhoneInput.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	id: PropTypes.string,
	val: PropTypes.string,
	handlePhone: PropTypes.func,
};
