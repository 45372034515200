import React, { useReducer, useState, useRef, useEffect } from "react";
import styles from "../../css/loc/LOCTable.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import {
	sortAlphaDescByKey,
	sortAlphaAscByKey,
	sortNumAscByKey,
	sortNumDescByKey,
} from "../../helpers/utils_processing";
import { green } from "../../helpers/utils_styles";
// components
import LOCTableColumns from "./LOCTableColumns";
import LOCTableBody from "./LOCTableBody";
import LOCTableRow from "./LOCTableRow";
import LOCTableFooter from "./LOCTableFooter";
import LOCHelpButton from "./LOCHelpButton";
import LOCInfoModal from "./LOCInfoModal";

const advancedSort = (sortType, state) => {
	switch (sortType) {
		case "Level Name": {
			const { isSorted, data: rows } = state;

			if (!isSorted) {
				const sortedDESC = sortAlphaDescByKey("Level Name", [...rows]);

				return {
					...state,
					data: sortedDESC,
					isSorted: !isSorted,
				};
			} else {
				const sortedASC = sortAlphaAscByKey("Level Name", [...rows]);
				return {
					...state,
					data: sortedASC,
					isSorted: !isSorted,
				};
			}
		}
		case "Level": {
			const { isSorted, data: rows } = state;

			if (!isSorted) {
				const sortedDESC = sortNumDescByKey("Level", [...rows]);

				return {
					...state,
					data: [...sortedDESC],
					isSorted: true,
				};
			} else {
				const sortedASC = sortNumAscByKey("Level", [...rows]);

				return {
					...state,
					data: [...sortedASC],
					isSorted: false,
				};
			}
		}
		case "Starting Points": {
			const { isSorted, data: rows } = state;

			if (!isSorted) {
				const sortedDESC = sortNumDescByKey("Starting Points", [...rows]);

				return {
					...state,
					data: [...sortedDESC],
					isSorted: true,
				};
			} else {
				const sortedASC = sortNumAscByKey("Starting Points", [...rows]);
				return {
					...state,
					data: [...sortedASC],
					isSorted: false,
				};
			}
		}
		case "Ending Points": {
			const { isSorted, data: rows } = state;

			if (!isSorted) {
				const sortedDESC = sortNumDescByKey("Ending Points", [...rows]);

				return {
					...state,
					data: [...sortedDESC],
					isSorted: true,
				};
			} else {
				const sortedASC = sortNumAscByKey("Ending Points", [...rows]);
				return {
					...state,
					data: [...sortedASC],
					isSorted: false,
				};
			}
		}
		default: {
			const { data: rows } = state;
			const sortedASC = sortNumDescByKey("Level", [...rows]);
			return {
				...state,
				data: [...sortedASC],
				isSorted: false,
			};
		}
	}
};

const getTempRowValues = (rows = []) => {
	if (isEmptyArray(rows)) {
		return {
			ID: 0,
			LocType: "Custom",
			FloorUnit: null,
			FacilityID: null,
			LevelDescription: "Change Level Description",
			LevelNumber: 0,
			PointsMin: 0,
			PointsMax: 99999,
			Pricing: Number("0.00").toFixed(2),
		};
	}

	const lastRow = rows[rows?.length - 1];
	const { FacilityID, FloorUnit, LOCType, LevelNumber } = lastRow;

	const level = !isEmptyVal(LevelNumber) ? +LevelNumber + 1 : rows.length;
	const levelMin = lastRow["PointsMax"] + 1;
	const levelMax = lastRow["PointsMax"] + 50;
	const price = Number("0.00").toFixed(2);

	return {
		ID: 0,
		LOCType: LOCType,
		FloorUnit: FloorUnit,
		FacilityID: FacilityID,
		LevelDescription: "Change Level Description",
		LevelNumber: level,
		PointsMin: levelMin,
		PointsMax: levelMax,
		Pricing: price,
	};
};

const defaultSchema = {
	cols: [],
	data: [],
	hasMaxLevels: false,
	isSorted: false,
};

const tableReducer = (state, action) => {
	switch (action.type) {
		case "SORT": {
			const { sortType } = action.data;
			const sortedState = advancedSort(sortType, { ...state });

			return {
				...state,
				data: [...sortedState.data],
				isSorted: sortedState?.isSorted,
			};
		}
		case "ROW_UPDATE": {
			// ##TODOS:
			// - Prevent empty/blank values for 'Level Name' cells
			// 		- Do so via column name or "cellIdx % 4 === 0"
			const { newTableData } = action.data;
			// const { rowIdx, newRowData } = action.data;
			// const { data } = state;
			// updates a single row's data, in place
			// data.splice(rowIdx, 1, newRowData);

			return { ...state, data: [...newTableData] };
		}
		case "ADD_ROW": {
			// Inserts a new row: data.splice(targetIdx, 0, newItem)
			const { newRowData } = action.data;
			const { data = [] } = state;

			const newRows = [...data, newRowData];

			return {
				...state,
				data: newRows,
				hasMaxLevels: newRows?.length >= 10,
			};
		}
		case "REMOVE_ROW": {
			const { newRows } = action.data;

			return {
				...state,
				data: newRows,
			};
		}
		case "RESET": {
			const { cols, data } = action.data;
			return {
				cols: cols,
				data: data,
				hasMaxLevels: false,
				isSorted: false,
			};
		}
		default:
			return { ...state };
	}
};

const LOCHeader = ({ title, subtitle, openHelpModal }) => {
	return (
		<div className={styles.LOCHeader}>
			<div className={styles.LOCHeader_titleBar}>
				<div className={styles.LOCHeader_titleBar_title}>{title}</div>
				<div className={styles.LOCHeader_titleBar_subtitle}>{subtitle}</div>
			</div>
			<div className={styles.LOCHeader_help}>
				<LOCHelpButton handleHelp={openHelpModal} />
			</div>
		</div>
	);
};

const LOCTable = ({
	title,
	subtitle,
	schema = {},
	syncTableData,
	btnText = "Save Changes",
	isEditable = true,
	handleTableReset,
}) => {
	// unaltered LOC, doesn't record changes
	const [tableState, dispatchAction] = useReducer(tableReducer, {
		...schema,
		isSorted: false,
		hasMaxLevels: schema?.data?.length >= 10,
	});
	const [hasChanges, setHasChanges] = useState(false);
	const [newTempRow, setNewTempRow] = useState({
		...getTempRowValues(tableState.data),
	});
	const [showHelpModal, setShowHelpModal] = useState(false);

	// currently disabled*
	const handleColumnSort = (col) => {
		dispatchAction({
			type: "SORT",
			data: {
				sortType: col,
			},
		});
	};

	// adds new row, w/ default values calculated from the last row
	const handleAddRow = (e) => {
		dispatchAction({
			type: "ADD_ROW",
			data: {
				newRowData: newTempRow,
			},
		});
		// only used for generating next temp row fields
		const { data = [] } = tableState;
		const tempRows = [...data, newTempRow];
		setNewTempRow({ ...getTempRowValues(tempRows) });
		setHasChanges(true);
	};

	const handleDeleteRow = (rowIdx) => {
		const { data } = tableState;
		const temp = [...data];
		const newRows = temp.filter((item, idx) => idx !== rowIdx);

		dispatchAction({
			type: "REMOVE_ROW",
			data: {
				rowIdx: rowIdx,
				newRows: newRows,
			},
		});
		setHasChanges(true);
		setNewTempRow({ ...getTempRowValues(newRows) });
		syncTableData(title, {
			...tableState,
			data: [...newRows],
		});
	};

	const handleReset = (e) => {
		setHasChanges(false);
		// setNewTempRow({ ...getTempRowValues(schema.data) });

		handleTableReset(title);
	};

	const updateTableData = (rowIdx, newCellData = {}) => {
		const currentRow = { ...tableState.data[rowIdx] };
		const newRowData = {
			...currentRow,
			...newCellData,
		};

		// updates tableData to pass to parent
		// added sync state updates are batched and the 'dispatchAction'...
		// ...wasn't resolving before calling 'syncTableData' so the data was stale
		tableState.data.splice(rowIdx, 1, newRowData);

		dispatchAction({
			type: "ROW_UPDATE",
			data: {
				newTableData: tableState?.data,
			},
		});

		// dispatchAction({
		// 	type: "ROW_UPDATE",
		// 	data: {
		// 		newRowData: newRowData,
		// 		rowIdx: rowIdx,
		// 	},
		// });
		setHasChanges(true);
		syncTableData(title, tableState);
	};

	return (
		<>
			<div className={styles.LOCTable}>
				<div className={styles.LOCTable_wrapper}>
					<LOCHeader
						title={title}
						subtitle={subtitle}
						openHelpModal={() => setShowHelpModal(true)}
					/>
					<div className={styles.LOCTable_cols}>
						<LOCTableColumns
							cols={tableState.cols}
							handleColumnSort={handleColumnSort}
						/>
					</div>
					<div className={styles.LOCTable_body}>
						<LOCTableBody
							key={`TABLE-BODY-${tableState?.data?.length}-${tableState?.isSorted}`}
							isEditable={isEditable}
						>
							{!isEmptyArray(tableState.data) &&
								tableState.data.map((rowData, rowIdx) => (
									<LOCTableRow
										key={`Row-${rowIdx}-${hasChanges}`}
										isEditable={isEditable}
										rowIdx={rowIdx}
										rowData={rowData}
										isDeleteDisabled={
											tableState?.data?.length === 1 || rowIdx === 0
										}
										updateTableData={updateTableData}
										handleDeleteRow={() => handleDeleteRow(rowIdx)}
									/>
								))}
						</LOCTableBody>
					</div>
					<div className={styles.LOCTable_footer}>
						<LOCTableFooter
							isEditable={isEditable}
							handleAddRow={handleAddRow}
							handleReset={handleReset}
							hasChanges={hasChanges}
							hasMaxLevels={tableState?.hasMaxLevels}
						/>
					</div>
				</div>
			</div>

			{showHelpModal && (
				<LOCInfoModal closeModal={() => setShowHelpModal(false)} />
			)}
		</>
	);
};

export default LOCTable;

LOCTable.defaultProps = {
	schema: {
		cols: ["Level Name", "Level", "Starting Points", "Ending Points"],
		data: [],
		hasMaxLevels: false,
		isSorted: false,
	},
};

LOCTable.propTypes = {
	schema: PropTypes.shape({
		cols: PropTypes.array,
		data: PropTypes.arrayOf(PropTypes.object),
		hasMaxLevels: PropTypes.bool,
		isSorted: PropTypes.bool,
	}),
	children: PropTypes.any,
	rest: PropTypes.any,
};
