import React from "react";
import styles from "../../css/facility/FacilityAccessColumns.module.scss";
import sprite from "../../assets/icons/settings.svg";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";

// const columns = [`SeniorCareEHR`, `CareTracker`, `EMAR`];
const columns = [`SeniorCareEHR`, `CareTracker`, `EMAR`, `ePay`];

const addPadding = (idx, appList) => {
	if (idx - 1 === appList.length) {
		return {
			marginLeft: ".1rem",
		};
	} else {
		return {};
	}
};

const FacilityAccessColumns = ({ handleFacilitySorting, handleAppSorting }) => {
	return (
		<div className={styles.FacilityAccessColumns}>
			<div className={styles.FacilityAccessColumns_inner}>
				<div
					className={styles.FacilityAccessColumns_inner_residentCol}
					onClick={handleFacilitySorting}
				>
					<div className={styles.FacilityAccessColumns_inner_residentCol_name}>
						Facility
					</div>
					<svg className={styles.FacilityAccessColumns_inner_residentCol_icon}>
						<use xlinkHref={`${sprite}#icon-filter_list`}></use>
					</svg>
				</div>
				{!isEmptyArray(columns) &&
					[...columns].map((app, idx) => {
						return (
							<div
								className={styles.FacilityAccessColumns_inner_item}
								key={app + idx}
								style={addPadding(idx, columns)}
								onClick={() => handleAppSorting(app)}
							>
								<span>{app}</span>
								<svg className={styles.FacilityAccessColumns_inner_item_icon}>
									<use xlinkHref={`${sprite}#icon-filter_list`}></use>
								</svg>
							</div>
						);
					})}

				<div className={styles.FacilityAccessColumns_inner_deactivate}>
					<div className={styles.FacilityAccessColumns_inner_deactivate_btn}>
						Deactivate
					</div>
				</div>
			</div>
		</div>
	);
};

export default FacilityAccessColumns;

FacilityAccessColumns.defaultProps = {
	availableApps: [],
};

FacilityAccessColumns.propTypes = {
	availableApps: PropTypes.arrayOf(PropTypes.object).isRequired,
	handleFacilitySorting: PropTypes.func.isRequired,
};
