import React, { useState, useEffect, useRef } from "react";
import styles from "../../css/facility/FacilityAccessRow.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";
import { featureFlags, isAccessEnabled } from "../../helpers/utils_permissions";
import {
	suspendFacilityAccount,
	unsuspendFacilityAccount,
} from "../../helpers/utils_suspend";
import { getFacilityType } from "../../helpers/utils_facility";
// components
import ModalFull from "../shared/ModalFull";
import FacilityDetails from "./FacilityDetails";
import FacilityBadge from "./FacilityBadge";
import EnabledBadge from "./EnabledBadge";
import DisabledBadge from "./DisabledBadge";

const getAddress = (facility = {}) => {
	const {
		address: { street, city, state },
	} = facility;
	if (isEmptyVal(street) || isEmptyVal(city)) return state;
	return `${city}, ${state}`;
};
// Indy: PINK
// Parent: PURPLE
// Child: TEAL
const FacilityTypeBadge = ({ type }) => {
	const renderBadge = (type) => {
		switch (type) {
			case "INDEPENDENT": {
				return (
					<div className={styles.IndyBadge} title={`Independent Community`}>
						<div className={styles.IndyBadge_label}>I</div>
					</div>
				);
			}
			case "PARENT": {
				return (
					<div className={styles.ParentBadge} title={`Parent Community`}>
						<div className={styles.ParentBadge_label}>P</div>
					</div>
				);
			}
			case "CHILD": {
				return (
					<div className={styles.ChildBadge} title={`Child Community`}>
						<div className={styles.ChildBadge_label}>C</div>
					</div>
				);
			}

			default:
				break;
		}
	};

	return <>{renderBadge(type)}</>;
};

const DeactivateButton = ({
	isDisabled = false,
	isDeactivated = false,
	initDeactivate,
}) => {
	return (
		<button
			type="button"
			className={styles.DeactivateButton}
			onClick={initDeactivate}
			disabled={isDisabled}
		>
			Deactivate
		</button>
	);
};

const ReactivateButton = ({
	isDisabled = false,
	isDeactivated = false,
	initReactivate,
}) => {
	return (
		<button
			type="button"
			className={styles.ReactivateButton}
			onClick={initReactivate}
			disabled={isDisabled}
		>
			Re-activate
		</button>
	);
};

const FacilityAccessRow = ({
	facility = {},
	currentUser = {},
	parentsMap = {},
	hasAccess = false, // tracker access
	hasTrackerAccess = false,
	hasEmarAccess = false,
	hasEPayAccess = false,
	enableFacilityType = false,
	updateAccessList,
	dispatchAlert,
}) => {
	const [facilityType, setFacilityType] = useState(() => {
		const type = getFacilityType(facility, parentsMap);
		return type;
	});

	const { communityName: name, address } = facility;
	// local state
	const [isDeactivated, setIsDeactivated] = useState(() => {
		return facility?.communityName === "TSB1 AL";
	});
	const [showFacilityDetails, setShowFacilityDetails] = useState(false);
	const [justUpdated, setJustUpdated] = useState(false);

	const triggerUpdateState = () => {
		setJustUpdated(true);
	};

	// unsuspends a facility account
	const reactivateHandler = async () => {
		const { token } = currentUser;
		const { facilityID } = facility;
		// const wasReactivated = await unsuspendFacilityAccount(token, facilityID);
		// 'bitSuspendSubscription' ???? OR 'SuspendFacilityAccount' API
		setIsDeactivated(false);
	};
	// suspends a facility account
	const deactivateHandler = async () => {
		const { token } = currentUser;
		const { facilityID } = facility;
		// const wasDeactivated = await suspendFacilityAccount(token, facilityID);
		// 'bitSuspendSubscription' ???? OR 'UnSuspendFacilityAccount' API
		setIsDeactivated(true);
	};

	// resets 'justUpdated' state
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		let timerID;
		if (justUpdated) {
			timerID = setTimeout(() => {
				setJustUpdated(false);
			}, 3000);
		}

		return () => {
			isMounted = false;
			clearTimeout(timerID);
		};
	}, [justUpdated]);

	return (
		<>
			<div
				className={
					justUpdated
						? styles.FacilityAccessRow_justUpdated
						: styles.FacilityAccessRow
				}
			>
				<div
					className={styles.FacilityAccessRow_facility}
					onClick={() => setShowFacilityDetails(facility)}
				>
					<div className={styles.FacilityAccessRow_facility_badge}>
						<FacilityBadge state={address?.state} />
						{enableFacilityType && <FacilityTypeBadge type={facilityType} />}
					</div>
					<div className={styles.FacilityAccessRow_facility_details}>
						<div className={styles.FacilityAccessRow_facility_details_name}>
							{name}
						</div>
						<div className={styles.FacilityAccessRow_facility_details_address}>
							{getAddress(facility)}
						</div>
					</div>
				</div>
				{/* APP1 - SENIORCARE-VB */}
				<div className={styles.FacilityAccessRow_option}>
					<div className={styles.FacilityAccessRow_option_status}>
						{true ? <EnabledBadge /> : <DisabledBadge />}
					</div>
				</div>
				{/* APP2 - ADVANTAGE-TRACKER */}
				<div
					className={styles.FacilityAccessRow_option}
					style={{ justifyContent: "center" }}
				>
					<div className={styles.FacilityAccessRow_option_status}>
						{hasTrackerAccess ? <EnabledBadge /> : <DisabledBadge />}
					</div>
				</div>
				{/* APP3 - EMAR */}
				<div
					className={styles.FacilityAccessRow_emarOption}
					// style={{ justifyContent: "center" }}
				>
					<div className={styles.FacilityAccessRow_option_status}>
						{hasEmarAccess ? <EnabledBadge /> : <DisabledBadge />}
					</div>
				</div>
				{/* APP4 - EPAY */}
				<div
					className={styles.FacilityAccessRow_ePayOption}
					// style={{ justifyContent: "center" }}
				>
					<div className={styles.FacilityAccessRow_option_status}>
						{hasEPayAccess ? <EnabledBadge /> : <DisabledBadge />}
					</div>
				</div>
				<div
					className={styles.FacilityAccessRow_option}
					style={{ justifyContent: "flex-end" }}
				>
					{isDeactivated && (
						<ReactivateButton
							isDeactivated={isDeactivated}
							initReactivate={reactivateHandler}
							isDisabled={true}
							// isDisabled={isAccessEnabled(
							// 	"enableDeactivations",
							// 	featureFlags.facility,
							// 	currentUser
							// )}
						/>
					)}
					{!isDeactivated && (
						<DeactivateButton
							isDeactivated={isDeactivated}
							initDeactivate={deactivateHandler}
							isDisabled={true}
							// isDisabled={isAccessEnabled(
							// 	"enableDeactivations",
							// 	featureFlags.facility,
							// 	currentUser
							// )}
						/>
					)}
				</div>
			</div>
			{showFacilityDetails && (
				<ModalFull
					title={`Facility Details: ${facility.communityName}`}
					closeModal={() => setShowFacilityDetails(false)}
				>
					<FacilityDetails
						facilityType={facilityType}
						currentUser={currentUser}
						facility={{ ...facility, hasAccess }}
						closeModal={() => setShowFacilityDetails(false)}
						updateAccessList={updateAccessList}
						triggerJustUpdated={triggerUpdateState}
						dispatchAlert={dispatchAlert}
						enableFacilityType={enableFacilityType}
					/>
				</ModalFull>
			)}
		</>
	);
};

export default FacilityAccessRow;

FacilityAccessRow.defaultProps = {
	facility: {},
};

FacilityAccessRow.propTypes = {
	facility: PropTypes.object.isRequired,
};
