import React, { useState } from "react";
import styles from "../../css/forms/MultiStepForm.module.scss";
import { PropTypes } from "prop-types";
import { range } from "../../helpers/utils_processing";
// components
import MultiStepCounter from "./MultiStepCounter";

// STEPS:
// 1. User: User type
// 2. Login: Username & email
// 3. About: First name & last name, job title
// 4. Password Reset: Security Questions
// 5. Schedule: What shift they work

// generates an array of numbers representing the # of steps
// this is used for the step bubbles nav at the bottom of the form
const getStepList = (start = 1, totalSteps = 3, disabledSteps = []) => {
	start = start <= 1 ? 0 : start;
	const stepList = range(start, totalSteps, (x) => x + 1);
	const cleaned = stepList.filter((step) => !disabledSteps.includes(step));
	return cleaned;
};

const MultiStepForm = ({
	totalSteps = 3,
	startStep = 1,
	stepEnabler = {},
	disabledSteps = [],
	forcedCurrentStep = null,
	children,
}) => {
	const [currentStep, setCurrentStep] = useState(() => {
		if (!forcedCurrentStep) {
			return startStep;
		} else {
			return forcedCurrentStep;
		}
	});

	const goToNext = () => {
		const next = currentStep + 1;
		// skip disabled step(s)
		if (disabledSteps.includes(next)) {
			return setCurrentStep(next + 1);
		}

		if (next <= totalSteps) {
			return setCurrentStep(next);
		} else {
			return setCurrentStep(totalSteps);
		}
	};

	const goToPrevious = () => {
		const prev = currentStep - 1;
		if (prev >= startStep) {
			return setCurrentStep(prev);
		} else {
			return setCurrentStep(startStep);
		}
	};

	const goToStep = (step) => {
		setCurrentStep(step);
	};

	const skipStep = (stepToSkip) => {
		if (stepToSkip === totalSteps) {
			return;
		} else {
			return goToStep(stepToSkip + 1);
		}
	};

	const withProps = React.Children.map(children, (child, i) => {
		return React.cloneElement(child, {
			startStep: startStep,
			currentStep: currentStep,
			totalSteps: totalSteps,
			steps: getStepList(startStep, totalSteps, disabledSteps),
			goToNext: goToNext,
			goToPrevious: goToPrevious,
			goToStep: goToStep,
			disabledSteps: disabledSteps,
			handleSkipStep: skipStep,
		});
	});

	return (
		<div className={styles.MultiStepForm}>
			{/* STEPS GO HERE... */}
			<section className={styles.MultiStepForm_inner}>{withProps}</section>

			<footer className={styles.MultiStepForm_footer}>
				<MultiStepCounter
					steps={getStepList(startStep, totalSteps)}
					currentStep={currentStep}
					totalSteps={totalSteps}
					goToNext={goToNext}
					goToPrevious={goToPrevious}
					goToStep={goToStep}
					stepEnabler={stepEnabler}
					disabledSteps={disabledSteps}
				/>
			</footer>
		</div>
	);
};

export default MultiStepForm;

MultiStepForm.defaultProps = {
	totalSteps: 3,
	startStep: 1,
};

MultiStepForm.propTypes = {
	totalSteps: PropTypes.number,
	startStep: PropTypes.number,
	children: PropTypes.any,
};
