import React from "react";
import styles from "../../css/facility/FacilityAccessHeader.module.scss";
import { PropTypes } from "prop-types";

const FacilityAccessHeader = ({
	title = "Manage Facility Access",
	subtitle = "Click a Facility Name to view or update",
	allFacilities = [],
}) => {
	return (
		<header className={styles.FacilityAccessHeader}>
			<div className={styles.FacilityAccessHeader_left}>
				<h2 className={styles.FacilityAccessHeader_left_title}>{title}</h2>
				<div className={styles.FacilityAccessHeader_left_count}>{subtitle}</div>
			</div>
			<div className={styles.FacilityAccessHeader_right}>
				<div className={styles.FacilityAccessHeader_right_count}>
					Showing <b>{allFacilities?.length}</b> facilities
				</div>
			</div>
		</header>
	);
};

export default FacilityAccessHeader;

FacilityAccessHeader.defaultProps = {
	allFacilities: [],
};

FacilityAccessHeader.propTypes = {
	allFacilities: PropTypes.arrayOf(PropTypes.object).isRequired,
};
