import React, { useRef, useEffect, useState, useContext } from "react";
import { PropTypes } from "prop-types";
import styles from "../css/pages/LoginPage.module.scss";
import sprite from "../assets/icons/modals-complete.svg";
import brandLogo from "../assets/brand/logo.png";
import { useForm } from "../utils/useForm";
import { AuthContext } from "../state/AuthContext";
import { GlobalStateContext } from "../state/GlobalStateContext";
import {
	SYSTEM_USER,
	getSystemAuth,
	shouldRefreshSystem,
} from "../helpers/utils_systemUser";
import { SERVICES } from "../helpers/utils_apps";
import { convertDaysToMins } from "../helpers/utils_dates";
import { isOtp } from "../helpers/utils_lockouts";
import { extractRawParams, getReferrerUrl } from "../helpers/utils_params";
import { themeColors } from "../helpers/utils_styles";
import { addMinutes } from "date-fns";
import { login, getUserAuthStatus } from "../helpers/utils_auth";
// ERROR OUTAGE MESSAGE
import { ERROR_OUTAGE, OutageUI } from "../helpers/utils_errors";

// components
import LoginForm from "../components/forms/LoginForm";
import Dialog from "../components/shared/Dialog";
import ButtonSM from "../components/shared/ButtonSM";
import ForgotPasswordButton from "../components/app/ForgotPasswordButton";
import ForgotPasswordModal from "../components/app/ForgotPasswordModal";
import ForgotPassword from "../components/app/ForgotPassword";
import ChangePasswordModal from "../components/app/ChangePasswordModal";
import OfflineIndicator from "../components/alerts/OfflineIndicator";
import RedirectedNotice from "../components/alerts/RedirectedNotice";
import LoginPageMessages from "../components/messages/LoginPageMessages";

// REQUIREMENTS:
// - Add Failed Login Notice:
// 		- Include "Warning!" # of failed logins
// 		- Include whether they're locked out
// - Add Lockout Notice
// 		- Include # of failed logins
// 		- Include details about how they can unlock their account
// - Finish adding 'Forgot Password' user flow & UI
// 		- Include different unlock methods:
// 				- By Security Questions
// 				- By Email
// 				- By Admin (ONLY)

const Message = ({ children }) => {
	return (
		<aside className={styles.Message}>
			<svg className={styles.Message_icon}>
				<use xlinkHref={`${sprite}#icon-check_circle`}></use>
			</svg>
			<div className={styles.Message_msg}>{children}</div>
		</aside>
	);
};

const showLogoutMsg = (history) => {
	const wasLoggedOut = history?.location?.state?.wasLoggedOut;
	return wasLoggedOut;
};

const custom = {
	backgroundColor: themeColors.main.red,
	color: "#ffffff",
	borderRadius: ".5rem",
};

const LoginPage = ({ history }) => {
	const tryAgainRef = useRef(); // focuses btn in login failure <Dialog/>
	const loginBtnRef = useRef(); // focuses login btn (after populating form), if OTP creds are in URL
	const { setAuthData } = useContext(AuthContext);
	const { state: globalState, dispatch: dispatchToState } =
		useContext(GlobalStateContext);
	const { formState, setFormState, handleChange } = useForm({
		username: "",
		password: "",
	});
	const { values } = formState;
	const [isLoading, setIsLoading] = useState(false);
	const [errorDialog, setErrorDialog] = useState({
		show: false,
		title: null,
		msg: null,
		subMsg: null,
	});
	// response from 'isOtp()' call
	const [otpInfo, setOtpInfo] = useState({});
	// only show when password is 'OTP' AND 'OTP' is NOT expired
	const [showChangePasswordModal, setShowChangePasswordModal] = useState(false); // change back to false
	// show when user clicks 'Forgot Password' button beneath form
	const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
	// was redirected from 'Tracker'
	const [wasRedirected, setWasRedirected] = useState(false);

	// triggers login error msg, based off 'login()' response
	const errorHandler = (authState = {}) => {
		const { isValidUser, token } = authState;

		switch (true) {
			// INVALID USER & UNKNOWN ERROR (ie. 'server' error, or backend-related)
			case token instanceof Error: {
				return setErrorDialog({
					show: true,
					title: `Ooops! Server Failure.`,
					msg: `Server Didn't Respond.`,
					subMsg: `Sorry. The server didn't respond. \n\nTry refreshing the page please.`,
				});
			}
			// VALID USER (ie. 'invalid credentials', 'locked-out' etc)
			case isValidUser: {
				return setErrorDialog({
					show: true,
					title: authState?.reasonForFailure?.name ?? "Unknown Reason!!!",
					msg: authState?.reasonForFailure?.name ?? "Unknown Reason!!!",
					subMsg: authState?.reasonForFailure?.desc,
				});
			}
			// INVALID USER (ie. account doesn't exist)
			case !isValidUser: {
				// ##TODOS:
				// - Replace this message w/ non-identifying message: "Sorry, those credentials are invalid."
				// 		- FIXED!!
				return setErrorDialog({
					show: true,
					title: `INVALID CREDENTIALS!`,
					msg: `Invalid username and/or password.`,
					subMsg: `Sorry, those credentials are invalid. Please try again.`,
					// title: `INVALID USER!`,
					// msg: `User Does Not Exist`,
					// subMsg: `Sorry. That user was not found in our system.`,
				});
			}

			default:
				return setErrorDialog({
					show: true,
					title: `UNKNOWN ERROR`,
					msg: `An uknown error occurred`,
					subMsg: `Sorry. An error occured. Try logging again.`,
				});
		}
	};

	// ##TODOS:
	// - Add logic to store 'token' in url via query params OR localStorage
	// - Upon successful login save auth to cache to support page refresh without logging user out
	const handleLogin = async (e) => {
		e.preventDefault();
		// double-check this logic 'shouldRefreshSystem'
		const { shouldRefresh } = shouldRefreshSystem("SYSTEM");
		if (shouldRefresh) {
			alert(`✅ Update has fininshed! Page will refresh.`);
			return window.location.reload();
		}

		setIsLoading(true);

		const { username, password } = formState.values;
		const { AdminPortal } = SERVICES;

		// check if provided 'password' value is a 'OTP', otherwise login as normal
		const otpData = await isOtp(SYSTEM_USER.token, username, password);
		const token = await login(username, password, AdminPortal.alias);

		// handle OTP reset flow
		if (otpData.isOtp && !otpData.isExpired) {
			// redirect to change password UI
			setOtpInfo(otpData);
			return handleOtp(otpData);
		}

		// if 'login()' fails or token is 'Error', null etc then throw UI error
		if (!token || token instanceof Error) {
			setIsLoading(false);
			const { token: sysToken } = globalState.systemUser;

			const loginRange = convertDaysToMins(3);
			const authState = await getUserAuthStatus(sysToken, username, loginRange);

			return errorHandler({ ...authState, token });
		} else {
			const newAuth = {
				username: username,
				password: password,
				token: token,
				expiry: addMinutes(Date.now(), 180),
				isAuthenticated: true,
			};
			setIsLoading(false);
			setAuthData(newAuth);

			return history.replace({
				pathname: "/portal",
				state: {
					from: history.location.pathname,
					token: token,
					wasLoggedOut: false,
				},
			});
		}
	};

	const handleOtp = (otpData) => {
		if (otpData.isOtp && !otpData.isExpired) {
			// show redirect password modal
			setIsLoading(false);
			return setShowChangePasswordModal(true);
		} else {
			// has otp, but is expired, show notice
			return setErrorDialog({
				show: true,
				msg: null,
				title: `Ooops! OTP is Expired!`,
				subMsg: `It appears that one-time-password is expired. Please request a new one and try again.`,
			});
		}
	};

	// auth's 'SYSTEM_USER' either from cache or new
	const initSystemUser = async () => {
		const authedSystemUser = await getSystemAuth();
		// sets local 'SYSTEM_USER' state
		SYSTEM_USER.token = authedSystemUser.token;
		SYSTEM_USER.expiry = authedSystemUser.expiry;
		// sets global 'SYSTEM_USER' state
		return dispatchToState({
			type: "AUTH_SYSTEM_USER",
			data: {
				user: { ...authedSystemUser },
			},
		});
	};

	// authenticates 'SYSTEM_USER' and checks for redirect(s)
	const initializeMount = () => {
		initSystemUser();
		checkForReferrer();
	};

	const resetLogin = () => {
		setErrorDialog({
			show: false,
			msg: null,
			title: null,
			subMsg: null,
		});
	};

	// check if user was redirected from:
	// - IF redirected from Tracker, then show Redirect UI
	// - IF logged out from Portal, then return early
	const checkForReferrer = () => {
		// prevents 'Redirect Notice UI' if user was logged out from the Portal, not the Tracker
		const wasLoggedOut = history?.location?.state?.wasLoggedOut;
		const from = history?.location?.state?.from;
		const fromPortal = /\/portal/gm;

		// prevent Redirece UI if user came from inside the Portal
		if (fromPortal.test(from) || wasLoggedOut) return;

		// get sanitized referrer url & check if exists in list of 'supported origins'
		const referrer = getReferrerUrl();
		const origins = [
			"http://localhost:3001",
			"https://tracker.aladvantage.com",
			"https://trackertest.aladvantage.com",
		];

		if (origins.includes(referrer)) {
			return setWasRedirected(true);
		} else {
			return setWasRedirected(false);
		}
	};

	// check for OTP creds in URL
	const checkForOTP = () => {
		const params = extractRawParams(window.location, ["username", "password"]);
		setFormState({
			...formState,
			values: {
				...values,
				username: params?.username ?? "",
				password: params?.password ?? "",
			},
		});
	};

	// check for creds in URL string for password resets from email
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		checkForOTP();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// initializes 'SYSTEM_USER' auth
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		initializeMount();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// onMount check for redirect
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		let redirectID;
		if (wasRedirected) {
			redirectID = setTimeout(() => {
				setWasRedirected(false);
			}, 5000);
		}

		return () => {
			isMounted = false;
			clearTimeout(redirectID);
		};
	}, [wasRedirected]);

	return (
		<>
			<div className={styles.LoginPage}>
				<section className={styles.LoginPage_logo}>
					<img
						src={brandLogo}
						alt="AL Advantage Senior Care Software Logo"
						className={styles.LoginPage_logo_image}
					/>
				</section>
				<section className={styles.LoginPage_form}>
					<LoginForm
						vals={formState.values}
						isLoading={isLoading}
						handleLogin={handleLogin}
						handleChange={handleChange}
						loginBtnRef={loginBtnRef}
					/>
					<ForgotPasswordButton
						openForgotPassword={() => setShowForgotPasswordModal(true)}
					/>
				</section>
				<section className={styles.LoginPage_messages}>
					<LoginPageMessages systemUser={SYSTEM_USER} disableAll={false} />
					{ERROR_OUTAGE.enableErrorMsg && (
						<div className={styles.LoginPage_messages_warning}>
							{/* {ERROR_OUTAGE.msg} */}
							<OutageUI />
						</div>
					)}
				</section>
			</div>

			{showChangePasswordModal && (
				<ChangePasswordModal
					systemUser={SYSTEM_USER}
					closeModal={() => setShowChangePasswordModal(false)}
					currentUser={{
						username: formState.values.username,
						password: formState.values.password,
						isAuthenticated: false,
						otpData: { ...otpInfo, otp: formState.values.password },
					}}
				/>
			)}

			{errorDialog.show && (
				<Dialog
					icon="ERROR"
					title={errorDialog.title}
					heading={errorDialog.msg}
					subheading={errorDialog.subMsg}
					closeModal={resetLogin}
				>
					<ButtonSM
						key={`TRY-AGAIN-BUTTON`}
						parentRef={tryAgainRef}
						focusOnMount={true}
						customStyles={custom}
						handleClick={resetLogin}
					>
						<span>Try Again?</span>
					</ButtonSM>
				</Dialog>
			)}

			{showLogoutMsg(history) && <Message>Your session has ended!</Message>}

			<OfflineIndicator />

			{showForgotPasswordModal && (
				<ForgotPasswordModal
					closeModal={() => setShowForgotPasswordModal(false)}
				>
					<ForgotPassword
						closeModal={() => setShowForgotPasswordModal(false)}
					/>
				</ForgotPasswordModal>
			)}

			{wasRedirected && (
				<RedirectedNotice closeAlert={() => setWasRedirected(false)} />
			)}
		</>
	);
};

export default LoginPage;

LoginPage.defaultProps = {};

LoginPage.propTypes = {
	history: PropTypes.object,
};
