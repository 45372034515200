import React, { useEffect, useState } from "react";
import styles from "../../css/forms/LoginForm.module.scss";
import { PropTypes } from "prop-types";
// utils
import { currentEnvName } from "../../helpers/utils_env";
import { themeColors } from "../../helpers/utils_styles";
import { isValid } from "../../helpers/utils_validation";
import { versionRelease } from "../../helpers/utils_version";
// components
import TextInput from "../shared/TextInput";
import PasswordInput from "../shared/PasswordInput";
import ButtonSM from "../shared/ButtonSM";

const customCSS = {
	button: {
		backgroundColor: themeColors.main.main,
		color: "#ffffff",
		padding: "1rem 2.2rem",
	},
	input: {
		width: "100%",
		marginBottom: "2rem",
	},
};

const formTitle = {
	title: {
		test: `ALA Portal`,
		development: `ALA Portal`,
		prod: `ALA Portal`,
		production: `ALA Portal`,
	},
	env: {
		test: `(TEST)`,
		development: `(TEST)`,
		prod: ``,
		production: ``,
	},
};

const Title = () => {
	return (
		<div className={styles.LoginForm_form_formTitles}>
			<h4 className={styles.LoginForm_form_formTitles_title}>
				{formTitle.title[currentEnvName]} <b>{formTitle.env[currentEnvName]}</b>
			</h4>
		</div>
	);
};

const LoginForm = ({
	vals,
	handleChange,
	handleLogin,
	handleSignup,
	isLoading = false,
	showSignup = false,
	loginBtnRef = null,
}) => {
	const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);

	// "watches" the form fields for non-empty values and enables the button
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (isValid(vals, ["username", "password"])) {
			return setIsReadyToSubmit(true);
		}
		setIsReadyToSubmit(false);
		return () => {
			isMounted = false;
		};
	}, [vals]);

	return (
		<section className={styles.LoginForm}>
			<form onSubmit={handleLogin} className={styles.LoginForm_form}>
				<Title />
				<TextInput
					label="Username"
					val={vals.username}
					handleChange={handleChange}
					name="username"
					id="username"
					autoComplete="username"
					required={true}
					customWidth={customCSS.input}
				/>
				<PasswordInput
					label="Password"
					val={vals.password}
					handleChange={handleChange}
					name="password"
					id="password"
					autoComplete="password"
					required={true}
				/>
				<ButtonSM
					type="submit"
					handleClick={handleLogin}
					customStyles={customCSS.button}
					isDisabled={!isReadyToSubmit || isLoading}
					parentRef={loginBtnRef}
				>
					<span>{isLoading ? "Logging in..." : "Login"}</span>
				</ButtonSM>
				<div className={styles.LoginForm_form_bottom}>
					<p className={styles.LoginForm_form_bottom_version}>
						{versionRelease}
					</p>
					{showSignup && (
						<p className={styles.LoginForm_form_bottom_switch}>
							<button
								className={styles.LoginForm_form_bottom_switch_button}
								onClick={handleSignup}
							>
								Or Signup here!
							</button>
						</p>
					)}
				</div>
			</form>
		</section>
	);
};

export default LoginForm;

LoginForm.defaultProps = {
	isLoading: false,
	showSignup: false,
};

LoginForm.propTypes = {
	vals: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleLogin: PropTypes.func.isRequired,
	handleSignup: PropTypes.func,
	isLoading: PropTypes.bool,
	showSignup: PropTypes.bool,
};
