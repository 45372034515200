import React from "react";
import styles from "../../css/residents/UnitTypeBadge.module.scss";
import { PropTypes } from "prop-types";
import {
	blue,
	blueGrey,
	green,
	orange,
	pink,
	red,
} from "../../helpers/utils_styles";
import { isEmptyVal } from "../../helpers/utils_types";

const units = {
	"Memory Care": {
		border: `2px solid ${red[600]}`,
		color: red[600],
	},
	"Assisted Living": {
		border: `2px solid ${pink[500]}`,
		color: pink[600],
	},
	Independent: {
		border: `2px solid ${green[600]}`,
		color: green[600],
	},
	"Personal Care": {
		border: `2px solid ${blue[600]}`,
		color: blue[600],
	},
	null: {
		backgroundColor: blueGrey[700],
		border: `2px solid ${blueGrey[700]}`,
		color: "#ffffff",
	},
};

const getStyles = (unitType) => {
	return units[unitType];
};

const getType = (unitType) => {
	if (!unitType || isEmptyVal(unitType)) {
		return "Unknown";
	} else {
		return unitType;
	}
};

const UnitTypeBadge = ({ unitType }) => {
	return (
		<div className={styles.UnitTypeBadge} style={getStyles(unitType)}>
			<span className={styles.UnitTypeBadge_label}>{getType(unitType)}</span>
		</div>
	);
};

export default UnitTypeBadge;

UnitTypeBadge.defaultProps = {};

UnitTypeBadge.propTypes = {};
