import React from "react";
import styles from "../../css/forms/MultiStepCounter.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";

// ##TODOS:
// - Implement enabling 'next' step based off <condition>
const isStepActive = (currentStep, step) => {
	if (step <= currentStep) {
		return true;
	} else {
		return false;
	}
};

const StepIndicator = ({
	step,
	isActive = false,
	isDisabled = true,
	goToStep,
}) => {
	return (
		<button
			type="button"
			className={
				isActive ? styles.StepIndicator_isActive : styles.StepIndicator
			}
			onClick={() => goToStep(step)}
			disabled={isDisabled}
		></button>
	);
};

const MultiStepCounter = ({
	steps = [],
	currentStep = 1,
	goToStep,
	stepEnabler = {},
	disabledSteps = [],
}) => {
	return (
		<div className={styles.MultiStepCounter}>
			{!isEmptyArray(steps) &&
				steps.map((step, idx) => {
					// return early for disabled steps
					if (disabledSteps.includes(step)) return null;
					return (
						<StepIndicator
							key={`--STEP-${step}-${idx}--`}
							isActive={currentStep === step}
							currentStep={currentStep}
							isDisabled={
								!stepEnabler[step] && !isStepActive(currentStep, step)
							}
							step={step}
							goToStep={() => goToStep(step)}
						/>
					);
				})}
		</div>
	);
};

export default MultiStepCounter;

MultiStepCounter.defaultProps = {
	steps: [],
	currentStep: 1,
	stepEnabler: {},
};

MultiStepCounter.propTypes = {
	steps: PropTypes.arrayOf(PropTypes.number),
	currentStep: PropTypes.number,
	goToStep: PropTypes.func,
	stepEnabler: PropTypes.object,
};
