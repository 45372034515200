import React from "react";
import styles from "../../css/otp/HiddenChar.module.scss";
import { PropTypes } from "prop-types";

const HiddenChar = () => {
	return <span className={styles.HiddenChar}>&#9679;</span>;
};

export default HiddenChar;

HiddenChar.defaultProps = {};

HiddenChar.propTypes = {};
