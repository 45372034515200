import React from "react";
import styles from "../../css/app/PasswordSuccess.module.scss";
import sprite from "../../assets/icons/multistep.svg";
import { PropTypes } from "prop-types";

const PasswordSuccess = ({ closeModal }) => {
	const goToLogin = () => {
		closeModal();
	};

	return (
		<div className={styles.PasswordSuccess}>
			<svg className={styles.PasswordSuccess_icon}>
				<use xlinkHref={`${sprite}#icon-vpn_lock`}></use>
			</svg>
			<div className={styles.PasswordSuccess_messages}>
				<div className={styles.PasswordSuccess_messages_msg}>Success!</div>
				<div className={styles.PasswordSuccess_messages_msg2}>
					Your Password was Changed Successfully!
				</div>
				<button
					type="button"
					className={styles.PasswordSuccess_messages_login}
					onClick={goToLogin}
				>
					Go to login page.
				</button>
			</div>
		</div>
	);
};

export default PasswordSuccess;

PasswordSuccess.defaultProps = {};

PasswordSuccess.propTypes = {
	closeModal: PropTypes.func,
};
