import React from "react";
import styles from "../../css/user/UserProfileActions.module.scss";
import { PropTypes } from "prop-types";
import { COLORS_MAP as colors } from "../../helpers/utils_styles";
import { isEmptyObj, isEmptyVal } from "../../helpers/utils_types";
import { phoneReg } from "../../helpers/utils_validation";
// components
import ControlledButton from "../shared/ControlledButton";

const { red, purple } = colors;

const customCSS = {
	deactivate: {
		backgroundColor: red[600],
		marginRight: ".5rem",
		padding: ".7rem 1.5rem",
		fontSize: "1.4rem",
	},
	delete: {
		backgroundColor: red[600],
		marginLeft: ".5rem",
		padding: ".7rem 1.5rem",
		fontSize: "1.4rem",
	},
	save: {
		backgroundColor: purple[600],
		color: purple[100],
		marginLeft: ".5rem",
		padding: ".7rem 1.5rem",
		fontSize: "1.4rem",
	},
	cancel: {
		backgroundColor: "transparent",
		color: red[700],
		padding: ".7rem 1.5rem",
		fontSize: "1.4rem",
		marginRight: ".5rem",
	},
};

// original version, w/ invalid logic
const DEPRECATED_enableBtn = (formState) => {
	const { values: vals, touched } = formState;
	const { userType, jobTitle } = vals;
	const hasType = !isEmptyVal(userType);
	const hasTitle = !isEmptyVal(jobTitle);
	const wasTouched =
		(touched?.jobTitle || touched?.phoneNumber || touched?.phoneExt) ?? false;
	// has both or neither - vals need to change together
	const hasRequired = hasType && hasTitle;
	const isEmpty = !hasType && !hasTitle;

	return hasRequired && wasTouched;
	// return hasRequired && !isEmpty;
};

// checks if a 'touched' field was also filled-in
const wasTouchedAndFilled = (keyName, formState = {}) => {
	const { touched, values } = formState;
	const wasTouched = touched[keyName] ?? false;
	const wasFilled = !isEmptyVal(values[keyName]) ?? false;

	if (keyName === "phoneNumber") {
		// runs validation for 'phone number'
		const isValidPhone = phoneReg.test(values.phoneNumber);
		return wasTouched && wasFilled && isValidPhone;
	} else {
		// handles fields besides 'phoneNumber'
		return wasTouched && wasFilled;
	}
};

const enableBtn = (formState = {}) => {
	const wasPhoneEdited = wasTouchedAndFilled("phoneNumber", formState);
	const wasExtEdited = wasTouchedAndFilled("phoneExt", formState);
	const wasTitleEdited = wasTouchedAndFilled("jobTitle", formState);

	const shouldBeEnabled =
		(wasPhoneEdited || wasExtEdited || wasTitleEdited) ?? false;

	return shouldBeEnabled;
};

const enableCancel = (formState = {}) => {
	const { touched } = formState;
	const isEmpty = isEmptyObj(touched);

	return !isEmpty;
};

const UserProfileActions = ({
	vals = {},
	formState = {},
	currentUser = {},
	saveProfileChanges,
	cancelProfileChances,
	deactivateUser,
	wasProfileSaved,
}) => {
	return (
		<div className={styles.UserProfileActions}>
			<div className={styles.UserProfileActions_left}>
				<ControlledButton
					isHidden={true}
					handleClick={deactivateUser}
					customStyles={customCSS.deactivate}
				>
					Suspend
				</ControlledButton>
			</div>
			<div className={styles.UserProfileActions_right}>
				<ControlledButton
					key={`Cancel:${!enableCancel(formState)}`}
					isDisabled={!enableCancel(formState)}
					handleClick={cancelProfileChances}
					customStyles={customCSS.cancel}
				>
					Cancel
				</ControlledButton>
				<ControlledButton
					key={`Save:${!enableBtn(formState)}`}
					isDisabled={!enableBtn(formState) || wasProfileSaved}
					handleClick={saveProfileChanges}
					customStyles={customCSS.save}
				>
					Save
				</ControlledButton>
			</div>
		</div>
	);
};

export default UserProfileActions;

UserProfileActions.defaultProps = {};

UserProfileActions.propTypes = {};
