import React from "react";
import styles from "../../css/loc/LOCTableBody.module.scss";
import { PropTypes } from "prop-types";

const LOCTableBody = ({ isEditable = true, children }) => {
	return (
		<fieldset disabled={!isEditable} className={styles.LOCTableBody}>
			<div className={styles.LOCTableBody_body}>{children}</div>
		</fieldset>
	);
};

export default LOCTableBody;

LOCTableBody.defaultProps = {};

LOCTableBody.propTypes = {};
