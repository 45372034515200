import React from "react";
import styles from "../css/pages/VerifyEmailPage.module.scss";
import { PropTypes } from "prop-types";
// components
import BrandLogo from "../components/app/BrandLogo";
import VerifyEmailController from "../components/login/VerifyEmailController";

// REQUIREMENTS:
// - This page is ONLY accessible via custom link provided via email
// - When page loads, a special code is extracted from url query params
//    - Then a request to verify the email w/ the code is fired off

// 1ST: Extract code from url
//    - Verify it's a valid code:
//        - IF INVALID, then redirect to login page
//        - IF VALID, then show 'Email Verified' UI

const VerifyEmailPage = ({ history }) => {
	return (
		<div className={styles.VerifyEmailPage}>
			<div className={styles.VerifyEmailPage_logo}>
				<BrandLogo />
			</div>
			<div className={styles.VerifyEmailPage_main}>
				<VerifyEmailController history={history} />
			</div>
		</div>
	);
};

export default VerifyEmailPage;

VerifyEmailPage.defaultProps = {};

VerifyEmailPage.propTypes = {
	history: PropTypes.object,
};
