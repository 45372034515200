import React, { useState } from "react";
import styles from "../../css/user/ChangeQuestionAndAnswer.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";
import { purple } from "../../helpers/utils_styles";
// components
import UserSecurityQuestion from "./UserSecurityQuestion";
import SuccessIndicator from "../shared/SuccessIndicator";
import UserSecurityAnswer from "./UserSecurityAnswer";
import ValidationMsg from "../shared/ValidationMsg";

// REQUIREMENTS:
// - Prevent duplicate questions being selected
// - Prevent duplicate answers
// - Prevent answers shorter than 3 characters
// - Prevent answers of same letter (eg "XXX", "DDDDDDD" etc.)

const InvalidNotice = ({ msg }) => {
	return (
		<div className={styles.InvalidNotice}>
			<svg className={styles.InvalidNotice_icon}>
				<use xlinkHref={`${sprite}#icon-error_outline`}></use>
			</svg>
			<span className={styles.InvalidNotice_msg}>{msg}</span>
		</div>
	);
};

// checks if q & a are populated
const hasQuestionAndAnswer = (question, answer) => {
	if (!isValidAnswer(answer)) return false;
	return !isEmptyVal(question) && !isEmptyVal(answer);
};

const hasInvalidQuestion = (userQuestion, allQuestions) => {
	const questionDoesNotExist = !allQuestions.includes(userQuestion);

	return questionDoesNotExist;
};

// checks if a single q/a entry is not populated completely
const isMissingEntry = (qName, aName, vals = {}) => {
	const qVal = vals[qName];
	const aVal = vals[aName];

	return isEmptyVal(qVal) || isEmptyVal(aVal);
};

// toggle lock/unlock answer
const lockAnswer = (isEditing, answerID, vals = {}) => {
	const answer = vals[answerID];

	// lock anwer, if empty value OR not editing
	return !isEmptyVal(answer) && !isEditing;
};

// checks for:
// - repeating characters
// - lengths less than 3
// - cannot be same as other answers
const isValidAnswer = (answer, vals = {}) => {
	if (isEmptyVal(answer)) return;
	const reg = /^(.{4,})/gim;
	if (answer.length < 3) return false;
	const { securityAnswer1, securityAnswer2, securityAnswer3 } = vals;

	const isNotDuplicate =
		securityAnswer1 !== securityAnswer2 &&
		securityAnswer1 !== securityAnswer3 &&
		securityAnswer2 !== securityAnswer3;

	return reg.test(answer);
};

const getMsg = (answer, vals = {}) => {
	if (isEmptyVal(answer)) return;
	const reg = /^(.{4,})/gim;
	if (answer.length < 3) return false;
	const { securityAnswer1, securityAnswer2, securityAnswer3 } = vals;

	const isNotDuplicate =
		securityAnswer1 !== securityAnswer2 &&
		securityAnswer1 !== securityAnswer3 &&
		securityAnswer2 !== securityAnswer3;

	const isValid = isNotDuplicate && reg.test(answer);

	if (!isValid) {
		return !isNotDuplicate
			? `Cannot use same answer twice.`
			: `Answers must be longer than 3 charactors`;
	}
};

const ChangeQuestionAndAnswer = ({
	questionNum, // question 1,2 or 3
	vals,
	handleSecurityQuestion,
	handleAnswer,
	questions, // remaining questions, after selections
	allQuestions,
}) => {
	// name/id strings
	const qName = `securityQuestion${questionNum}`;
	const aName = `securityAnswer${questionNum}`;

	// unlocks question & answer to be edited
	const [isEditing, setIsEditing] = useState(() => {
		return isMissingEntry(qName, aName, vals);
	});

	// syncs edit state from child to here
	const getEditState = (state) => {
		setIsEditing(state);
	};

	return (
		<div className={styles.ChangeQuestionAndAnswer}>
			<UserSecurityQuestion
				vals={vals}
				label={`Security Question #${questionNum}:`}
				name={qName}
				id={qName}
				handleQuestion={handleSecurityQuestion}
				securityQuestions={questions}
				hideEditButtons={false}
				getEditState={getEditState}
				lockQuestion={!isEditing}
				initialEditState={isEditing}
			/>
			{hasQuestionAndAnswer(vals[qName], vals[aName]) && (
				<SuccessIndicator
					msg="Success!"
					iconColor={purple[600]}
					msgColor={purple[600]}
				/>
			)}
			{hasInvalidQuestion(vals[qName], allQuestions) && isEditing && (
				<InvalidNotice msg="Custom questions are NOT allowed. Please select an existing question" />
			)}
			<UserSecurityAnswer
				vals={vals}
				label={`Security Answer #${questionNum}:`}
				name={aName}
				id={aName}
				handleAnswer={handleAnswer}
				isEditing={isEditing}
				isDisabled={lockAnswer(isEditing, aName, vals)}
			/>
			{!isValidAnswer(vals[aName], vals) && (
				<ValidationMsg icon="invalid" msg={getMsg(vals[aName], vals)} />
			)}
			{hasQuestionAndAnswer(vals[qName], vals[aName]) &&
				isValidAnswer(vals[aName], vals) && (
					<SuccessIndicator
						msg="Success!"
						iconColor={purple[600]}
						msgColor={purple[600]}
					/>
				)}
		</div>
	);
};

export default ChangeQuestionAndAnswer;

ChangeQuestionAndAnswer.defaultProps = {};

ChangeQuestionAndAnswer.propTypes = {
	questionNum: PropTypes.number,
	vals: PropTypes.object,
	handleSecurityQuestion: PropTypes.func,
	handleAnswer: PropTypes.func,
	questions: PropTypes.arrayOf(PropTypes.string),
};
