import React from "react";
import styles from "../../css/login/EmailVerifiedError.module.scss";
import { PropTypes } from "prop-types";
import Illustration from "../illustrations/Illustration";

const EmailVerifiedError = () => {
	return (
		<div className={styles.EmailVerifiedError}>
			<div className={styles.EmailVerifiedError_ui}>
				<Illustration asset="denied" alt="Email could NOT be verified" />
			</div>
			<div className={styles.EmailVerifiedError_details}>
				<h2 className={styles.EmailVerifiedError_details_title}>
					Email Could NOT Be Verified
				</h2>
				<p className={styles.EmailVerifiedError_details_desc}>
					Your email could NOT be verified. Would you like to resend a
					verification?
				</p>
			</div>
		</div>
	);
};

export default EmailVerifiedError;

EmailVerifiedError.defaultProps = {};

EmailVerifiedError.propTypes = {};
