import React, { useState } from "react";
import styles from "../css/pages/ErrorPage.module.scss";
import { PropTypes } from "prop-types";
import { useHistory } from "react-router";
import { getErrorFromUrl, getErrorType } from "../helpers/utils_errors";
import fallbackLogo from "../assets/brand/logo.png";
// components
import LoginError from "../components/errors/LoginError";
import AppError from "../components/errors/AppError";

const ErrorPage = () => {
	const history = useHistory();
	// error from URL query param
	const [error, setError] = useState(() => {
		const info = getErrorFromUrl(window.location);
		const type = getErrorType(info.code);

		return {
			...info,
			type: type,
		};
	});

	return (
		<div className={styles.ErrorPage}>
			<div className={styles.ErrorPage_wrapper}>
				<div className={styles.ErrorPage_logoContainer}>
					<img
						src={fallbackLogo}
						alt="AL Advantage Brand Logo"
						className={styles.ErrorPage_logoContainer_logo}
					/>
				</div>
				{error.type === `LOGIN_ERROR` && <LoginError history={history} />}
				{/* <AppError/> */}
			</div>
		</div>
	);
};

export default ErrorPage;

ErrorPage.defaultProps = {};

ErrorPage.propTypes = {};
