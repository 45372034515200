import React, { useEffect, useRef, useState } from "react";
import styles from "../../css/app/ResetPasswordByEmail.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import sprite2 from "../../assets/icons/widget.svg";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import { isValidEmail } from "../../helpers/utils_validation";
import { isEmptyVal } from "../../helpers/utils_types";
import { generateAndSendOTP } from "../../helpers/utils_lockouts";
import { generateResetEmail } from "../../helpers/utils_templates";
import SpinnerSM from "../shared/SpinnerSM";
import { SERVICES } from "../../helpers/utils_apps";
import { isDevOrTest } from "../../helpers/utils_env";

// input state borders
const shadows = {
	valid: {
		boxShadow: " 0 0 0 2px hsla(170, 100%, 39%, 0.6)",
	},
	invalid: {
		boxShadow: "0 0 0 2px hsla(352, 70%, 50%, 0.6)",
	},
	empty: {
		boxShadow: "0 0 0 2px hsla(240, 100%, 50%, 0.3)",
	},
};

// REQUIREMENTS:
// - Once username/login is verified from "<ForgotPassword/>":
//    - Show verbiage for OTP
//    - Show OTP form
//    - Show message confirming OTP has been sent via email
// - OTP Verfication:
//    - If SUCCESS:
//        - Show Password form without requiring current password (use OTP for request)
//    - If FAILURE:
//        - Show message indicating failure
//            - "Sorry. The OTP you entered has expired!"
//            - "Sorry. The OTP you entered is not correct!"

// disabled reset controls for security
const SHOW_RESET_CONTROLS = false;

const getInputCSS = (val) => {
	if (isEmptyVal(val)) return shadows.empty;
	if (!isValidEmail(val)) return shadows.invalid;
	return shadows.valid;
};

const ResetControls = ({
	emailRef,
	vals = {},
	handleEmail,
	isEmailValid,
	sendResetEmail,
	isSendingReset,
	resetWasSent,
}) => {
	return (
		<>
			{!resetWasSent && (
				<>
					<div className={styles.ResetPasswordByEmail_email}>
						<label
							htmlFor="userEmail"
							className={styles.ResetPasswordByEmail_email_label}
						>
							Enter your email
						</label>
						<div className={styles.ResetPasswordByEmail_email_inputWrapper}>
							<input
								ref={emailRef}
								type="text"
								name="userEmail"
								id="userEmail"
								value={vals.userEmail}
								onChange={handleEmail}
								className={styles.ResetPasswordByEmail_email_inputWrapper_input}
								required={true}
								placeholder="Email address..."
								style={getInputCSS(vals.userEmail)}
							/>
							{isEmailValid && (
								<svg
									className={
										styles.ResetPasswordByEmail_email_inputWrapper_validIcon
									}
								>
									<use xlinkHref={`${sprite}#icon-check_circle`}></use>
								</svg>
							)}
						</div>
					</div>

					<div className={styles.ResetPasswordByEmail_send}>
						<button
							disabled={!isEmailValid || isEmptyVal(vals?.userEmail)}
							className={styles.ResetPasswordByEmail_send_sendBtn}
							onClick={sendResetEmail}
						>
							{!isSendingReset && (
								<>
									<svg
										className={styles.ResetPasswordByEmail_send_sendBtn_icon}
									>
										<use xlinkHref={`${sprite2}#icon-paper-plane`}></use>
									</svg>
									<span>Send Reset Email</span>
								</>
							)}
							{isSendingReset && <SpinnerSM color="#ffffff" />}
						</button>
					</div>
				</>
			)}
			{resetWasSent && (
				<div className={styles.ResetPasswordByEmail_wasSent}>
					<div className={styles.ResetPasswordByEmail_wasSent_wrapper}>
						<div className={styles.ResetPasswordByEmail_wasSent_wrapper_msg}>
							✓ Password reset was sent!
						</div>
						<div className={styles.ResetPasswordByEmail_wasSent_wrapper_submsg}>
							Please check your email.
						</div>
					</div>
				</div>
			)}
		</>
	);
};

const ResetPasswordByEmail = ({
	userEmail,
	authData = {},
	accountSecurity = {},
	systemUser = {},
}) => {
	const emailRef = useRef();
	const { formState, handleChange } = useForm({
		userEmail: isEmptyVal(userEmail) ? "" : userEmail,
	});
	const { values } = formState;
	const [isSendingReset, setIsSendingReset] = useState(false);
	const [resetWasSent, setResetWasSent] = useState(false);
	const [isEmailValid, setIsEmailValid] = useState(() => {
		return isValidEmail(userEmail);
	});

	const handleEmail = (e) => {
		const { value } = e.target;
		handleChange(e);
		setIsEmailValid(() => {
			return isValidEmail(value);
		});
	};

	const sendResetEmail = async () => {
		const { token } = systemUser;

		const resetEmail = generateResetEmail({
			currentUser: {
				email: values.userEmail,
			},
			emailContent: {
				appLoginURL:
					SERVICES["AdminPortal"].url[isDevOrTest() ? "test" : "prod"],
				otp: null,
			},
		});

		const emailContent = {
			subject: `Password Reset`,
			body: resetEmail,
			isBodyHtml: true,
		};
		const wasSent = await generateAndSendOTP(
			token,
			accountSecurity.userID,
			values?.userEmail,
			emailContent
		);
		// setIsSendingReset(true);

		if (wasSent) {
			setIsSendingReset(false);
			return setResetWasSent(true);
		} else {
			setIsSendingReset(false);
			return setResetWasSent(false);
		}
	};

	// auto-focus input
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (emailRef?.current) {
			emailRef?.current?.focus();
		}
		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// if email address is found, automatically send reset email
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		const autoSendResetEmail = async () => {
			setIsSendingReset(true);
			sendResetEmail();
		};

		if (!isEmptyVal(userEmail) && !isEmptyVal(values.userEmail)) {
			autoSendResetEmail();
		}

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.ResetPasswordByEmail}>
			{/* <div className={styles.ResetPasswordByEmail_header}>
				<div className={styles.ResetPasswordByEmail_header_title}>
					Reset Password
				</div>
				<p className={styles.ResetPasswordByEmail_header_desc}>
					Enter the email address associated with your account and we'll send an
					email with your password reset instructions.
				</p>
			</div> */}

			{SHOW_RESET_CONTROLS && (
				<ResetControls
					vals={values}
					emailRef={emailRef}
					isSendingReset={isSendingReset}
					resetWasSent={resetWasSent}
					isEmailValid={isEmailValid}
					handleEmail={handleEmail}
					sendResetEmail={sendResetEmail}
				/>
			)}
		</div>
	);
};

export default ResetPasswordByEmail;

ResetPasswordByEmail.defaultProps = {};

ResetPasswordByEmail.propTypes = {};
