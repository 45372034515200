import React from "react";
import styles from "../../css/admin/UserAccessIndicator.module.scss";
import sprite from "../../assets/icons/settings.svg";
import { PropTypes } from "prop-types";
import CustomCheckbox from "../shared/CustomCheckbox";

// check_circle_outline
const HasAccess = ({ appName }) => {
	return (
		<div className={styles.HasAccess}>
			<svg className={styles.HasAccess_icon}>
				<use xlinkHref={`${sprite}#icon-check_circle_outline`}></use>
			</svg>
			<span className={styles.HasAccess_appName}>{appName}</span>
		</div>
	);
};

// highlight_remove
// circle-with-cross
const NoAccess = ({ appName }) => {
	return (
		<div className={styles.NoAccess}>
			<svg className={styles.NoAccess_icon}>
				<use xlinkHref={`${sprite}#icon-highlight_remove`}></use>
			</svg>
			<span className={styles.NoAccess_appName}>{appName}</span>
		</div>
	);
};

const UserAccessIndicator = ({ appName, hasAccess = false }) => {
	return (
		<div className={styles.UserAccessIndicator}>
			{hasAccess && <HasAccess appName={appName} />}
			{!hasAccess && <NoAccess appName={appName} />}
		</div>
	);
};

export default UserAccessIndicator;

UserAccessIndicator.defaultProps = {};

UserAccessIndicator.propTypes = {};
