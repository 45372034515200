import React, { useState, useRef, useEffect } from "react";
import styles from "../../css/app/TempPassword.module.scss";
import { PropTypes } from "prop-types";

const EditInput = ({ tempPassword, handleChange }) => {
	const inputRef = useRef(null);

	// focus & select temp password 'onMount'
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		inputRef.current.focus();
		inputRef.current.select();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<input
			type="text"
			name="tempPassword"
			id="tempPassword"
			value={tempPassword}
			onChange={handleChange}
			className={styles.TempPassword_wrapper_input}
			ref={inputRef}
		/>
	);
};

const TempPassword = ({
	tempPassword = "",
	handleChange,
	disableEdit = false,
	maxLength = 9,
}) => {
	const [showEditInput, setShowEditInput] = useState(false);
	const [wasCopied, setWasCopied] = useState(false);
	const [reachedMax, setReachedMax] = useState(
		tempPassword?.length >= maxLength
	);

	const editTempPassword = () => {
		setShowEditInput(!showEditInput);
	};

	const copyTempPassword = () => {
		navigator.clipboard.writeText(tempPassword);
		setWasCopied(true);
	};

	const handleTemp = (e) => {
		const { value } = e.target;
		if (value?.length >= maxLength) {
			return setReachedMax(true);
		}
		setReachedMax(false);
		return handleChange(e);
	};

	// reset 'wasCopied' state after copy
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		let timerID;
		if (wasCopied) {
			timerID = setTimeout(() => {
				setWasCopied(false);
			}, 2000);
		}

		return () => {
			isMounted = false;
			clearTimeout(timerID);
		};
	}, [wasCopied]);

	return (
		<div className={styles.TempPassword}>
			<div className={styles.TempPassword_wrapper}>
				{!showEditInput && (
					<div className={styles.TempPassword_wrapper_value}>
						{tempPassword}
					</div>
				)}
				{showEditInput && (
					<EditInput tempPassword={tempPassword} handleChange={handleTemp} />
				)}
				{!disableEdit && (
					<button
						type="button"
						onClick={editTempPassword}
						className={styles.TempPassword_wrapper_toggle}
					>
						{showEditInput ? `Cancel` : `Edit`}
					</button>
				)}
			</div>
			{reachedMax && (
				<div className={styles.TempPassword_max}>
					Max characters allowed is <b>{maxLength}</b>
				</div>
			)}
			<div className={styles.TempPassword_copy}>
				<button
					type="button"
					onClick={copyTempPassword}
					className={styles.TempPassword_copy_btn}
				>
					{wasCopied ? "✓ Copied" : "Copy Temp Password"}
				</button>
			</div>
		</div>
	);
};

export default TempPassword;

TempPassword.defaultProps = {};

TempPassword.propTypes = {
	tempPassword: PropTypes.string,
	handleChange: PropTypes.func,
};
