import React, { useEffect, useRef } from "react";
import styles from "../../css/otp/OTPChar.module.scss";
import { PropTypes } from "prop-types";
import { blueGrey, green, red } from "../../helpers/utils_styles";

const CHAR_STATES = {
	isValid: {
		border: `4px solid ${green[400]}`,
	},
	isInvalid: {
		border: `4px solid ${red[500]}`,
	},
	wasNotTouched: {
		border: `4px solid ${blueGrey[500]}`,
	},
	wasTouched: {
		border: `4px solid ${red[500]}`,
	},
};

const getCharStatus = (isValid, wasTouched) => {
	if (!wasTouched) return CHAR_STATES["wasNotTouched"];

	switch (true) {
		// valid char
		case isValid: {
			return CHAR_STATES["isValid"];
		}
		// invalid char
		case !isValid: {
			return CHAR_STATES["isInvalid"];
		}

		default:
			return CHAR_STATES["wasNotTouched"];
	}
};

const OTPChar = ({
	name,
	id,
	val,
	charIdx,
	isValid = false,
	wasTouched = true,
	handleChar,
	handleBlur,
	focusInput,
}) => {
	const inputRef = useRef();

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		const handleFocus = () => {
			if (focusInput) {
				return inputRef.current.focus();
			} else {
				return;
			}
		};

		handleFocus();

		return () => {
			isMounted = false;
		};
	}, [focusInput]);

	return (
		<div className={styles.OTPChar}>
			<div
				className={styles.OTPChar_fieldWrapper}
				style={getCharStatus(isValid, wasTouched)}
			>
				<input
					ref={inputRef}
					type="text"
					name={name}
					id={id}
					value={val}
					onChange={handleChar}
					className={styles.OTPChar_fieldWrapper_field}
					autoComplete="one-time-code"
					onBlur={handleBlur}
					data-charindex={charIdx}
				/>
			</div>
		</div>
	);
};

export default OTPChar;

OTPChar.defaultProps = {};

OTPChar.propTypes = {
	name: PropTypes.string,
	id: PropTypes.string,
	val: PropTypes.string,
	isValid: PropTypes.bool,
	isUntouched: PropTypes.bool,
	handleChar: PropTypes.func,
};
