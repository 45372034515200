const UNIT_TYPES = [
	`Assisted Living`,
	`Independent`,
	`Memory Care`,
	`Personal Care`,
];

// SECURITY QUESTIONS & ANSWERS - HARD-CODED
const SECURITY_QUESTIONS = [
	"What was your childhood nickname?",
	"What is the name of your favorite childhood friend?",
	"What school did you attend for sixth grade?",
	"What was the last name of your third grade teacher?",
	"In what city or town was your first job?",
	"What was your favorite place to visit as a child?",
	"What was your dream job as a child?",
	"Who was your childhood hero?",
];

const USER_TYPES = ["Administrator", "Staff", "Contractor", "Manager"];

// client-formatted
const USER_TYPES_FORMATTED = [
	{ name: "SuperUser", desc: "Super user type", typeID: 1 },
	{ name: "Staff", desc: "Staff user type", typeID: 2 },
	{ name: "Manager", desc: "Manager user type", typeID: 3 },
	{ name: "Contractor", desc: "Contractor user type", typeID: 4 },
	{ name: "Administrator", desc: "Administrator user type", typeID: 5 },
	{
		name: "FacilityAdministrator",
		desc: "Facility Administrator user type",
		typeID: 6,
	},
];
export { SECURITY_QUESTIONS };

export { USER_TYPES, USER_TYPES_FORMATTED };

export { UNIT_TYPES };
