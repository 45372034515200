import React, { useCallback, useState, useContext, useEffect } from "react";
import styles from "../css/pages/AdminPage.module.scss";
import sprite from "../assets/icons/settings.svg";
import sprite2 from "../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { GlobalStateContext } from "../state/GlobalStateContext";
import { useQueryParams } from "../utils/useQueryParams";
import { portalPageOptions } from "../helpers/utils_routes";
import { purple } from "../helpers/utils_styles";
import { isEmptyArray, isEmptyVal } from "../helpers/utils_types";
import { initialSettings, useAlerts } from "../utils/useAlerts";
import {
	getFacilityInfoAndSummary,
	matchUserFacilityByName,
	processFacilityInfo,
} from "../helpers/utils_facility";
import { processUserList } from "../helpers/utils_user";
import { processLockoutsList } from "../helpers/utils_lockouts";
import { hasParams } from "../helpers/utils_params";
import {
	enableFeatureViaInternalList,
	enableFeatureViaWhiteList,
	featuresWhiteList,
} from "../helpers/utils_permissions";
import { isDevOrTest } from "../helpers/utils_env";

// components ~ views
import ManageFacilityAccessView from "../views/admin/ManageFacilityAccessView";
import ManageUserAccessView from "../views/admin/ManageUserAccessView";
import CustomSearchView from "../views/admin/CustomSearchView";
import UserLockouts from "../components/summary/UserLockouts";
import NewUserTab from "../components/admin/NewUserTab";
import FacilityInfo from "../components/facility/FacilityInfo";
// utility components
import DashboardTabNav from "../components/dashboard/DashboardTabNav";
import ViewContainer from "../components/app/ViewContainer";
import ALASelector from "../components/app/ALASelector";
// tabs & other components
// new tabs
import ControllableTabsWrapper from "../components/tabs/ControllableTabsWrapper";
import ControllableTabsButtons from "../components/tabs/ControllableTabsButtons";
import ControllableTabsButton from "../components/tabs/ControllableTabsButton";
import ControllableTabsPanels from "../components/tabs/ControllableTabsPanels";
import ControllableTabsPanel from "../components/tabs/ControllableTabsPanel";
import ManageResidentsView from "../views/admin/ManageResidentsView";

/**
 * NEW Tabs Configuration & Changes:
 * - Add new 'New Users' tab
 * - Reorder existing tabs
 * 		- Order: "New User", "Existing Users", "Lockouts", "Facility App Access", "Search"
 * - Rename existing tab(s)
 * 		- "Facility Access" => "Facility"
 * 		- "User Access" => "Existing Users"
 * 		- "Custom Search" => "Search"
 *
 */
// maps the existing tabs' indices to their 'history.location.state.target' value
const tabMap = {
	newUser: 0,
	existingUsers: 1, // alias user
	user: 1,
	lockouts: 2,
	facility: 3,
	facilityInfo: 4,
	search: 5,
	residents: 6,
	// profile: 5, this was the previous index???
	// profile: 4,
	// search: 5,
};

const tabIndexMap = {
	0: "newUser",
	1: "existingUsers",
	2: "lockouts",
	3: "facility",
	4: "facilityInfo",
	5: "search",
	6: "residents",
};

const viewDetails = {
	title: `Admin Panel`,
	desc: `Administrative settings & management for users and applications.`,
};

// grabs 'target' tab from history.location.state.target
// which designates which tab to show onMount
// defaults to 'Facility Access'
const getDefaultTab = (history) => {
	const target = history?.location?.state?.target ?? null;
	if (!target || target === "admin") return 3;
	return tabMap[target];
};
// determine which sprite to use based off 'available keys'
const getSprite = (icon) => {
	// list of keys for 'sprite2'
	const optionalList = [
		"Facility Access",
		"Facility App Access",
		"Facility Management",
		"Facility Info",
		"Manage Residents",
	];
	if (optionalList.includes(icon)) {
		return sprite2;
	} else {
		return sprite;
	}
};
// gets active, disabled or hidden tab button styles
const getTabStyles = (isActive) => {
	if (isActive) {
		return {
			fill: purple[600],
			color: purple[600],
		};
	} else {
		return null;
	}
};
// determines which tabs to hide based off 'user-type'
const getHiddenTabs = (currentUser = {}) => {
	const {
		isFacilityAdmin,
		isRegionalAdmin,
		isSuperUser,
		isMedTechRestricted,
		isAdmin,
		isALAAdmin,
	} = currentUser;
	switch (true) {
		// enables 'Manage Residents' tab for whitelisted users
		case enableFeatureViaInternalList(currentUser.userID): {
			return [];
		}
		case isSuperUser: {
			// return [6];
			return isDevOrTest() ? [] : [6];
		}
		case isAdmin && !isSuperUser:
		case isRegionalAdmin && !isSuperUser: {
			return [5, 6];
		}
		case isRegionalAdmin: {
			return [5, 6];
		}
		case isFacilityAdmin && !isRegionalAdmin: {
			return [5, 6];
		}
		case isMedTechRestricted: {
			return [0, 1, 5, 6];
		}

		default:
			return [5, 6];
	}
};

/**
 * Generic 'Alerts' badge for notices, alerts etc.
 * - Currently in-use for 'Lockout' notices
 */
const AlertsIndicator = ({ alerts = [] }) => {
	if (alerts?.length === 0 || isEmptyArray(alerts)) {
		return null;
	}
	return (
		<div className={styles.AlertsIndicator}>
			<div className={styles.AlertsIndicator_count}>{alerts?.length}</div>
		</div>
	);
};

const CustomButton = ({
	icon,
	name,
	isActive = false,
	isDisabled = false,
	isHidden = false,
	setTab,
	children,
}) => {
	if (isHidden) {
		return null;
	}
	return (
		<div
			className={
				isDisabled ? styles.CustomButton_isDisabled : styles.CustomButton
			}
			onClick={setTab}
		>
			<svg className={styles.CustomButton_icon} style={getTabStyles(isActive)}>
				<use xlinkHref={`${getSprite(name)}#icon-${icon}`}></use>
			</svg>
			<span className={styles.CustomButton_name} style={getTabStyles(isActive)}>
				{name}
			</span>

			{children}
		</div>
	);
};

const AdminPage = ({ history, winSize, isCollapsed }) => {
	const { state: globalState, dispatch: dispatchToState } =
		useContext(GlobalStateContext);
	const { AlertsHandler, dispatchAlert } = useAlerts(initialSettings);
	const { queryParams, setParams } = useQueryParams(window.location);
	const { currentUser, currentFacility } = globalState;
	const [selectedFacility, setSelectedFacility] = useState(() => {
		return currentFacility?.communityName ?? "";
	});
	// current tab's index: default - 3 (ie. 'facility' tab)
	const [currentIdx, setCurrentIdx] = useState(3);

	// sets current tab index & syncs 'target' to query params & url
	const tabsController = (targetIdx) => {
		setCurrentIdx(targetIdx);
		setParams(window.location, {
			activeTab: tabIndexMap[targetIdx],
		});
	};

	// syncs current tab & url query params
	const setTabsByParams = (target) => {
		// if no 'activeTab' value provided, default to 'facility' tab
		if (isEmptyVal(target)) {
			return setParams(window.location, { activeTab: "facility" });
		}

		// otherwise set activeTab & current tab from url query params
		// ...then sync to local & tab's state

		setParams(window.location, {
			activeTab: target,
		});
		tabsController(tabMap[target]);
	};

	const handleParams = (target) => {
		// if no 'target' tab default to 'facility'
		if (target === "admin" || !target) {
			return setParams(window.location, {
				activeTab: "facility",
			});
		} else {
			// otherwise set 'activeTab' to 'target'
			return setParams(window.location, {
				activeTab: target,
			});
		}
	};

	const changeFacility = (selection) => {
		// fetch facility details here
		dispatchAlert("SUCCESS", {
			heading: `Loaded Facility`,
			subheading: `Loaded ${selection}`,
		});
	};

	// sets 'currentFacility' obj when 'selectedResident' changes
	const setFacility = useCallback(async () => {
		if (isEmptyVal(selectedFacility)) {
			return dispatchToState({ type: "CLEAR_FACILITY" });
		}
		const { token } = currentUser;
		const facilityRecord = matchUserFacilityByName(
			selectedFacility,
			currentUser?.facilities
		);
		const { facilityID } = facilityRecord;
		const { facilityInfo, facilityUsers, facilityLockouts } =
			await getFacilityInfoAndSummary(token, facilityID);

		dispatchToState({
			type: "SET_FACILITY_USERS",
			data: {
				facilityRecord: facilityRecord,
				facilityInfo: { ...processFacilityInfo(facilityInfo) },
				facilityUsers: [...processUserList(facilityUsers)],
				facilityLockouts: [...processLockoutsList(facilityLockouts)],
			},
		});
	}, [currentUser, dispatchToState, selectedFacility]);

	// sets 'currentFacility' in global store when it changes
	useEffect(() => {
		setFacility();
	}, [setFacility]);

	// syncs rendered tab to query params (eg. 'useQueryParams')
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (history?.location?.state?.target) {
			const target = history?.location?.state?.target;
			handleParams(target);
		}

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// onmount: set 'activeTab' via 'queryParams', if applicable
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (hasParams()) {
			setTabsByParams(queryParams?.activeTab);
		}

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<DashboardTabNav
				history={history}
				winSize={winSize}
				isCollapsed={isCollapsed}
				goBackCB={() => history.replace("/portal")}
			/>
			<ViewContainer title={viewDetails.title} desc={viewDetails.desc}>
				<div className={styles.AdminPage}>
					<header className={styles.AdminPage_header}>
						<div className={styles.AdminPage_header_facility}>
							{!isEmptyVal(currentFacility?.facilityID)
								? currentFacility?.communityName
								: ""}
						</div>
						<ALASelector
							key={`ADMIN_PAGE_SELECTOR`}
							disableResident={true} // facility only selector
							isAdmin={true} // admin only route
							facilities={currentUser?.facilities}
							defaultFacility={selectedFacility}
							syncFacility={setSelectedFacility}
							loadFacility={changeFacility}
						/>
					</header>

					<ControllableTabsWrapper
						activeIndex={currentIdx}
						disabledIndices={getHiddenTabs(currentUser)}
						hiddenIndices={getHiddenTabs(currentUser)}
						tabsController={tabsController}
					>
						<ControllableTabsButtons>
							{/* NEW USER - BUTTON */}
							<ControllableTabsButton>
								<CustomButton
									name="New User"
									icon={portalPageOptions.icons["New User"]}
									// setTab={() => handleParams("newUser")}
								/>
							</ControllableTabsButton>
							{/* EXISTING USERS - BUTTON */}
							<ControllableTabsButton>
								<CustomButton
									name="Existing Users"
									icon={portalPageOptions.icons["Existing Users"]}
									// setTab={() => handleParams("user")}
								/>
							</ControllableTabsButton>
							{/* LOCKOUTS - BUTTON */}
							<ControllableTabsButton>
								<CustomButton
									name="Lockouts"
									icon={portalPageOptions.icons["Lockouts"]}
									// setTab={() => handleParams("lockouts")}
								>
									<AlertsIndicator alerts={currentFacility?.lockouts} />
								</CustomButton>
							</ControllableTabsButton>
							{/* FACILITY MGMT - BUTTON */}
							<ControllableTabsButton>
								<CustomButton
									name="Facility Management"
									icon={portalPageOptions.icons["Facility Management"]}
									// setTab={() => handleParams("facility")}
								/>
							</ControllableTabsButton>
							{/* FACILITY INFO - BUTTON */}
							<ControllableTabsButton>
								<CustomButton
									name="Facility Info"
									icon={portalPageOptions.icons["Facility Info"]}
									// setTab={() => handleParams("profile")}
								/>
							</ControllableTabsButton>
							{/* CUSTOM SEARCH - BUTTON */}
							<ControllableTabsButton>
								<CustomButton
									name="Search"
									icon={portalPageOptions.icons["Search"]}
									// setTab={() => handleParams("search")}
								/>
							</ControllableTabsButton>
							{/* MANAGE RESIDENTS - BUTTON - DISABLED AS PER REQUEST AS OF 5/9/2023 */}
							{/* <ControllableTabsButton>
								<CustomButton
									name="Manage Residents"
									icon={portalPageOptions.icons["Manage Residents"]}
									// setTab={() => handleParams("search")}
								/>
							</ControllableTabsButton> */}
						</ControllableTabsButtons>

						<ControllableTabsPanels>
							{/* NEW USER VIEW ("NEW USER") */}
							<ControllableTabsPanel>
								<NewUserTab
									currentFacility={currentFacility}
									currentUser={currentUser}
									globalState={globalState}
									dispatchToState={dispatchToState}
									dispatchAlert={dispatchAlert}
								/>
							</ControllableTabsPanel>
							{/* USER ACCESS PANEL ("EXISTING USERS") */}
							<ControllableTabsPanel>
								<ManageUserAccessView
									globalState={globalState}
									dispatchToState={dispatchToState}
									currentFacility={currentFacility}
									selectedFacility={selectedFacility}
									key={selectedFacility || currentFacility?.users?.length}
								/>
							</ControllableTabsPanel>
							{/* USER LOCKOUTS ("LOCKOUTS") */}
							<ControllableTabsPanel>
								<UserLockouts
									globalState={globalState}
									dispatchToState={dispatchToState}
									currentFacility={currentFacility}
									currentUser={currentUser}
									selectedFacility={selectedFacility}
									key={`LOCKOUTS:${currentFacility?.lockouts?.length}`}
								/>
							</ControllableTabsPanel>
							{/* FACILITY ACCESS PANEL ("FACILITY APP ACCESS") */}
							<ControllableTabsPanel>
								<ManageFacilityAccessView
									globalState={globalState}
									dispatchToState={dispatchToState}
									dispatchAlert={dispatchAlert}
								/>
							</ControllableTabsPanel>
							{/* EDIT FACILITY INFO */}
							<ControllableTabsPanel>
								<FacilityInfo
									key={currentFacility?.facilityID}
									globalState={globalState}
									dispatchToState={dispatchToState}
									dispatchAlert={dispatchAlert}
									currentFacility={currentFacility}
									currentUser={currentUser}
								/>
							</ControllableTabsPanel>
							{/* CUSTOM SEARCH PANEL */}
							<ControllableTabsPanel>
								<CustomSearchView
									currentFacility={currentFacility}
									currentUser={currentUser}
									globalState={globalState}
									dispatchToState={dispatchToState}
								/>
							</ControllableTabsPanel>
							{/* MANAGE RESIDENTS PANEL */}
							{/* <ControllableTabsPanel>
								<ManageResidentsView
									currentFacility={currentFacility}
									currentUser={currentUser}
									globalState={globalState}
									selectedFacility={selectedFacility}
									dispatchToState={dispatchToState}
									dispatchAlert={dispatchAlert}
									key={
										`RESIDENTS:` + selectedFacility ||
										currentFacility?.users?.length
									}
								/>
							</ControllableTabsPanel> */}
						</ControllableTabsPanels>
					</ControllableTabsWrapper>
				</div>
			</ViewContainer>

			{AlertsHandler}
		</>
	);
};

export default AdminPage;

AdminPage.defaultProps = {};

AdminPage.propTypes = {
	history: PropTypes.object,
};
