import { currentEnv, isDevOrTest, isLocalhost } from "./utils_env";
import { permissions } from "./utils_endpoints";
import { WhiteListedUserModel } from "./utils_models";
import {
	USER_TYPE_RANK_BY_LABEL,
	USER_TYPE_RANK_MAP,
	getUserTypeLabel,
} from "./utils_userTypes";

////////////////////////////////////////////////////////////
///////////////////// USER PERMISSIONS /////////////////////
////////////////////////////////////////////////////////////

const ENABLE_EMAR = false;
const ENABLE_EPAY = false;

/**
 * Creates/sets user permissions for various ALA modules.
 * @param {String} token - Auth token
 * @param {String} userLoginId - UserLoginID string
 * @param {Object} permissionsModel - Custom 'permissions' object.
 * @returns {Boolean} - Returns whether user's permissions were saved.
 */
const createUserPermissions = async (token, userLoginId, permissionsModel) => {
	let url = currentEnv.base + permissions.create.byUser;
	url += "?" + new URLSearchParams({ userLoginId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(permissionsModel),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// List of UserIDs that should have access to super-features
const featuresWhiteList = [
	`f61244bd-f3c3-4b2f-a073-2a58f850c046`, // trainingsb@aladvantage.com - Seth's #1 (TEST & PROD)
	`7801cc7e-4462-4442-b214-bcdff70b3f95`, // trainingsb2@aladvantage.com - Seth's #2 (TEST & PROD)
	`edc28929-2c5d-4fc6-8188-f786c1d598d4`, // dandemo@aladvantage.com - Seth's super-user account
	`2807d3cc-c791-4eae-810d-5749a2347a68`, // testingme334@gmail... (PROD)
	`e99c5c77-68b7-4ccf-aa5f-5dd5601aff16`, // sgore99@ala... (PROD)
	`86a67ebe-38e6-4b66-bc5a-8b14940ddf68`, // crule@ala... (PROD)
	// `30e9000c-0b38-4645-bde2-824bdbfed572`, // granitecollission@gmail.com
];

/**
 * Internal Feature's WhiteList:
 * - Restricts/enables feature access for INTERNAL-USE-ONLY to select users ONLY!
 */
const internalWhiteList = [
	`e99c5c77-68b7-4ccf-aa5f-5dd5601aff16`, // sgore99@aladvantage.com - main
	`2807d3cc-c791-4eae-810d-5749a2347a68`, // sgore99@ala/testingme334@gmail.com... (PROD)
	`86a67ebe-38e6-4b66-bc5a-8b14940ddf68`, // crule@ala... (PROD)
	`f61244bd-f3c3-4b2f-a073-2a58f850c046`, // trainingsb@ala... (TEST/PROD)
	`7801cc7e-4462-4442-b214-bcdff70b3f95`, // trainingsb2@ala... (TEST/PROD)
	`edc28929-2c5d-4fc6-8188-f786c1d598d4`, // dandemo@ala... (TEST/PROD)
];

/**
 * Concise map of 'Migration' states.
 */
const migrationStates = {
	COMPLETED: "COMPLETED",
	FAILED: "FAILED",
	PROCESSING: "PROCESSING",
	NOT_MIGRATED: "NOT-MIGRATED",
	SCHEDULED: "SCHEDULED",
};

/**
 * Detailed 'Migration' states w/ descriptions of each state.
 */
const MIGRATION_STATES = {
	COMPLETED: {
		state: "COMPLETED",
		desc: "Migration has completed.",
	},
	FAILED: {
		state: "FAILED",
		desc: "There was an error. Migration attempt failed.",
	},
	PROCESSING: {
		state: "PROCESSING",
		desc: "Migration is still processing. Check back later.",
	},
	NOT_MIGRATED: {
		state: "NOT-MIGRATED",
		desc: "Migration has not started. There are no current migrations scheduled.",
	},
	SCHEDULED: {
		state: "SCHEDULED",
		desc: "Migration has been scheduled for a future date. Check back later.",
	},
};

/**
 * 'Feature Flags': temporary feature's map for toggling features on/off.
 * - Should be replaced via table-driven records in the future.
 */
const featureFlags = {
	user: {
		changeResetMethods: true,
		changeAppAccess: true,
		changeUserType: true,
		suspendUser: true,
		suspendMyUser: false, // internal only: allows a logged in user to suspend their account, should force log them out
		unlockUser: true,
		deleteUser: true,
		deleteMyUser: false, // internal only: allows a logged in user to delete their account, should force log them out
		addCustomTitle: false,
		resetUsersPassword: true,
		editAvatar: false,
		enableNameChanges: true,
		newUserButton: false,
		editOwnFacilityAccess: false,
		enableResetPreference: false,
		enableEmar: true,
	},
	facility: {
		deleteFacility: false,
		grantAccess: true,
		enableNotifications: true,
		enableActionButtons: false,
		enableYardiNumber: true,
		enableCustomSearch: false,
		enableDeactivations: false, // '<FacilityAccessRow/>' feature
		enableLockoutSearch: false,
		enableEmar: true,
		enableEmarToggles: false,
	},
	search: {
		byUserType: true,
	},
	views: {},
	banners: {
		disableServiceBannerOnLocal: false,
		// enableEmarBanners: !isLocalhost(),
		enableEmarBanners: false,
	},
};

// TEMPORARY MOCK EXAMPLE(S)
// organizes 'featureFlags' map by Application
const permissionsByApp = {
	AdvantageTracker: {
		pageViews: [
			{
				name: "enableDailyView",
				id: "enableDailyView",
				label: "Daily View",
			},
			{
				name: "enableCalendarView",
				id: "enableCalendarView",
				label: "Calendar View",
			},
			// reports
			{
				name: "enableCompletionReport",
				id: "enableCompletionReport",
				label: "Completion Report",
			},
			{
				name: "enableExceptionReport",
				id: "enableExceptionReport",
				label: "Exception Report",
			},
			{
				name: "enablePastDueReport",
				id: "enablePastDueReport",
				label: "Past Due Report",
			},
			{
				name: "enableReassessReport",
				id: "enableReassessReport",
				label: "Reassess Report",
			},
			{
				name: "enableTaskCreatedReport",
				id: "enableTaskCreatedReport",
				label: "Task Created Report",
			},
			{
				name: "enableTaskStatus",
				id: "enableTaskStatus",
				label: "Task Status Report",
			},
		],
		tasks: {
			deleteTask: false,
			createTask: false,
			editTask: false,
		},
	},
	SeniorCareVB: {
		pageViews: [
			{
				name: "enableResidentsPage",
				id: "enableResidentsPage",
				label: "Residents Page", // 'Residents' page
			},
			{
				name: "enableReportsPage",
				id: "enableReportsPage",
				label: "Reports Page", // 'Reports' page
			},
			{
				name: "enableChartingPage",
				id: "enableChartingPage",
				label: "Charting Page", // 'Charting' page
			},
			{
				name: "enableCommunityInfo",
				id: "enableCommunityInfo",
				label: "Community Info Page", // 'Community Info' page
			},
			{
				name: "enableArchivedResidentsPage",
				id: "enableArchivedResidentsPage",
				label: "Archived Residents Page", // 'Archived Residents' page
			},
			// tabs within a 'Community Info' page
			{
				name: "enableCommunityMTC", // community maintenance
				id: "enableCommunityMTC", // community maintenance
				label: "Community Maintenance",
			},
			{
				name: "enableUserMTC", // user maintenance
				id: "enableUserMTC", // user maintenance
				label: "User Maintenance",
			},
		],
	},
	AdminPortal: {
		userAccess: { ...featureFlags?.user },
		facilityAccess: { ...featureFlags?.facility },
	},
};

/**
 * Determines whether to enable/disable a feature, via permissions and/or user-type.
 * @param {Object} currentUser - A client-formatted user object.
 * @param {Array} permissions - An array of permissions records.
 * @returns {Boolean} - Returns true|false
 */
const enableAccess = (currentUser = {}) => {
	const { userID, isAdmin, isRegionalAdmin, isSuperUser } = currentUser;
	const normalWhitelist = featuresWhiteList.map((x) => x?.toLowerCase());
	// const whiteListOverride = featuresWhiteList.includes(userID);
	// normalized userIDs to match casing
	const whiteListOverride = normalWhitelist.includes(userID?.toLowerCase());
	// override feature access via 'whitelist'
	if (whiteListOverride) {
		return true;
	} else {
		return isAdmin || isRegionalAdmin || isSuperUser;
	}
};

/**
 * Determines whether a feature should be enabled/disabled.
 * @param {String} featureName - A feature's key name.
 * @param {Object} featureFlags - An object map of feature flags
 * @returns {Boolean} - Returns true|false
 */
const enableFeature = (featureName, featureFlags = {}) => {
	const isEnabled = featureFlags[featureName];
	return isEnabled;
};

/**
 * Determines a user's access, along with the feature's state (eg 'on/off').
 * @param {String} featureName - A string feature name.
 * @param {Object} featureFlags - An object map of feature flags.
 * @param {Object} currentUser - A user for whom to check access for.
 * @returns {Boolean} - Returns true|false
 */
const isAccessEnabled = (featureName, featureFlags = {}, currentUser = {}) => {
	// feature override for whitelist

	if (!enableFeature(featureName, featureFlags)) return false;
	if (featuresWhiteList.includes(currentUser.userID)) return true;
	if (!enableAccess(currentUser)) return false;
	// if (currentUser?.isReadOnly) return false;
	return true;
};

/**
 * Enables/disables a feature via whitelist override
 * @param {String} userID - User ID
 * @returns {Boolean} - Returns whether a feature can be enabled (even if turned off) via whitelist
 */
const enableFeatureViaWhiteList = (userID) => {
	const hasOverride = featuresWhiteList.includes(userID);

	return hasOverride;
};

/**
 * Enables/disables a feature via internal whitelist override
 * @param {String} userID - User ID
 * @returns {Boolean} - Returns whether a feature can be enabled (even if turned off) via whitelist
 */
const enableFeatureViaInternalList = (userID) => {
	const normalList = internalWhiteList.map((x) => x?.toLowerCase());
	const hasOverride = normalList.includes(userID?.toLowerCase());
	// const hasOverride = internalWhiteList.includes(userID);

	return hasOverride;
};

// USER-TYPES UPDATER UTILS //

// WHITELISTS UPDATER UTILS //

const updateWhiteListModel = (userVals = {}) => {
	const base = new WhiteListedUserModel(userVals);
	const model = base.getModel();

	return model;
};

// WHITELISTS REQUEST UTILS //

const getUserLoginFromWhiteList = async (token, userID) => {
	let url = currentEnv.base + permissions.whitelist.getInfo;
	url += "?" + new URLSearchParams({ UserLoginID: userID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data?.[0] ?? {};
	} catch (err) {
		return err.message;
	}
};

const getUserLoginsFromWhiteList = async (token, whitelist = []) => {
	//
	//
};

/**
 * Fetches list of whitelisted user records.
 * @param {String} token - Auth token
 * @returns {Array} - Returns an array of whitelisted user objects.
 */
const getUserWhiteList = async (token) => {
	let url = currentEnv.base + permissions.whitelist.get;

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

const addUserToWhiteList = async (token, whiteListModel) => {
	let url = currentEnv.base + permissions.whitelist.save;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(whiteListModel),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// USER-ACCESS PERMISSIONS //

// enables a feature by certain user type of selected user
const enableFeatureByTargetUserType = (selectedUser, currentUser) => {
	// check 'selectedUser' user type
	// check 'currentUser' user type
	// add condition for whitelisted users & user types

	// we want to disable certain features if the 'selected' user is an FA or higher
	const isAdminType =
		selectedUser.isFacilityAdmin ||
		selectedUser?.isRegionalAdmin ||
		selectedUser?.isAdmin;
	const isInternalOverride = enableFeatureViaInternalList(currentUser?.userID);
	const isWhitelistOverride = enableFeatureViaWhiteList(currentUser?.userID);
	const hasOverride = isInternalOverride || isWhitelistOverride;

	if (hasOverride) {
		return true;
	}
	const shouldEnable = !isAdminType;

	return shouldEnable;
};

// Checks a users facility access:
// - If user has multi-facility access we DISABLE the feature
// - If user has single facility access we ENABLE the feature
// - If user's facility access count is null, the default to disable
const disableForMultiFacilityAccess = (selectedUser) => {
	const accessCount = selectedUser?.facilityAccessCount;

	// if count > 1 && count !== null
	const shouldDisable = accessCount > 1 || !accessCount;

	return shouldDisable;
};

// We check:
// - If feature should be enabled by according to their user type
// - If feature should be enabled according to their facility access
const disableByUserTypeAndAccess = (targetUser, currentUser) => {
	// for FAs and higher, this feature should be disabled
	const isEnabledByUserType = enableFeatureByTargetUserType(
		targetUser,
		currentUser
	);
	// for non-FAs w/ multi-facility access, this feature should be disabled
	const disableByAccess = disableForMultiFacilityAccess(targetUser);
	const shouldDisable = !isEnabledByUserType || disableByAccess;

	return shouldDisable;
};

// Takes a user & returns their user type rank as a number
const getUserTypeRankValue = (user) => {
	const userTypeLabel = getUserTypeLabel(user);
	const rank = USER_TYPE_RANK_BY_LABEL[userTypeLabel];

	return rank;
};

/**
 * Enable 'Suspend' Feature by User Type Comparison
 * - Default logic:
 * 		- IF 'authed' user's rank is higher than selected user, EDIT IS ENABLED
 * 		- IF 'authed' user's rank is equal to selected user, EDIT IS DISABLED
 * - Special Case (ExecutiveAdmin - EA):
 * 		- IF 'authed' user's rank is EA, they MAY EDIT OTHER EA USER'S ACCESS
 *
 */
const enableSuspendPermission = (targetUser, currentUser) => {
	const selectedUserRank = getUserTypeRankValue(targetUser);
	const authedUserRank = getUserTypeRankValue(currentUser);

	const hasHigherRank = authedUserRank > selectedUserRank;
	const selectedIsEA =
		selectedUserRank === USER_TYPE_RANK_MAP.ExecutiveAdministrator;
	const authedIsEA =
		authedUserRank === USER_TYPE_RANK_MAP.ExecutiveAdministrator;
	// special handling for EAs
	if (selectedIsEA && authedIsEA) return true;

	return hasHigherRank;
};

const enableFeatureByUserRank = (selectedUser, currentUser) => {
	const selectedUserRank = getUserTypeRankValue(selectedUser);
	const authedUserRank = getUserTypeRankValue(currentUser);

	const hasHigherRank = authedUserRank > selectedUserRank;
	// Special handling for EAs
	const selectedIsEA =
		selectedUserRank === USER_TYPE_RANK_MAP.ExecutiveAdministrator;
	const authedIsEA =
		authedUserRank === USER_TYPE_RANK_MAP.ExecutiveAdministrator;

	// Special handling for SuperUsers
	const isSuperOrAbove = authedUserRank >= USER_TYPE_RANK_MAP.SuperUser;

	// console.group("Enable Feature By User Rank");
	// console.log("selectedUserRank", selectedUserRank);
	// console.log("authedUserRank", authedUserRank);
	// console.log("isSuperOrAbove", isSuperOrAbove);
	// console.log("selectedIsEA", selectedIsEA);
	// console.log("authedIsEA", authedIsEA);
	// console.groupEnd();

	// special handling for SuperUsers
	if (isSuperOrAbove) return true;
	// special handling for EAs
	if (selectedIsEA && authedIsEA) return true;

	return hasHigherRank;
};

// Compare's user's ranks & whether 'selectedUser' has multi-facility access
const enableFeatureByRankAndAccess = (selectedUser, currentUser) => {
	const selectedUserRank = getUserTypeRankValue(selectedUser);
	const currentUserRank = getUserTypeRankValue(currentUser);
	const enableByRank = enableFeatureByUserRank(selectedUser, currentUser);
	const isNonAdmin =
		selectedUserRank < USER_TYPE_RANK_MAP.FacilityAdministrator;
	// we disable feature IF 'selectedUesr' is non-admin AND has multi-facility access
	const shouldDisableByAccess =
		isNonAdmin && selectedUser?.facilityAccessCount > 1;

	// give super users global permission
	if (currentUserRank >= USER_TYPE_RANK_MAP.SuperUser) return true;

	// disable when 'selectedUser' is non-admin && has multi-fac access
	if (shouldDisableByAccess) return false;

	return enableByRank;
};

// permissions request utils
export { createUserPermissions };

export { permissionsByApp };

export { migrationStates, MIGRATION_STATES };

// feature flags & utils
export {
	featuresWhiteList,
	internalWhiteList,
	featureFlags,
	enableAccess,
	enableFeature,
	isAccessEnabled,
	enableFeatureViaWhiteList,
	enableFeatureViaInternalList,
};

export {
	// whitelist model updaters
	updateWhiteListModel,
	// whitelist request utils
	getUserWhiteList,
	addUserToWhiteList,
	getUserLoginFromWhiteList,
};

export {
	enableFeatureByTargetUserType,
	disableByUserTypeAndAccess,
	enableSuspendPermission,
	// reusable version for generic feature-permissions
	enableFeatureByUserRank,
	enableFeatureByRankAndAccess,
};

export { ENABLE_EMAR, ENABLE_EPAY };
