import { red, green, purple } from "./utils_styles";
import { isEmptyArray, isEmptyVal } from "./utils_types";
import { isSpecialChar } from "./utils_validation";

//////////////////////////////////////////////////////////////////////
////////////////////// CURRENCY PROCESSING UTILS //////////////////////
//////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////
////////////////////// STRING PROCESSING UTILS //////////////////////
//////////////////////////////////////////////////////////////////////

const enforceStrMaxLength = (str, maxLength = 30) => {
	if (isEmptyVal(str)) return "";
	if (str.length < maxLength) return str;
	return str?.slice(0, maxLength);
};

const addEllipsis = (str, maxLength = 30) => {
	if (isEmptyVal(str)) return ``;
	if (str.length < maxLength) return str;
	const managedStr = enforceStrMaxLength(str, maxLength);
	return managedStr + "...";
};

const capitalizeCharByIdx = (idx, str) => {
	return str.charAt(idx).toUpperCase() + str.slice(1);
};

// converts num to letter (MUST BE WITHIN RANGE: 97-122 A-Za-z)
// 65-122
const numToLetter = (num) => {
	const letter = String.fromCharCode(num);
	return letter;
};
// gets a 'random' number within a range
const numInRange = (min = 67, max = 122) => {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
};
const getRandomNum = () => {
	return Math.round(Math.random() * (11 - 1) + 1);
};

// count: how many 'random chars' to generate
// used for ids in <ReassessReport/>
const generateID = (count = 8) => {
	const baseCount = range(1, count, (x) => x + 1);
	const random = baseCount
		.map((x) => {
			const inRange = numInRange();
			return numToLetter(inRange);
		})
		.join("");
	return random;
};

// creates a unique ID, w/ a 'timestamp' of when it was created
const generateUID = (idLength = 36) => {
	const x1 = generateID(idLength);
	return `${x1}=${Date.now()}`;
};

/**
 * Generates 'guid' string via 'crypto.randomUUID()' (if available), otherwise fallback to util.
 * @returns {String} - Returns string guid
 */
const generateGUID = () => {
	if (crypto) {
		return crypto.randomUUID();
	} else {
		return generateUID(36);
	}
};

// generates a temp password of a given length
const generateTempPassword = (length = 9) => {
	let tempPwd = ``;

	for (let i = 0; i < length; i++) {
		const num = numInRange(65, 122);
		const char = numToLetter(num);
		tempPwd += isSpecialChar(char) ? `${getRandomNum()}` : `${char}`;
	}
	// finish length enforcement
	if (tempPwd.length > length) {
		return enforceStrMaxLength(tempPwd, length);
	}

	return tempPwd;
};

// check for odd
// returns 0 if there's a remainer, then true, else if evenly divided then false
const isOdd = (num) => {
	return Boolean(num % 2);
};

// check for even
const isEven = (num) => {
	return !Boolean(num % 2);
};

//////////////////////////////////////////////////////////////////////
/////////////////////// ARRAY PROCESSING UTILS ///////////////////////
//////////////////////////////////////////////////////////////////////

const range = (start, stop, callback) => {
	return Array.from({ length: stop - start }, (_, i) => callback(i + start));
};

const groupBy = (list, iteratee) => {
	return list.reduce((acc, item) => {
		const keyToSortBy = iteratee(item);
		if (!acc[keyToSortBy]) {
			acc[keyToSortBy] = [];
		}
		acc[keyToSortBy].push(item);
		return acc;
	}, {});
};

const removeDups = (list) => {
	return [...new Set(list)];
};

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
	var timeout;

	return function executedFunction() {
		var context = this;
		var args = arguments;

		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};

		var callNow = immediate && !timeout;

		clearTimeout(timeout);

		timeout = setTimeout(later, wait);

		if (callNow) func.apply(context, args);
	};
}

//////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////// SORTING ARRAYS  //////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////

/**
 * Sorts an array alphabetically (non-objects).
 */

// sorts string alphabetically (A-Z)
const sortByAlphaAsc = (list = []) => {
	if (isEmptyArray(list)) return [];
	return [...list].sort((a, b) => {
		return a?.localeCompare(b);
	});
};
// sorts string alphabetically (Z-A)
const sortByAlphaDesc = (list = []) => {
	if (isEmptyArray(list)) return [];
	return [...list].sort((a, b) => {
		return b?.localeCompare(a);
	});
};
// returns 'true' values first (ie. [true, true, false, false, null] )
const sortByBoolAsc = (list = []) => {
	if (isEmptyArray(list)) return [];
	return [...list].sort((a, b) => {
		return a === b ? 0 : a ? -1 : 1;
	});
};
// returns 'false' values first (ie. [null, null, false, false, true] )
const sortByBoolDesc = (list = []) => {
	if (isEmptyArray(list)) return [];
	return [...list].sort((a, b) => {
		return a === b ? 0 : a ? 1 : -1;
	});
};
// sorts number ascending order (1-10)
const sortByNumAsc = (list = []) => {
	if (isEmptyArray(list)) return [];
	return [...list].sort((a, b) => {
		return a - b;
	});
};
// sorts number descending order (10-1)
const sortByNumDesc = (list = []) => {
	if (isEmptyArray(list)) return [];
	return [...list].sort((a, b) => {
		return b - a;
	});
};
// sorts date ascending order (10-1)
const sortByDateAsc = (list = []) => {
	if (isEmptyArray(list)) return [];
	return [...list].sort((a, b) => {
		return new Date(a) - new Date(b);
	});
};
// sorts date descending order (10-1)
const sortByDateDesc = (list = []) => {
	if (isEmptyArray(list)) return [];
	return [...list].sort((a, b) => {
		return new Date(b) - new Date(a);
	});
};

/**
 * Sorts an array alphabetically by object property (key).
 * - Key value MUST be a <String>
 */

// sorts alphabetically (ascending order) by key
const sortAlphaAscByKey = (key, list = []) => {
	if (isEmptyArray(list)) return [];
	return list.sort((a, b) => {
		return a?.[key]?.localeCompare(b?.[key]);
	});
};

// sorts alphabetically (descending order) by key
const sortAlphaDescByKey = (key, list = []) => {
	return list.sort((a, b) => {
		return b?.[key]?.localeCompare(a?.[key]);
	});
};

// returns 'true' values first
const sortBoolAscByKey = (key, list = []) => {
	if (isEmptyArray(list)) return [];
	return [...list].sort((a, b) => {
		return a?.[key] === b?.[key] ? 0 : a?.[key] ? -1 : 1;
	});
};

// returns 'false' values first
const sortBoolDescByKey = (key, list = []) => {
	if (isEmptyArray(list)) return [];
	return [...list].sort((a, b) => {
		return a?.[key] === b?.[key] ? 0 : a?.[key] ? 1 : -1;
	});
};

// sorts number ascending order (1-10)
const sortNumAscByKey = (key, list = []) => {
	if (isEmptyArray(list)) return [];
	return [...list].sort((a, b) => {
		return a?.[key] - b?.[key];
	});
};
// sorts number descending order (10-1)
const sortNumDescByKey = (key, list = []) => {
	if (isEmptyArray(list)) return [];
	return [...list].sort((a, b) => {
		return b?.[key] - a?.[key];
	});
};

// sorts date ascending order (10-1)
const sortDateAscByKey = (key, list = []) => {
	if (isEmptyArray(list)) return [];
	return [...list].sort((a, b) => {
		return new Date(a?.[key]) - new Date(b?.[key]);
	});
};
// sorts date descending order (10-1)
const sortDateDescByKey = (key, list = []) => {
	if (isEmptyArray(list)) return [];
	return [...list].sort((a, b) => {
		return new Date(b?.[key]) - new Date(a?.[key]);
	});
};

/**
 * Global array sorter util.
 * - Sorts array of objects or primitives
 */

// OBJECT HANDLING UTILS //
// deletes a single key from an object
const deleteKey = (key, obj) => {
	const { [key]: _, ...rest } = obj;
	return rest;
};
// removes multiple keys from an obj
const deleteKeysMany = (obj, keys = []) => {
	return keys.reduce((newObj, key) => {
		// destructure, drop 'key' & keep the rest
		const { [key]: _, ...rest } = newObj;
		newObj = rest;
		return newObj;
	}, obj);
};

// LOGGING UTILS //

const migrationLogger = (facilityName, userCount = 0) => {};

/**
 * Logs environment, and maintenance mode status upon application's mounting.
 * @param {String} env - Custom 'REACT_APP_CURRENT_ENV_NAME' value as manual env/override.
 * @param {String} nodeEnv - Current 'NODE_ENV' value.
 * @param {Boolean} maintenanceMode - Boolean defining whether 'MAINTENANCE_MODE' is enabled/disabled.
 * @returns {Null} - Returns null and merely logs out the current environment and status of maintenance mode.
 */
const envLogger = (env, nodeEnv, maintenanceMode) => {
	switch (env) {
		case "development":
		case "test": {
			console.group(
				`%cENVIRONMENT: ${env}/test`,
				`color: ${green[500]};font-size: 1rem;`
			);

			console.log(
				`%cMaintenance Mode: ${maintenanceMode ? "Enabled" : "Disabled"}`,
				`color: ${maintenanceMode ? red[500] : green[500]};`
			);

			return;
		}
		case "prod":
		case "production": {
			console.group(
				`%cENVIRONMENT: ${env}`,
				`color: ${red[600]};font-size: 1rem;`
			);

			console.log(
				`%cMaintenance Mode: ${maintenanceMode ? "Enabled" : "Disabled"}`,
				`color: ${maintenanceMode ? red[500] : green[500]};`
			);

			return;
		}
		case "custom": {
			console.log(
				`%cMaintenance Mode: ${maintenanceMode ? "Enabled" : "Disabled"}`,
				`color: ${maintenanceMode ? red[500] : green[500]};`
			);

			return;
		}
		default:
			return "unknown";
	}
};

// performance & misc utils
export { range, debounce, isOdd, isEven };

// id & guid utils
export {
	capitalizeCharByIdx,
	getRandomNum,
	numToLetter,
	numInRange,
	generateID,
	generateUID,
	generateGUID,
	generateTempPassword,
};

// array-sorting utils
export {
	groupBy,
	removeDups,
	// sort arrays (non-objects)
	sortByAlphaAsc,
	sortByAlphaDesc,
	sortByBoolAsc,
	sortByBoolDesc,
	sortByDateAsc,
	sortByDateDesc,
	sortByNumAsc,
	sortByNumDesc,
	// sort arrays of objects by key
	sortAlphaAscByKey,
	sortAlphaDescByKey,
	sortBoolAscByKey,
	sortBoolDescByKey,
	sortNumAscByKey,
	sortNumDescByKey,
	sortDateAscByKey,
	sortDateDescByKey,
};

// string processing utils
export { enforceStrMaxLength, addEllipsis };

// object utils
export { deleteKey, deleteKeysMany };

// logging utils
export { migrationLogger, envLogger };
