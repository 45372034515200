import React from "react";
import styles from "../../css/user/CreateUserStep7.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";
import { green } from "../../helpers/utils_styles";
// components
import CreateUserSummary from "./CreateUserSummary";
import SuccessMsg from "./SuccessMsg";
import ButtonSM from "../shared/ButtonSM";

// REQUIREMENTS:
// - Add 'Send Temp Password Via Email' button that applies to users using a valid email

const customCSS = {
	another: {
		display: "flex",
		justifyContent: "center",
		padding: "1rem 1.7rem",
		backgroundColor: green[500],
		color: "#ffffff",
		fontSize: "1.6rem",
		fontWeight: "600",
	},
};

const getNewUsersLoginName = (vals = {}) => {
	const { username, email } = vals;
	if (isEmptyVal(username) && isEmptyVal(email)) {
		return `Not Provided`;
	} else if (isEmptyVal(email)) {
		return username;
	} else {
		return email;
	}
};

const InfoMsg = () => {
	return (
		<div className={styles.InfoMsg}>
			<div className={styles.InfoMsg_msg}>
				This new user can be found & viewed by clicking the{" "}
				<b>Existing Users</b> tab above.
			</div>
		</div>
	);
};

const CreateUserStep7 = ({
	vals = {},
	handleChange,
	cancelNewUser,
	createNewUser,
	userWasCreated = false,
	createAnother,
	goToStep,
	facilityAccessList = [],
}) => {
	const successHandler = (e) => {
		goToStep(1);
		createAnother(e);
	};

	return (
		<div className={styles.CreateUserStep7}>
			{!userWasCreated && (
				<CreateUserSummary
					vals={vals}
					handleChange={handleChange}
					cancelNewUser={cancelNewUser}
					createNewUser={createNewUser}
					facilityAccessList={facilityAccessList}
				/>
			)}

			{userWasCreated && (
				<SuccessMsg
					msg={`The user "${getNewUsersLoginName(
						vals
					)}" was created successfully!`}
				>
					<InfoMsg />
					<div className={styles.Success}>
						<ButtonSM
							customStyles={customCSS.another}
							// handleClick={createAnother}
							handleClick={successHandler}
						>
							Create Another Account?
						</ButtonSM>
					</div>
				</SuccessMsg>
			)}
		</div>
	);
};

export default CreateUserStep7;

CreateUserStep7.defaultProps = {
	vals: {},
};

CreateUserStep7.propTypes = {
	vals: PropTypes.object,
	cancelNewUser: PropTypes.func,
	createNewUser: PropTypes.func,
};
