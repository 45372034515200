import React, { useEffect, useState } from "react";
import styles from "../../css/user/CreateUserStep2.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";
import { isValidEmail } from "../../helpers/utils_validation";
// components
import Divider from "../forms/Divider";
import CustomCheckbox from "../shared/CustomCheckbox";
import EmailValidator from "../forms/EmailValidator";
import UsernameValidator from "../forms/UsernameValidator";
import TempPassword from "../app/TempPassword";

const customCSS = {
	adminEmail: {
		marginBottom: "2rem",
		backgroundColor: "#eaecef",
	},
	username: {
		width: "100%",
		marginBottom: "3rem",
		backgroundColor: "#eaecef",
	},
	password: {
		width: "100%",
		marginBottom: "2rem",
	},
};

const adminMsg = `Admins & Super-Users MUST provide a valid, working email address.`;
const nonAdminMsg = `Usernames MUST be in an email format such as: `;
const nonAdminSamples = `fakeemail@email.com or myusername@mycompany.com`;

// REQUIREMENTS:
// - For ADMINS, MUST PROVIDE A VALID EMAIL ADDRESS!!!

const adminTypes = [`Administrator`, `SuperUser`, `FacilityAdministrator`];

const checkPasswordsMatch = (password, confirmPassword) => {
	return (
		!isEmptyVal(password) &&
		!isEmptyVal(confirmPassword) &&
		password === confirmPassword
	);
};

const CreateUserStep2 = ({
	currentUser = {},
	vals = {},
	handleAdminEmail,
	handleValidEmailCheck,
	handleChange,
	handleCheckbox,
}) => {
	const { password, confirmedPassword } = vals;
	// disabled; now we use 'tempPassword' that's programmatically generated!
	const [passwordsMatch, setPasswordsMatch] = useState(() => {
		return checkPasswordsMatch(password, confirmedPassword);
	});

	// 'watcher' for confirming passwords match
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		setPasswordsMatch(false);
		if (checkPasswordsMatch(password, confirmedPassword)) {
			setPasswordsMatch(true);
		}

		return () => {
			isMounted = false;
		};
	}, [confirmedPassword, password]);

	return (
		<div className={styles.CreateUserStep2}>
			<div className={styles.CreateUserStep2_info}>
				{!adminTypes.includes(vals.userType) && (
					<p className={styles.CreateUserStep2_info_requirements}>
						<span className={styles.CreateUserStep2_info_requirements_text}>
							{nonAdminMsg}
						</span>
						<span className={styles.CreateUserStep2_info_requirements_sample}>
							{nonAdminSamples}
						</span>
					</p>
				)}
			</div>
			{/* ADMINS REQUIRE A VALID EMAIL/ EMAIL VALIDATOR */}
			{adminTypes.includes(vals.userType) && (
				<>
					<p className={styles.CreateUserStep2_adminEmail}>{adminMsg}</p>
					<EmailValidator
						key={`EMAIL-FOR-ADMIN`}
						name="email"
						id="email"
						label="Enter a valid email"
						initialVal={vals?.email ?? ""}
						val={vals?.email}
						handleChange={handleAdminEmail}
						customStyles={customCSS.adminEmail}
						placeholder="Your email..."
						token={currentUser?.token}
					/>
					{isValidEmail(vals?.email) && (
						<div className={styles.CreateUserStep2_isWorkingEmail}>
							<CustomCheckbox
								name="isValidEmail"
								id="isValidEmail"
								label="Is This a Working Email Address?"
								val={vals?.isValidEmail}
								handleCheckbox={handleValidEmailCheck}
							/>
						</div>
					)}
				</>
			)}
			{/* NON-ADMIN USERNAME VALIDATOR */}
			{!adminTypes.includes(vals?.userType) && (
				<>
					<EmailValidator
						key={`USERNAME-FOR-NON-ADMIN`}
						label="Email (eg. myfakeemail@email.com)"
						name="email"
						id="email"
						initialVal={vals?.email}
						val={vals.email}
						handleChange={handleChange}
						customStyles={customCSS.username}
						placeholder="Create a username..."
						pattern="^([A-Z]{1,})([a-z]{1,})([0-9]{1,})"
						disableErrors={true}
						token={currentUser?.token}
					/>
					{isValidEmail(vals?.email) && (
						<div className={styles.CreateUserStep2_isWorkingEmail}>
							<CustomCheckbox
								name="isValidEmail"
								id="isValidEmail"
								label="Is This a Working Email Address?"
								val={vals?.isValidEmail}
								handleCheckbox={handleValidEmailCheck}
							/>
						</div>
					)}
				</>
			)}

			<Divider />
			<div className={styles.CreateUserStep2_password}>
				{/* TEMP PASSWORD - PRE-GENERATED */}

				<TempPassword
					tempPassword={vals.tempPassword}
					handleChange={handleChange}
					disableEdit={true}
				/>
				<div className={styles.CreateUserStep2_password_msg}>
					NOTE: Temporary passwords will be emailed to all users who provide a
					valid email address.
				</div>
			</div>
		</div>
	);
};

export default CreateUserStep2;

CreateUserStep2.defaultProps = {
	vals: {},
};

CreateUserStep2.propTypes = {
	vals: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
};
