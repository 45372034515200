import React from "react";
import styles from "../../css/loctemplate/LOCLoadedTemplateValidator.module.scss";
import sprite from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";
import { green, red } from "../../helpers/utils_styles";
import { isEmptyVal } from "../../helpers/utils_types";
// components
import Divider from "../forms/Divider";
import LOCApiValidator from "../loc/LOCApiValidator";

const custom = {
	pass: {
		icon: "check_circle",
		css: {
			fill: green[500],
		},
	},
	fail: {
		icon: "clearclose",
		css: {
			fill: red[600],
		},
	},
};

const { pass, fail } = custom;

const Name = ({ vals }) => {
	const { templateName } = vals;
	const isEmpty = isEmptyVal(templateName);
	return (
		<div className={styles.Name}>
			<svg className={styles.Name_icon} style={isEmpty ? fail.css : pass.css}>
				<use
					xlinkHref={`${sprite}#icon-${isEmpty ? fail.icon : pass.icon}`}
				></use>
			</svg>
			<div
				className={styles.Name_text}
				style={isEmpty ? { color: red[600] } : {}}
			>
				{!isEmptyVal(templateName)
					? "Template 'name' is ok!"
					: "Template 'name' is required!"}
			</div>
		</div>
	);
};
const Desc = ({ vals }) => {
	const { templateDesc } = vals;
	const isEmpty = isEmptyVal(templateDesc);
	return (
		<div
			className={styles.Desc}
			style={isEmptyVal(templateDesc) ? { color: red[600] } : {}}
		>
			<svg className={styles.Desc_icon} style={isEmpty ? fail.css : pass.css}>
				<use
					xlinkHref={`${sprite}#icon-${isEmpty ? fail.icon : pass.icon}`}
				></use>
			</svg>
			<div
				className={styles.Desc_text}
				style={isEmpty ? { color: red[600] } : {}}
			>
				{!isEmptyVal(templateDesc)
					? "Template 'description' is ok!"
					: "Template 'description' is required!"}
			</div>
		</div>
	);
};

const LOCLoadedTemplateValidator = ({
	vals = {},
	currentFacility,
	templateValidation,
	confirmSave,
	cancelSave,
}) => {
	return (
		<div className={styles.LOCLoadedTemplateValidator}>
			<div className={styles.LOCLoadedTemplateValidator_info}>
				<Name vals={vals} />
				<Desc vals={vals} />
			</div>
			<Divider />
			<div className={styles.LOCLoadedTemplateValidator_summary}>
				<LOCApiValidator
					key={`VALIDATOR-EXISTING`}
					vals={vals}
					currentFacility={currentFacility}
					locDetails={templateValidation}
					confirmSave={confirmSave}
					cancelSave={cancelSave}
				/>
			</div>
		</div>
	);
};

export default LOCLoadedTemplateValidator;

LOCLoadedTemplateValidator.defaultProps = {};

LOCLoadedTemplateValidator.propTypes = {};
