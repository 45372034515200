import React, { useState } from "react";
import styles from "../../css/residents/ResidentsTableRow.module.scss";
import { PropTypes } from "prop-types";
import {
	getResidentAge,
	getResidentName,
	getResidentStatus,
} from "../../helpers/utils_residents";
import UnitTypeBadge from "./UnitTypeBadge";
import ModalLG from "../shared/ModalLG";
import ResidentDetails from "./ResidentDetails";

const ArchiveButton = ({ handleArchive }) => {
	return (
		<button
			type="button"
			onClick={handleArchive}
			className={styles.ArchiveButton}
		>
			Archive Resident
		</button>
	);
};
const UnarchiveButton = ({ handleArchive }) => {
	return (
		<button
			type="button"
			onClick={handleArchive}
			className={styles.UnarchiveButton}
		>
			Archive Resident
		</button>
	);
};

const ResidentsTableRow = ({
	currentFacility,
	currentUser,
	resident,
	archiveResident,
}) => {
	const [isArchived, setIsArchived] = useState(resident?.isArchived ?? false);
	// const [status, setStatus] = useState(getResidentStatus(resident));
	const [showResidentDetails, setShowResidentDetails] = useState(false);

	const handleArchive = (e) => {
		setIsArchived(!isArchived);
		archiveResident(resident);
	};

	return (
		<>
			<div className={styles.ResidentsTableRow}>
				<div className={styles.ResidentsTableRow_inner}>
					<div
						className={styles.ResidentsTableRow_inner_name}
						onClick={() => setShowResidentDetails(true)}
					>
						{getResidentName(resident)}
					</div>
					<div className={styles.ResidentsTableRow_inner_age}>
						{getResidentAge(resident)}
					</div>
					<div className={styles.ResidentsTableRow_inner_unitType}>
						<UnitTypeBadge unitType={resident?.unitType} />
					</div>
					<div className={styles.ResidentsTableRow_inner_archive}>
						{isArchived && <ArchiveButton handleArchive={handleArchive} />}
						{!isArchived && <UnarchiveButton handleArchive={handleArchive} />}
					</div>
				</div>
			</div>

			{showResidentDetails && (
				<ModalLG
					title={`Resident Details`}
					closeModal={() => setShowResidentDetails(false)}
				>
					<ResidentDetails
						currentFacility={currentFacility}
						currentUser={currentUser}
						resident={resident}
					/>
				</ModalLG>
			)}
		</>
	);
};

export default ResidentsTableRow;

ResidentsTableRow.defaultProps = {};

ResidentsTableRow.propTypes = {};
