import React from "react";
import styles from "../../css/user/ResetMethodWarnings.module.scss";
import { PropTypes } from "prop-types";

const warnings = {
	admin: `Admins may assist a user in resetting their password, when needed.`,
	email: `If 'Reset by Email' is selected, resetting via security questions will not be an option.`,
	questions: `Users without a working email on file can reset their password via security questions or ask their Admin to assist.`,
};

const Warning = ({ msg }) => {
	return (
		<li className={styles.Warning}>
			<div className={styles.Warning_msg}>{msg}</div>
		</li>
	);
};

const ResetMethodWarnings = ({ formState = {} }) => {
	const { values } = formState;
	// check for fields' values
	const isPwdResetByAdmin = values?.isPwdResetByAdmin ?? true;
	const isPwdResetByQuestions = values?.isPwdResetByQuestions ?? false;
	const isPwdResetByEmail = values?.isPwdResetByEmail ?? false;

	return (
		<div className={styles.ResetMethodWarnings}>
			<ul className={styles.ResetMethodWarnings_list}>
				<Warning msg={warnings["email"]} />
				<Warning msg={warnings["questions"]} />
				{/* {isPwdResetByAdmin && <Warning msg={warnings["admin"]} />} */}
			</ul>
		</div>
	);
};

export default ResetMethodWarnings;

ResetMethodWarnings.defaultProps = {};

ResetMethodWarnings.propTypes = {
	formState: PropTypes.object,
};
