import React from "react";
import { NavLink } from "react-router-dom";
import { PropTypes } from "prop-types";
import styles from "../../css/app/MobileSidebarListItem.module.scss";
import sprite from "../../assets/icons/settings.svg";

const activeStyles = {
	color: "rgb(248, 250, 252)",
	fill: "rgb(248, 250, 252)",
};

const MobileSidebarHelp = ({
	path = null,
	name,
	icon,
	history,
	isActive = false,
	openHelpWidget,
}) => {
	return (
		<li className={styles.MobileSidebarHelp}>
			<button
				type="button"
				className={styles.MobileSidebarHelp_btn}
				onClick={openHelpWidget}
			>
				<svg
					className={styles.MobileSidebarHelp_btn_icon}
					style={isActive ? activeStyles : null}
				>
					<use xlinkHref={`${sprite}#icon-${icon}`}></use>
				</svg>
				<span
					className={styles.MobileSidebarHelp_btn_text}
					style={isActive ? activeStyles : null}
				>
					{name}
				</span>
			</button>
		</li>
	);
};

export { MobileSidebarHelp };

const MobileSidebarListItem = ({ path, name, icon, history = {} }) => {
	const isActive = history?.location?.pathname === path;

	return (
		<li className={styles.MobileSidebarListItem}>
			<NavLink
				to={{
					pathname: path,
					state: {
						from: history?.location?.pathname,
					},
				}}
				isActive={(match, location) => {
					if (!match) {
						return false;
					}
					const url = match.url;
					return url === path;
				}}
			>
				<svg
					className={styles.MobileSidebarListItem_icon}
					style={isActive ? activeStyles : null}
				>
					<use xlinkHref={`${sprite}#icon-${icon}`}></use>
				</svg>
				<span
					className={styles.MobileSidebarListItem_text}
					style={isActive ? activeStyles : null}
				>
					{name}
				</span>
			</NavLink>
		</li>
	);
};

export default MobileSidebarListItem;

MobileSidebarListItem.defaultProps = {
	history: {},
};

MobileSidebarListItem.propTypes = {
	path: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	history: PropTypes.object.isRequired,
};
