import React, { useContext } from "react";
import styles from "../css/pages/LevelsOfCarePage.module.scss";
import { PropTypes } from "prop-types";
import { GlobalStateContext } from "../state/GlobalStateContext";
import { initialSettings, useAlerts } from "../utils/useAlerts";
// components
import LevelsOfCareView from "../views/loc/LevelsOfCareView";

const LevelsOfCarePage = ({ history }) => {
	const { state: globalState, dispatch: dispatchToState } =
		useContext(GlobalStateContext);
	const { currentUser, currentFacility } = globalState;
	const { AlertsHandler, dispatchAlert } = useAlerts({ ...initialSettings });

	return (
		<>
			<div className={styles.LevelsOfCarePage}>
				<div className={styles.LevelsOfCarePage_main}>
					<LevelsOfCareView
						globalState={globalState}
						currentFacility={currentFacility}
						currentUser={currentUser}
						dispatchAlert={dispatchAlert}
						dispatchToState={dispatchToState}
						history={history}
					/>
				</div>
			</div>

			{AlertsHandler}
		</>
	);
};

export default LevelsOfCarePage;

LevelsOfCarePage.defaultProps = {};

LevelsOfCarePage.propTypes = {
	history: PropTypes.object,
};
