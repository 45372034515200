import React, { useState, useReducer, useEffect } from "react";
import styles from "../../css/loctemplate/LOCTemplateTable.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import { green } from "../../helpers/utils_styles";
import {
	sortAlphaAscByKey,
	sortAlphaDescByKey,
	sortNumAscByKey,
	sortNumDescByKey,
} from "../../helpers/utils_processing";
// components
import LOCHelpButton from "../loc/LOCHelpButton";
import LOCInfoModal from "../loc/LOCInfoModal";
// template custom components
import LOCTemplateTableColumns from "./LOCTemplateTableColumns";
import LOCTemplateTableBody from "./LOCTemplateTableBody";
import LOCTemplateTableRow from "./LOCTemplateTableRow";
import LOCTemplateTableFooter from "./LOCTemplateTableFooter";
import { getDefaultLOC } from "../../helpers/utils_defaultLOC";

const customCSS = {
	save: {
		padding: "1rem 1.7rem",
		borderRadius: "1rem",
		backgroundColor: green[500],
		color: "#ffffff",
		fontSize: "1.6rem",
		fontWeight: "600",
	},
};

const advancedSort = (sortType, state) => {
	switch (sortType) {
		case "Level Name": {
			const { isSorted, data: rows } = state;

			if (!isSorted) {
				const sortedDESC = sortAlphaDescByKey("Level Name", [...rows]);

				return {
					...state,
					data: sortedDESC,
					isSorted: !isSorted,
				};
			} else {
				const sortedASC = sortAlphaAscByKey("Level Name", [...rows]);
				return {
					...state,
					data: sortedASC,
					isSorted: !isSorted,
				};
			}
		}
		case "Level": {
			const { isSorted, data: rows } = state;

			if (!isSorted) {
				const sortedDESC = sortNumDescByKey("Level", [...rows]);

				return {
					...state,
					data: [...sortedDESC],
					isSorted: true,
				};
			} else {
				const sortedASC = sortNumAscByKey("Level", [...rows]);

				return {
					...state,
					data: [...sortedASC],
					isSorted: false,
				};
			}
		}
		case "Starting Points": {
			const { isSorted, data: rows } = state;

			if (!isSorted) {
				const sortedDESC = sortNumDescByKey("Starting Points", [...rows]);

				return {
					...state,
					data: [...sortedDESC],
					isSorted: true,
				};
			} else {
				const sortedASC = sortNumAscByKey("Starting Points", [...rows]);
				return {
					...state,
					data: [...sortedASC],
					isSorted: false,
				};
			}
		}
		case "Ending Points": {
			const { isSorted, data: rows } = state;

			if (!isSorted) {
				const sortedDESC = sortNumDescByKey("Ending Points", [...rows]);

				return {
					...state,
					data: [...sortedDESC],
					isSorted: true,
				};
			} else {
				const sortedASC = sortNumAscByKey("Ending Points", [...rows]);
				return {
					...state,
					data: [...sortedASC],
					isSorted: false,
				};
			}
		}
		default: {
			const { data: rows } = state;
			const sortedASC = sortNumDescByKey("Level", [...rows]);
			return {
				...state,
				data: [...sortedASC],
				isSorted: false,
			};
		}
	}
};

const getTempRowValues = (rows = []) => {
	if (isEmptyArray(rows)) {
		return {
			ID: 0,
			LocType: "Custom",
			FloorUnit: null,
			FacilityID: null,
			LevelDescription: "Change Level Description",
			LevelNumber: 0,
			PointsMin: 0,
			PointsMax: 99999,
			Pricing: Number("0.00").toFixed(2),
		};
	}

	const lastRow = rows[rows?.length - 1];
	const { FacilityID, FloorUnit, LOCType, LevelNumber } = lastRow;

	const level = !isEmptyVal(LevelNumber) ? +LevelNumber + 1 : rows.length;
	const levelMin = lastRow["PointsMax"] + 1;
	const levelMax = lastRow["PointsMax"] + 50;
	const price = Number("0.00").toFixed(2);

	return {
		ID: 0,
		LOCType: LOCType,
		FloorUnit: FloorUnit,
		FacilityID: FacilityID,
		LevelDescription: "Change Level Description",
		LevelNumber: level,
		PointsMin: levelMin,
		PointsMax: levelMax,
		Pricing: price,
	};
};

const tableReducer = (state, action) => {
	switch (action.type) {
		case "SORT": {
			const { sortType } = action.data;
			const sortedState = advancedSort(sortType, { ...state });

			return {
				...state,
				data: [...sortedState.data],
				isSorted: sortedState?.isSorted,
			};
		}
		case "ROW_UPDATE--DEPRECATED": {
			// ##TODOS:
			// - Prevent empty/blank values for 'Level Name' cells
			// 		- Do so via column name or "cellIdx % 4 === 0"

			// OLD UPDATER CODE Removed 5/2/2022 at 7:00 AM //
			const { rowIdx, newRowData } = action.data;
			const { data } = state;
			data.splice(rowIdx, 1, newRowData);

			return {
				...state,
				data: [...data],
			};
		}
		case "ROW_UPDATE": {
			// ##TODOS:
			// - Prevent empty/blank values for 'Level Name' cells
			// 		- Do so via column name or "cellIdx % 4 === 0"

			// OLD UPDATER CODE Removed 5/2/2022 at 7:00 AM //
			// const { rowIdx, newRowData } = action.data;
			// const { data } = state;
			// data.splice(rowIdx, 1, newRowData);
			// NEW CODE: //
			// instead of mutating data within state action, data is mutated before action is called
			const { newTableData } = action.data;
			// updates a single row's data, in place

			return {
				...state,
				// data: [...data],
				data: newTableData,
				// RECENTLY ADDED - 4/27/2022 AT 1:46 PM
				// hasChanges: true
			};
		}
		case "ADD_ROW": {
			// Inserts a new row: data.splice(targetIdx, 0, newItem)
			const { newRowData } = action.data;
			const { data = [] } = state;

			const newRows = [...data, newRowData];

			return {
				...state,
				data: newRows,
				hasMaxLevels: newRows?.length >= 10,
				// RECENTLY ADDED - 4/27/2022 AT 1:46 PM
				// hasChanges: true,
			};
		}
		case "REMOVE_ROW": {
			const { newRows } = action.data;

			return {
				...state,
				data: newRows,
				// RECENTLY ADDED - 4/27/2022 AT 1:46 PM
				// hasChanges: true,
			};
		}
		case "RESET": {
			const { cols, data } = action.data;

			return {
				...state,
				cols,
				data,
				// hasChanges: false,
			};
		}
		default:
			return { ...state };
	}
};

const LOCHeader = ({ title, subtitle, openHelpModal }) => {
	return (
		<div className={styles.LOCHeader}>
			<div className={styles.LOCHeader_titleBar}>
				<div className={styles.LOCHeader_titleBar_title}>{title}</div>
				<div className={styles.LOCHeader_titleBar_subtitle}>{subtitle}</div>
			</div>
			<div className={styles.LOCHeader_help}>
				<LOCHelpButton handleHelp={openHelpModal} />
			</div>
		</div>
	);
};

const LOCTemplateTable = ({
	title,
	subtitle,
	schema = {},
	syncTableData,
	handleSave,
	handleTableReset,
	btnText = "Save Changes",
	isEditable = true,
	enableTableReset = false,
}) => {
	const [tableState, dispatchAction] = useReducer(tableReducer, {
		...schema,
		isSorted: false,
		hasMaxLevels: schema?.data?.length >= 10,
		// hasChanges: false,
	});
	const [hasChanges, setHasChanges] = useState(false);
	const [newTempRow, setNewTempRow] = useState({
		...getTempRowValues(tableState.data),
	});
	const [showHelpModal, setShowHelpModal] = useState(false);

	const handleColumnSort = (col) => {
		dispatchAction({
			type: "SORT",
			data: {
				sortType: col,
			},
		});
	};

	// adds new row, w/ default values calculated from the last row
	const handleAddRow = (e) => {
		dispatchAction({
			type: "ADD_ROW",
			data: {
				newRowData: newTempRow,
			},
		});
		// only used for generating next temp row fields
		const { data = [] } = tableState;
		const tempRows = [...data, newTempRow];
		setNewTempRow({ ...getTempRowValues(tempRows) });
		setHasChanges(true);
		// new changes
		const newRows = [...data, newTempRow]; // only used for syncing with parent
		syncTableData(title, {
			...tableState,
			data: [...newRows],
		});
	};

	const handleDeleteRow = (rowIdx) => {
		const { data } = tableState;
		const temp = [...data];
		const newRows = temp.filter((item, idx) => idx !== rowIdx);
		dispatchAction({
			type: "REMOVE_ROW",
			data: {
				rowIdx: rowIdx,
				newRows: newRows,
			},
		});

		setNewTempRow({ ...getTempRowValues(newRows) });
		setHasChanges(true);
		syncTableData(title, {
			...tableState,
			data: [...newRows],
		});
	};

	// Needs to be updated:
	// - For New Templates: should reset to default
	// - For Loaded Templates: should reset to what's in database
	const handleReset = (e) => {
		setHasChanges(false);
		// reset temp row state, as well so it's ready when used
		setNewTempRow({ ...getTempRowValues(schema.data) });
		// ##TODOS:
		// - Consider adding another prop called 'resetSchema' that is used when 'Reset' is clicked...
		// ...instead of using 'getDefaultLOC()'
		const defaultLOC = getDefaultLOC();
		const defaultData = defaultLOC[title];

		dispatchAction({
			type: "RESET",
			data: {
				cols: schema.cols,
				data: defaultData,
			},
		});
		handleTableReset(title);
	};

	// updates data & syncs data to parent component
	const updateTableData = (rowIdx, newCellData = {}) => {
		const currentRow = { ...tableState.data[rowIdx] };
		const newRowData = {
			...currentRow,
			...newCellData,
		};
		// updates tableData to pass to parent
		// added sync state updates are batched and the 'dispatchAction'...
		// ...wasn't resolving before calling 'syncTableData' so the data was stale
		tableState.data.splice(rowIdx, 1, newRowData);

		dispatchAction({
			type: "ROW_UPDATE",
			data: {
				newTableData: tableState?.data,
			},
		});

		// dispatchAction({
		// 	type: "ROW_UPDATE",
		// 	data: {
		// 		newRowData: newRowData,
		// 		rowIdx: rowIdx,
		// 	},
		// });
		setHasChanges(true);
		syncTableData(title, tableState);
	};

	return (
		<>
			<div className={styles.LOCTemplateTable}>
				<div className={styles.LOCTable_wrapper}>
					<LOCHeader
						title={title}
						subtitle={subtitle}
						openHelpModal={() => setShowHelpModal(true)}
					/>
					<div className={styles.LOCTable_cols}>
						<LOCTemplateTableColumns
							cols={tableState.cols}
							handleColumnSort={handleColumnSort}
						/>
					</div>
					<div className={styles.LOCTable_body}>
						<LOCTemplateTableBody
							key={`TABLE-BODY-${tableState?.data?.length}-${tableState?.isSorted}`}
							isEditable={isEditable}
						>
							{!isEmptyArray(tableState.data) &&
								tableState.data.map((rowData, rowIdx) => (
									<LOCTemplateTableRow
										key={`Row-${rowIdx}-${hasChanges}`}
										isEditable={isEditable}
										rowIdx={rowIdx}
										rowData={rowData}
										isDeleteDisabled={
											tableState?.data?.length === 1 || rowIdx === 0
										}
										updateTableData={updateTableData}
										handleDeleteRow={() => handleDeleteRow(rowIdx)}
									/>
								))}
						</LOCTemplateTableBody>
					</div>
					<div className={styles.LOCTable_footer}>
						<LOCTemplateTableFooter
							key={`TEMP-FOOTER-${title}-${schema?.data?.length}`}
							isEditable={isEditable}
							handleAddRow={handleAddRow}
							handleReset={handleReset}
							hasChanges={hasChanges}
							hasMaxLevels={tableState?.hasMaxLevels}
							enableReset={enableTableReset}
							template={tableState}
						/>
					</div>
				</div>
			</div>

			{showHelpModal && (
				<LOCInfoModal closeModal={() => setShowHelpModal(false)} />
			)}
		</>
	);
};

export default LOCTemplateTable;

LOCTemplateTable.defaultProps = {
	schema: {
		cols: ["Level Name", "Level", "Starting Points", "Ending Points"],
		data: [],
		hasMaxLevels: false,
		isSorted: false,
	},
};

LOCTemplateTable.propTypes = {
	schema: PropTypes.shape({
		cols: PropTypes.array,
		data: PropTypes.arrayOf(PropTypes.object),
		hasMaxLevels: PropTypes.bool,
		isSorted: PropTypes.bool,
	}),
	children: PropTypes.any,
	rest: PropTypes.any,
};
