import React, { useState } from "react";
import styles from "../../css/app/SubList.module.scss";
import sprite from "../../assets/icons/settings.svg";
import sprite2 from "../../assets/icons/carets-arrows.svg";
import { PropTypes } from "prop-types";

const SubList = ({ name, icon, history, isCollapsed = false, children }) => {
	const [isOpen, setIsOpen] = useState(false);

	if (isCollapsed) return null;
	if (isCollapsed) {
		return (
			<div className={styles.CollapsedSubList}>
				<svg className={styles.CollapsedSubList_content_icon}>
					<use xlinkHref={`${sprite}#icon-${icon}`}></use>
				</svg>
				<svg className={styles.CollapsedSubList_icon}>
					<use xlinkHref={`${sprite}#icon-caret-down`}></use>
				</svg>
				<ul className={styles.CollapsedSubList_list}>{children}</ul>
			</div>
		);
	}
	return (
		<button className={styles.SubList} onClick={() => setIsOpen(!isOpen)}>
			<div className={styles.SubList_content}>
				<svg className={styles.SubList_content_icon}>
					<use xlinkHref={`${sprite}#icon-${icon}`}></use>
				</svg>
				<div className={styles.SubList_content_name}>{name}</div>
				<svg className={styles.SubList_content_caret}>
					<use
						xlinkHref={`${sprite2}#icon-${isOpen ? "caret-up" : "caret-down"}`}
					></use>
				</svg>
			</div>
			{isOpen && <ul className={styles.SubList_list}>{children}</ul>}
		</button>
	);
};

export default SubList;

SubList.defaultProps = {};

SubList.propTypes = {};
