import { acknowledge, banners } from "./utils_endpoints";
import { currentEnv } from "./utils_env";

/**
 * Fetches all service banners for a given facility
 * @param {String} token - Auth token
 * @param {String} userID - User guid
 * @returns {Array} - Returns array of service banner records
 */
const getFacilityServiceBanners = async (token, userID) => {
	let url = currentEnv.base + banners.getFacilityBanners;
	url += "?" + new URLSearchParams({ userId: userID });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Fetches all service banners for a given facility
 * @param {String} token - Auth token
 * @param {String} facilityID - Facility guid
 * @returns {Array} - Returns array of service banner records
 */
const getServiceBannersByFacility = async (token, facilityID) => {
	let url = currentEnv.base + banners.getBannersByFacility;
	url += "?" + new URLSearchParams({ IsEnable: true, FacilityId: facilityID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Fetches ALL currently active facility service banners as record.
 * @param {String} token - Auth token
 * @returns {Array} - Returns array of service banner records
 */
const getAllCurrentServiceBanners = async (token) => {
	let url = currentEnv.base + banners.getCurrentBanners2;
	url += "?" + new URLSearchParams({ IsEnable: true });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Fetches ALL services banners regardless whether they're active or inactive.
 * @param {String} token - Auth token
 * @returns {Array} - Returns array of service banner records
 */
const getAllBannersActiveAndInactive = async (token) => {
	let url = currentEnv.base + banners.getCurrentBanners;

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
const saveNewServiceBanner = async (token, newBanner = {}) => {
	let url = currentEnv.base + banners.saveNewBanner;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(newBanner),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Update a single service banner record in the 'FacilityBanner' table
 * @param {String} token - Auth token
 * @param {Object} banner - FacilityBanner record.
 * @returns {Boolean} - Returns true|false if successful/failed
 */
const updateServiceBanner = async (token, banner = {}) => {
	let url = currentEnv.base + banners.updateBanner;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(banner),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Deletes a single service banner record in the 'FacilityBanner' table
 * @param {String} token - Auth token
 * @param {Number} bannerID - Numeric 'FacilityBannerId' field
 * @returns {Boolean} - Returns true|false if successful/failed
 */
const deleteServiceBanner = async (token, bannerID) => {
	let url = currentEnv.base + banners.deleteBanner;
	url += "?" + new URLSearchParams({ FacilityBannerId: bannerID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// ACKNOWLEDGEMENT REQUESTS //

/**
 * Saves a single acknowledgement for a given user & list of messageIDs
 * @param {String} token - Auth token
 * @param {Object} allParams - Request details; params, body content etc.
 * @param {String} allParams.name - 'name' field defines target table (eg. 'FacilityBanner')
 * @param {String} allParams.userID - 'userID' is the user who's acknowledging.
 * @param {Array} allParams.messageIDs - List of 'FacilityBannerId's being acknowledged
 * @returns {Object} - Returns success\Fail state
 */
const acknowledgeBy = async (token, allParams = {}) => {
	const {
		name,
		userID,
		messageIDs,
		// OPTIONAL FIELDS //
		message = null, // Acknowledgement message (eg. string)
		messageID = null, // Message id (eg. 'FacilityBannerId'); not needed due to 'messageIDs' array
	} = allParams;

	let url = currentEnv.base + acknowledge.byUserID;
	url += "?" + new URLSearchParams({ userId: userID, name: name });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(messageIDs),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

//////////////////////////////////////////////////
///////////// BANNER STRUCTURE UTILS /////////////
//////////////////////////////////////////////////

const bannerModelDetails = {
	model: {
		FacilityBannerId: 0,
		FacilityId: "XXXXXXX-XXXX-XXXX-XXXX-XXXXXXX",
		ExpiredDate: null,
		ExpiredMessage: "Sorry, need to come back later",
		GraceDays: 0,
		IsEnable: true,
		IsEditEnable: false,
		IsEmailEnable: false,
		IsPrintEnable: false,
		IsReportEnable: false,
		IsProcessEnable: false,
		IsActive: true,
		CreatedDate: null,
		CreatedBy: null,
		CreatedLoginBy: null,
		CreatedStation: null,
		ModifiedDate: null,
		ModifiedBy: "XXXXXXX-XXXX-XXXX-XXXX-XXXXXXX",
		ModifiedLoginBy: null,
		ModifiedStation: null,
	},
	sample: {
		FacilityBannerId: 1,
		FacilityId: "9bfd8ff6-75f1-4861-8c69-4a6482755fe1",
		ExpiredDate: "2023-08-30T00:28:00.72Z",
		ExpiredMessage: "Sorry, need to come back later",
		GraceDays: 0,
		IsEnable: true,
		IsEditEnable: false,
		IsEmailEnable: false,
		IsPrintEnable: false,
		IsReportEnable: false,
		IsProcessEnable: false,
		IsActive: true,
		CreatedDate: "2023-08-30T00:28:00.72Z",
		CreatedBy: null,
		CreatedLoginBy: "AdvantageSQL",
		CreatedStation: "DEVCHARITY",
		ModifiedDate: "2023-08-30T00:28:00.72Z",
		ModifiedBy: null,
		ModifiedLoginBy: "AdvantageSQL",
		ModifiedStation: "DEVCHARITY",
	},
};

// SERVICE BANNERS WARNING MESSAGE //
const warningMsg = `By clicking 'Acknowledge' you are confirming that you've read the above notice(s).`;

// applies form values to table-form data structure
const createServiceBannerModel = (values = {}) => {
	const { facilityID, expiry, message, isEnabled } = values;

	const model = {
		FacilityId: facilityID,
		ExpiredMessage: message,
		ExpiredDate: expiry,
		IsEnable: isEnabled,
	};

	return model;
};

// applies form values to existing table-form data structure to be saved
const updateServiceBannerModel = (values = {}) => {
	const { facilityID, bannerID, expiry, message, isEnabled } = values;

	const model = {
		FacilityBannerId: bannerID,
		FacilityId: facilityID,
		ExpiredMessage: message,
		ExpiredDate: expiry,
		IsEnable: isEnabled,
	};

	return model;
};

// extract 'FacilityBannerId' from each record into an array
const getBannerIDs = (banners = []) => {
	const list = banners.map((entry) => entry?.FacilityBannerId);

	return list;
};

export {
	getFacilityServiceBanners,
	getAllCurrentServiceBanners,
	getServiceBannersByFacility,
	getAllBannersActiveAndInactive,
	saveNewServiceBanner,
	updateServiceBanner,
	deleteServiceBanner,
};

export { acknowledgeBy };

// model/data-structure handlers
export { createServiceBannerModel, updateServiceBannerModel, getBannerIDs };

export { warningMsg };
