import React from "react";
import styles from "../../css/app/ListItem.module.scss";
import sprite from "../../assets/icons/settings.svg";
import { PropTypes } from "prop-types";
import { NavLink } from "react-router-dom";

const ListItem = ({ item = {}, isCollapsed = false, history }) => {
	if (isCollapsed) {
		return (
			<li className={styles.CollapsedListItem}>
				<NavLink
					to={{
						pathname: item?.path,
						state: {
							from: history?.location?.pathname,
						},
					}}
				>
					<svg className={styles.CollapsedListItem_icon}>
						<use xlinkHref={`${sprite}#icon-${item?.icon}`}></use>
					</svg>
				</NavLink>
			</li>
		);
	}
	return (
		<li className={styles.ListItem}>
			<NavLink
				to={{
					pathname: item?.path,
					state: {
						from: history?.location?.pathname,
					},
				}}
			>
				<svg className={styles.ListItem_icon}>
					<use xlinkHref={`${sprite}#icon-${item?.icon}`}></use>
				</svg>
				<div className={styles.ListItem_name}>{item?.name}</div>
			</NavLink>
		</li>
	);
};

export default ListItem;

ListItem.defaultProps = {
	isCollapsed: false,
};

ListItem.propTypes = {
	item: PropTypes.shape({
		path: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		icon: PropTypes.string.isRequired,
	}),
	history: PropTypes.object,
	isCollapsed: PropTypes.bool,
};
