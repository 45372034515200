import { currentEnv } from "./utils_env";
import { settings } from "./utils_endpoints";
import { sortDateAscByKey } from "./utils_processing";

/**
 * Fetches a facility's record from the 'SettingFacility' table.
 * @param {String} token - Security token
 * @param {String} facilityID - A unique facility id (guid)
 * @returns {Array} - Returns an array of objects (records from 'SettingFacility' table)
 */
const getMigrationStatus = async (token, facilityID) => {
	let url = currentEnv.base + settings.migration.getStatus2;
	url += "?" + new URLSearchParams({ FacilityID: facilityID });
	url += "&" + new URLSearchParams({ index: 0 });
	url += "&" + new URLSearchParams({ rows: 100 });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Fetches record(s) from 'CommandScheduler' table detailing a migration process's schedule
 * @returns {Array} - Returns an array of 1 or more records.
 */
const getMigrationDetails = async (token, facilityID) => {
	const desc = `Migrating users for facility: ${facilityID}`;
	const name = `MigrateFacilityUsers`;

	let url = currentEnv.base + settings.migration.getScheduler2;
	url += "?" + new URLSearchParams({ CommandName: name });
	url += "&" + new URLSearchParams({ ScheduleDescription: desc });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// MIGRATION STATUS' //

const getProcessStatus = async (token, facilityID) => {
	const statusRecords = await getMigrationStatus(token, facilityID);
	const clientRecord = getMigrateStatusRecord(statusRecords);
	return clientRecord;
};

const MIGRATION_STATES_USER = {
	COMPLETED: "COMPLETED",
	FAILED: "FAILED",
	PROCESSING: "PROCESSING",
	NOT_MIGRATED: "NOT-MIGRATED",
	SCHEDULED: "SCHEDULED",
};

const MIGRATION_STATES = {
	PartiallyCompleted: "Processing...",
	Schedule: "Scheduled",
	Completed: "Completed",
	NotMigrated: "Not-Migrated",
	undefined: "Not-Migrated",
};

const processMigrateRecords = (records = []) => {
	const onlyStatus = records.filter(
		(record) => record?.Name === "MigrationStatus"
	);
	const statusRecord = onlyStatus?.[0] ?? {};
	const {
		Content: content,
		Name: name,
		Description: desc,
		ModifiedDate: dateModified,
	} = statusRecord;

	return {
		name: name,
		dateModified: dateModified,
		status: MIGRATION_STATES[content],
		desc: desc,
	};
};

const getMigrateStatusRecord = (allRecords = []) => {
	const sorted = sortDateAscByKey("ModifiedDate", allRecords);
	const processed = processMigrateRecords(sorted);

	return processed;
};

export { getMigrationStatus, getMigrationDetails, getProcessStatus };

// states
export { MIGRATION_STATES, MIGRATION_STATES_USER, getMigrateStatusRecord };
