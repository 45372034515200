import React from "react";
import styles from "../../css/summary/AccountUnlockSwitch.module.scss";
import sprite from "../../assets/icons/multistep.svg";
import { PropTypes } from "prop-types";
import { green, red } from "../../helpers/utils_styles";

const lockIcon = "lock-open";
const unlockIcon = "lock";

const btnCSS = {
	isLocked: {
		backgroundColor: green[500],
		color: green[50],
		fill: green[50],
	},
	isUnlocked: {
		backgroundColor: red[600],
		color: red[50],
		fill: red[50],
	},
};

const AccountUnlockSwitch = ({ isLocked, handleUnlockSwitch }) => {
	return (
		<div className={styles.AccountUnlockSwitch}>
			<div className={styles.AccountUnlockSwitch_switch}>
				<button
					type="button"
					className={styles.AccountUnlockSwitch_switch_btn}
					style={isLocked ? btnCSS?.isLocked : btnCSS?.isUnlocked}
					onClick={handleUnlockSwitch}
				>
					<svg
						className={styles.AccountUnlockSwitch_switch_btn_icon}
						style={isLocked ? btnCSS?.isLocked : btnCSS?.isUnlocked}
					>
						<use
							xlinkHref={`${sprite}#icon-${isLocked ? lockIcon : unlockIcon}`}
						></use>
					</svg>
					<span>{isLocked ? "Unlock Account" : "Lock Account"}</span>
				</button>
			</div>
		</div>
	);
};

export default AccountUnlockSwitch;

AccountUnlockSwitch.defaultProps = {};

AccountUnlockSwitch.propTypes = {};
