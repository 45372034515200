import React, { useEffect, useState } from "react";
import styles from "../../css/user/CreateUserForm.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import { useForm } from "../../utils/useForm";
import {
	formatSecurityQuestions,
	getAllSecurityQuestions,
	getTypesAndTitles,
} from "../../helpers/utils_security";
import {
	formatAndSortUserFacilities,
	getNewUserFacilityID,
	matchFacilityByName,
	matchUserFacilityByName,
} from "../../helpers/utils_facility";
import {
	createNewUserMgmtObj,
	formatAndSortUserTypes,
	processTypesAndTitles,
} from "../../helpers/utils_user";
import {
	createNewUserProfileAndLogin,
	generateAllNewUserRecords,
	getUserFailureErrors,
} from "../../helpers/utils_createUser";
import { purple, red } from "../../helpers/utils_styles";
import { format } from "date-fns";
import { generateTempPassword } from "../../helpers/utils_processing";
import { isValidEmail } from "../../helpers/utils_validation";
// components
import Step from "../forms/Step";
import MultiStepForm from "../forms/MultiStepForm";
import Dialog from "../shared/Dialog";
import ButtonSM from "../shared/ButtonSM";
// custom steps
import CreateUserStep1 from "./CreateUserStep1";
import CreateUserStep2 from "./CreateUserStep2";
import CreateUserStep3 from "./CreateUserStep3";
import CreateUserStep4 from "./CreateUserStep4";
import CreateUserStep5 from "./CreateUserStep5";
import CreateUserStep6 from "./CreateUserStep6";
import CreateUserStep7 from "./CreateUserStep7";

// REQUIREMENTS:
// 1. SELECT USER TYPE (ie ADMIN, NON-ADMIN, MED-TECH) ✓
// 2. CREATE USERNAME (eg include unique username requirements) ✓
// 3. CREATE PASSWORD (eg include unique password requirements) ✓
// 4. PASSWORD RESET (eg setup security questions and/or email or phone number) ✓
// 5. FIRST NAME, LAST NAME, JOB TITLE ✓
// 6. GRANT FACILITY ACCESS ✓

// NOTE(S):
// - Reset method 'isPwdResetByQuestions' is 'true' by default:
// 		- If a valid email is entered then it's set to 'false'
// - For non-admins using an email format username, but not valid email:
// 		- 'isPwdResetByQuestions' will be set to 'true' automatically
// - For non-admins using an email format username, but WITH a VALID email:
// 		- 'isPwdResetByQuestions' will be set to 'false' automatically
// 		- 'isPwdResetByEmail' will be set to 'true' when 'isValidEmail' is selected

// ##TODOS:
// - Create 'alertHandler()' to extend support for:
// 		- Add support for 'already exists'
// 		- Add support for 'failures' w/ more descript details
// - Update <Dialog/> to read from a state that allows setting the title, subtitle etc
// - Check whether facility has 'Tracker Access' enabled:
// 		- IF NOT ENABLED: then lock 'App Access' toggle
// 		- IF ENABLED: then unlock 'App Access' toggle

const customCSS = {
	createBtn: {
		padding: ".7rem 1.5rem",
		fontSize: "1.5rem",
		fontWeight: "600",
		backgroundColor: purple[500],
		color: "#ffffff",
	},
	cancelBtn: {
		padding: ".5rem 1.5rem",
		fontSize: "1.5rem",
		fontWeight: "600",
		backgroundColor: "transparent",
		color: red[700],
		marginRight: "1rem",
	},
};

// allows enabling step counter circle(s)
// replace this w/ 'enableNext' & 'enableBack' logic
const stepEnabler = {
	1: true, // default: true - 'USER TYPE'
	2: false, // default: false - 'USERNAME & PASSWORD'
	3: false, // default: false - 'FIRST/LAST NAME/JOB-TITLE'
	4: false, // default: false - 'SECURITY QUESTIONS/PASSWORD RESET'
	5: false, // default: false - 'APP ACCESS'
	6: false, // default: false - 'FACILITY ACCESS'
	7: false, // default: false - 'NEW USER SUMMARY'
	8: false, // default: false - 'IDK WHAT THIS IS???'
};

// confirms each key provided in 'required' is filled in
const enableStepButton = (required = [], vals = {}) => {
	return required.every((key) => {
		const isFilledIn = vals[key];
		return isFilledIn;
	});
};

const stepsConfig = {
	step1: {
		icon: `userDarkAdd`,
		title: `Select User Type:`,
		desc: `Choose which user type to create/add.`,
	},
	step2: {
		icon: `lockClosed`,
		title: `Create Login:`,
		desc: `Create a username and password which you'll use to login.`,
	},
	step3: {
		icon: "idCard",
		title: `Info About This User:`,
		desc: `Provide some info about this user.`,
	},
	step4: {
		icon: "worldLock",
		title: `Setup Password Reset:`,
		desc: `Users MUST provide either a valid email address or 3 security questions for password resets.`,
	},
	step5: {
		icon: `vpnLock`,
		title: `Setup User's App Access`,
		desc: `Setup which application(s) this user has access to.`,
	},
	step6: {
		icon: `tuner2`,
		title: `Grant Facility Access`,
		desc: `Grant access to one or more facilities for this user.`,
	},
	step7: {
		icon: `save`,
		title: `Save & Create User`,
		desc: `Create this user with provided settings.`,
	},
};

const { step1, step2, step3, step4, step5, step6, step7 } = stepsConfig;

// 'Administrator', 'Staff', 'Contractor', 'Manager', 'SuperUser'
const disabledTypes = [`Regional Admin`, `SuperUser`, `ReadOnlyUser`];
// defined admin types (ie user types that require an email)
const adminTypes = [`Administrator`, `SuperUser`, `FacilityAdministrator`];

// Runs username and/or email validation based off 'userType' field:
// - IF 'Admin/SuperUser' type: confirm is valid email
// - IF non-'Admin/SuperUser' type: confirm username is valid
const getStep2Enable = (vals) => {
	const { userType, email, username } = vals;

	if (adminTypes.includes(userType)) {
		const hasEmail = !isEmptyVal(email) && email?.length >= 6;
		// check is valid email
		const isValid = isValidEmail(email);
		return hasEmail && isValid;
	} else {
		const hasEmail = !isEmptyVal(email) && email?.length >= 6;
		// check is valid email
		const isValid = isValidEmail(email);
		return hasEmail && isValid;
		// DEPRECATED VERSION - Removed as of 11/15/2021 at 5:17 PM
		// const isValidUsername = testUsername(email, variations, errorMsgs);
		// return isValidUsername && email?.length >= 6;
	}
};

const getDisabledSteps = (vals = {}) => {
	const allDisabledSteps = [];
	const { userType, isValidEmail: emailMarkedValid } = vals;

	// disables 'Step4: Security Questions' for admins and/or valid emails
	// if (adminTypes.includes(userType) || emailMarkedValid) {
	// 	allDisabledSteps.push(4);
	// }
	// disables 'Step4: Security Questions' as tho should be created by the actual user
	allDisabledSteps.push(4);

	return allDisabledSteps;
};

const initialState = {
	userType: "",
	email: "", // used for 'Admins' typically, but anyone w/ an email
	isPrimaryEmail: false,
	isValidEmail: false,
	username: "",
	tempPassword: generateTempPassword(9),
	firstName: "",
	lastName: "",
	jobTitle: "",
	// dates
	effectiveStartDate: format(new Date(), "MM/DD/YYYY"),
	effectiveEndDate: null,
	// created by
	createdByID: "",
	targetUserID: null,
	// user apps - grant access
	hasTrackerAccess: false,
	hasLegacyAccess: false,
	hasPortalAccess: true,
	// password reset(s)
	isPwdResetByAdmin: true, // always acceptable
	isPwdResetByEmail: false, // for admins/super-users and valid emails
	isPwdResetByQuestions: true, // for non-admins/med-tech & non-email usernames
	is2FAEnabled: false,
	// status
	suspendStartDate: null,
	isSuspended: false,
	isActive: true,
	// security questions
	securityQuestion1: "",
	securityAnswer1: "",
	securityQuestion2: "",
	securityAnswer2: "",
	securityQuestion3: "",
	securityAnswer3: "",
};

const CreateUserForm = ({
	currentUser = {},
	currentFacility = {},
	userTypes = [],
	userTitles = [],
	allApps = [],
	dispatchToState,
	dispatchAlert,
	closeModal,
}) => {
	const { formState, setFormState, handleChange, handleCheckbox, handleReset } =
		useForm({
			userType: "",
			email: "", // used for 'Admins' typically, but anyone w/ an email
			username: "", // no longer really used, for now since legacy requires email format
			isPrimaryEmail: false,
			isValidEmail: false,
			tempPassword: generateTempPassword(9),
			firstName: "",
			lastName: "",
			jobTitle: "",
			// created jobTitle ID
			jobTitleID: null,
			hasCustomJobTitle: false,
			// dates
			effectiveStartDate: format(new Date(), "MM/DD/YYYY"),
			effectiveEndDate: null,
			// created by
			createdByID: currentUser?.userID,
			targetUserID: null,
			// user apps - grant access
			hasTrackerAccess: false,
			hasLegacyAccess: false,
			hasPortalAccess: true,
			// password reset(s)
			isPwdResetByAdmin: true, // always acceptable
			isPwdResetByEmail: false, // for admins/super-users and valid emails
			isPwdResetByQuestions: true, // for non-admins/med-tech & non-email usernames
			is2FAEnabled: false,
			// status
			suspendStartDate: null,
			isSuspended: false,
			isActive: true,
			// security questions
			securityQuestion1: "",
			securityAnswer1: "",
			securityQuestion2: "",
			securityAnswer2: "",
			securityQuestion3: "",
			securityAnswer3: "",
		});
	const { values } = formState;
	const [securityQuestions, setSecurityQuestions] = useState([]);
	const [facilitySelections, setFacilitySelections] = useState([]);
	// confirms user has copied temp password
	const [showConfirmDialog, setShowConfirmDialog] = useState(false);
	const [userWasCreated, setUserWasCreated] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	// handles email validator for 'Admin...' & 'SuperUser'
	const handleAdminEmail = (e) => {
		e?.persist();
		const { email } = values;
		if (isValidEmail(email)) {
			return setFormState({
				...formState,
				values: {
					...values,
					email: e.target.value,
					isPrimaryEmail: true,
					isValidEmail: true,
					isPwdResetByAdmin: true,
					isPwdResetByEmail: true,
					isPwdResetByQuestions: false,
				},
			});
		} else {
			return handleChange(e);
		}
	};

	// handles 'isValidEmail' checkbox and...
	// ...handles non-admins who enter username in 'email' format
	const handleValidEmailCheck = (e) => {
		const { name, checked } = e.target;
		// marked as 'valid email'
		if (
			name === "isValidEmail" &&
			checked === true &&
			isValidEmail(values.email)
		) {
			setFormState({
				...formState,
				values: {
					...values,
					isValidEmail: checked,
					isPwdResetByEmail: true,
					isPwdResetByQuestions: false,
				},
			});
		} else {
			// not marked as 'valid email'
			return setFormState({
				...formState,
				values: {
					...values,
					isValidEmail: checked,
					isPwdResetByQuestions: true,
					isPwdResetByEmail: false,
				},
			});
		}
	};

	const handleMultiSelect = (selections) => {
		setFacilitySelections(selections);
	};

	const handlePhone = (name, val) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: val,
			},
		});
	};

	// handles form dropdowns
	const handleSelection = (name, val) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: val,
			},
		});
	};

	const handleAlert = (type, msg = {}) => {
		// closeModal();
		dispatchAlert(type, msg);
	};

	// handles setting custom jobtitle and it's new ID
	const handleCustomJobTitle = (titleID) => {
		setFormState({
			...formState,
			values: {
				...values,
				jobTitleID: titleID,
				hasCustomJobTitle: true,
			},
		});
	};

	// fetches 'questions', 'user-titles', & 'user-types'
	const fetchInitialResource = async () => {
		const { token } = currentUser;
		const [questions, typesAndTitles] = await Promise.all([
			getAllSecurityQuestions(token),
			getTypesAndTitles(token),
		]);
		setSecurityQuestions(questions);
		const all = processTypesAndTitles(
			typesAndTitles?.userTypes,
			typesAndTitles?.userTitles
		);

		dispatchToState({
			type: "SYNC_USER_DATA",
			data: {
				userTypes: all?.userTypes,
				userTitles: all?.userTitles,
				// jobTitle: values?.jobtitle, // UNCOMMENT //
			},
		});
	};

	// ##TODOS:
	// - Add 'AdminPortal' access switch to UI ✓
	// - Add generator for access record for 'AdminPortal' ✓
	// - Test user creation including 'AdminPortal' access record/entry - PENDING TESTING!!!
	const createNewUser = async (e) => {
		const { token } = currentUser;
		// get base facilityID from facility selections
		const defaultFacilityID = getNewUserFacilityID(
			facilitySelections,
			currentUser?.facilities
		);
		// new-user dependencies
		const userDeps = {
			allFacilities: currentUser?.facilities,
			allQuestions: securityQuestions,
			allApps: allApps,
			allUserTypes: userTypes,
			allUserTitles: userTitles,
		};
		// new users passwords are created using 'tempPassword'...
		// ...so they're required to reset their password upon 1st login
		const newUserRecords = generateAllNewUserRecords(userDeps, {
			...values,
			password: null, // not-used for creation; 'tempPassword' is used for new users.
			// facilityID: facilityID, // removed to prevent facility assignment accidents as of 11/18/2021 at 9:24 AM
			facilityID: defaultFacilityID, // REPLACEMENT TEST, USED TO USE 'currentUser.facilityID'
			facilitySelections: facilitySelections,
		});

		// const wasCreated = false;
		const wasCreated = await createNewUserProfileAndLogin(
			token,
			newUserRecords
		);

		// ##TODOS:
		// - PROCESS ERROR(S):
		// 		- Add better handling for errors
		// 		- Add better handling for validation (ie failed requirements like empty required fields etc)

		if (wasCreated?.Status === "Created") {
			const newID = wasCreated.Data;
			dispatchToState({
				type: "CREATE_NEW_USER",
				data: {
					newUser: createNewUserMgmtObj(newID, newUserRecords),
				},
			});
			setUserWasCreated(true);
			// close 'TempPassword' dialog & trigger alert
			setIsLoading(false);
			setShowConfirmDialog(false);
			return handleAlert("SUCCESS", {
				heading: `Success!`,
				subheading: `User Was Created!!`,
			});
		} else {
			setUserWasCreated(false);
			setIsLoading(false);
			const errors = getUserFailureErrors(wasCreated?.Messages);
			const errMsg = errors?.[0];
			return handleAlert("ERROR", {
				heading: "Failed!",
				subheading: errMsg,
				// text: errors?.[1] ?? "",
			});
		}
	};

	const cancelNewUser = (e) => {
		if (closeModal) {
			handleReset(e);
			return closeModal();
		} else {
			return handleReset(e);
		}
	};

	const restartForm = (e) => {
		handleReset(e);
		setUserWasCreated(false);
		setFacilitySelections([]);
	};

	// fetch questions
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		fetchInitialResource();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<form className={styles.CreateUserForm}>
				{!isEmptyArray(userTypes) && (
					<main className={styles.CreateUserForm_main}>
						<MultiStepForm
							key={`CREATE-USER-FORM`}
							startStep={1}
							totalSteps={7}
							stepEnabler={stepEnabler}
							disabledSteps={getDisabledSteps(values)}
						>
							{/* STEP #1 - USER TYPE */}
							<Step
								step={1}
								stepDetails={step1}
								hidePrev={true}
								enableNext={!isEmptyVal(values.userType)}
							>
								<CreateUserStep1
									key={`TYPES:${userTypes?.length}`}
									vals={values}
									currentUser={currentUser}
									disabledTypes={disabledTypes}
									userTypes={[...formatAndSortUserTypes(userTypes)]}
									handleSelection={handleSelection}
								/>
							</Step>
							{/* STEP #2 - USERNAME & PASSWORD */}
							<Step
								step={2}
								stepDetails={step2}
								enablePrev={true}
								enableNext={getStep2Enable(values)}
							>
								<CreateUserStep2
									currentUser={currentUser}
									vals={values}
									handleVals={handleSelection}
									handleChange={handleChange}
									handleAdminEmail={handleAdminEmail}
									handleCheckbox={handleCheckbox}
									handleValidEmailCheck={handleValidEmailCheck}
								/>
							</Step>
							{/* STEP #3 - FIRST/LAST NAME, TITLE & PHONE NUMBER */}
							<Step
								step={3}
								stepDetails={step3}
								enablePrev={true}
								enableNext={enableStepButton(
									["firstName", "lastName", "jobTitle"],
									values
								)}
							>
								<CreateUserStep3
									vals={values}
									handlePhone={handlePhone}
									handleChange={handleChange}
									handleCheckbox={handleCheckbox}
									handleSettings={handleSelection}
									userTitles={userTitles}
									currentUser={currentUser}
									handleCustomJobTitle={handleCustomJobTitle}
								/>
							</Step>
							{/* STEP #4 - PASSWORD RESET/SECURITY QUESTIONS */}
							{/* THIS STEP IS DISABLED FOR ADMINS -- LATER WILL BE DISABLED FOR EVERYONE!!! */}
							<Step
								step={4}
								stepDetails={step4}
								enablePrev={true}
								enableNext={enableStepButton(
									[
										"securityQuestion1",
										"securityQuestion2",
										"securityQuestion3",
										"securityAnswer1",
										"securityAnswer2",
										"securityAnswer3",
									],
									values
								)}
							>
								<CreateUserStep4
									vals={values}
									handleQuestion={handleSelection}
									handleChange={handleChange}
									handleCheckbox={handleCheckbox}
									securityQuestions={[
										...formatSecurityQuestions(securityQuestions),
									]}
								/>
							</Step>
							{/* STEP #5 - APPLICATION ACCESS */}
							<Step
								step={5}
								stepDetails={step5}
								enablePrev={true}
								enableNext={true}
								hideSkip={false}
							>
								<CreateUserStep5
									vals={values}
									handleChange={handleChange}
									handleCheckbox={handleCheckbox}
									isTrackerLocked={true} // for facilities w/o access
								/>
							</Step>
							{/* STEP #6: GRANT FACILITY ACCESS */}
							<Step
								step={6}
								stepDetails={step6}
								enablePrev={true}
								enableNext={!isEmptyArray(facilitySelections)}
								hideSkip={true}
							>
								<CreateUserStep6
									facilitySelections={facilitySelections}
									handleMultiSelect={handleMultiSelect}
									allFacilities={formatAndSortUserFacilities(
										currentUser?.facilities
									)}
								/>
							</Step>
							{/* STEP #7: USER SUMMARY/CREATE NEW USER */}
							<Step
								step={7}
								stepDetails={step7}
								enablePrev={false}
								hidePrev={true}
								hideNext={true}
								enableNext={false}
							>
								<CreateUserStep7
									vals={values}
									handleChange={handleChange}
									cancelNewUser={cancelNewUser}
									createNewUser={() => setShowConfirmDialog(true)}
									facilityAccessList={facilitySelections}
									userWasCreated={userWasCreated}
									createAnother={restartForm}
								/>
							</Step>
						</MultiStepForm>
					</main>
				)}
			</form>

			{showConfirmDialog && (
				<Dialog
					icon="WARN"
					title="Create User Account?"
					subheading="Ready to create this user account?"
					closeModal={() => setShowConfirmDialog(false)}
				>
					<div className={styles.CreateUserForm_confirmDialog}>
						<ButtonSM
							handleClick={() => setShowConfirmDialog(false)}
							customStyles={customCSS.cancelBtn}
						>
							Cancel
						</ButtonSM>
						<ButtonSM
							key={`CONFIRM-USER-${isLoading}`}
							isDisabled={isLoading}
							handleClick={(e) => {
								createNewUser(e);
								setIsLoading(true);
							}}
							customStyles={customCSS.createBtn}
						>
							{isLoading ? "Saving..." : "Yes, create account."}
						</ButtonSM>
					</div>
				</Dialog>
			)}
		</>
	);
};

export default CreateUserForm;

CreateUserForm.defaultProps = {
	currentFacility: {},
	currentUser: {},
};

CreateUserForm.propTypes = {
	currentFacility: PropTypes.object,
	currentUser: PropTypes.object,
	dispatchToState: PropTypes.func,
};
