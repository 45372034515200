import { currentEnv } from "./utils_env";
import { security, facilityAccess, resetMethods } from "./utils_endpoints";
import { isEmptyArray, isEmptyObj, isEmptyVal } from "./utils_types";
import {
	SecurityQuestionAndAnswerModel,
	PasswordResetConfig,
	PasswordResetRules,
} from "./utils_models";
import { defaultParams } from "./utils_params";
import { isEmailDeleted } from "./utils_user";
import { sortAlphaAscByKey, sortNumAscByKey } from "./utils_processing";
import { appAliases, appNames, SERVICES } from "./utils_apps";
import {
	hasAlaAdminPerms,
	hasFacilityAdminPerms,
	hasMedTechPerms,
	hasSuperUserPerms,
} from "./utils_userTypes";

// VALIDATE NEW USER'S LOGIN //

/**
 * Check whether a user's 'username' or 'email' already exist in the system.
 * @param {String} token - Security token.
 * @param {String} usernameOrEmail - A user's 'LoginName' to validate.
 * @returns {Boolean} - Returns 'true|false'
 */
const validateUserLogin = async (token, usernameOrEmail) => {
	let url = currentEnv.base + security.userLogin.validate;
	url += "?" + new URLSearchParams({ name: usernameOrEmail });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Tests a password security strength.
 * @param {String} token - Security token.
 * @param {String} password - A password to test strength on.
 */
const validateStrongPassword = async (token, password) => {
	let url = currentEnv.base + security.userLogin.checkPasswordStrength;
	url += "?" + new URLSearchParams({ password: encodeURIComponent(password) });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Validates a given user's security question ONLY. Does NOT support answers.
 * @param {String} token - Auth token
 * @param {String} userLoginId - User's guid/id
 * @param {String} question - A string-form security question.
 */
const validateSecurityQuestion = async (token, userLoginId, question) => {
	let url = currentEnv.base + security.questions.validate.userQuestion;
	url += "?" + new URLSearchParams({ userLoginId });
	url += "&" + new URLSearchParams({ question });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Validates a given user's security question ONLY. Does NOT support answers.
 * @param {String} token - Auth token
 * @param {String} userLoginId - User's guid/id
 * @param {String} question - A string-form security question.
 */
const validateSecurityAnswer = async (
	token,
	userLoginId,
	questionId,
	answer
) => {
	let url = currentEnv.base + security.questions.validate.userAnswer;
	url += "?" + new URLSearchParams({ userLoginId });
	url += "&" + new URLSearchParams({ securityQuestionId: questionId });
	url += "&" + new URLSearchParams({ answer });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// consider creating a 'message' handler to return any errors to the user via UI
const validateAllQuestions = async (token, userLoginId, questions = {}) => {
	const { securityQuestion1, securityQuestion2, securityQuestion3 } = questions;
	// fire off ALL requests, if any fail they all fail???
	const [question1, question2, question3] = await Promise.all([
		validateSecurityQuestion(token, userLoginId, securityQuestion1),
		validateSecurityQuestion(token, userLoginId, securityQuestion2),
		validateSecurityQuestion(token, userLoginId, securityQuestion3),
	]);

	return {
		questionResult1: question1,
		questionResult2: question2,
		questionResult3: question3,
	};
};
// consider creating a 'message' handler to return any errors to the user via UI
const validateAllAnswers = async (
	token,
	userLoginId,
	allQuestions,
	answers = {}
) => {
	const {
		confirmSecurityAnswer1,
		confirmSecurityAnswer2,
		confirmSecurityAnswer3,
	} = answers;
	const { questionOneID, questionTwoID, questionThreeID } =
		prepareAnswerRequests(allQuestions, answers);
	// fire off ALL requests, if any fail they all fail???
	const [answer1, answer2, answer3] = await Promise.all([
		validateSecurityAnswer(
			token,
			userLoginId,
			questionOneID,
			confirmSecurityAnswer1
		),
		validateSecurityAnswer(
			token,
			userLoginId,
			questionTwoID,
			confirmSecurityAnswer2
		),
		validateSecurityAnswer(
			token,
			userLoginId,
			questionThreeID,
			confirmSecurityAnswer3
		),
	]);

	return {
		answerResult1: answer1,
		answerResult2: answer2,
		answerResult3: answer3,
	};
};

/**
 * Saves a config of methods a user can use to reset their password.
 * @param {String} token - Security token
 * @param {String} userLoginId - A user's login ID (eg userID/guid)
 * @param {Object} resetSettings - An object of password settings (PasswordResetConfig)
 */
const saveUserResetTypes = async (token, userLoginId, resetSettings = {}) => {
	let url = currentEnv.base + security.resetType.save;
	url += "?" + new URLSearchParams({ userLoginId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(resetSettings),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Updates a user's reset method preference.
 * @param {String} token - Auth token
 * @param {String} userID - String user guid
 * @param {Number} resetPreferenceID - Numeric reset id.
 * @returns {Boolean} - Returns whether update was successful
 */
const saveUserResetPreference = async (token, userID, resetPreferenceID) => {
	let url = currentEnv.base + resetMethods.byUser.update;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				UserLoginID: userID,
				PreferredResetMethodID: resetPreferenceID,
				IsPwdResetByQuestions: true,
			}),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

const setQuestionsAsUserPreference = async (token, userID) => {
	let url = currentEnv.base + resetMethods.byUser.update;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				UserLoginID: userID,
				PreferredResetMethodID: 3,
				IsPwdResetByQuestions: true,
			}),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Fetches ALL available/active security questions in ALA Services.
 * @returns {Array} - Returns an array of security question records.
 */
const getAllSecurityQuestions = async (token) => {
	let url = currentEnv.base + security.questions.get;

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Fetch security questions matching specific criteria from the query params.
 * @param {Object} params - An object or query params to filter the request's data.
 */
const getAllSecurityQuestions2 = async (token, params = {}) => {
	let url = currentEnv.base + security.questions.get2;
	url += "?" + new URLSearchParams(params);

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Saves one or more "Security Question & Answer" pairs.
 * @param {String} token - Security token
 * @param {String} userLoginID - A 'UserLoginId' meant to be a backwards-compatible alias for 'UserId'
 * @param {Array} questionsAndAnswers - An array of Q/A records.
 */
const saveUserSecurityQuestionAndAnswers = async (
	token,
	userLoginID,
	questionsAndAnswers = []
) => {
	let url = currentEnv.base + security.questions.create;
	url += "?" + new URLSearchParams({ userLoginId: userLoginID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(questionsAndAnswers),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Fetches a user's security info: profile, logins, alerts, emails roles, communities etc.
 * @param {String} token - Auth token
 * @param {String} userIdOrUsername - This arg accepts either 'UserId' or a 'Username/Email'
 */
const getUserSecurityInfo = async (token, userIdOrUsername) => {
	let url = currentEnv.base + security.info.get;
	url += "?" + new URLSearchParams({ IdOrName: userIdOrUsername });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Fetches a facility's users (ADVUSER), logins, profiles, application access by app. (uses new user-infra)
 * @param {String} token - Security token.
 * @param {String} facilityId - A facility id.
 */
const getFacilityUserInfo = async (token, facilityId) => {
	let url = currentEnv.base + security.info.getFacilitySecurityInfo;
	url += "?" + new URLSearchParams({ facilityId });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Fetches & processes 'FacilityUserSecurityInfo' along w/ app access records.
 * - 'usersList': processed users
 * - 'userProfiles': new-infra profiles
 * - 'userLogins': new-infra logins
 * - 'isLegacyOnly': boolean indicating these users have NOT been migrated
 * - 'appAccess': object of userIDs with app access
 * 		- 'appAccess.portalAccess': array of userIDs with access
 * 		- 'appAccess.trackerAccess': array of userIDs with access
 * 		- 'appAccess.legacyAccess': array of userIDs with access
 */
const getProcessedFacililtyUserInfo = async (token, facilityId) => {
	const data = await getFacilityUserInfo(token, facilityId);
	const processedData = processFacilitySecurity(data);

	return processedData;
};

//////////////////////////////////////////////////
//////// UPDATE SECURITY QUESTIONS/ANSWERS ////////
//////////////////////////////////////////////////

//////////////////////////////////////////////////
///////// UPDATE & SAVE PROFILE SETTINGS /////////
//////////////////////////////////////////////////

/**
 * Saves changes to a user's 'UserProfile' in the new user-infra.
 * @param {String} token - Security token.
 * @param {Object} profileRecord - An updated 'UserProfile' record.
 */
const saveUserProfileChanges = async (token, profileRecord = {}) => {
	let url = currentEnv.base + security.userProfile.save;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(profileRecord),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Updates a user's phone record, as part of their profile data.
 * @param {String} token - Auth token
 * @param {Object} phoneRecord - An updated UserPhoneRecord
 * @returns {Boolean} - Returns whether changes were saved.
 */
const saveUserPhoneChanges = async (token, phoneRecord = {}) => {
	let url = currentEnv.base + security.userProfile.update.phone;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(phoneRecord),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// 'UserType' & 'UserTitle' utils

/**
 * Fetches ALL user type categories: 'Admin', 'Manager', 'Staff', 'Contractor' etc
 */
const getAllUserTypes = async (token, params = { ...defaultParams }) => {
	let url = currentEnv.base + security.userType.get2;
	url += "?" + new URLSearchParams({ ...params });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Fetches user titles
 */
const getAllUserTitles = async (token, params = { ...defaultParams }) => {
	let url = currentEnv.base + security.userTitle.get2;
	url += "?" + new URLSearchParams({ ...params });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// fetches 'UserTypes' & 'UserTitles'
const getTypesAndTitles = async (token, params = { ...defaultParams }) => {
	const [userTypes, userTitles] = await Promise.all([
		getAllUserTypes(token, params),
		getAllUserTitles(token, params),
	]);

	return {
		userTypes,
		userTitles,
	};
};

/**
 * Saves a series of facility access records for a given user, that can enable/disable access.
 * @param {String} token - Security token
 * @param {Array} accessRecords - An array of custom objects w/ 'UserId', 'FacilityId' and 'IsAccessible'.
 * @returns {Object}
 */
const registerFacilityAccess = async (token, accessRecords = []) => {
	let url = currentEnv.base + facilityAccess.registerAccess;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(accessRecords),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Enables/Grants a user access to one or more facilities, by their facilityID.
 * @param {String} token - Security token
 * @param {String} userId - A user guid
 * @param {Array} facilityIDs - An array of facilityIDs
 */
const grantFacilityAccess = async (token, userId, facilityIDs = []) => {
	let url = currentEnv.base + facilityAccess.grantAccess;
	url += "?" + new URLSearchParams({ userId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(facilityIDs),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Disables/Denies a user access to one or more facilities, by their facilityID.
 * @param {String} token - Security token
 * @param {String} userId - A user guid
 * @param {Array} facilityIDs - An array of facilityIDs
 */
const denyFacilityAccess = async (token, userId, facilityIDs = []) => {
	let url = currentEnv.base + facilityAccess.grantAccess;
	url += "?" + new URLSearchParams({ userId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(facilityIDs),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// QUESTION/ANSWER PROCESSING UTILS //

const createQuestionMap = (allQuestions = []) => {
	return allQuestions.reduce((map, question) => {
		if (!map[question.Question]) {
			map[question.Question] = {
				id: question.SecurityQuestionID,
				question: question.Question,
				isActive: question.IsActive,
			};
			return map;
		}
		return map;
	}, {});
};

const prepareAnswerRequests = (allQuestions = [], vals = {}) => {
	const {
		confirmSecurityQuestion1,
		confirmSecurityQuestion2,
		confirmSecurityQuestion3,
	} = vals;
	const questionMap = createQuestionMap(allQuestions);

	// get ids for each question
	const questionId1 = questionMap[confirmSecurityQuestion1];
	const questionId2 = questionMap[confirmSecurityQuestion2];
	const questionId3 = questionMap[confirmSecurityQuestion3];

	return {
		questionOneID: questionId1.id,
		questionTwoID: questionId2.id,
		questionThreeID: questionId3.id,
	};
};

/**
 * Inits & populates a security question model.
 * @param {Array} questions - An array of 'UserSecurityQuestion' records.
 * @param {Object} vals - An object w/ a single question & answer.
 */
const initQAModel = (questions = [], vals = {}) => {
	const base = new SecurityQuestionAndAnswerModel(questions, vals);
	const model = base.getModel();

	return model;
};

/**
 * Converts user's security question/answers into models to be saved to ALA Services.
 * @param {Array} allQuestions - An array of 'SecurityQuestion' records.
 * @param {Object} vals - An object of user security question & answer pairs.
 * @returns {Array} - Returns an array of populated models.
 */
const createSecurityQAModels = (allQuestions = [], vals = {}) => {
	const model1 = initQAModel(allQuestions, {
		securityQuestion: vals?.securityQuestion1,
		securityAnswer: vals?.securityAnswer1,
	});
	const model2 = initQAModel(allQuestions, {
		securityQuestion: vals?.securityQuestion2,
		securityAnswer: vals?.securityAnswer2,
	});
	const model3 = initQAModel(allQuestions, {
		securityQuestion: vals?.securityQuestion3,
		securityAnswer: vals?.securityAnswer3,
	});
	const allModels = [model1, model2, model3];
	return allModels;
};

/**
 * Finds a security question's matching 'SecurityQuestion' record.
 * @param {String} str - A string-form security question.
 * @param {Array} allQuestions - An array of Security Question records.
 * @returns {Object} - Returns an object-form record.
 */
const matchQuestionRecordFromStr = (str, allQuestions = []) => {
	if (isEmptyArray(allQuestions) || isEmptyVal(str)) return null;
	return allQuestions.reduce((match, record) => {
		const { Question } = record;
		if (str === Question) {
			match = record;
			return match;
		}
		return match;
	}, {});
};
/**
 * Finds a security question's matching 'SecurityQuestionID'
 * @param {String} str - A string-form security question.
 * @param {Array} allQuestions - An array of Security Question records.
 * @returns {Number} - Returns the 'SecurityQuestionID' as a number.
 */
const matchQuestionIDFromStr = (str, allQuestions = []) => {
	if (isEmptyArray(allQuestions) || isEmptyVal(str)) return null;
	return allQuestions.reduce((match, record) => {
		const { Question } = record;
		if (str === Question) {
			match = record?.SecurityQuestionID;
			return match;
		}
		return match;
	}, 0);
};

// extracts just the string questions into an array
const formatSecurityQuestions = (questions = []) => {
	if (isEmptyArray(questions)) return [];
	// saveToStorage(`_QUESTIONS_`, questions);
	return questions.map((record) => record?.Question);
};

/**
 * Removes questions that have already been used/selected.
 * @param {Object} vals - Form-state values w/ current question selections.
 * @param {Array} allQuestions - An array of formatted security questions as strings.
 */
const getRemainingQuestions = (vals, allQuestions = []) => {
	if (isEmptyArray(allQuestions)) return [];
	const { securityQuestion1, securityQuestion2, securityQuestion3 } = vals;
	// add selections to list, remove falsey/empty values
	const selected = [
		securityQuestion1,
		securityQuestion2,
		securityQuestion3,
	].filter(Boolean);
	// remaining 'available' questions
	const remaining = allQuestions.filter(
		(question) => !selected.includes(question)
	);

	return remaining;
};

// extracts all user-selected questions from 'values' state
const extractQuestions = (userQAs = {}) => {
	const { securityQuestion1, securityQuestion2, securityQuestion3 } = userQAs;
	const usersQuestions = [
		securityQuestion1,
		securityQuestion2,
		securityQuestion3,
	];

	return usersQuestions.filter((x) => Boolean(x));
};

// checks that both 'question' & 'answer' have been selected
const hasQuestionAndAnswer = (question, answer) => {
	return !isEmptyVal(question) && !isEmptyVal(answer);
};

/**
 * Match the answer that goes with the security question.
 * @param {Number} questionID - A 'SecurityQuestionID' that identifies a specific SecurityQuestion record.
 * @param {Array} answers - An array of a user's 'UserSecurityAnswers' to match w/ their respective questions.
 */
const findSecurityAnswer = (questionID, answers = []) => {
	return answers.filter((x) => x?.SecurityQuestionID === questionID);
};

/**
 * Create an array of security question & answer pairs
 * @param {Array} usersQuestions - An array of a user's "UserSecurityQuestions".
 * @param {Array} usersAnswers - An array of a user's "UserSecurityAnswers".
 * @returns {Object} - Returns an object w/ the QAs mapped: 'securityQuestion1', 'securityAnswer1' etc
 */
const mergeSecurityQuestionsAndAnswers = (
	usersQuestions = [],
	usersAnswers = []
) => {
	const baseQ = `securityQuestion`;
	const baseA = `securityAnswer`;

	return usersQuestions.reduce((allQAs, qRecord, idx) => {
		const { SecurityQuestionID: id, Question } = qRecord;
		const { Answer } = findSecurityAnswer(id, usersAnswers)[0];
		const qName = `${baseQ}${idx + 1}`;
		const aName = `${baseA}${idx + 1}`;
		if (!allQAs[qName] && !allQAs[aName]) {
			allQAs[qName] = Question;
			allQAs[aName] = Answer;
			return allQAs;
		}
		return allQAs;
	}, {});
};

const processAppsAccess = (security) => {
	const { UserApps } = security;

	// no apps
	if (isEmptyArray(UserApps)) {
		return {
			hasLegacyAccess: false,
			hasTrackerAccess: false,
			hasPortalAccess: true,
		};
	}

	const sorted = UserApps.sort((a, b) => b.ApplicationID - a.ApplicationID);
	//     Legacy: 1,     Tracker: 2,   Portal: 3
	const [LegacyAccess, TrackerAccess, PortalAccess] = sorted;

	return {
		hasLegacyAccess: LegacyAccess?.IsAccessible ?? true,
		hasTrackerAccess: TrackerAccess?.IsAccessible ?? false,
		hasPortalAccess: PortalAccess?.IsAccessible ?? true,
	};
};

// USER LIST NORMALIZATION & PROCESSING //
const getAdvMap = (advUsers = []) => {
	return advUsers.reduce((userMap, user) => {
		const { guidUser } = user;
		if (!userMap[guidUser]) {
			userMap[guidUser] = { ...user };
			return userMap;
		}
		return userMap;
	}, {});
};

const getLoginMap = (logins = []) => {
	return logins.reduce((loginMap, login) => {
		const { UserLoginID: id } = login;
		if (!loginMap[id]) {
			loginMap[id] = { ...login };
			return loginMap;
		}
		return loginMap;
	}, {});
};
const getProfileMap = (profiles = []) => {
	return profiles.reduce((profileMap, profile) => {
		const { UserProfileID: id } = profile;
		if (!profileMap[id]) {
			profileMap[id] = { ...profile };
			return profileMap;
		}
		return profileMap;
	}, {});
};
const getMergesMap = (merges = []) => {
	return merges.reduce((loginMap, login) => {
		const { UserLoginID: id } = login;
		if (!loginMap[id]) {
			loginMap[id] = { ...login };
			return loginMap;
		}
		return loginMap;
	}, {});
};

const getUserMaps = (securityInfo) => {
	const {
		FacilityAdvUsers: advUsers,
		FacilityUserLogins: userLogins,
		// FacilityUserMerges: userMerges, // uncomment later
		FacilityUserProfiles: userProfiles,
		FacilityUserAccessToApps: accessList,
	} = securityInfo;
	const advMap = getAdvMap(advUsers);
	const loginMap = getLoginMap(userLogins);
	const profileMap = getProfileMap(userProfiles);
	// const mergesMap = getMergesMap(userMerges); // uncomment later
	const accessMap = getAccessMap(accessList);

	return {
		advMap,
		loginMap,
		profileMap,
		appAccess: accessMap,
	};
};
// ##TODOS:
// - Update to include 'portalAccess' ✓
const getAccessMap = (accessRecords) => {
	// sorted by appID
	const sorted = accessRecords.sort(
		(a, b) => a.ApplicationId - b.ApplicationId
	);
	const [legacy, tracker, portal] = sorted;

	return {
		trackerAccess: tracker?.UserIds ?? [],
		legacyAccess: legacy?.UserIds ?? [],
		portalAccess: portal?.UserIds ?? [],
	};
};

// legacy-only equivalent of 'generateUserObj'
const generateLegacyUserObj = (user, access) => {
	const { guidUser: id } = user;
	// user's  app access
	const hasPortalAccess = access?.portalAccess?.includes(id);
	const hasTrackerAccess = access.trackerAccess.includes(id);
	const hasLegacyAccess = access?.legacyAccess?.includes(id) ?? true;

	const newUser = {
		firstName: user.strFirstName,
		lastName: user.strLastName,
		email: user?.strEmail,
		password: user?.strPassword,
		userID: id,
		userLoginID: id,
		userProfileID: null,
		title: user?.strTitle,
		phoneNumber: null,
		facilityID: user.guidFacility,
		avatarID: null,
		hasPortalAccess: hasPortalAccess,
		hasTrackerAccess: hasTrackerAccess,
		hasLegacyAccess: hasLegacyAccess, // should default to 'true' since they're legacy ONLY!!!
		isEmployee: !user.NoLongerAnEmployee,
		isLockedOut: false,
		isSuspended: false,
		isSuperUser: user.superUser ?? false,
		isAdmin: user?.alaAdmin ?? false,
		isFacilityAdmin: user?.bitFacilityAdmin ?? false,
		suspendedDate: null,
		lockoutDate: null,
		isPwdResetByAdmin: true,
		isPwdResetByQuestions: false,
		isPwdResetByEmail: user?.alaAdmin ? true : false,
		// new fields added - 6/17/2021 at 8:06 PM
		lastLogin: user?.LastLoginDate,
		dateCreated: null,
		// new fields added - 8/16/2021 at 1:39 PM
		isLoginActive: true,
		isProfileActive: null,
		isActive: !user?.NoLongerAnEmployee,
	};

	return newUser;
};

// ##TODOS:
// - Update to support 'FacilityUserMerges' as primary data source
const generateUserObj = (user, advMap, loginMap, profileMap, access) => {
	const { UserLoginID: id } = user;
	const login = loginMap?.[id] ?? {};
	const profile = profileMap?.[login?.UserProfileID] ?? {};
	const adv = advMap?.[id] ?? {};
	// get number of facility(s) a user has access to
	const facilityAccessCount = user?.FacilityAccessCount ?? 1;

	// check if user has a 'Login' record, if not fallback to 'ADV' record
	// that means user doesn't exist in new infra and is Legacy-ONLY
	const username = isEmptyObj(login)
		? adv?.strEmail
		: isEmptyVal(login?.LoginName)
		? login.LoginNameByEmail
		: login.LoginName;

	const title = !isEmptyObj(adv) ? adv.strTitle : null;
	const lastLogin = adv?.LastLoginDate ?? login?.ModifiedDate;
	// user's  app access
	const hasTrackerAccess = access.trackerAccess.includes(id);
	const hasLegacyAccess = access.legacyAccess.includes(id);
	const hasPortalAccess = access.portalAccess.includes(id);

	// new perms
	const isSuper =
		profile?.UserTypeID === 1 || profile?.userTypeID === 1 || adv?.superUser
			? true
			: false;
	const isFacAdmin =
		profile?.UserTypeID === 6 ||
		profile?.userTypeID === 6 ||
		adv?.bitFacilityAdmin
			? true
			: false;
	const isALAAdmin = hasAlaAdminPerms(adv, profile) || adv?.alaAdmin;
	const isMedTech = hasMedTechPerms(adv, profile);
	const isEA = profile?.UserTypeID === 4 || adv?.alaAdmin || isALAAdmin;

	const newUser = {
		firstName: user.FirstName,
		lastName: user.LastName,
		email: username,
		password: null,
		userID: id,
		userLoginID: login?.UserLoginID,
		userProfileID: profile?.UserProfileID,
		title: title,
		phoneNumber: null,
		facilityID: user.FacilityId,
		avatarID: user.UserAvatarID,
		hasPortalAccess: hasPortalAccess,
		hasTrackerAccess: hasTrackerAccess,
		hasLegacyAccess: hasLegacyAccess,
		isEmployee: user.IsEmployee,
		isLockedOut: user.IsLockOut,
		isSuspended: user.IsSuspended,
		// update user type logic
		isSuperUser: isSuper,
		isFacilityAdmin: isFacAdmin,
		isRegionalAdmin: false,
		isALAAdmin: isALAAdmin,
		isMedTechRestricted: isMedTech,
		isAdmin: isEA,
		// isAdmin: adv?.alaAdmin ?? false,
		// OLD 'UserType' HANDLING //
		// isSuperUser: user?.IsSuperUser ?? adv?.superUser ?? false,
		// // newly added fields
		// isFacilityAdmin: adv?.bitFacilityAdministrator ?? false,
		// isRegionalAdmin: false,
		// isALAAdmin: adv?.alaAdmin ?? false,
		suspendedDate: user.SuspendedDate,
		suspendedBy: login?.ModifiedBy ?? "", // recently added
		lockoutDate: user.LockOutDate,
		isPwdResetByAdmin: user.IsPwdResetByAdmin,
		isPwdResetByQuestions: user.IsPwdResetByQuestions,
		isPwdResetByEmail: user.IsPwdResetByEmail,
		// new fields added - 6/17/2021 at 8:06 PM
		lastLogin: lastLogin,
		dateCreated: login?.CreatedDate ?? "",
		createdBy: login?.CreatedBy ?? "",
		// new fields added - 8/16/2021 at 1:39 PM
		isLoginActive: login?.IsActive,
		isProfileActive: profile?.IsActive,
		isActive: profile?.IsActive && login?.IsActive,
		// newly added 1/30/2024
		facilityAccessCount: facilityAccessCount,
	};

	return newUser;
};
// - Added support for 'Legacy-ONLY' user types (ie non-migrated users)
// ##TODOS:
// - Update to rely on 'FacilityUserMerges' as primary source
const processFacilityUsers = (securityInfo = {}) => {
	const { FacilityUserMerges: mergedList, FacilityAdvUsers: advUsers } =
		securityInfo;
	const { advMap, loginMap, profileMap, appAccess } = getUserMaps(securityInfo);

	// legacy users ONLY (ie non-migrated users)
	if (isEmptyArray(mergedList)) {
		return advUsers.map((user) => {
			const newUserObj = generateLegacyUserObj(user, appAccess);
			// check if user's email was deleted or profile/login is 'inactive'
			if (isEmailDeleted(newUserObj.email) || !newUserObj?.isActive) {
				return null;
			} else {
				return newUserObj;
			}
		});
	} else {
		// iterate thru 'mergedList' and normalize users
		return mergedList.reduce((userList, user) => {
			const newUserObj = generateUserObj(
				user,
				advMap,
				loginMap,
				profileMap,
				appAccess
			);

			// if user's email is deleted or account is inactive, don't include them
			if (isEmailDeleted(newUserObj.email) || !newUserObj?.isActive) {
				return userList;
			} else {
				// otherwise add them to the user's list
				userList = [...userList, newUserObj];
				return userList;
			}
		}, []);
	}
};

// finds 'AdminPortal' app access via ID & returns object record
// returns object: { ApplicationID: 3, ApplicationName: '..', IsAccessible: true, UserIds: [] }
const getPortalAccess = (appAccess) => {
	const portal = appAccess.filter(
		(x) => x.ApplicationId === "3" || x.ApplicationId === 3
	);
	return portal?.[0];
};
// finds 'AdminPortal' app access via ID & returns object record
// returns object: { ApplicationID: 19, ApplicationName: '..', IsAccessible: true, UserIds: [] }
const getEmarAccess = (appAccess) => {
	const emar = appAccess.filter(
		(x) => x.ApplicationId === "19" || x.ApplicationId === 19
	);
	return emar?.[0];
};

// finds 'AdvantageTracker' app access via ID & returns object record
// returns object: { ApplicationID: 2, ApplicationName: '..', IsAccessible: true, UserIds: [] }
const getTrackerAccess = (appAccess) => {
	const tracker = appAccess.filter(
		(x) => x.ApplicationId === "2" || x.ApplicationId === 2
	);
	return tracker?.[0];
};
// finds 'SeniorCareVB' app access via ID & returns object record
// returns object: { ApplicationID: 1, ApplicationName: '..', IsAccessible: true, UserIds: [] }
const getLegacyAccess = (appAccess) => {
	const legacy = appAccess.filter(
		(x) => x.ApplicationId === "1" || x.ApplicationId === 1
	);
	return legacy?.[0];
};
// finds 'ePay' app access via ID & returns object record
// returns object: { ApplicationID: 22, ApplicationName: '..', IsAccessible: true, UserIds: [] }
const getEPayAccess = (appAccess) => {
	const ePay = appAccess.filter(
		(x) => x.ApplicationId === "22" || x.ApplicationId === 22
	);
	return ePay?.[0];
};

// processes 'getFacilityUserInfo' access records
const processAccessRecords = (appAccess = []) => {
	if (isEmptyArray(appAccess)) return {};

	const AdvantageTracker = getTrackerAccess(appAccess);
	const SeniorCareVB = getLegacyAccess(appAccess);
	const AdminPortal = getPortalAccess(appAccess);
	const Emar = getEmarAccess(appAccess);
	const ePay = getEPayAccess(appAccess);

	const legacyAccess = {
		appID: 1,
		appName: SeniorCareVB?.ApplicationName,
		isAccessible: SeniorCareVB?.IsAccessible,
		userIDs: SeniorCareVB?.UserIds,
	};
	const trackerAccess = {
		appID: 2,
		appName: AdvantageTracker?.ApplicationName,
		isAccessible: AdvantageTracker?.IsAccessible,
		userIDs: AdvantageTracker?.UserIds,
	};
	const portalAccess = {
		appID: 3,
		appName: AdminPortal?.ApplicationName,
		isAccessible: AdminPortal?.IsAccessible,
		userIDs: AdminPortal?.UserIds,
	};
	const emarAccess = {
		appID: 19,
		appName: Emar?.ApplicationName,
		isAccessible: Emar?.IsAccessible,
		userIDs: Emar?.UserIds,
	};
	const ePayAccess = {
		appID: 22,
		appName: ePay?.ApplicationName,
		isAccessible: ePay?.IsAccessible,
		userIDs: ePay?.UserIds,
	};

	return {
		trackerAccess,
		legacyAccess,
		portalAccess,
		emarAccess,
		ePayAccess,
	};
};
// processes 'getFacilityUserInfo'
const processFacilitySecurity = (data = {}) => {
	const {
		FacilityAdvUsers: advUsers,
		FacilityUserLogins: userLogins,
		FacilityUserProfiles: userProfiles,
		FacilityUserAccessToApps: userAppAccess,
		FacilityUserAccessCounts: userAccessCounts,
	} = data;

	// app access lists (list of userIDs with access)
	const { trackerAccess, legacyAccess, portalAccess, emarAccess, ePayAccess } =
		processAccessRecords(userAppAccess);
	// process users into client-format
	const usersList = processFacilityUsers(data);

	return {
		usersList,
		advUsers, // previously commented out
		userProfiles, // previously commented out
		userLogins, // previously commented out
		// denotes non-migrated & legacy ONLY user list
		isLegacyOnly: isEmptyArray(userLogins) && !isEmptyArray(advUsers),
		appAccess: {
			trackerAccess,
			legacyAccess,
			portalAccess,
			emarAccess,
			ePayAccess,
		},
	};
};

const RESETS = {
	isPwdResetByAdmin: "Admin",
	isPwdResetByEmail: "Email",
	isPwdResetByQuestions: "Questions",
};

/**
 * Determines list of user password reset methods.
 * @param {Object} clientData - Response data from 'GetUserPasswordRules'
 * @returns {Array} - Returns an array of strings.
 */
const getResetMethods = (clientData = {}) => {
	const {
		IsPwdResetByAdmin: isPwdResetByAdmin,
		IsPwdResetByEmail: isPwdResetByEmail,
		IsPwdResetByQuestions: isPwdResetByQuestions,
	} = clientData;

	const admin = isPwdResetByAdmin ? RESETS.isPwdResetByAdmin : null;
	const email = isPwdResetByEmail ? RESETS.isPwdResetByEmail : null;
	const questions = isPwdResetByQuestions ? RESETS.isPwdResetByQuestions : null;

	return [admin, email, questions].filter(Boolean);
};

const processAccountSecurity = (data = {}) => {
	if (isEmptyObj(data) || !data) return {};

	const {
		IsProfileActive,
		IsProfileLockout,
		IsLoginActive,
		IsLoginLockout,
		IsSuspended,
		IsPwdResetByAdmin,
		IsPwdResetByEmail,
		IsPwdResetByQuestions,
		// LoginStartDate: lastLogin,
	} = data;

	// both profile & login records are 'active' status
	const isActive = IsProfileActive && IsLoginActive;
	// if profile/login are 'active', but either/or is 'locked'
	const isLockedOut = (IsProfileLockout || IsLoginLockout) && isActive;

	const methods = getResetMethods(data);

	return {
		// lastLogin: lastLogin,
		isLockedOut: isLockedOut,
		isSuspended: IsSuspended,
		isPwdResetByAdmin: IsPwdResetByAdmin ?? true,
		isPwdResetByEmail: IsPwdResetByEmail,
		isPwdResetByQuestions: IsPwdResetByQuestions,
		resetMethods: [...methods],
	};
};

///////////////////////////////////////////////////////////////////
////////////// PROCESS A SINGLE USER'S SECURITY INFO //////////////
///////////////////////////////////////////////////////////////////

// processes a single 'UserApp' record from GetUserSecurityInfo API
const processUserApp = (app = {}) => {
	const {
		ApplicationID: appID,
		ApplicationByUserID: appByUserID,
		IsAccessible: isAccessible,
		IsActive: isActive,
		UserID: userID,
	} = app;
	// client-formatted object
	const newRecord = {
		appID: appID,
		appByUserID: appByUserID,
		isAccessible: isAccessible,
		isActive: isActive,
		userID: userID,
		appName: appNames[appID],
	};

	return newRecord;
};

// process 'UserApps' records from GetUserSecurityInfo API
const processUserApps = (apps = []) => {
	// sorts by ID: 1, 2, 3
	const sorted = apps.sort((a, b) => a.ApplicationID - b.ApplicationID);
	const [legacy, tracker, portal, emar] = sorted;

	const legacyAccess = processUserApp(legacy);
	const trackerAccess = processUserApp(tracker);
	const portalAccess = processUserApp(portal);
	const emarAccess = processUserApp(emar);

	return {
		legacyAccess,
		trackerAccess,
		portalAccess,
		emarAccess,
	};
};
// process 'UserProfile' records from GetUserSecurityInfo API
const processUserProfile = (profile = {}) => {
	const {
		UserProfileID: userProfileID,
		UserTypeID: userTypeID,
		UserTitleID: userTitleID,
		FirstName: firstName,
		LastName: lastName,
		MiddleName: middleName,
		DisplayName: displayName,
		IsLockOut: isLockedOut,
		LockOutDate: lockoutDate,
		LastLockOutDate: lastLockoutDate,
		FacilityId: facilityID,
		IsActive: isProfileActive,
	} = profile;
	// new obj
	const userProfile = {
		userProfileID,
		userTypeID,
		userTitleID,
		firstName,
		lastName,
		middleName,
		displayName,
		isLockedOut,
		lockoutDate,
		lastLockoutDate,
		facilityID,
		isProfileActive,
	};

	return userProfile;
};

const processSingleLogin = (login = {}) => {
	const {
		UserProfileID: userProfileID,
		UserAvatarID: userAvatarID,
		LoginName: username,
		LoginNameByEmail: email,
		StartDate: userStartDate,
		EndDate: userEndDate,
		IsSuspended: isSuspended,
		SuspendedDate: suspendedDate,
		IsPwdResetByAdmin: isPwdResetByAdmin,
		IsPwdResetByEmail: isPwdResetByEmail,
		IsPwdResetByQuestions: isPwdResetByQuestions,
		IsActive: isLoginActive,
		IsLockOut: isLockedOut,
		LockOutDate: lockoutDate,
		LastLockOutDate: lastLockoutDate,
		CreatedDate: dateCreated,
		IsEmailVerified: isEmailVerified,
	} = login;

	const resetMethods = getResetMethods(login);

	const newLogin = {
		userProfileID,
		userAvatarID,
		username,
		email,
		isSuspended,
		suspendedDate,
		isPwdResetByAdmin,
		isPwdResetByEmail,
		isPwdResetByQuestions,
		isLoginActive,
		isLockedOut,
		lockoutDate,
		lastLockoutDate,
		dateCreated,
		isEmailVerified,
		resetMethods,
	};

	return newLogin;
};

// iterates thru a user's 'UserLogins' records, processes & maps them into object
const processUserLogins = (userLogins = []) => {
	const loginsMap = userLogins.reduce((grouped, login) => {
		const newRecord = processSingleLogin(login);
		if (!grouped[login.ApplicationID]) {
			grouped[login.ApplicationID] = { ...newRecord };
			return grouped;
		}
		return grouped;
	}, {});

	return loginsMap;
};

const processSingleEmail = (emailRecord = {}) => {
	const {
		UserEmailID: userEmailID,
		Email: email,
		IsPrimary: isPrimary,
		IsActive: isActive,
		IsValidEmail: isValidEmail,
		IsEmailVerified: isEmailVerified,
	} = emailRecord;

	const newEmail = {
		userEmailID,
		email,
		isPrimary,
		isActive,
		isValidEmail,
		isEmailVerified,
	};

	return newEmail;
};

// returns map of user email records (if multiple)
// consider changing this to an array of processed email records
const processUserEmails = (userEmails = []) => {
	const userEmailRecords = userEmails.reduce((map, email) => {
		const userEmail = processSingleEmail(email);
		if (!map[email.UserEmailID]) {
			map[email.UserEmailID] = { ...userEmail };
			return map;
		}
		return map;
	}, {});

	return userEmailRecords;
};

const processUserPhones = (userPhones = []) => {
	const processed = userPhones.map((phone) => processSinglePhone(userPhones));

	return processed;
};

const processSinglePhone = (phoneRecord = {}) => {
	const {
		UserPhoneID: userPhoneID,
		Phone: phone,
		Extension: phoneExt,
		IsPrimary: isPrimaryPhone,
		IsActive: isActivePhone,
	} = phoneRecord;

	const newRecord = {
		userPhoneID,
		phone,
		phoneExt,
		isPrimaryPhone,
		isActivePhone,
	};

	return newRecord;
};

// processes a single user's security info
const processUserSecurity = (securityInfo = {}) => {
	const {
		UserProfile,
		UserLogins,
		UserAlerts,
		UserAvatars,
		UserEmails,
		UserPhones,
		UserApps,
		UserRoles,
		UserRoleGroups,
		UserSecurityAccesses,
		UserSecurityRoles,
		UserSecurityUsers,
		UserSecurityAnswers,
		UserSecurityQuestions,
		AdvUsers,
		AdvUserCommunities,
	} = securityInfo;

	const userProfile = processUserProfile(UserProfile); // obj
	const userLogin = processSingleLogin(UserLogins?.[0]); // obj
	const userEmail = processSingleEmail(UserEmails?.[0]); // obj
	const userPhones = processUserPhones(UserPhones); // array of objects

	const { trackerAccess, portalAccess, legacyAccess } =
		processUserApps(UserApps); // 3 separate objects

	// new formatted user security object
	const newUserSecurity = {
		...userProfile,
		...userLogin,
		...userEmail,
		...trackerAccess,
		...portalAccess,
		...legacyAccess,
		hasTrackerAccess: trackerAccess?.isAccessible,
		hasPortalAccess: portalAccess?.isAccessible,
		hasLegacyAccess: legacyAccess?.isAccessible,
		userPhones: userPhones,
	};

	return newUserSecurity;
};

// populates a 'PasswordResetConfig' model
const initAndUpdateResetModel = (vals = {}) => {
	// const base = new PasswordResetConfig(vals); // previous
	const base = new PasswordResetRules(vals);
	const model = base.getModel();
	return model;
};

// QUESTION/ANSWER MODELS UPDATES //

// REQUEST UTILS //
export {
	validateUserLogin,
	validateStrongPassword,
	// security questions
	validateSecurityQuestion,
	validateSecurityAnswer,
	// validate ALL Q&A's
	validateAllAnswers,
	validateAllQuestions,
};
// FACILITY ACCESS UPDATERS
export { registerFacilityAccess, grantFacilityAccess, denyFacilityAccess };

export {
	getUserSecurityInfo,
	getFacilityUserInfo,
	getProcessedFacililtyUserInfo,
	// user access apps
	processAppsAccess,
};
// User Profile-related request utils
export {
	getAllSecurityQuestions,
	getAllSecurityQuestions2,
	saveUserSecurityQuestionAndAnswers,
	saveUserProfileChanges,
	saveUserPhoneChanges,
	saveUserResetTypes, // pwd reset types/methods
	saveUserResetPreference,
	setQuestionsAsUserPreference,
};
// user types & titles utils
export { getAllUserTitles, getAllUserTypes, getTypesAndTitles };

// Q/A HELPERS & UTILS //
export {
	formatSecurityQuestions,
	getRemainingQuestions,
	// matchers
	matchQuestionRecordFromStr,
	matchQuestionIDFromStr,
	// formatters
	mergeSecurityQuestionsAndAnswers,
	extractQuestions,
	// model utils
	initQAModel,
	createSecurityQAModels,
	hasQuestionAndAnswer,
};

// user security processing utils
export {
	processAccessRecords,
	processFacilitySecurity,
	processAccountSecurity,
	// new utils for 'UserDetails'
	processUserLogins,
	processSingleLogin,
	processUserApps,
	processUserApp,
	processUserEmails,
	processSingleEmail,
	// complete wrapper around above utils
	processUserSecurity,
};

// USER SECURITY MODEL UTILS //
// Updaters for 'UserLogin' records
export {
	// password reset model
	initAndUpdateResetModel,
};
