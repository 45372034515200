import React from "react";
import styles from "../../css/shared/ControlledButton.module.scss";
import { PropTypes } from "prop-types";

const ControlledButton = ({
	isDisabled = false,
	isHidden = false,
	handleClick,
	children,
	title,
	type = "button",
	customStyles = {},
}) => {
	if (isHidden) {
		return null;
	}
	return (
		<button
			type={type}
			disabled={isDisabled}
			onClick={handleClick}
			className={styles.ControlledButton}
			title={title}
			style={customStyles}
		>
			{children}
		</button>
	);
};

export default ControlledButton;

ControlledButton.defaultProps = {
	isDisabled: false,
	isHidden: false,
	type: "button",
	customStyles: {},
};

ControlledButton.propTypes = {
	isDisabled: PropTypes.bool,
	isHidden: PropTypes.bool,
	handleClick: PropTypes.func.isRequired,
	children: PropTypes.any,
	title: PropTypes.string,
	type: PropTypes.string,
	customStyles: PropTypes.object,
};
