import React, { useState } from "react";
import styles from "../../css/messages/HomePageMessages.module.scss";
import { PropTypes } from "prop-types";
import { blue } from "../../helpers/utils_styles";
import { isEmptyArray } from "../../helpers/utils_types";
import { ACTIVE_MESSAGES } from "../../helpers/utils_messages";
// components
import HomePageMessage from "./HomePageMessage";

const HomePageMessages = ({ systemUser, disableAll = false }) => {
	const [messages, setMessages] = useState([...ACTIVE_MESSAGES?.homePage]);
	// Messages stored in database - NOT WORKING YET!!!
	// const [messages, setMessages] = useState(async () => {
	// 	const { token } = systemUser;
	// 	const rawMsgs = await fetchLoginPageMessages(token);
	// 	const loginMsgs = processMessages(rawMsgs);
	// 	return loginMsgs;
	// });

	if (disableAll) {
		return null;
	}
	return (
		<div className={styles.HomePageMessages}>
			{!isEmptyArray(messages) &&
				messages.map((msg, idx) => (
					<HomePageMessage
						key={`LOGIN-MESSAGE-${idx + 1}`}
						message={msg}
						customMsgStyles={{ color: blue[600] }}
					/>
				))}
		</div>
	);
};

export default HomePageMessages;

HomePageMessages.defaultProps = {};

HomePageMessages.propTypes = {};
