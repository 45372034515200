import React, { useEffect } from "react";
import styles from "../../css/user/UserProfilePage.module.scss";
import { PropTypes } from "prop-types";
import { sendPageTracking } from "../../helpers/utils_tracking";
// components
import UserProfile from "./UserProfile";

// REQUIREMENTS:
// - Split view up into 'full-page' tabs:
//    - Profile
//    - Notifications (alerts)
//    - Security (password reset)
//    - Preferences (independent task visibility, unit-types etc)

const UserProfilePage = ({
	globalState = {},
	currentUser = {},
	currentFacility = {},
	dispatchToState,
	dispatchAlert,
}) => {
	// page tracker
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		sendPageTracking(currentUser?.username, {
			pagename: `UserSettings-Profile`,
			path: `/portal/profile?activeTab=profile`,
		});

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.UserProfilePage}>
			<UserProfile
				globalState={globalState}
				currentFacility={currentFacility}
				currentUser={currentUser}
				dispatchToState={dispatchToState}
				dispatchAlert={dispatchAlert}
			/>
		</div>
	);
};

export default UserProfilePage;

UserProfilePage.defaultProps = {};

UserProfilePage.propTypes = {
	currentUser: PropTypes.object,
};
