import React from "react";
import styles from "../../css/ui/DownloadFile.module.scss";
import sprite from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";

const DownloadFile = ({ src, enableFlash = true, flashText = "Updated" }) => {
	return (
		<div className={styles.DownloadFile}>
			<a href={src} className={styles.DownloadFile_link} download>
				<svg className={styles.DownloadFile_link_icon}>
					<use xlinkHref={`${sprite}#icon-attachment21`}></use>
				</svg>
				<span className={styles.DownloadFile_link_text}>
					Download Training Document{" "}
				</span>
				{enableFlash && (
					<div className={styles.Flash}>
						<span className={styles.Flash_text}>{flashText}</span>
						<span className={styles.Flash_badge}></span>
					</div>
				)}
			</a>
		</div>
	);
};

export default DownloadFile;

DownloadFile.defaultProps = {};

DownloadFile.propTypes = {};
