import React from "react";
import styles from "../../css/tabs/ControllableTabsPanels.module.scss";
import { PropTypes } from "prop-types";

// CONTAINS SHOWN/HIDDEN TAB CONTENT SECTIONS //

const ControllableTabsPanels = ({ activeIndex, children }) => {
	const withActiveIndex = React.Children.map(children, (child, i) => {
		return React.cloneElement(child, {
			activeIndex: activeIndex,
			tabIndex: i,
		});
	});

	return (
		<section className={styles.ControllableTabsPanels}>
			{withActiveIndex}
		</section>
	);
};

export default ControllableTabsPanels;

ControllableTabsPanels.defaultProps = {};

ControllableTabsPanels.propTypes = {
	activeIndex: PropTypes.number,
	children: PropTypes.any.isRequired,
};
