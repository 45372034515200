import React from "react";
import styles from "../../css/facility/FacilityNotificationsPanel.module.scss";
import { PropTypes } from "prop-types";
import IncidentNotificationList from "./IncidentNotificationList";
import AssessmentNotificationList from "./AssessmentNotificationList";
import LockoutNotificationList from "./LockoutNotificationList";

const FacilityNotificationsPanel = ({
	listType = "INCIDENTS",
	notificationsList = {},
	currentFacility = {},
	currentUser = {},
	dispatchAlert,
	syncChangesHandler,
}) => {
	return (
		<div className={styles.FacilityNotificationsPanel}>
			<div className={styles.FacilityNotificationsPanel_section_list}>
				{listType === "INCIDENTS" && (
					<IncidentNotificationList
						key={`INCIDENTS-${notificationsList?.ID}`}
						listType="INCIDENTS"
						notificationRecord={notificationsList}
						currentUser={currentUser}
						currentFacility={currentFacility}
						dispatchAlert={dispatchAlert}
						syncChangesHandler={syncChangesHandler}
					/>
				)}
				{listType === "ASSESSMENTS" && (
					<AssessmentNotificationList
						key={`ASSESSMENTS-${notificationsList?.ID}`}
						listType="ASSESSMENTS"
						notificationRecord={notificationsList}
						currentUser={currentUser}
						currentFacility={currentFacility}
						dispatchAlert={dispatchAlert}
						syncChangesHandler={syncChangesHandler}
					/>
				)}
				{listType === "LOCKOUTS" && (
					<LockoutNotificationList
						key={`LOCKOUTS-${notificationsList?.ID}`}
						listType="LOCKOUTS"
						notificationRecord={notificationsList}
						currentUser={currentUser}
						currentFacility={currentFacility}
						dispatchAlert={dispatchAlert}
						syncChangesHandler={syncChangesHandler}
					/>
				)}
			</div>
		</div>
	);
};

export default FacilityNotificationsPanel;

FacilityNotificationsPanel.defaultProps = {
	listType: "INCIDENTS",
	notificationsList: {},
	currentFacility: {},
	currentUser: {},
};

FacilityNotificationsPanel.propTypes = {
	listType: PropTypes.string,
	notificationList: PropTypes.object,
	currentFacility: PropTypes.object,
	currentUser: PropTypes.object,
	dispatchAlert: PropTypes.func,
	syncChangesHandler: PropTypes.func,
};
