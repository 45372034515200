import React, { useContext, useState, useEffect, useCallback } from "react";
import styles from "../css/pages/PermissionsPanelPage.module.scss";
import { PropTypes } from "prop-types";
import { GlobalStateContext } from "../state/GlobalStateContext";
import { initialSettings, useAlerts } from "../utils/useAlerts";
import { isEmptyVal } from "../helpers/utils_types";
import {
	getFacilityInfoAndSummary,
	matchUserFacilityByName,
	processFacilityInfo,
} from "../helpers/utils_facility";
import { processUserList } from "../helpers/utils_user";
import { processLockoutsList } from "../helpers/utils_lockouts";
// components
import ViewContainer from "../components/app/ViewContainer";
import ModulePermissionsPanel from "../components/modules/ModulePermissionsPanel";
import ModulePermission from "../components/modules/ModulePermission";
import UserDropdown from "../components/admin/UserDropdown";
import ALASelector from "../components/app/ALASelector";

const customCSS = {
	selector: {
		backgroundColor: "#ffffff",
	},
};

const viewDetails = {
	title: `Permissions Panel`,
	subtitle: `Manage user permissions and access such as pages, views, functionality and other permissions.`,
};

const PermissionsPanelPage = ({ history }) => {
	const { state: globalState, dispatch: dispatchToState } =
		useContext(GlobalStateContext);
	const { currentFacility, currentUser } = globalState;
	const [selectedFacility, setSelectedFacility] = useState(
		currentFacility?.communityName ?? ""
	);
	const { AlertsHandler, dispatchAlert } = useAlerts({ ...initialSettings });
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [selectedPermissions, setSelectedPermissions] = useState([]);
	const [isAllSelected, setIsAllSelected] = useState(false);

	const handleSelectUsers = (selections) => {
		setSelectedUsers(selections);
	};

	const handleSelectAll = () => {
		setIsAllSelected(true);
	};
	const handleDeselectAll = () => {
		setIsAllSelected(false);
	};

	// fires off 'facility loaded' alert
	const changeFacility = (selection) => {
		// fetch facility details here
		dispatchAlert("SUCCESS", {
			heading: `Loaded Facility`,
			subheading: `Loaded ${selection}`,
		});
	};

	// sets 'currentFacility' obj when 'selectedResident' changes
	const setFacility = useCallback(async () => {
		if (isEmptyVal(selectedFacility)) {
			return dispatchToState({ type: "CLEAR_FACILITY" });
		}
		const { token } = currentUser;
		const facilityRecord = matchUserFacilityByName(
			selectedFacility,
			currentUser?.facilities
		);
		const { facilityID } = facilityRecord;
		const { facilityInfo, facilityUsers, facilityLockouts } =
			await getFacilityInfoAndSummary(token, facilityID);

		dispatchToState({
			type: "SET_FACILITY_USERS",
			data: {
				facilityRecord: facilityRecord,
				facilityInfo: { ...processFacilityInfo(facilityInfo) },
				facilityUsers: [...processUserList(facilityUsers)],
				facilityLockouts: [...processLockoutsList(facilityLockouts)],
			},
		});
	}, [currentUser, dispatchToState, selectedFacility]);

	// sets 'currentFacility' in global store when it changes
	useEffect(() => {
		setFacility();
	}, [setFacility]);

	return (
		<>
			<ViewContainer title={viewDetails.title} desc={viewDetails.subtitle}>
				<div className={styles.PermissionsPanelPage}>
					<div className={styles.PermissionsPanelPage_selector}>
						<ALASelector
							key={`PERMS_PAGE_SELECTOR`}
							disableResident={true} // facility only selector
							isAdmin={true} // admin only route
							facilities={currentUser?.facilities}
							defaultFacility={selectedFacility}
							syncFacility={setSelectedFacility}
							loadFacility={changeFacility}
						/>
					</div>
					<div className={styles.PermissionsPanelPage_selector}>
						<label
							htmlFor="selectedUsers"
							className={styles.PermissionsPanelPage_selector_label}
						>
							Select one or more users
						</label>
						<UserDropdown
							key={`DROPDOWN:${currentFacility?.users?.length}`}
							initialSelections={[]}
							handleUserSelector={handleSelectUsers}
							options={currentFacility?.users ?? []}
							customStyles={customCSS.selector}
						/>
					</div>
					<div className={styles.PermissionsPanelPage_panels}>
						<ModulePermissionsPanel
							key="PAGE-PERMISSIONS"
							title="Set Page Permissions"
							subtitle="Click to select one or more permissions to enable for this user."
							isAllSelected={isAllSelected}
							selectAll={handleSelectAll}
							deselectAll={handleDeselectAll}
						>
							<ModulePermission
								key="MODULE-1"
								moduleIdx={1}
								moduleName="Admin Panel"
								moduleID="adminPanel"
							/>
							<ModulePermission
								key="MODULE-2"
								moduleIdx={2}
								moduleName="Levels of Care"
								moduleID="levelsOfCare"
							/>
							<ModulePermission
								key="MODULE-3"
								moduleIdx={3}
								moduleName="App Access"
								moduleID="appAccess"
							/>
							<ModulePermission
								key="MODULE-4"
								moduleIdx={4}
								moduleName="Legacy Tools"
								moduleID="legacyTools"
							/>
							<ModulePermission
								key="MODULE-5"
								moduleIdx={5}
								moduleName="Enable Tracker"
								moduleID="enableTracker"
							/>
						</ModulePermissionsPanel>
						<ModulePermissionsPanel
							key="FEATURE-PERMISSIONS"
							title="Feature Permissions"
							subtitle="Click to select one or more features to give access to for this user."
						>
							<ModulePermission
								key="MODULE-6"
								moduleIdx={6}
								moduleName="Change User Type(s)"
								moduleID="changeUserTypes"
							/>
							<ModulePermission
								key="MODULE-7"
								moduleIdx={7}
								moduleName="Delete Tasks"
								moduleID="deleteTasks"
							/>
							<ModulePermission
								key="MODULE-8"
								moduleIdx={8}
								moduleName="Create New Tasks"
								moduleID="createNewTasks"
							/>
							<ModulePermission
								key="MODULE-9"
								moduleIdx={9}
								moduleName="Run Reports"
								moduleID="runReports"
							/>
						</ModulePermissionsPanel>
						<ModulePermissionsPanel
							key="APP-PERMISSIONS"
							title="App Permissions"
							subtitle="Click to select one or more permissions per app to enable."
						>
							{/*  */}
							{/*  */}
						</ModulePermissionsPanel>
					</div>
				</div>
			</ViewContainer>

			{AlertsHandler}
		</>
	);
};

export default PermissionsPanelPage;

PermissionsPanelPage.defaultProps = {};

PermissionsPanelPage.propTypes = {
	history: PropTypes.object,
};
