import React from "react";
import styles from "../../css/facility/FacilityNotificationsList.module.scss";
import sprite from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";
import { addEllipsis } from "../../helpers/utils_processing";
import { isEmptyArray } from "../../helpers/utils_types";
import Placeholder from "../shared/Placeholder";

const NotificationRecipient = ({
	email,
	isDisabled = false,
	removeRecipient,
}) => {
	return (
		<li className={styles.NotificationRecipient} title={email}>
			<div className={styles.NotificationRecipient_email}>
				{addEllipsis(email, 35)}
			</div>
			<button
				type="button"
				disabled={isDisabled}
				className={styles.NotificationRecipient_removeBtn}
				onClick={removeRecipient}
			>
				<svg className={styles.NotificationRecipient_removeBtn_icon}>
					<use xlinkHref={`${sprite}#icon-clearclose`}></use>
				</svg>
			</button>
		</li>
	);
};

const FacilityNotificationsList = ({
	listType = "INCIDENTS",
	notificationsList = [],
	removeRecipient,
}) => {
	return (
		<ul className={styles.FacilityNotificationsList}>
			{!isEmptyArray(notificationsList) &&
				notificationsList.map((recipientsEmail, idx) => (
					<NotificationRecipient
						email={recipientsEmail}
						recipient={recipientsEmail}
						key={`${recipientsEmail}--${idx}`}
						removeRecipient={() => removeRecipient(listType, recipientsEmail)}
					/>
				))}
			{isEmptyArray(notificationsList) && (
				<Placeholder
					size="SM"
					msg="No email recipients found for this notification type. Add an email address above."
				/>
			)}
		</ul>
	);
};

export default FacilityNotificationsList;

FacilityNotificationsList.defaultProps = {
	notificationsList: [],
};

FacilityNotificationsList.propTypes = {
	notificationsList: PropTypes.array,
	removeRecipient: PropTypes.func,
};
