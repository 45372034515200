import React from "react";
import styles from "../../css/alerts/AlertBanner.module.scss";
import sprite from "../../assets/icons/alerts.svg";
import { PropTypes } from "prop-types";
import { purple, red, green, yellow } from "../../helpers/utils_styles";

const ICONS = {
	alertCircle: "alert-circle",
	alertOutline: "alert-outline",
	alertOctagon: "alert-octagon",
	alertTriangle: "alert-triangle",
	arrowLeft: "arrow-left",
	arrowRight: "arrow-right",
	help: "help-circle",
	info: "info",
	xCircle: "x-circle",
	xOctagon: "x-octagon",
	xSquare: "x-square",
	checkmark: "checkmark",
	checkmarkOutline: "checkmark-outline",
	chevronDownOutline: "cheveron-outline-down",
	chevronUpOutline: "cheveron-outline-up",
	close: "close",
	closeOutline: "close-outline",
	closeSolid: "close-solid",
	exclamationSolid: "exclamation-solid",
	exclamationOutline: "exclamation-outline",
	informationSolid: "information-solid",
	informationOutline: "information-outline",
	lightbulb: "light-bulb",
	question: "question",
	warning: "warning",
	check: "check",
	circleWithCross: "circle-with-cross",
	new: "new",
};
const ICON_TYPES = {
	ERROR: ICONS["alertTriangle"],
	SUCCESS: ICONS["checkmark"],
	WARNING: ICONS["alertTriangle"],
	INFO: ICONS["exclamationOutline"],
};

const baseCSS = {
	headingCSS: {
		color: "#ffffff",
	},
	subheadingCSS: {
		color: "#ffffff",
	},
	msgCSS: {
		color: "#ffffff",
	},
	iconCSS: {
		fill: "#ffffff",
	},
};

const TYPES = {
	ERROR: {
		backgroundColor: red[500],
		color: "#ffffff",
		fill: "#ffffff",
	},
	SUCCESS: {
		backgroundColor: green[500],
		color: "#ffffff",
		fill: "#ffffff",
	},
	WARNING: {
		backgroundColor: yellow[500],
		color: "#ffffff",
		fill: "#ffffff",
	},
	INFO: {
		backgroundColor: purple[500],
		color: "#ffffff",
		fill: "#ffffff",
	},
};

const AlertBanner = ({
	heading,
	subheading,
	msg,
	icon = "alertTriangle",
	closeAlert,
	type = "SUCCESS",
}) => {
	return (
		<aside className={styles.AlertBanner} style={{ ...TYPES[type] }}>
			<div className={styles.AlertBanner_inner}>
				<div className={styles.AlertBanner_inner_iconWrapper}>
					<svg
						className={styles.AlertBanner_inner_iconWrapper_icon}
						style={{ ...TYPES[type] }}
					>
						<use xlinkHref={`${sprite}#icon-${ICON_TYPES[type]}`}></use>
					</svg>
				</div>
				<hgroup
					className={styles.AlertBanner_inner_headings}
					style={{ ...TYPES[type] }}
				>
					<h2 className={styles.AlertBanner_inner_headings_heading}>
						{heading}
					</h2>
					<h3 className={styles.AlertBanner_inner_headings_subheading}>
						{subheading}
					</h3>
					<p className={styles.AlertBanner_inner_headings_msg}>{msg}</p>
				</hgroup>
			</div>
			<div className={styles.AlertBanner_close} onClick={closeAlert}>
				<svg className={styles.AlertBanner_close_icon}>
					<use xlinkHref={`${sprite}#icon-x-circle`}></use>
				</svg>
			</div>
		</aside>
	);
};

export default AlertBanner;

AlertBanner.defaultProps = {};

AlertBanner.propTypes = {};
