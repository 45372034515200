import React, { useState } from "react";
import styles from "../../css/emar/EmarErrorSummary.module.scss";
import { PropTypes } from "prop-types";
import {
	generateEmarErrorSummary,
	getEmarIssuesRedirectUrl,
} from "../../helpers/utils_emar";
import { enforceStrMaxLength } from "../../helpers/utils_processing";
import { addEllipsis } from "../../helpers/utils_processing";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";

const customCSS = {
	link: {
		fontSize: "1.2rem",
	},
};

const LegacyRedirect = ({
	selectedApp = "SeniorCareEHR",
	currentUser = {},
	customStyles = {},
}) => {
	const redirectUrl = getEmarIssuesRedirectUrl(selectedApp, currentUser);

	return (
		<a
			href={redirectUrl}
			target="_blank"
			rel="noopener noreferrer"
			className={styles.LegacyRedirect}
			title={redirectUrl}
			style={customStyles}
		>
			View Issues in EHR
		</a>
	);
};

const EmarErrorSummary = ({
	hasErrors = false,
	errorSummaryData = {},
	currentUser = {},
}) => {
	/**
	 * Summary State:
	 * - 'raw': array of raw server records
	 * - 'msg': string summary text
	 */

	if (isEmptyArray(errorSummaryData?.raw)) {
		return null;
	}
	return (
		<div className={styles.EmarErrorSummary} title={errorSummaryData?.msg}>
			<div className={styles.EmarErrorSummary_top}>
				<div className={styles.EmarErrorSummary_top_heading}>
					EMAR Issues Found
				</div>
			</div>
			<div className={styles.EmarErrorSummary_main}>
				<div className={styles.EmarErrorSummary_main_msg}>
					There are <b>{errorSummaryData?.raw?.length}</b> communities with EMAR
					related issues:
				</div>
				<div className={styles.EmarErrorSummary_main_details}>
					{!isEmptyVal(errorSummaryData?.msg) &&
						addEllipsis(errorSummaryData?.msg, 65)}
				</div>
				<div className={styles.EmarErrorSummary_main_redirect}>
					<LegacyRedirect
						selectedApp="SeniorCareEHR"
						currentUser={currentUser}
						customStyles={customCSS.link}
					/>
				</div>
			</div>
		</div>
	);
};

export default EmarErrorSummary;

EmarErrorSummary.defaultProps = {};

EmarErrorSummary.propTypes = {};
