import React, { useState } from "react";
import styles from "../../css/alerts/HomeAlertsBanner.module.scss";
import sprite from "../../assets/icons/alerts.svg";
import altSprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import {
	purple,
	blue,
	red,
	pink,
	green,
	yellow,
	orange,
	grey,
	blueGrey,
	teal,
} from "../../helpers/utils_styles";
import { hasProp } from "../../helpers/utils_types";
import { ICONS, ICONS_ALT } from "../../helpers/utils_alerts";

// Icon fills/colors
const ICON_FILLS = {
	SUCCESS: green[500],
	WARN: yellow[500],
	ERROR: red[500],
	INFO: purple[600],
	HELP: blue[600],
	REPORT: teal[700],
	PRINT: blueGrey[800],
	SAVE: blueGrey[900],
	// CHECK:
	// ALTERNATE(S)
	WARN2: red[600],
	INFO2: blue[600],
	INFO3: blue[600],
	INFO4: blue[600],
	INFO5: blue[600],
	INFO6: blue[600],
	INFO7: blue[600],
};
// Icon wrapper background styles/colors
const WRAPPER = {
	SUCCESS: green[100],
	WARN: yellow[100],
	ERROR: red[100],
	INFO: purple[100],
	HELP: blue[100],
	REPORT: teal[200],
	PRINT: blueGrey[200],
	SAVE: green[200],
	// ALTERNATE(S)
	WARN2: red[100],
	INFO2: blue[100],
	INFO3: blue[100],
	INFO4: blue[100],
	INFO5: blue[100],
	INFO6: blue[100],
	INFO7: blue[100],
};

// determine sprite from 'icon'
const getIconSprite = (icon) => {
	// alternate sprite (eg. 'altSprite')
	if (!hasProp(ICONS, icon)) {
		return altSprite;
	} else {
		return sprite;
	}
};
// determine icon name from 'icon' map label
const getIconName = (icon) => {
	if (!hasProp(ICONS, icon)) {
		const name = ICONS_ALT[icon];

		return name;
	} else {
		const name = ICONS[icon];

		return name;
	}
};
// determine wrapper css from 'icon'
const getWrapperCss = (icon) => {
	const color = WRAPPER[icon];
	const css = {
		backgroundColor: color,
	};

	return css;
};

// determine icon css from 'icon'
const getIconCss = (icon) => {
	const color = ICON_FILLS[icon];

	if (icon === "WARN" || icon === "WARN2") {
		const css = {
			fill: color,
			marginTop: "-1rem",
		};

		return css;
	} else {
		const css = {
			fill: color,
		};

		return css;
	}
};

const HomeAlertsBanner = ({ icon = "SAVE", children }) => {
	const [showDetails, setShowDetails] = useState(false);

	return (
		<>
			<div className={styles.HomeAlertsBanner}>
				<div
					className={styles.HomeAlertsBanner_iconWrapper}
					style={getWrapperCss(icon)}
				>
					<svg
						className={styles.HomeAlertsBanner_iconWrapper_icon}
						style={getIconCss(icon)}
					>
						<use
							xlinkHref={`${getIconSprite(icon)}#icon-${getIconName(icon)}`}
						></use>
					</svg>
				</div>
				<div className={styles.HomeAlertsBanner_content}>{children}</div>
				{/* <div className={styles.HomeAlertsBanner_close}>
					<svg className={styles.HomeAlertsBanner_close_icon}>
						<use xlinkHref={`${altSprite}#icon-clearclose`}></use>
					</svg>
				</div> */}
			</div>
		</>
	);
};

export default HomeAlertsBanner;

HomeAlertsBanner.defaultProps = {};

HomeAlertsBanner.propTypes = {};
