import React, { useState } from "react";
import styles from "../../css/widget/WidgetTrigger.module.scss";
import { PropTypes } from "prop-types";
import FeedbackWidget from "./FeedbackWidget";

const WidgetTrigger = ({ children }) => {
	const [showWidget, setShowWidget] = useState(false);

	const withState = React.Children.map(children, (child, i) => {
		if (!child) return;
		return React.cloneElement(child, {
			isActive: showWidget,
		});
	});

	return (
		<div className={styles.WidgetTrigger}>
			<button
				type="button"
				className={styles.WidgetTrigger_btn}
				onClick={() => setShowWidget(true)}
			>
				{withState}
			</button>

			{showWidget && (
				<FeedbackWidget closeWidget={() => setShowWidget(false)} />
			)}
		</div>
	);
};

export default WidgetTrigger;

WidgetTrigger.defaultProps = {};

WidgetTrigger.propTypes = {
	children: PropTypes.any,
};
