import React from "react";
import styles from "../../css/app/BrandLogo.module.scss";
import brandLogo from "../../assets/brand/logo.png";
import { PropTypes } from "prop-types";

const BrandLogo = () => {
	return (
		<div className={styles.BrandLogo}>
			<img
				src={brandLogo}
				alt="AL Advantage LLC."
				className={styles.BrandLogo_img}
			/>
		</div>
	);
};

export default BrandLogo;

BrandLogo.defaultProps = {};

BrandLogo.propTypes = {};
