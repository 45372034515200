import React from "react";
import styles from "../../css/loc/LOCTip.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";

const Step = ({ stepIdx, step }) => {
	return (
		<li data-stepidx={stepIdx} className={styles.Step}>
			{step}
		</li>
	);
};

const Steps = ({ steps = [] }) => {
	return (
		<div className={styles.Steps}>
			<ul className={styles.Steps_list}>
				{!isEmptyArray(steps) &&
					steps.map((step, stepIdx) => (
						<Step key={`LOCTip:${stepIdx}`} step={step} stepIdx={stepIdx} />
					))}
			</ul>
		</div>
	);
};

const LOCTip = ({ title, desc, steps = [], gif = {} }) => {
	return (
		<div className={styles.LOCTip}>
			<div className={styles.LOCTip_top}>
				<h4 className={styles.LOCTip_top_title}>{title}</h4>
				<h4 className={styles.LOCTip_top_desc}>{desc}</h4>
			</div>
			<div className={styles.LOCTip_label}>Instructions:</div>
			<div className={styles.LOCTip_steps}>
				<Steps steps={steps} />
			</div>
		</div>
	);
};

export default LOCTip;

LOCTip.defaultProps = {};

LOCTip.propTypes = {};
