import { currentEnv } from "./utils_env";
import {
	generic,
	facility,
	getFacilities,
	facilityAccess,
	facilityTypes,
	notifications,
} from "./utils_endpoints";
import { params } from "./utils_params";
import {
	getFacilityMapByName,
	getUsersByFacility,
	hasMultiFacility,
} from "./utils_user";
import { isEmptyArray, isEmptyVal } from "./utils_types";
import { FacilityInfo, UserFacilityAccessModel } from "./utils_models";
import { getUserLockoutSummary } from "./utils_lockouts";
import { sortAlphaAscByKey } from "./utils_processing";
import {
	checkFacilityUsersAppAccess,
	findAppInFacilityAccess,
	getFacilityAccessRecord,
	getFacilityAccessRecord2,
	getFacilityAppAccessByApp,
} from "./utils_apps";
import {
	fetchAndProcessNotifications,
	getAllNotificationsLists,
	processAllNotificationLists,
	processAllNotifications,
} from "./utils_notifications";
import { pink, purple, teal } from "./utils_styles";

///////////////////////////////////////////////////////////////////////////
///////////////////////// FACILITY-REQUEST UTILS /////////////////////////
///////////////////////////////////////////////////////////////////////////

/**
 * @description - Fetches a list of facilities a user has access to based off the user's email.
 * @param {String} token - Base64 encoded auth token.
 * @param {String} userEmail - A string user email.
 * @param {Number} applicationId - A numeric appID.
 * @returns {Array} - Returns an array of custom facility records containing:
 * - "CommunityName": facility's community name as a string.
 * - "FacilityId": uid for facility
 * - "ParentFacilityId": uid for parent facility, if applicable.
 * - "Shifts": a list of 'AssessmentFacilityShift' records, IF AVAILABLE; IF NOT AVAILABLE, then returns default 'AssessmentShift' records.
 * - "Address":
 * 		- "Address.Street"
 * 		- "Address.State"
 * 		- "Address.City"
 * 		- "Address.Zip"
 *
 * - ✅ UPDATED API (10/18/2021 at 9:43 AM) to include: 'applicationId':
 * 		- NOW the API will check if each facility to be returned as access to the given application:
 * 				- IF NO ACCESS, then the facility is NOT returned
 * 				- IF YES ACCESS, then the facility IS returned, as normal
 */
const getFacilitiesByUserEmail = async (
	token,
	userEmail,
	applicationId = 3
) => {
	let url = currentEnv.base + getFacilities.byUserEmail;
	url += "?" + new URLSearchParams({ userEmail: userEmail });
	url += "&" + new URLSearchParams({ applicationId });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * @description - Fetches the current user's facility info; location/address, director's name, capacity & other info.
 * @param {String} token - Base64 encoded auth token.
 * @param {String} facilityID - A specific uid for the current user's facility.
 * @returns {Object} - Returns an object representing an entry in the "FACILITY" table in ALA Services.
 */
const getFacilityInfo = async (token, facilityID) => {
	let url = currentEnv.base + generic.get;
	url += "?" + new URLSearchParams({ ...params.facility });
	url += "&" + new URLSearchParams({ guidFacility: facilityID });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		const { Data } = response; // array of objects
		const [entry] = Data; // object (ie 1st index)
		return entry;
	} catch (err) {
		return err.message;
	}
};
/**
 * Fetches the number of residents for a facility, given a facility ID.
 * @returns {Number} - Returns a number
 */
const getResidentCountByFacility = async (token, facilityID) => {
	let url = currentEnv.base + generic.count2;
	url += "?" + new URLSearchParams({ ...params.residents });
	url += "&" + new URLSearchParams({ FacilityID: facilityID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Migrates a facility's users. An empty array('userIDs') will migrate ALL users otherwise a populated array will ONLY migrate the included user's IDs.
 * @param {String} token - Security token
 * @param {String} facilityId - A unique facility guid.
 * @param {Date|Null} scheduleFor - A target date to schedule the migration for.
 * @param {Array} userIds - An array of user IDs (guids) to migrate.
 */
const migrateFacilityUsers = async (
	token,
	facilityId,
	scheduleFor = null,
	userIds = null
) => {
	let url = currentEnv.base + facility.migrateUsers;
	url += "?" + new URLSearchParams({ facilityId });
	url += "&" + new URLSearchParams({ scheduleFor });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(userIds),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Fetches facility info, user's list, and current lockouts' summary.
 * @param {String} token - Token
 * @param {String} facilityID - Facility
 *
 * - Updated 6/17/2021 at 11:01 AM
 * 		- Added 'facilityInfo' fetch to parallel request array (eg Promise.all([...]))
 */
const getFacilityInfoAndSummary = async (token, facilityID) => {
	// ##TODOS:
	// - Replace/add 'getFacilityUserInfo' request
	// 		- Includes: 'AdvUsers', 'UserLogins', 'UserProfiles', 'UserMerges' & 'AccessToApps'

	const [facilityInfo, usersList, lockoutInfo] = await Promise.all([
		getFacilityInfo(token, facilityID), // generic 'GET' to 'FACILITY' table
		getUsersByFacility(token, facilityID), // fetch from 'ADVUSER' table
		getUserLockoutSummary(token, facilityID), // fetch security lockout info
	]);

	const { UserLockOuts: lockouts } = lockoutInfo;

	return {
		facilityInfo: facilityInfo,
		facilityUsers: usersList,
		facilityLockouts: lockouts,
	};
};

// fetches app access (eg. AppByFacility) & 'FACILITY' record
const getFacilityDetails = async (token, facilityID) => {
	const [accessRecord, facilityRecord] = await Promise.all([
		getFacilityAccessRecord(token, 2, facilityID),
		getFacilityInfo(token, facilityID),
	]);

	const normalFacility = createFacilityInfoObj(facilityRecord);

	return {
		facilityRecord: normalFacility,
		accessRecord: accessRecord,
	};
};
/**
 * Fetches a facility's: FACILITY record, AppByFacility record(s) and their notifications (incidents, assessments, lockouts)
 * @param {String} token - Auth token
 * @param {String} facilityID - String facility guid
 * @returns {Object} - Returns an object w/ 'facilityRecord', 'accessRecord', and 'notifications' as properties
 */
const getFacilityInfoDetails_OLD = async (token, facilityID) => {
	const [accessRecord, emarAccess, facilityRecord, notifications] =
		await Promise.all([
			getFacilityAccessRecord2(token, 2, facilityID),
			getFacilityAccessRecord2(token, 19, facilityID),
			// getFacilityAppAccessByApp(token, facilityID, 2),
			getFacilityInfo(token, facilityID),
			getAllNotificationsLists(token, {
				facilityID: facilityID,
				index: 0,
				rows: 5,
			}),
		]);

	// process data
	// const normalAccess = findAppInFacilityAccess(2, accessRecord);
	const normalFacility = createFacilityInfoObj(facilityRecord);
	const normalNotifications = processAllNotifications(notifications);

	return {
		facilityRecord: normalFacility,
		// accessRecord: normalAccess,
		accessRecord: accessRecord, // tracker access
		emarAccess: emarAccess,
		notifications: normalNotifications,
	};
};
/**
 * Fetches a facility's: FACILITY record, AppByFacility record(s) and their notifications (incidents, assessments, lockouts)
 * @param {String} token - Auth token
 * @param {String} facilityID - String facility guid
 * @returns {Object} - Returns an object w/ 'facilityRecord', 'accessRecord', and 'notifications' as properties
 */
const getFacilityInfoDetails = async (token, facilityID) => {
	const [
		trackerAccess,
		emarAccess,
		enableAllTracker,
		enableAllEmar,
		facilityRecord,
		notifications,
	] = await Promise.all([
		getFacilityAccessRecord2(token, 2, facilityID),
		getFacilityAccessRecord2(token, 19, facilityID),
		// NEW CHECKS
		checkFacilityUsersAppAccess(token, facilityID, 2),
		checkFacilityUsersAppAccess(token, facilityID, 19),
		getFacilityInfo(token, facilityID),
		getAllNotificationsLists(token, {
			facilityID: facilityID,
			index: 0,
			rows: 5,
		}),
	]);

	// process data
	// const normalAccess = findAppInFacilityAccess(2, accessRecord);
	const normalFacility = createFacilityInfoObj(facilityRecord);
	const normalNotifications = processAllNotifications(notifications);

	return {
		facilityRecord: normalFacility,
		// accessRecord: normalAccess,
		trackerAccess: trackerAccess, // tracker access
		emarAccess: emarAccess,
		enableAllTracker: enableAllTracker,
		enableAllEmar: enableAllEmar,
		notifications: normalNotifications,
	};
};

// FACILITY FIELD UPDATER FN(S) //

/**
 * Updates a facility's info such as: address, exec director, license #, yardi # etc.
 * @param {String} token - Auth token
 * @param {Object} updatedRecord - Updated 'FacilityInfo' model data-structure
 * @returns {Boolean} - Returns whether update was successful
 */
const updateFacilityInfo = async (token, updatedRecord = {}) => {
	let url = currentEnv.base + facility.update.facilityInfo;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(updatedRecord),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// FACILITY ACCESS REQUESTS //

/**
 * Allows sending a custom array of facility access that includes enabling/disabling the varied facility's access for a given user.
 * @param {String} token - Security token
 * @param {Array} accessRecords - An array of custom objects with 'userID', 'facilityID', & 'isAccessible' fields.
 * @returns {Object}
 */
const registerFacilityAccess = async (token, accessRecords = []) => {
	let url = currentEnv.base + facilityAccess.registerAccess;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(accessRecords),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Enables/grants a user access to a list of facilities.
 * @param {String} token - Auth token
 * @param {String|GUID} userId - Target user's ID.
 * @param {Array} facilityIDs - An array of facility IDs (guids) to grant access to.
 * @returns {Object}
 */
const grantFacilityAccess = async (token, userId, facilityIDs = []) => {
	let url = currentEnv.base + facilityAccess.grantAccess;
	url += "?" + new URLSearchParams({ userId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(facilityIDs),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Disables/Denies a user's access for a list of facilities.
 * @param {String} token - Auth token
 * @param {String|GUID} userId - Target user's ID.
 * @param {Array} facilityIDs - An array of facility IDs (guids) to grant access to.
 * @returns {Object}
 */
const denyFacilityAccess = async (token, userId, facilityIDs = []) => {
	let url = currentEnv.base + facilityAccess.denyAccess;
	url += "?" + new URLSearchParams({ userId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(facilityIDs),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// SEARCH FOR FACILITY BY VARIOUS METHODS //

/**
 * Search for a facility by facility-related values.
 * @param {String} token - Auth token
 * @param {Object} searchParams - Custom query params w/ the search criteria formatted.
 * @returns {Object|Array|Null} - Returns search results for a given search query.
 */
const searchForFacility = async (token, searchParams = {}) => {
	let url = currentEnv.base + facility.getFacility.search;
	url += "?" + new URLSearchParams({ ...params.facility });
	url += "&" + new URLSearchParams({ ...searchParams });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Wrapper around 'searchForUser' request util that pre-processes search params for request.
 * @param {String} token - Auth token.
 * @param {Object} vals - User search values.
 */
const searchForFacilityBy = async (token, vals = {}) => {
	switch (true) {
		case vals.byEmail: {
			const { emailSearch } = vals;
			const results = await searchForFacility(token, {
				Email: emailSearch,
			});
			return results;
		}
		case vals.byCommunityName: {
			const { communityNameSearch } = vals;
			const results = await searchForFacility(token, {
				CommunityName: communityNameSearch,
			});
			return results;
		}
		case vals?.byFacilityID: {
			const { facilityIDSearch } = vals;
			const results = await searchForFacility(token, {
				guidFacility: facilityIDSearch,
			});
			return results;
		}
		case vals?.byParentID: {
			const { parentIDSearch } = vals;
			const results = await searchForFacility(token, {
				// guidParentFacility: parentIDSearch,
				guidFacility: parentIDSearch,
			});
			return results;
		}
		case vals?.byParentChildren: {
			const { parentChildrenSearch } = vals;
			const results = await searchForFacility(token, {
				guidParentFacility: parentChildrenSearch,
			});
			return results;
		}
		case vals?.bySuspendedStatus: {
			const { suspendedStatus } = vals;
			const results = await searchForFacility(token, {
				bitSuspendSubscription: suspendedStatus,
			});
			return results;
		}

		default:
			return [];
	}
};

///////////////////////////////////////////////////////////////////////////
///////////////////////// FACILITY-RELATED UTILS /////////////////////////
///////////////////////////////////////////////////////////////////////////

/**
 * Base 'currentFacility' data structure.
 */
const initialFacility = {
	communityName: null,
	facilityID: null,
	parentID: null,
	shifts: [],
	address: {},
	exceptionTypes: [],
};

// sets facility for NON-ADMIN users
const getFacilityByUserType = (facilityList) => {
	const isAdmin = hasMultiFacility(facilityList);
	if (isAdmin) {
		return { ...initialFacility };
	} else {
		// const [currentFacility] = facilityList;
		const currentFacility = facilityList?.[0];

		return {
			communityName: currentFacility?.CommunityName ?? null,
			facilityID: currentFacility?.FacilityId ?? null,
			parentID: currentFacility?.ParentFacilityId ?? null,
			shifts: currentFacility?.Shifts ?? [],
			address: {
				street: currentFacility?.Address?.Street,
				city: currentFacility?.Address?.City,
				state: currentFacility?.Address?.State,
				zip: currentFacility?.Address?.Zip,
			},
		};
	}
};

///////////////////////////////////////////////////////////////////////////
//////////////////////// FACILITY-PROCESSING UTILS ////////////////////////
///////////////////////////////////////////////////////////////////////////
const formatAndSortFacilities = (facilities = []) => {
	return facilities
		.sort((a, b) => {
			return a?.CommunityName.localeCompare(b?.CommunityName);
		})
		.map(({ CommunityName }) => CommunityName);
};

// formats and sorts 'currentUser.facilities' & returns ONLY the communityName(s)
const formatAndSortUserFacilities = (facilities = []) => {
	if (isEmptyArray(facilities)) return [];
	return facilities
		.sort((a, b) => {
			return a?.communityName?.localeCompare(b?.communityName);
		})
		.map(({ communityName }) => communityName);
};

const sortFacilityAccess = (records = []) => {
	return [...sortAlphaAscByKey("facilityName", records)];
};

// processes raw 'FACILITY' table record & formats it for the client
const createFacilityInfoObj = (record = {}) => {
	const {
		guidFacility: facilityID,
		guidParentFacility: parentID,
		CommunityName: communityName,
		ExecDirector: execDirector,
		ALDirector: alaDirector,
		LocalTimeZoneID: timeZone,
		LicenseNumber: licenseNumber,
		// states
		bitALAAssessment: enableALAAssessments,
		bitMedications: enabledMeds,
		bitContacts: enableContacts,
		bitAssessmentALA: enableAssessmentALA,
		bitSuspendSubscription: isSuspended,
		YardiFacilityNumber: yardiNumber,
	} = record;

	const newObj = {
		facilityID,
		parentID,
		communityName,
		execDirector,
		alaDirector,
		timeZone,
		enableALAAssessments,
		enabledMeds,
		enableContacts,
		enableAssessmentALA,
		isSuspended,
		licenseNumber,
		yardiNumber,
	};

	return newObj;
};

// inits a new custom facility state object from an ALA object
const createFacilityObj = (record = {}) => {
	const {
		CommunityName,
		Address,
		FacilityId,
		ParentFacilityId,
		LocalTimeZoneID,
		LicenseNumber,
		ExecDirector,
		ALDirector,
		Shifts,
	} = record;

	const newRecord = {
		communityName: CommunityName,
		facilityID: FacilityId,
		parentID: ParentFacilityId,
		licenseNumber: LicenseNumber,
		execDirector: ExecDirector,
		alaDirector: ALDirector,
		timeZone: LocalTimeZoneID,
		address: {
			street: Address?.Street ?? "",
			city: Address?.City ?? "",
			state: Address?.State ?? "",
			zip: Address?.Zip ?? "",
			suiteNumber: Address?.Street2 ?? Address?.Address2 ?? "",
		},
		shifts: Shifts,
		// new fields as of 12/8/2021 at 3:15 PM
		// isSuspended: false,
	};
	return { ...newRecord };
};

// formats/normalizes user's facility access list for client-formatting
const processFacilityList = (facilityList = []) => {
	if (isEmptyArray(facilityList)) return [];

	return facilityList.reduce((newList, facility) => {
		const newRecord = createFacilityObj(facility);
		newList = [...newList, newRecord];
		return newList;
	}, []);
};

/**
 * @description - Finds the matching facility record based off a Community's 'CommunityName' field.
 * @param {String} selection - A user-selected string value representing a facility's 'CommunityName' field.
 * @param {Array} facilities - An array of facility records by current user (ie user has access to).
 * @returns {Object} - Returns an object; matching 'AssessmentFacility' record.
 */
const matchFacilityByName = (selection, facilities = []) => {
	return facilities.reduce((match, cur) => {
		if (cur?.CommunityName === selection) {
			match = { ...cur };
			return match;
		}
		return match;
	}, {});
};
const matchUserFacilityByName = (selection, facilities = []) => {
	return facilities.reduce((match, cur) => {
		if (cur?.communityName === selection) {
			match = { ...cur };
			return match;
		}
		return match;
	}, {});
};
const matchFacilityByID = (facilityID, facilities = []) => {
	return facilities.reduce((match, cur) => {
		const curID =
			cur?.guidFacility ??
			cur?.facilityID ??
			cur?.FacilityId ??
			cur?.FacilityID;
		if (curID === facilityID) {
			match = { ...cur };
			return match;
		}
		return match;
	}, {});
};
// determines the facility by matching record.
const getFacilityID = (selection, facilities = []) => {
	const { FacilityId } = matchFacilityByName(selection, facilities);
	return FacilityId;
};
const getUserFacilityID = (selection, facilities = []) => {
	const { facilityID } = matchUserFacilityByName(selection, facilities);
	return facilityID;
};

// grabs 1st facility selection from list & makes default facility
const getNewUserFacilityID = (selections = [], facilities = []) => {
	const firstChoice = selections?.[0] ?? "";
	const facRecord = matchUserFacilityByName(firstChoice, facilities);

	return facRecord?.facilityID ?? "";
};

// FACILITY ACCESS RECORDS ~ ADVUSERCOMMUNITY RECORDS //

// CLIENT ONLY!!! MODEL UPDATERS ~ USER FACILITY ACCESS //

// used for 'RegisterFacilityAccess': this API requires an object form

const getDeSelections = (originalList, newList) => {
	return originalList.filter((facilityName) => {
		if (!newList.includes(facilityName)) {
			return facilityName;
		} else {
			return;
		}
	});
};

const getFacilityIDsFromSelections = (selections = [], facilityMap = {}) => {
	const listOfIDs = selections.map((facilityName) => {
		const id = facilityMap[facilityName].facilityID;
		return id;
	});

	return listOfIDs;
};

// deprecated due to RegisterFaciliityAccess API not handling revoke unless the facility record is provided
// DEPRECATED AS OF 2/25/2022 at 9:05 AM
const getRegisterRecordsFromSelections2 = (
	userID,
	selections = [],
	allFacilities = []
) => {
	const facilityMap = getFacilityMapByName(allFacilities);
	return selections.reduce((accessRecords, facilityName) => {
		const facilityRecord = facilityMap[facilityName];

		const newRecord = {
			UserId: userID,
			FacilityId: facilityRecord?.facilityID,
			IsAccessible: true,
		};

		accessRecords = [...accessRecords, newRecord];
		return accessRecords;
	}, []);
};
const getRegisterRecordsFromSelections = (
	userID,
	selections = [],
	deSelections = [],
	allFacilities = []
) => {
	const facilityMap = getFacilityMapByName(allFacilities);

	// records to remove access for
	const newDeSelections = deSelections.reduce(
		(recordsToRemove, facilityName) => {
			const facilityRecord = facilityMap[facilityName];

			const newRecord = {
				UserId: userID,
				FacilityId: facilityRecord?.facilityID,
				IsAccessible: false,
			};
			recordsToRemove = [...recordsToRemove, newRecord];
			return recordsToRemove;
		},
		[]
	);
	// records to add access for
	const newSelections = selections.reduce((accessRecords, facilityName) => {
		const facilityRecord = facilityMap[facilityName];

		const newRecord = {
			UserId: userID,
			FacilityId: facilityRecord?.facilityID,
			IsAccessible: true,
		};

		accessRecords = [...accessRecords, newRecord];
		return accessRecords;
	}, []);

	const mergedList = [...newDeSelections, ...newSelections];

	return mergedList;
};

// used for 'GrantFacilityAccess': this API requires an array of facilityIDs
const getGrantAccessRecordsFromSelections = (selections, allFacilities) => {
	if (isEmptyArray(selections)) return [];

	const facilityMap = getFacilityMapByName(allFacilities);
	const listOfIDs = getFacilityIDsFromSelections(selections, facilityMap);
	return listOfIDs;
};
// used for 'GrantFacilityAccess': this API requires an array of facilityIDs
const getDenyAccessRecordsFromSelections = (selections, allFacilities) => {
	const facilityMap = getFacilityMapByName(allFacilities);
	const listOfIDs = getFacilityIDsFromSelections(selections, facilityMap);
	return listOfIDs;
};

/**
 * Inits & populates a custom model data structure used ONLY in the client
 * - Values:
 *    - 'userID'
 *    - 'facilityID'
 *    - 'facilityName'
 *    - 'grantedDate'
 *    - 'location' (eg city, STATE)
 */
const updateUserFacilityAccessModel = (userAccessVals = {}) => {
	const base = new UserFacilityAccessModel(userAccessVals);
	const model = base.getModel();
	return model;
};

// generates normalized client-formatted facility access records
const createUserFacilityAccessRecords = (facilities = [], currentUser = {}) => {
	if (isEmptyArray(facilities)) return [];

	return facilities.reduce((accessRecords, facility) => {
		const accessModel = updateUserFacilityAccessModel({
			userID: currentUser?.userID,
			facilityID: facility?.facilityID,
			communityName: facility?.communityName,
			city: facility?.address?.city,
			state: facility?.address?.state,
			grantedDate: null,
		});
		accessRecords = [...accessRecords, accessModel];
		return accessRecords;
	}, []);
};
// removes access record(client-formatted) from current list
const removeAccessRecord = (entry = {}, currentAccessList = []) => {
	return currentAccessList.filter((record) => {
		const { facilityID: idToRemove } = entry;
		return record.facilityID !== idToRemove;
	});
};

const updateFacilityModel = (vals = {}) => {
	const base = new FacilityInfo({ ...vals });
	const model = base.getModel();
	return model;
};

// used for '<FacilityInfo/>' updates
// only carries over a specific set of fields from the 'FACILITY' table record
const updateCustomFacilityModel = (vals = {}) => {
	const customModel = {
		guidFacility: vals?.facilityID ?? "",
		CommunityName: vals?.communityName ?? "",
		ExecDirector: vals?.execDirector ?? "",
		ALDirector: vals?.alaDirector ?? "", // str optional
		Address1: vals?.street ?? "", // str optional
		Address2: vals?.suiteNumber ?? "", // str optional
		City: vals?.city ?? "", // str optional
		State: vals?.state ?? "", // str optional
		ZIP: vals?.zip ?? "", // str optional
		Phone: vals?.phone ?? "", // str optional
		Fax: vals?.fax ?? "", // str optional
		Email: vals?.email ?? "", // str optional
		LicenseNumber: vals?.licenseNumber ?? "", // str optional
		YardiFacilityNumber: vals?.yardiNumber ?? "", // str optional (50 chars max)
	};
	return customModel;
};

// processes data from 'GetFacility2' request
const processFacilityInfo = (facilityInfo = {}) => {
	const {
		ExecDirector = "",
		ALDirector = "",
		Email = "",
		Phone = "",
		Fax = "",
		LicenseNumber = "",
		guidParentFacility = "N/A",
		LocalTimeZoneID,
		Address2,
		YardiFacilityNumber = "",
	} = facilityInfo;

	return {
		execDirector: ExecDirector,
		alaDirector: ALDirector,
		email: Email,
		phone: Phone,
		fax: Fax,
		parentID: guidParentFacility,
		timeZone: LocalTimeZoneID,
		suiteNumber: Address2,
		licenseNumber: LicenseNumber,
		yardiNumber: YardiFacilityNumber,
	};
};

const getFacilityAddress = (facility) => {
	const { address } = facility;
	return `${address?.street}\n\n${address?.city}, ${address?.state} ${address?.zip}`;
};

const noFacilityLoaded = (currentFacility = {}) => {
	return isEmptyVal(currentFacility?.facilityID);
};

// PARENT & CHILD UTILS //
// fetches all children for a parent

/**
 * Fetches all child 'FACILITY' records for a given parent facility.
 * @param {String} token - Auth token
 * @param {String} parentID - Parent facility guid.
 * @returns {Object[]} - Returns array of child 'FACILITY' records for a given parent, if applicable.
 */
const getChilds = async (token, parentID) => {
	let url = currentEnv.base + facilityTypes.getChilds;
	url += "?" + new URLSearchParams({ parentFacilityId: parentID });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Fetches ALL facilities that are established 'PARENT' communities.
 * @param {String} token - Auth token
 * @returns {Object[]} - Fetches ALL parent 'FACILITY' records
 */
const getParents = async (token) => {
	let url = currentEnv.base + facilityTypes.getParents;

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Fetches ALL community records for a parent; including: the children 'FACILITY' records and the parent's 'FACILITY' record.
 * @param {String} token - Auth token
 * @param {String} parentID - Parent guid
 * @returns {Object[]} - Returns ALL child 'FACILITY' records & the parent 'FACILITY' record.
 */
const getParentAndChilds = async (token, parentID) => {
	let url = currentEnv.base + facilityTypes.getChildAndParent;
	url += "?" + new URLSearchParams({ parentFacilityId: parentID });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// FACILITY TYPE UTILS //

/**
 * Fetches & processes all parent facility records into a lists separated by 'id', 'name' and raw 'FACILITY' record.
 * @param {Object[]} allParents - AN array of all parent 'FACILITY' records
 * @returns {Object} - Returns an object/map containing a list of all parentIDs, all parent community names & their raw 'FACILITY' records.
 */
const getParentsMap = (allParents = []) => {
	const parentsMap = allParents.reduce(
		(map, parentRecord) => {
			const { guidFacility: facilityID, CommunityName: name } = parentRecord;
			// create object w/ 'ids', 'names' and 'raw' keys
			map = {
				ids: [...map.ids, facilityID],
				names: [...map.names, name],
				raw: [...map.raw, parentRecord],
			};

			return map;
		},
		{ ids: [], names: [], raw: [] }
	);

	return parentsMap;
};

const fetchAndProcessAllParents = async (token) => {
	// fetch & sort all parent records
	const rawParents = getParents(token);

	const sorted = sortAlphaAscByKey("CommunityName", rawParents);

	if (!isEmptyArray(rawParents)) {
		// create 'parents' map: ids, names, & raw records.
		const parentsMap = getParentsMap(sorted);
		return parentsMap;
	} else {
		return {
			ids: [],
			names: [],
			raw: [],
		};
	}
};

/**
 * Checks if a target facility's guid exists in the global list of all parent facility IDs to determine if parent facility.
 * @param {String} targetID - Target facility guid to check for.
 * @param {Object} parentsMap - An object of all ALA parent facility IDs, community names & raw 'FACILITY' records.
 * @returns {Boolean} - Returns true|false
 */
const isParentFacility = (targetID, parentsMap = {}) => {
	const { ids } = parentsMap;
	const normalIDs = ids?.map((id) => id?.toLowerCase());
	const hasID = normalIDs?.includes(targetID?.toLowerCase());

	return hasID;
};

/**
 * Determines whether a facility is a child facility, based off parentID.
 * @param {Object} currentFacility - Client-formatted 'facility' object
 * @returns {Boolean} - Returns true|false
 */
const isChildFacility = (currentFacility = {}) => {
	const { parentID } = currentFacility;

	return !isEmptyVal(parentID);
};

/**
 * Determines whether a facility is a stand-alone (independent) facility. (no parent or children)
 * @param {Object} currentFacility - Client-formatted 'facility' object
 * @returns {Boolean} - Returns true|false
 */
const isIndependentFacility = (currentFacility = {}, parentsMap = {}) => {
	const { facilityID } = currentFacility;
	const isChild = isChildFacility(currentFacility);
	const isParent = isParentFacility(facilityID, parentsMap);
	// not a parent or child
	const isIndy = !isChild && !isParent;

	return isIndy;
};

// returns string-name for facility type (eg. 'CHILD', 'PARENT' or 'INDEPENDENT')
const getFacilityType = (currentFacility, parentsMap = {}) => {
	const { facilityID } = currentFacility;
	const isChild = isChildFacility(currentFacility);
	const isParent = isParentFacility(facilityID, parentsMap);
	const isIndy = isIndependentFacility(currentFacility, parentsMap);

	switch (true) {
		case isIndy: {
			return `INDEPENDENT`;
		}
		case isParent: {
			return `PARENT`;
		}
		case isChild: {
			return `CHILD`;
		}

		default:
			return `UNKNOWN`;
	}

	// if (!isChild) {
	// 	const isParent = isParentFacility(facilityID, parentsMap);
	// 	return isParent ? `PARENT` : `INDEPENDENT`;
	// } else {
	// 	return `CHILD`;
	// }
};

const facilityTypeColors = {
	INDEPENDENT: pink[600],
	PARENT: purple[700],
	CHILD: teal[600],
};

// facility-updater utils
export { updateFacilityInfo, updateFacilityModel, updateCustomFacilityModel };

// facility-request utils
export {
	getResidentCountByFacility,
	getFacilitiesByUserEmail,
	getFacilityInfo,
	getFacilityInfoAndSummary,
	getFacilityDetails,
	getFacilityInfoDetails,
	// facility access
	grantFacilityAccess,
	denyFacilityAccess,
	registerFacilityAccess,
};
// facility access utils
export {
	getRegisterRecordsFromSelections,
	getGrantAccessRecordsFromSelections,
	getDenyAccessRecordsFromSelections,
};

// migrate facility users (setup API)
export { migrateFacilityUsers };

// facility-related utils
export { getFacilityByUserType };

// facility-processing utils
export {
	getDeSelections,
	sortFacilityAccess,
	processFacilityList,
	processFacilityInfo,
	formatAndSortFacilities,
	formatAndSortUserFacilities,
	matchFacilityByName,
	matchUserFacilityByName,
	matchFacilityByID,
	getNewUserFacilityID,
	getFacilityID,
	getUserFacilityID,
	getFacilityAddress,
	// facility type (eg. 'CHILD', 'PARENT' or 'INDEPENDENT') utils
	getParents,
	getChilds,
	getParentAndChilds,
	getParentsMap,
	fetchAndProcessAllParents,
};

// user-facility access utils
export {
	updateUserFacilityAccessModel,
	createUserFacilityAccessRecords,
	removeAccessRecord,
};

export {
	noFacilityLoaded,
	isChildFacility,
	isParentFacility,
	isIndependentFacility,
	getFacilityType,
	facilityTypeColors,
};

export { searchForFacility, searchForFacilityBy };
