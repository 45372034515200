import React, { useEffect, useContext } from "react";
import styles from "../css/pages/UserPage.module.scss";
import sprite from "../assets/icons/multistep.svg";
import { PropTypes } from "prop-types";
import { GlobalStateContext } from "../state/GlobalStateContext";
import { useAlerts, initialSettings } from "../utils/useAlerts";
import { useQueryParams } from "../utils/useQueryParams";
import { USERPAGE_ICONS as ICONS } from "../helpers/utils_styles";
// components
import FullPageTabsController from "../components/tabs/FullPageTabsController";
import FullPageTabButtons from "../components/tabs/FullPageTabButtons";
import FullPageTabButton from "../components/tabs/FullPageTabButton";
import FullPageTabPanels from "../components/tabs/FullPageTabPanels";
import FullPageTabPanel from "../components/tabs/FullPageTabPanel";
// user-profile components
import UserProfilePage from "../components/user/UserProfilePage";
import UserNotifications from "../components/user/UserNotifications";
import UserSecurity from "../components/user/UserSecurity";
import UserPreferences from "../components/user/UserPreferences";
import ViewContainer from "../components/app/ViewContainer";
import UserAccess from "../components/user/UserAccess";
import DashboardTabNav from "../components/dashboard/DashboardTabNav";

const tabsMap = {
	profile: 0, // all
	notifications: 1, // all
	security: 2, // all
	preferences: 3, // admin only
	facilities: 4, // admin only
};

// icon btn styles
const getBtnStyles = (isActive, isDisabled) => {
	if (isActive) {
		return styles.IconButtonActive;
	} else if (isDisabled) {
		return styles.IconButtonDisabled;
	} else {
		return styles.IconButton;
	}
};

const getHiddenTabs = (currentUser = {}) => {
	const { isAdmin, isSuperUser } = currentUser;
	if (!isAdmin && !isSuperUser) {
		// hide 'Preferences' & 'Facilities' tabs for non-admins and non-super-users
		return [
			tabsMap["preferences"],
			tabsMap["facilities"],
			tabsMap["notifications"],
		];
	} else {
		// hide 'notifications' tab for everyone
		return [tabsMap["notifications"], tabsMap["preferences"]];
	}
};

const IconButton = ({
	isActive = false,
	isHidden = false,
	isDisabled = false,
	icon = "default",
	btnText,
	activeIndex, // from 'FullPageTabsController'
	tabIndex, // from 'FullPageTabsController'
	setParams,
}) => {
	if (isHidden) {
		return null;
	}
	return (
		<div className={getBtnStyles(isActive, isDisabled)} onClick={setParams}>
			<svg
				className={
					isActive ? styles.IconButtonActive_icon : styles.IconButton_icon
				}
			>
				<use xlinkHref={`${sprite}#icon-${ICONS[icon]}`}></use>
			</svg>
			<span
				className={
					isActive ? styles.IconButtonActive_btnText : styles.IconButton_btnText
				}
			>
				{btnText}
			</span>
		</div>
	);
};

const UserPage = ({ history, isCollapsed, winSize }) => {
	const { state: globalState, dispatch: dispatchToState } =
		useContext(GlobalStateContext);
	const { currentUser } = globalState;
	// const { winSize, isCollapsed } = history.location.state;
	const { AlertsHandler, dispatchAlert } = useAlerts(initialSettings);
	const { setParams, queryParams } = useQueryParams(window.location);

	// syncs 'activeTab' onMount
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		setParams(window.location, {
			activeTab: "profile",
		});

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<DashboardTabNav
				history={history}
				winSize={winSize}
				isCollapsed={isCollapsed}
				goBackCB={() => history.replace("/portal")}
			/>
			<div className={styles.UserPage}>
				<header className={styles.UserPage_header}>
					<h2 className={styles.UserPage_header_title}>User Settings</h2>
				</header>
				<section className={styles.UserPage_main}>
					<FullPageTabsController
						defaultIndex={0}
						disabledIndices={[]}
						forceCurrentTab={tabsMap[queryParams.activeTab]}
						hiddenIndices={getHiddenTabs(currentUser)}
					>
						<FullPageTabButtons>
							{/* USER PROFILE */}
							<FullPageTabButton>
								<IconButton
									setParams={() =>
										setParams(window.location, {
											activeTab: "profile",
										})
									}
									icon="profile"
									btnText="Profile"
								/>
							</FullPageTabButton>
							{/* USER NOTIFICATIONS - CURRENTLY HIDDEN/DISABLED ENTIRELY */}
							<FullPageTabButton>
								<IconButton
									isDisabled={true}
									isHidden={true}
									setParams={() =>
										setParams(window.location, {
											activeTab: "notifications",
										})
									}
									icon="notifications"
									btnText="Notifications"
								/>
							</FullPageTabButton>
							{/* USER SECURITY */}
							<FullPageTabButton>
								<IconButton
									setParams={() =>
										setParams(window.location, {
											activeTab: "security",
										})
									}
									icon="security"
									btnText="Security"
								/>
							</FullPageTabButton>
							{/* USER PREFERENCES */}
							<FullPageTabButton>
								<IconButton
									setParams={() =>
										setParams(window.location, {
											activeTab: "preferences",
										})
									}
									icon="preferences"
									btnText="Preferences"
								/>
							</FullPageTabButton>
							{/* USER ACCESS */}
							<FullPageTabButton>
								<IconButton
									setParams={() =>
										setParams(window.location, {
											activeTab: "facilities",
										})
									}
									icon="facilities"
									btnText="Facilities"
								/>
							</FullPageTabButton>
						</FullPageTabButtons>
						<FullPageTabPanels>
							{/* VIEW/EDIT USER PROFILE */}
							<FullPageTabPanel>
								<ViewContainer title="Edit User Profile">
									<UserProfilePage
										globalState={globalState}
										dispatchToState={dispatchToState}
										dispatchAlert={dispatchAlert}
									/>
								</ViewContainer>
							</FullPageTabPanel>
							{/* NOTIFICATIONS */}
							<FullPageTabPanel>
								<ViewContainer title="Notifications">
									<UserNotifications
										globalState={globalState}
										dispatchToState={dispatchToState}
										dispatchAlert={dispatchAlert}
									/>
								</ViewContainer>
							</FullPageTabPanel>
							{/* USER SECURITY */}
							<FullPageTabPanel>
								<ViewContainer title="User Security">
									<UserSecurity
										globalState={globalState}
										dispatchToState={dispatchToState}
										dispatchAlert={dispatchAlert}
									/>
								</ViewContainer>
							</FullPageTabPanel>
							{/* USER PREFERENCES */}
							<FullPageTabPanel>
								<ViewContainer title="User Preferences">
									<UserPreferences
										globalState={globalState}
										dispatchToState={dispatchToState}
										dispatchAlert={dispatchAlert}
									/>
								</ViewContainer>
							</FullPageTabPanel>
							{/* FACILITY ACCESS */}
							<FullPageTabPanel>
								<ViewContainer title="User Access">
									<UserAccess
										globalState={globalState}
										dispatchToState={dispatchToState}
										dispatchAlert={dispatchAlert}
									/>
								</ViewContainer>
							</FullPageTabPanel>
						</FullPageTabPanels>
					</FullPageTabsController>
				</section>
			</div>

			{AlertsHandler}
		</>
	);
};

export default UserPage;

UserPage.defaultProps = {
	globalState: {},
};

UserPage.propTypes = {
	globalState: PropTypes.object,
	dispatchToState: PropTypes.func,
};
