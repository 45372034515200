import React, { useEffect, useState } from "react";
import styles from "../../css/user/UserProfile.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";
import { createURL } from "../../helpers/utils_files";
import { useForm } from "../../utils/useForm";
import { red, blueGrey } from "../../helpers/utils_styles";
import { getTypesAndTitles } from "../../helpers/utils_security";
import {
	formatAndSortUserTitles,
	formatAndSortUserTypes,
	handleUserProfileChanges,
	matchJobTitleFromID,
	processTypesAndTitles,
} from "../../helpers/utils_user";
import { createUserFacilityAccessRecords } from "../../helpers/utils_facility";
import { featureFlags, isAccessEnabled } from "../../helpers/utils_permissions";
// components
import EditAvatar from "./EditAvatar";
import AvatarModal from "./AvatarModal";
import EditUserProfile from "./EditUserProfile";
import EditAvatarForm from "./EditAvatarForm";
import UserProfileActions from "./UserProfileActions";
import Dialog from "../shared/Dialog";
import ButtonSM from "../shared/ButtonSM";
import DeleteUserButton from "./DeleteUserButton";
import ProtectedFeature from "../permissions/ProtectedFeature";

// ##TODOS:
// - Create separate requests in 'saveProfileChanges()' request for:
// 		- 'UpdateUserProfile' (ie first/last name, jobTitle)
// 		- 'UpdateUserPhone' (ie phone)
// - Add UI input for 'Phone Ext.' OR update existing input to support extension.

const customCSS = {
	confirm: {
		backgroundColor: red[600],
		padding: ".7rem 1.5rem",
		fontSize: "1.4rem",
	},
	cancel: {
		backgroundColor: "transparent",
		padding: ".7rem 1.5rem",
		fontSize: "1.4rem",
		color: blueGrey[700],
		marginRight: ".5rem",
	},
};

const dialogDetails = {
	deleteUser: {
		title: `Delete User`,
		heading: `Are you sure you want to delete this user?`,
		text: `Deleting a user is not reversable. All related data will be discard and removed from the system.`,
	},
	deactivateUser: {
		title: `Deactivate User`,
		heading: `Are you sure you want to deactivate this user?`,
		text: `Deactivating a user will disable their ability to login to any ALA applications. It will NOT remove user data.`,
	},
};

const getResetValues = (currentUser) => {
	return {
		// user's profile ID
		profileID: currentUser?.profileID ?? null,
		// editable fields
		firstName: currentUser?.firstName ?? "",
		lastName: currentUser?.lastName ?? "",
		username: currentUser?.username ?? "",
		password: currentUser?.password ?? "",
		userType: currentUser?.userType ?? "", // userTypeID
		jobTitle: currentUser?.jobTitle ?? currentUser?.title ?? "",
		phoneNumber: currentUser?.phoneNumber ?? "",
		phoneExt: currentUser?.phoneExt ?? "",
		// current password
		currentPassword: currentUser?.password,
		hasTrackerAccess: currentUser?.hasTrackerAccess ?? false,
		hasLegacyAccess: currentUser?.hasLegacyAccess ?? true,
		hasPortalAccess: currentUser?.hasPortalAccess ?? true,
	};
};

const UserProfile = ({ globalState = {}, dispatchToState, dispatchAlert }) => {
	const { currentUser } = globalState;
	// user action modals
	const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
	const [showConfirmDeactivateModal, setShowConfirmDeactivateModal] =
		useState(false);
	// user avatar
	const [showEditAvatarModal, setShowEditAvatarModal] = useState(false);
	const [fileUpload, setFileUpload] = useState(null);
	const [userAvatar, setUserAvatar] = useState("");
	const [wasSaved, setWasSaved] = useState(false);
	const { formState, setFormState, handleChange, handleCheckbox, handleReset } =
		useForm({
			// user's profile ID
			userID: currentUser?.userID, // added 12/21/2021 at 3:25 PM
			profileID: currentUser?.profileID ?? null,
			// editable fields
			firstName: currentUser?.firstName ?? "",
			lastName: currentUser?.lastName ?? "",
			username: currentUser?.username ?? "",
			password: currentUser?.password ?? "",
			userType: currentUser?.userType ?? "", // userTypeID
			typeID: currentUser?.userTypeID ?? "",
			// job title fields
			jobTitle: currentUser?.jobTitle ?? currentUser?.title ?? "",
			jobTitleID: currentUser?.jobTitleID ?? null,
			hasCustomJobTitle: false,
			phoneNumber: currentUser?.phoneNumber ?? "",
			phoneExt: currentUser?.phoneExt ?? "",
			// current password
			currentPassword: currentUser?.password ?? "",
			hasTrackerAccess: currentUser?.hasTrackerAccess ?? false,
			hasLegacyAccess: currentUser?.hasLegacyAccess ?? true,
			hasPortalAccess: currentUser?.hasPortalAccess ?? true,
		});
	const { values, touched } = formState;
	const [facilityAccessList, setFacilityAccessList] = useState([
		...createUserFacilityAccessRecords(currentUser?.facilities, currentUser),
	]);
	const [userTypesAndTitles, setUserTypesAndTitles] = useState(() => {
		const all = processTypesAndTitles(
			globalState?.userTypes ?? [],
			globalState?.userTitles ?? []
		);
		return all;
	});

	// handles custom dropdown API patterns
	const handleUserSettings = (name, val) => {
		setFormState({
			...formState,
			values: { ...values, [name]: val },
			touched: { ...touched, [name]: true },
		});
	};

	// creates custom job title
	const handleCustomJobTitle = (titleID) => {
		setFormState({
			...formState,
			values: {
				...values,
				jobTitleID: titleID,
				hasCustomJobTitle: true,
			},
		});
	};

	// handles file input uploads & blob creation
	const handleFile = (e) => {
		const { files } = e.target;
		// save file to state, set avatar 'src'
		setFileUpload(...files);
		setUserAvatar(() => {
			const blob = new Blob([...files]);
			return createURL(blob);
		});
	};
	// handles UI hover/drop state
	const handleDragOver = (e) => {
		e.preventDefault();
		e.dataTransfer.dropEffect = "move";
	};
	// handles file drop upload & blob creation
	const handleFileDrop = (e) => {
		e.preventDefault();
		const { files } = e.dataTransfer;

		setFileUpload(...files);
		setUserAvatar(() => {
			const blob = new Blob([...files]);
			return createURL(blob);
		});
	};

	const handleAlert = (type, msg = {}) => {
		dispatchAlert(type, msg);
	};

	// ##TODOS:
	// - Fix 'jobTitle' updates ✓
	// 		- JobTitle is located in Profile & 'ADVUSER' table
	// 		- THis requires either updating both OR reading solely from 1 table.
	// - Fix 'User Phone' updates:
	// 		- Need to fetch a user's phone number record(s) & apply update to existing record ONLY, not create a new record each time.
	const saveProfileChanges = async (e) => {
		const { userTypes, userTitles } = userTypesAndTitles;

		const { savedProfile, savedPhone } = await handleUserProfileChanges(
			userTypes,
			userTitles,
			currentUser,
			{ values: { ...values }, touched: { ...touched } }
		);

		// if either are 'falsey', then 1 or more requests failed; otherwise success!
		if (savedPhone && savedProfile) {
			dispatchToState({
				type: "USER_UPDATE",
				data: {
					jobTitle: values?.jobTitle,
					phoneNumber: values?.phoneNumber ?? "",
					phoneExt: values?.phoneExt ?? "",
				},
			});
			setWasSaved(true);
			return handleAlert("SUCCESS", {
				heading: "Success!",
				subheading: "User changes were saved",
			});
		} else {
			setWasSaved(false);
			return handleAlert("ERROR", {
				heading: "Ooops!",
				subheading: "Changes were not saved. Try again!",
			});
		}
	};
	const cancelProfileChanges = (e) => {
		setFormState({
			...formState,
			values: {
				...getResetValues(currentUser),
			},
			touched: {},
		});
	};

	/**
	 * "Deactivate User" (suspend user):
	 * - Requires user confirmation, before deactivating
	 */
	const deactivateUser = (e) => {
		setShowConfirmDeactivateModal(true);
	};
	const confirmDeactivateUser = (e) => {
		// submit profile update w/ 'IsActive' set to 'false'
	};
	const cancelDeactivateUser = (e) => {
		setShowConfirmDeactivateModal(false);
	};

	/**
	 * "Delete User":
	 * - Requires user confirmation, before deleting
	 */
	const deleteUser = (e) => {
		setShowConfirmDeleteModal(true);
	};
	const confirmDeleteUser = (e) => {};
	const cancelDeleteUser = (e) => {
		setShowConfirmDeleteModal(false);
	};

	// runs 'onMount' then syncs to global store
	// ONLY fetches user 'types' and 'titles'
	const fetchUserResource = async () => {
		const { token } = currentUser;
		const { userTypes, userTitles } = await getTypesAndTitles(token);

		const all = processTypesAndTitles(userTypes, userTitles);
		// ##TODOS:
		// - Uncomment below to sync user title from 'New-Infra'
		// match user's title from title's list
		const usersTitle = matchJobTitleFromID(
			currentUser?.jobTitleID,
			all.userTitles
		);

		dispatchToState({
			type: "SYNC_USER_DATA",
			data: {
				userTypes,
				userTitles,
				newJobTitle: usersTitle,
				// jobTitle: values?.jobtitle, // UNCOMMENT //
				phoneNumber: values?.phoneNumber ?? "",
			},
		});
		return setUserTypesAndTitles({
			userTypes: [...all?.userTypes],
			userTitles: [...all?.userTitles],
		});
	};

	// fetches 'userTypes' & 'userTitles'
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		fetchUserResource();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className={styles.UserProfile}>
				<section className={styles.UserProfile_main}>
					<div className={styles.UserProfile_main_avatar}>
						<EditAvatar
							currentUser={currentUser}
							userImgSrc={userAvatar}
							openEditAvatarModal={() => setShowEditAvatarModal(true)}
						/>
					</div>
					<div className={styles.UserProfile_main_editForm}>
						<EditUserProfile
							key={`Profile--${userTypesAndTitles?.userTitles?.length}`}
							vals={values}
							handlePhone={handleUserSettings}
							handleChange={handleChange}
							handleCheckbox={handleCheckbox}
							handleUserSettings={handleUserSettings}
							handleCustomJobTitle={handleCustomJobTitle}
							handleReset={handleReset}
							currentUser={currentUser}
							hasTrackerAccess={currentUser.hasTrackerAccess}
							hasLegacyAccess={currentUser.hasLegacyAccess}
							facilityAccessList={facilityAccessList}
							globalState={globalState}
							dispatchAlert={dispatchAlert}
							allTitles={userTypesAndTitles?.userTitles}
							allTypes={userTypesAndTitles?.userTypes}
							// allTitles={[
							// 	...formatAndSortUserTitles(userTypesAndTitles?.userTitles),
							// ]}
							// allTypes={[
							// 	...formatAndSortUserTypes(userTypesAndTitles?.userTypes),
							// ]}
						/>
					</div>
				</section>
				<section className={styles.UserProfile_actions}>
					<UserProfileActions
						vals={values}
						formState={formState}
						currentUser={currentUser}
						saveProfileChanges={saveProfileChanges}
						cancelProfileChances={cancelProfileChanges}
						deactivateUser={deactivateUser}
						deleteUser={deleteUser}
						wasProfileSaved={wasSaved}
					/>
				</section>
			</div>

			{showEditAvatarModal && (
				<AvatarModal closeModal={() => setShowEditAvatarModal(false)}>
					<EditAvatarForm
						fileVal={fileUpload}
						hasFile={!isEmptyVal(fileUpload)}
						handleFile={handleFile}
						handleDragOver={handleDragOver}
						handleFileDrop={handleFileDrop}
						currentUser={currentUser}
					/>
				</AvatarModal>
			)}

			{showConfirmDeleteModal && (
				<Dialog
					icon="WARN"
					title={dialogDetails.deleteUser.title}
					heading={dialogDetails.deleteUser.heading}
					text={dialogDetails.deleteUser.text}
					closeModal={() => setShowConfirmDeleteModal(false)}
				>
					<ButtonSM
						customStyles={customCSS.cancel}
						handleClick={cancelDeleteUser}
					>
						Cancel
					</ButtonSM>
					<ButtonSM
						customStyles={customCSS.confirm}
						handleClick={confirmDeleteUser}
					>
						Confirm
					</ButtonSM>
				</Dialog>
			)}

			{showConfirmDeactivateModal && (
				<Dialog
					icon="WARN"
					title={dialogDetails.deactivateUser.title}
					heading={dialogDetails.deactivateUser.heading}
					text={dialogDetails.deactivateUser.text}
					closeModal={() => setShowConfirmDeactivateModal(false)}
				>
					<ButtonSM
						customStyles={customCSS.cancel}
						handleClick={cancelDeactivateUser}
					>
						Cancel
					</ButtonSM>
					<ButtonSM
						customStyles={customCSS.confirm}
						handleClick={confirmDeactivateUser}
					>
						Confirm
					</ButtonSM>
				</Dialog>
			)}
		</>
	);
};

export default UserProfile;

UserProfile.defaultProps = {
	globalState: {},
};

UserProfile.propTypes = {
	globalState: PropTypes.object,
	dispatchToState: PropTypes.func,
};
