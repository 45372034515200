import React from "react";
import styles from "../../css/app/SidebarWrapper.module.scss";
import { PropTypes } from "prop-types";
import { useWindowSize } from "../../utils/useWindowSize";
import { mobileSize } from "../../helpers/utils_styles";
import { linksConfig, linksHandler } from "../../helpers/utils_routes";
import { enableFeatureViaWhiteList } from "../../helpers/utils_permissions";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
// components
import WidgetTrigger from "../widget/WidgetTrigger";
import Sidebar from "./Sidebar";
import SidebarHelp from "./SidebarHelp";
import SidebarVersion from "./SidebarVersion";
import List from "./List";
import MobileSidebar from "./MobileSidebar";
import MobileSidebarList from "./MobileSidebarList";
import MobileSidebarListItem, {
	MobileSidebarHelp,
} from "./MobileSidebarListItem";
import AnchorLink from "./AnchorLink";
import ProtectedFeature from "../permissions/ProtectedFeature";

const helpWidget = {
	name: "Help",
	path: null,
	icon: "help",
};

const SidebarCustom = ({ winSize, isCollapsed, currentUser }) => {
	if (isCollapsed) {
		return null;
	}
	return (
		<ProtectedFeature isEnabled={enableFeatureViaWhiteList(currentUser.userID)}>
			<div className={styles.SidebarCustom}>
				<div className={styles.SidebarCustom_inner}>
					<AnchorLink
						href="https://internal-portal-app.herokuapp.com"
						inNewTab={true}
					>
						Open Internal Portal
					</AnchorLink>
				</div>
			</div>
		</ProtectedFeature>
	);
};

const SidebarWrapper = ({
	globalState = {},
	currentUser = {},
	history,
	isCollapsed = false,
	setIsCollapsed,
}) => {
	const winSize = useWindowSize();

	// mobile sidebar (fixed to bottom) ~ width: 780px & height: 650px
	if (winSize.width < mobileSize?.width || winSize.height < mobileSize.height) {
		return (
			<MobileSidebar history={history}>
				<MobileSidebarList currentUser={currentUser} history={history}>
					{!isEmptyArray(linksConfig) &&
						linksConfig.map((link, idx) => (
							<MobileSidebarListItem
								key={`${link.name}-${idx}-${link.path}-`}
								name={link.name}
								path={link.path}
								icon={link.icon}
								history={history}
							/>
						))}
					<WidgetTrigger key={`WIDGET_MOBILE`}>
						<MobileSidebarHelp
							name={helpWidget?.name}
							path={helpWidget?.path}
							icon={helpWidget?.icon}
							history={history}
						/>
					</WidgetTrigger>
				</MobileSidebarList>
			</MobileSidebar>
		);
	}
	return (
		<Sidebar
			currentUser={currentUser}
			globalState={globalState}
			isCollapsed={isCollapsed}
			setIsCollapsed={setIsCollapsed}
			history={history}
		>
			<List history={history} globalState={globalState}>
				{!isEmptyVal(currentUser.token) && [
					...linksHandler(linksConfig, currentUser),
				]}
				<WidgetTrigger key={`WIDGET_DESKTOP`}>
					<SidebarHelp
						name={helpWidget?.name}
						path={helpWidget?.path}
						icon={helpWidget?.icon}
						history={history}
						isCollapsed={isCollapsed}
					/>
				</WidgetTrigger>
				<SidebarVersion isCollapsed={isCollapsed} winSize={winSize} />
				<SidebarCustom
					currentUser={currentUser}
					winSize={winSize}
					isCollapsed={isCollapsed}
				/>
			</List>
		</Sidebar>
	);
};

export default SidebarWrapper;

SidebarWrapper.defaultProps = {
	currentUser: {},
};

SidebarWrapper.propTypes = {
	currentUser: PropTypes.object,
	history: PropTypes.object,
};
