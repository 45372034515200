import React from "react";
import styles from "../../css/admin/UserSuspensionSwitch.module.scss";
import { PropTypes } from "prop-types";
import ToggleSwitch from "../shared/ToggleSwitch";

const UserSuspensionSwitch = ({ suspendUserVal, handleSuspendUser }) => {
	return (
		<div className={styles.UserSuspensionSwitch}>
			<div className={styles.UserSuspensionSwitch_label}>Suspend User</div>
			<ToggleSwitch
				size="MD"
				name="suspendUser"
				id="suspendUser"
				val={suspendUserVal}
				handleToggle={handleSuspendUser}
			/>
		</div>
	);
};

export default UserSuspensionSwitch;

UserSuspensionSwitch.defaultProps = {};

UserSuspensionSwitch.propTypes = {};
