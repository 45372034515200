import React from "react";
import styles from "../../css/forms/FormSection.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import sprite2 from "../../assets/icons/buttons.svg";
import sprite3 from "../../assets/icons/emar.svg";
import sprite4 from "../../assets/icons/epay.svg";
import { PropTypes } from "prop-types";
import { hasProp } from "../../helpers/utils_types";
// components
import InfoIcon from "../shared/InfoIcon";

const padding = {
	marginLeft: ".5rem",
};

const ICONS = {
	attachment: "attach_file",
	attachment2: "attachment21",
	archive: "box11",
	book: "open-book",
	camera: "camera111",
	checkbox: "check_box",
	checkCircle: "check_circle",
	checkBlank: "check_box_outline_blank",
	chart: "insert_chartpollassessment",
	chartOutlined: "insert_chart_outlined",
	lightbulb: "light-bulb",
	calendarCheck: "event_available",
	calendarBusy: "event_busy",
	calendarNote: "event_note",
	file: "description",
	search: "search2",
	building: "location_city",
	error: "error1",
	errorOutlined: "error_outline",
	folderImages: "folder-images",
	folderMusic: "folder-music",
	folderVideo: "folder-video",
	folderOpen: "folder21",
	imageDark: "image-inverted",
	imageLight: "image11",
	images: "images",
	lockOpen2: "lock-open111",
	lock2: "lock-open111",
	news: "news",
	warning: "warningreport_problem",
	brochure: "new_releases",
	help: "live_help",
	edit: "createmode_editedit",
	signature: "gesture",
	plus: "add2",
	close: "clearclose",
	alarm: "access_alarmalarm",
	alarmAdd: "add_alarmalarm_add",
	alarmDisable: "alarm_off",
	alarmCheck: "alarm_on",
	clock: "access_timequery_builderschedule",
	cloud: "cloud5",
	silverware: "restaurant",
	print: "local_print_shopprint",
	user: "person",
	userOutlined: "person_outlineperm_identity",
	userCircle: "account_circle",
	userSquare: "account_box",
	recurring: "historyrestore",
	trash: "delete",
	inbox: "all_inbox",
	timer: "timer1",
	alert: "notifications1",
	alertOutlined: "notifications-outline",
	photo: "photo1",
	save: "save-disk",
	show: "view-show",
	hide: "view-hide",
	info: "info11",
	infoOutline: "info_outline",
	infoCircle: "info-with-circle",
	firstAid: "local_hospital",
	settings: "tune",
	note: "assignment",
	flag: "flag",
	tag: "tag",
	notAllowed: "not_interesteddo_not_disturb",
};
const ICONS2 = {
	lock: "lock11",
	lockOpen: "lock-open111",
	login: "login",
};

const ICONS3 = {
	// emar
	emar: "pil",
	pharmacy: "local_pharmacy",
	meds: "medkit",
	hospital: "hospital-o",
	hospital2: "local_hospital",
	hospital3: "medical_services",
	hospital4: "aid-kit",
};
const ICONS4 = {
	// epay
	ePay: "credit-card",
	ePay1: "credit-card2",
	ePay2: "credit-card3",
	ePay3: "credit-card4",
	ePay4: "payments",
};

const getIcon = (icon) => {
	const has1 = hasProp(ICONS, icon);
	const has2 = hasProp(ICONS2, icon);
	const has3 = hasProp(ICONS3, icon);
	const has4 = hasProp(ICONS4, icon);

	switch (true) {
		case has1: {
			return ICONS[icon];
		}
		case has2: {
			return ICONS2[icon];
		}
		case has3: {
			return ICONS3[icon];
		}
		case has4: {
			return ICONS4[icon];
		}
		default:
			return ICONS[icon];
	}
};
const getSprite = (icon) => {
	const has1 = hasProp(ICONS, icon);
	const has2 = hasProp(ICONS2, icon);
	const has3 = hasProp(ICONS3, icon);
	const has4 = hasProp(ICONS4, icon);

	switch (true) {
		case has1: {
			return sprite;
		}
		case has2: {
			return sprite2;
		}
		case has3: {
			return sprite3;
		}
		case has4: {
			return sprite4;
		}
		default:
			return sprite;
	}
};

const FormSection = ({
	title,
	exampleText = null,
	exampleColor = "",
	icon = "checkbox",
	hoverText,
	hideInfoIcon = false,
	isDisabled = false,
	isHidden = false,
	titleColor,
	iconColor,
	children,
}) => {
	if (isHidden) {
		return null;
	}
	return (
		<fieldset className={styles.FormSection} disabled={isDisabled}>
			<h4 className={styles.FormSection_heading}>
				<svg className={styles.FormSection_heading_icon}>
					<use
						// xlinkHref={`${getSprite(icon, ICONS)}#icon-${getIcon(icon, ICONS)}`}
						xlinkHref={`${getSprite(icon)}#icon-${getIcon(icon)}`}
						fill={iconColor}
					></use>
				</svg>
				<span
					className={styles.FormSection_heading_title}
					style={{ color: titleColor }}
				>
					{title}{" "}
					{exampleText && (
						<i style={{ color: exampleColor }}>({exampleText})</i>
					)}
				</span>
				{!hideInfoIcon && (
					<InfoIcon size="XSM" hoverText={hoverText} customStyles={padding} />
				)}
			</h4>
			<div className={styles.FormSection_content}>{children}</div>
		</fieldset>
	);
};

export default FormSection;

FormSection.defaultProps = {
	exampleText: null,
	icon: "checkbox",
	hideInfoIcon: false,
	isDisabled: false,
};

FormSection.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	exampleText: PropTypes.string,
	icon: PropTypes.string,
	hoverText: PropTypes.string,
	hideInfoIcon: PropTypes.bool,
	iconColor: PropTypes.string,
	isDisabled: PropTypes.bool,
	children: PropTypes.any,
};
