import {
	user,
	facility,
	security,
	userProfile,
	userLogin,
} from "./utils_endpoints";
import { currentEnv } from "./utils_env";
import { isEmptyArray, isEmptyObj, isEmptyVal } from "./utils_types";
import { getFileRegistryByUser } from "./utils_files";
import {
	NewUserModel,
	AdvUserCommunityModel,
	UserLoginFacilityRecord,
	UserPhoneRecord,
	UserTitleModel,
	ADVUSERModel,
} from "./utils_models";
import {
	addEllipsis,
	sortAlphaAscByKey,
	sortAlphaDescByKey,
} from "./utils_processing";
import { alaSystemDB, params } from "./utils_params";
import {
	getFacilityID,
	matchFacilityByName,
	matchUserFacilityByName,
} from "./utils_facility";
import {
	getTypesAndTitles,
	mergeSecurityQuestionsAndAnswers,
	saveUserPhoneChanges,
	saveUserProfileChanges,
} from "./utils_security";
import {
	initAndUpdateUserLoginModel,
	updateCustomProfileRecord,
	updateUserEmailsRecord,
	updateUserPhonesRecord,
	updateUserProfileRecord,
} from "./utils_createUser";
import { appAliases, appIDs, appNames, SERVICES } from "./utils_apps";
import { getFromStorage, saveToStorage } from "./utils_caching";
import {
	findSelected,
	findSelectedKey,
	getKeyName,
	isValidEmail,
} from "./utils_validation";
import { USER_TYPES_FORMATTED } from "./utils_userTypes";

////////////////////////////////////////////////////////////
//////////////////// USER-REQUEST UTILS ////////////////////
////////////////////////////////////////////////////////////

/**
 * User-related CRUD request utils:
 * - Create new user
 * - Edit existing user's changes
 * - Delete a user
 */

/**
 * A de-facto virtual deletion of user account:
 * - Disables 'UserLogin', 'UserProfile' and 'AdvUser' records
 * - Adds 'X-' prefix to username/email to free up that username/email for reuse
 * @param {String} token - Auth token
 * @param {String} userLoginID - A user's UserLoginID
 * @returns {Boolean} - Returns whether deactivation was sucessful.
 */
const deactivateUser = async (token, userLoginID) => {
	let url = currentEnv.base + user.deactivateUser;
	url += "?" + new URLSearchParams({ userLoginId: userLoginID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Deletes a single user from the ADVUSER table.
 */
const deleteUser = async (token, userID) => {
	let url = currentEnv.base + user.delete.user;
	url += "?" + new URLSearchParams({ guidUser: userID });

	try {
		const request = await fetch(url, {
			method: "DELETE",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * 'Virtual' deletes a user's userlogin record
 * @param {String} token - Auth token
 * @param {String} userID - User 'UserLoginID'
 * @returns {Boolean} - Returns whether 'virtual' deletion was successful.
 */
const deleteUserLogin = async (token, userLoginID) => {
	let url = currentEnv.base + user.delete.userLogin;
	url += "?" + new URLSearchParams({ UserLoginID: userLoginID });

	try {
		const request = await fetch(url, {
			method: "DELETE",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Virtual deletes a UserProfile record.
 * @param {String} token - Auth token
 * @param {Number} userProfileID - A numberic UserProfileID
 * @returns {Boolean} - Returns whether user's profile was 'virtual' deleted successfuly
 */
const virtualDeleteUserProfile = async (token, userProfileID) => {
	let url = currentEnv.base + userProfile.deleteProfile;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				UserProfileID: userProfileID,
				IsActive: false,
			}),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// virtually delete
const virtualDeleteUserLogin = async (token, userLoginID) => {
	let url = currentEnv.base + userLogin.updateUserLogin;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				UserLoginID: userLoginID,
				IsActive: false,
			}),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// deletes both user login & profile record from new infra
const virtualDeleteUser = async (token, userIDs = {}) => {
	const { userLoginID, userProfileID } = userIDs;
	// call profile/login APIs
	const [deletedProfile, deletedLogin] = await Promise.all([
		virtualDeleteUserProfile(token, userProfileID),
		virtualDeleteUserLogin(token, userLoginID),
	]);

	return {
		deletedProfile,
		deletedLogin,
	};
};

/**
 * Disables a user via the 'NoLongerAnEmployee' flag.
 * @param {String} token - Security token
 * @param {Object} advUserModel - Advuser object w/ 'NoLongerAnEmployee' set to false.
 */
const disableUser = async (token, advUserModel) => {
	let url = currentEnv.base + user.edit.user;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(advUserModel),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Save changes to an existing user.
 */
const saveUserChanges = async (token, advUserModel) => {
	let url = currentEnv.base + user.edit.user;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(advUserModel),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Fetch a user's custom ALA Profile.
 * - Returns a profile 'massaged' by ALA Services.
 */
const getUserProfile = async (token, userID) => {
	let url = currentEnv.base + user.getProfile;
	url += "?userId=" + userID;

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: new Headers({
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
			}),
		});
		const response = await request.json();
		const profile = await JSON.parse(response.Data);
		return profile;
	} catch (err) {
		return err.message;
	}
};
const getUserProfileByEmail = async (token, email) => {
	let url = currentEnv.base + user.getProfileByEmail;
	url += "?" + new URLSearchParams({ userEmail: email });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: new Headers({
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
			}),
		});
		const response = await request.json();
		const profile = response.Data;
		return profile;
	} catch (err) {
		return;
	}
};

/**
 * Fetches all user phone numbers via 'UserProfile'
 * @param {String} token - Auth token
 * @param {Number} profileID - User's 'UserProfileID' number
 * @returns {Object[]} - Returns an array of ALL user phone records, if available.
 */
const getUserPhone = async (token, profileID) => {
	let url = currentEnv.base + user.getPhone;
	url += "?" + new URLSearchParams({ UserProfileID: profileID });
	url += "&" + new URLSearchParams({ index: 0, rows: 10 });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Fetches a number of users for a facility.
 * - The index & rows determines *how* many users to fetch.
 */
const getUsersByFacility = async (
	token,
	facilityId,
	index = 0,
	rows = 1000
) => {
	let url = currentEnv.base + facility.getUsers;
	url += "?" + new URLSearchParams({ guidFacility: facilityId });
	url += "&" + new URLSearchParams({ index, rows });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Fetches a number of users determined by the params.
 */
const getAllUsers = async (token, params = { index: 0, rows: 100 }) => {
	let url = currentEnv.base + facility.getUsers;
	url += "?" + new URLSearchParams({ ...params });
	url += "&" + new URLSearchParams({ NoLongerAnEmployee: false });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Fetches a user's file records
 * @returns {Array|Null}
 */
const getUserFileList = async (token, userID) => {
	const fileList = await getFileRegistryByUser(token, userID);

	if (!isEmptyArray(fileList)) {
		return fileList;
	} else {
		return null;
	}
};
// fetches list of facilities a user has access to
const getUserFacilityList = async (token, userEmail) => {
	let url = currentEnv.base + user.access.getFacilityList;
	url += "?" + new URLSearchParams({ userEmail: userEmail });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

const searchModernForUser = async (token, searchParams = {}) => {
	let url = currentEnv.base + user.getUser.search;
	url += "?" + new URLSearchParams({ ...alaSystemDB });
	url += "&" + new URLSearchParams({ source: "UserLogin" });
	url += "&" + new URLSearchParams({ ...searchParams });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Search for a user by user values.
 * @param {String} token - Auth token
 * @param {Object} searchParams - Custom query params w/ the search criteria formatted.
 * @returns {Object|Array|Null} - Returns search results for a given search query.
 */
const searchForUser = async (token, searchParams = {}) => {
	let url = currentEnv.base + user.getUser.search;
	url += "?" + new URLSearchParams({ ...params.user });
	url += "&" + new URLSearchParams({ ...searchParams });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

const searchUsersByUserType = async (token, allFacilities = [], vals = {}) => {
	switch (true) {
		case vals?.byALAAdmin: {
			return;
		}
		case vals?.byMedTechRestricted: {
			return;
		}
		case vals?.bySuperUser: {
			return;
		}
		case vals?.byFacilityAdmin: {
			return;
		}
		case vals?.byRegionalAdmin: {
			return;
		}

		default:
			break;
	}
};

/**
 * Wrapper around 'searchForUser' request util that pre-processes search params for request.
 * @param {String} token - Auth token.
 * @param {Object} vals - User search values.
 */
const searchForUserBy = async (token, allFacilities = [], vals = {}) => {
	// find user type selected
	const key = findSelectedKey(vals);
	const hasUserType = !isEmptyVal(key);

	switch (true) {
		case vals.byEmail: {
			const { emailSearch } = vals;

			if (hasUserType) {
				const typeKey = getKeyName(key);
				const resp = await searchForUser(token, {
					strEmail: emailSearch,
					[typeKey]: vals[key],
				});
				return resp;
			} else {
				const resp = await searchForUser(token, {
					strEmail: emailSearch,
				});
				return resp;
			}
		}
		case vals.byFirstName: {
			const { firstNameSearch } = vals;

			if (hasUserType) {
				const typeKey = getKeyName(key);
				const resp = await searchForUser(token, {
					strFirstName: firstNameSearch,
					[typeKey]: vals[key],
				});
				return resp;
			} else {
				const resp = await searchForUser(token, {
					strFirstName: firstNameSearch,
				});
				return resp;
			}
		}
		case vals.byLastName: {
			const { lastNameSearch } = vals;

			if (hasUserType) {
				const typeKey = getKeyName(key);
				const resp = await searchForUser(token, {
					strLastName: lastNameSearch,
					[typeKey]: vals[key],
				});
				return resp;
			} else {
				const resp = await searchForUser(token, {
					strLastName: lastNameSearch,
				});
				return resp;
			}
		}
		case vals?.byFacility: {
			const { facilitySearch } = vals;

			if (hasUserType) {
				const typeKey = getKeyName(key);
				const record = matchUserFacilityByName(facilitySearch, allFacilities);
				const id = record?.facilityID ?? record?.FacilityId;
				const resp = await searchForUser(token, {
					guidFacility: id,
					[typeKey]: vals[key],
				});
				return resp;
			} else {
				const record = matchUserFacilityByName(facilitySearch, allFacilities);
				const id = record?.facilityID ?? record?.FacilityId;
				const resp = await searchForUser(token, {
					guidFacility: id,
				});
				return resp;
			}
		}
		case vals?.byUserID: {
			const { userIDSearch } = vals;
			const resp = await searchForUser(token, {
				guidUser: userIDSearch?.toLowerCase(),
			});

			return resp;
		}
		case vals?.byALAAdmin: {
			const { byALAAdmin } = vals;
			const resp = await searchForUser(token, {
				alaAdmin: byALAAdmin,
			});
			return resp;
		}
		case vals?.byMedTechRestricted: {
			const { byMedTechRestricted } = vals;
			const resp = await searchForUser(token, {
				MedTechRestrictedAccess: byMedTechRestricted,
			});
			return resp;
		}
		case vals?.bySuperUser: {
			const { bySuperUser } = vals;
			const resp = await searchForUser(token, {
				superUser: bySuperUser,
			});
			return resp;
		}
		case vals?.byFacilityAdmin: {
			const { byFacilityAdmin } = vals;
			const resp = await searchForUser(token, {
				bitFacilityAdministrator: byFacilityAdmin,
			});
			return resp;
		}
		case vals?.byRegionalAdmin: {
			const { byRegionalAdmin } = vals;
			const resp = await searchForUser(token, {
				bitFacilityAdministrator: byRegionalAdmin,
				alaAdmin: true,
			});
			return resp;
		}
		case vals?.byReadOnly: {
			const { byReadOnly } = vals;
			const resp = await searchForUser(token, {
				bitFacilityAdministrator: false,
				alaAdmin: false,
			});
			return resp;
		}

		default:
			return [];
	}
};

// creates new 'UserLogin' in user infra
const createNewUserLogin = async (token, loginRecords = {}) => {
	let url = currentEnv.base + security.userLogin.create;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(loginRecords),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

////////////////////////////////////////////////////////////
////////////////////// NEW-USER UTILS //////////////////////
////////////////////////////////////////////////////////////

/**
 * Create New User Request & Utils
 * - Update new user model
 * - Send off request
 */

/**
 * Creates a brand new user via the 'UserProfile' table in the new "USER-INFRA"
 * @param {String} token - Security token.
 * @param {Object} profileModel - A new 'UserProfileModel' entry for a brand new user.
 */
const createNewUserProfile = async (token, profileModel = {}) => {
	let url = currentEnv.base + user.create.newUserProfile;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(profileModel),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Saves a new user in the 'ADVUSER' table of the database.
 * @returns {String} - Returns the new UserId, as a string.
 */
const createNewUser = async (token, userModel = {}) => {
	let url = currentEnv.base + user.create.user;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(userModel),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err;
	}
};

/**
 * Applies user values to NewUserModel and fires off request
 * @returns {Object} - Returns an object:  { Data: "", Messages: [] }
 * - NOTE: encodes the 'strPassword' and 'TempPassword' for the request
 */
const createAndSaveNewUser = async (token, userVals = {}) => {
	const userModel = updateUserModel(userVals);
	const success = await createNewUser(token, {
		...userModel,
		strPassword: encodeURIComponent(userModel?.strPassword ?? null),
		TempPassword: encodeURIComponent(userModel?.TempPassword ?? null),
	});

	if (success) {
		return success;
	} else {
	}
};

/**
 * Saves one or more facility access records for a given user.
 * @param {String} token - Base64 security token
 * @param {Array} accessRecords - An array of 'AdvUserCommunity' model records.
 */
const grantFacilityAccess = async (token, accessRecords = []) => {
	let url = currentEnv.base + user.access.grantAccessMany;

	if (!Array.isArray(accessRecords)) {
		accessRecords = [accessRecords];
	}

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(accessRecords),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Enables changing a user's password. All passwords are encoded to enable special characters for passwords.
 * @param {String} token - Security token
 * @param {Object} vals - An object of new user password vals:
 * - 'userID': target user to be modified
 * - 'oldPassword': target user's old/current password OR 'OTP'
 * - 'newPassword': target user's new password
 *
 * Updated 2/3/2021 at 10:07 AM
 * - Removed 'new URLSearchParams' from "oldPassword" & "newPassword"
 * - This is due to the additional encoding applied via these methdods, which messes w/ special chars.
 */
const changeUserPassword = async (token, vals = {}) => {
	let url = currentEnv.base + user.edit.changePassword;
	url += "?" + new URLSearchParams({ userId: vals?.userID });
	url += "&oldPassword=" + encodeURIComponent(vals?.oldPassword);
	url += "&newPassword=" + encodeURIComponent(vals?.newPassword);

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// NEW USER INFRA
const generateAndCreateNewUserLogin = async (
	token,
	allFacilities = [],
	allApps = [],
	allQuestions = [],
	userVals = {}
) => {
	const loginRecords = initAndUpdateUserLoginModel(
		allFacilities,
		allApps,
		allQuestions,
		userVals
	);

	const wasCreated = await createNewUserLogin(token, loginRecords);
	return wasCreated;
};

// email format w/ 'aladvantage.com' as the domain
const isALAEmail = (username) => {
	const isALA = /aladvantage\.com/gim.test(username);
	return isALA;
};

/**
 * Fetches an array of 'UserLogin' records by username.
 * @param {String} token - Security token
 * @param {String} username - A user's username, specifically the 'LoginName'
 * @returns {Array} - Returns an array of all 'UserLogin' records for a given user.
 */
const getUserLoginByUsername = async (token, username) => {
	let url = currentEnv.base + security.userLogin.getLogin2;

	if (isValidEmail(username) && !isALAEmail(username)) {
		url += "?" + new URLSearchParams({ LoginNameByEmail: username });
	}
	if (!isValidEmail(username) || isALAEmail(username)) {
		url += "?" + new URLSearchParams({ LoginName: username });
	}

	// UPDATE TO CHECK FOR EMAIL AND CHANGE FIELD TO 'LoginNameByEmail'

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data?.[0] ?? {};
	} catch (err) {
		return err.message;
	}
};

/**
 * Fetches a user's 'UserLogin' record by searching for 'LoginName' & 'LoginNameByEmail'
 * @param {String} token - Auth token
 * @param {String} username - A string username.
 * @returns {Object} - Returns a user's 'UserLogin' record.
 * - NOTE: this replaces the 'getUserLoginByUsername()' request, as this handles edge-cases
 */
const getUserLoginsByUsername = async (token, username) => {
	const [viaLoginName, viaLoginNameByEmail] = await Promise.all([
		getUserLoginByUsernameType(token, {
			LoginName: username,
			IsActive: true,
		}),
		getUserLoginByUsernameType(token, {
			LoginNameByEmail: username,
			IsActive: true,
		}),
	]);
	const usernameObj = viaLoginName?.[0] ?? {};
	const emailObj = viaLoginNameByEmail?.[0] ?? {};

	const loginRecord = isEmptyObj(usernameObj) ? emailObj : usernameObj;

	return loginRecord;
};

// fetches a user's 'UserLogin' record via: 'LoginName' OR 'LoginNameByEmail'
const getUserLoginByUsernameType = async (token, params = {}) => {
	let url = currentEnv.base + security.userLogin.getLogin2;
	url += "?" + new URLSearchParams({ ...params });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		// return response.Data?.[0] ?? {};
		return response.Data ?? [];
	} catch (err) {
		return err.message;
	}
};

////////////////////////////////////////////////////////////
//////////////// EDIT USERNAME/EMAIL UTILS ////////////////
////////////////////////////////////////////////////////////

/**
 * Changes an existing user's username.
 * @param {String} token - Auth token
 * @param {Object} userVals - Object of user values used as query params.
 * @param {String} userVals.userID - Target user's userID (eg. 'UserLoginID')
 * @param {String} userVals.editUsername - New username to apply for target user.
 * @param {Boolean} userVals.isValidEmail - Indicates whether new username is a working email.
 * @returns {Boolean} - Returns whether 'username' was updated successfully.
 */
const changeUsername = async (token, userVals = {}) => {
	const { userID, editUsername, isValidEmail } = userVals;
	let url = currentEnv.base + user.edit.changeUsername;
	url += "?" + new URLSearchParams({ userId: userID });
	url += "&" + new URLSearchParams({ userName: editUsername });
	url += "&" + new URLSearchParams({ isValidEmail: isValidEmail });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

const changeUserTitle = async (token, userVals = {}) => {
	let url = currentEnv.base + user.edit.userTitle;
	url += "?" + new URLSearchParams({ userId: userVals?.userID });
	url += "&" + new URLSearchParams({ userTitle: userVals?.jobTitle });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

////////////////////////////////////////////////////////////
//////////////// USER-PROFILE UPDATER UTILS ////////////////
////////////////////////////////////////////////////////////

const updateCustomPhone = (vals) => {
	const phoneModel = {
		UserPhoneID: vals?.phoneID ?? 0,
		UserProfileID: vals?.profileID,
		Phone: vals?.phoneNumber ?? "",
		Extension: vals?.phoneExt ?? vals?.extension ?? "",
	};
	return phoneModel;
};

/**
 * Update a user's First & Last names in the 'ADVUSER' and 'USERPROFILE' records.
 * @param {String} token - Auth token of user making changes (ie current auth'd user)
 * @param {Object} targetUser - Object of client-formatted target user.
 * @param {Object} newVals - Object of 'formState' values to be applied to 'targetUser'
 * @returns {Object} - Returns object defining whether both updates were successful
 */
const handleUserNameChanges = async (token, targetUser = {}, newVals = {}) => {
	// need to update 'ADVUSER' & 'UserProfile' records
	const newAdvUserRecord = {
		guidUser: targetUser?.userID ?? "",
		strFirstName: newVals?.firstName ?? "",
		strLastName: newVals?.lastName ?? "",
	};
	const newProfileRecord = {
		UserProfileID: targetUser?.userProfileID ?? 0,
		FirstName: newVals?.firstName ?? "",
		LastName: newVals?.lastName ?? "",
	};

	const [profileUpdate, advUserUpdate] = await Promise.all([
		saveUserProfileChanges(token, newProfileRecord),
		saveUserChanges(token, newAdvUserRecord),
	]);

	return {
		savedProfile: profileUpdate,
		savedAdvUser: advUserUpdate,
	};
};

/**
 * Determines which request(s) & data-models to update to save user's changes & returns the results.
 * @param {Array} allTypes - An array of 'UserTypes' records
 * @param {Array} allTitles - An array of 'UserTitle' records
 * @param {Object} currentUser - Client-formatted user object
 * @param {Object} allVals - 'formState' from 'useForm' w/ 'touched' and 'values' nested within
 * @returns {Object} - Returns an object w/ result of request(s) made
 */
const handleUserProfileChanges = async (
	allTypes = [],
	allTitles = [],
	currentUser = {},
	allVals = {}
) => {
	const { values, touched } = allVals;
	const { token } = currentUser;
	// checks which fields were changed
	const touchedPhone = (touched?.phoneNumber || touched?.phoneExt) ?? "";
	const touchedTitle = touched?.jobTitle ?? "";

	switch (true) {
		// both phone & profile records
		case touchedPhone && touchedTitle: {
			// ##TODOS:
			// - Update to use 'changeUserTitle' request
			// 		- Maybe leave this one as is???

			// NEW MODEL - updated 7/29/2021 at 3:23 PM
			const advUserRecord = updateCustomUserModel({
				...values,
				userID: currentUser?.userID,
			});

			const phoneRecord = updateCustomPhone({
				...values,
				phoneID: currentUser?.phoneID ?? 0,
				profileID: currentUser?.profileID,
			});

			// NEW MODEL
			const profileRecord = updateCustomProfileRecord(allTypes, allTitles, {
				...values,
				userID: currentUser?.userID,
				profileID: currentUser?.profileID,
			});

			// Add a: send update request to 'ADVUSER' table here...
			// const savedProfile = true;
			// const savedPhone = true;
			const [savedProfile, savedPhone, advUser] = await Promise.all([
				saveUserProfileChanges(token, profileRecord),
				saveUserPhoneChanges(token, phoneRecord),
				saveUserChanges(token, advUserRecord),
			]);

			return {
				savedProfile: savedProfile,
				savedPhone: savedPhone,
			};
		}
		// ONLY phone record
		case touchedPhone: {
			// check for existing phone # & fallback to 0 if one doesn't exist
			const phoneID = isEmptyVal(currentUser?.phoneID)
				? 0
				: currentUser.phoneID;

			const phoneRecord = updateCustomPhone({
				...values,
				phoneID: phoneID,
				profileID: currentUser?.profileID,
			});

			const savedPhone = await saveUserPhoneChanges(token, phoneRecord);
			// const savedPhone = true;

			return {
				savedProfile: "N/A",
				savedPhone: savedPhone,
			};
		}
		// ONLY profile (title) record
		case touchedTitle: {
			// ##TODOS:
			// - Update to use 'changeUserTitle' request

			// NEW MODEL - updated 7/29/2021 at 3:23 PM
			const advUserRecord = updateCustomUserModel({
				...values,
				userID: currentUser?.userID,
			});
			// NEW MODEL - updated 7/29/2021 at 3:23 PM
			const profileRecord = updateCustomProfileRecord(allTypes, allTitles, {
				...values,
				userID: currentUser?.userID,
				profileID: currentUser?.profileID,
			});

			// previous requests
			// const savedProfile = await saveUserProfileChanges(token, profileRecord);
			// const advUser = await saveUserChanges(token, advUserRecord);

			// new requests - 12/15/2021 at 2:56 PM
			const [savedProfile, savedAdv] = await Promise.all([
				saveUserProfileChanges(token, profileRecord),
				saveUserChanges(token, advUserRecord),
			]);

			// new title handler - 12/22/2021 at 8:12 AM
			// const savedProfile = await changeUserTitle(token, {
			// 	userID: currentUser?.userID,
			// 	jobTitle: values?.jobTitle,
			// });

			return {
				savedProfile: savedProfile,
				savedPhone: "N/A",
			};
		}

		default:
			return {
				savedProfile: null,
				savedPhone: null,
			};
	}
};

////////////////////////////////////////////////////////////
//////////////////// USER-RELATED UTILS ////////////////////
////////////////////////////////////////////////////////////

const hasMultiFacility = (facilityList = []) => {
	if (isEmptyArray(facilityList) || facilityList.length <= 1) {
		return false;
	}
	return true;
};

/**
 * Inits and populates the 'NewUserModel' with user values.
 * - User fields are prefixed with 'new' (eg 'newUsername', 'newPassword' etc.)
 */
const updateUserModel = (userVals = {}) => {
	const base = new NewUserModel(userVals);
	const model = base.getModel();
	return model;
};

const updateAdvUserModel = (userVals = {}) => {
	const base = new ADVUSERModel(userVals);
	const model = base.getModel();
	return model;
};

const updateCustomUserModel = (userVals = {}) => {
	// job title
	// phone
	const customModel = {
		guidUser: userVals?.userID,
		strTitle: userVals?.jobTitle ?? "",
	};
	return customModel;
};

////////////////////////////////////////////////////////////////////////
//////// PROCESS LIST OF USERS TO CUSTOM DATA STRUCTURE FOR UI ////////
////////////////////////////////////////////////////////////////////////

// checks if a user's email was deleted

/**
 * Checks if a user's email is marked with a 'deleted' marking on their email.
 * - This implies the user is *likely* no longer in use.
 * @param {String} email - A user's email address from the ADVUSER table.
 */
const isEmailDeleted = (email) => {
	const deletedReg = /deleted/;
	const isDeleted = deletedReg.test(email);
	return isDeleted;
};

// creates a custom 'user' object ~ used in <ManageUserAccessView/>
const createUserObj = (user) => {
	const {
		guidUser,
		guidFacility,
		strFirstName,
		strLastName,
		strEmail,
		strPassword,
		strTitle,
		MedTechRestrictedAccess,
		bitFacilityAdministrator,
		alaAdmin,
		superUser,
		NoLongerAnEmployee,
	} = user;

	const newUser = {
		firstName: strFirstName,
		lastName: strLastName,
		email: strEmail,
		password: strPassword,
		title: strTitle,
		userID: guidUser,
		facilityID: guidFacility,
		isMedTechRestricted: MedTechRestrictedAccess,
		isRegionalAdmin: alaAdmin,
		isFacilityAdmin: bitFacilityAdministrator,
		isSuperUser: superUser,
		isFormerEmployee: NoLongerAnEmployee,
		isActive: true, // update later
		// isActive: !NoLongerAnEmployee, // replacement for 'isActive'???
	};
	return newUser;
};

// ##TODOS:
// - Add check for 'IsNoLongerEmployee'; these accounts should be ignored

// formats user object into custom data structure
// removes user's with '/delete/' in their email/username
/**
 * Processes & normalizes a list of users from the 'ADVUSER' table.
 * @param {Array} userList - An array of 'ADVUSER' table records
 * @param {Boolean} filterByDeletedEmail - A toggle to remove users with 'deleted' in their email.
 */
const processUserList = (userList = [], filterByDeletedEmail = true) => {
	// change back to 'false'
	if (isEmptyArray(userList)) return [];
	return userList.reduce((newList, user) => {
		const newUser = createUserObj(user);
		// if filtered is true, then remove emails with the 'deleted' tag
		if (filterByDeletedEmail) {
			if (!isEmailDeleted(newUser?.email) || !newUser?.isActive) {
				newList = [...newList, newUser];
				return newList;
			} else {
				return newList;
			}
		} else {
			newList = [...newList, newUser];
			return newList;
		}
	}, []);
};

// used in 'ManageUserAccessView' on re-fetch on mouse
const processUserMgmtList = (securityInfo) => {
	const maps = getUserMaps(securityInfo);
	const { advUsers, loginMap, profileMap } = maps;
	return advUsers.reduce((userList, advUser) => {
		const newUser = generateUser(advUser, profileMap, loginMap);
		userList = [...userList, newUser];
		return userList;
	}, []);
};

const createLoginMap = (userLogins) => {
	return userLogins.reduce((loginsMap, login) => {
		const { UserLoginID } = login;
		if (!loginsMap[UserLoginID]) {
			loginsMap[UserLoginID] = { ...login };
			return loginsMap;
		}
		return loginsMap;
	}, {});
};
// creates 'FacilityUserProfile' map by profileID
const createProfileMap = (userProfiles = []) => {
	return userProfiles.reduce((profilesMap, profile) => {
		const { UserProfileID } = profile;
		if (!profilesMap[UserProfileID]) {
			profilesMap[UserProfileID] = { ...profile };
			return profilesMap;
		}
		return profilesMap;
	}, {});
};

const getUserMaps = (securityInfo = {}) => {
	const {
		FacilityAdvUsers: advUsers,
		FacilityUserLogins: userLogins,
		FacilityUserProfiles: userProfiles,
	} = securityInfo;

	const loginMap = createLoginMap(userLogins);
	const profileMap = createProfileMap(userProfiles);
	return {
		advUsers,
		loginMap,
		profileMap,
	};
};

// transforms newly created profile & login records into concise client-formatted user object
const createNewUserMgmtObj = (userID, securityProfile = {}) => {
	const { UserLoginModel, UserProfileModel } = securityProfile;
	const {
		UserProfile: profile,
		UserAvatars: avatars,
		UserPhones: phones,
	} = UserProfileModel;
	const { UserLogin: login } = UserLoginModel;

	const userLogin = isEmptyVal(login.LoginNameByEmail)
		? login.LoginName
		: login.LoginNameByEmail;

	const newUser = {
		firstName: profile.FirstName,
		lastName: profile.LastName,
		email: userLogin,
		username: profile.DisplayName,
		password: login.Password,
		title: null,
		userID: userID,
		facilityID: profile.FacilityId,
		isMedTechRestricted: false,
		isRegionalAdmin: false,
		isFacilityAdmin: false,
		isSuperUser: false,
		isFormerEmployee: false,
		phoneNumber: phones?.[0]?.Phone,
		lockoutDate: null,
		isLockedOut: false,
		isSuspended: false,
		suspendDate: null,
		isPwdResetByEmail: login.IsPwdResetByEmail,
		isPwdResetByAdmin: login.IsPwdResetByAdmin,
		isPwdResetByQuestions: login.IsPwdResetByQuestions,
		avatarID: avatars?.[0]?.UserAvatarID,
	};

	return newUser;
};

// includes data merged in from user security profile
const createUserMgmtObj = (user) => {
	const {
		guidUser,
		guidFacility,
		strFirstName,
		strLastName,
		strEmail,
		strPassword,
		strTitle,
		MedTechRestrictedAccess,
		bitFacilityAdministrator,
		alaAdmin,
		superUser,
		NoLongerAnEmployee,
		LastLoginDate,
	} = user;

	// client-formatted user object
	const newUser = {
		firstName: strFirstName,
		lastName: strLastName,
		email: strEmail,
		password: strPassword,
		title: strTitle,
		userID: guidUser,
		facilityID: guidFacility,
		isMedTechRestricted: MedTechRestrictedAccess,
		isRegionalAdmin: alaAdmin,
		isFacilityAdmin: bitFacilityAdministrator,
		isSuperUser: superUser,
		isFormerEmployee: NoLongerAnEmployee,
		phoneNumber: "",
		lockoutDate: user.LockOutDate,
		isLockedOut: user.IsLockOut,
		isSuspended: user.IsSuspended,
		suspendDate: user.SuspendedDate,
		isPwdResetByEmail: user.IsPwdResetByEmail,
		isPwdResetByAdmin: user.IsPwdResetByAdmin,
		isPwdResetByQuestions: user.IsPwdResetByQuestions,
		avatarID: user.UserAvatarID,
		lastLogin: LastLoginDate,
	};
	return newUser;
};
const createAdvUserObj = (user) => {
	const {
		guidUser,
		guidFacility,
		strFirstName,
		strLastName,
		strEmail,
		strPassword,
		strTitle,
		MedTechRestrictedAccess,
		bitFacilityAdministrator,
		alaAdmin,
		superUser,
		NoLongerAnEmployee,
		LastLoginDate,
	} = user;
	const newUser = {
		firstName: strFirstName,
		lastName: strLastName,
		email: strEmail,
		password: strPassword,
		title: strTitle,
		userID: guidUser,
		facilityID: guidFacility,
		isMedTechRestricted: MedTechRestrictedAccess,
		isRegionalAdmin: alaAdmin,
		isFacilityAdmin: bitFacilityAdministrator,
		isSuperUser: superUser,
		isFormerEmployee: NoLongerAnEmployee,
		// no data - set to default
		phoneNumber: null,
		lockoutDate: null,
		isLockedOut: false,
		isSuspended: false,
		suspendDate: null,
		isPwdResetByEmail: false,
		isPwdResetByAdmin: true,
		isPwdResetByQuestions: false,
		avatarID: null,
		lastLogin: LastLoginDate,
	};
	return newUser;
};

const generateUser = (advUser, profileMap, loginMap) => {
	// if email is 'deleted' then remove 'profile' & 'login' data
	if (isEmailDeleted(advUser.strEmail)) {
		const newUser = createAdvUserObj(advUser);
		return newUser;
	} else {
		const { guidUser } = advUser;
		const login = loginMap?.[guidUser] ?? {};
		const profile = profileMap?.[login?.UserProfileID] ?? {};
		const newUser = createUserMgmtObj({
			...advUser,
			...profile,
			...login,
			IsLockedOut: login.IsLockOut,
		});
		return newUser;
	}
};

////////////////////////////////////////////////////////////////////////
///////////////////// GRANT FACILITY ACCESS UTILS /////////////////////
////////////////////////////////////////////////////////////////////////

/**
 * Inits & populates a single 'AdvUserCommunity' record w/ user values for:
 * - When to grant access (eg. a target date)
 * - Whom is granting acceess, and to whom
 */
const updateFacilityAccessModel = (userAccessVals = {}) => {
	const base = new AdvUserCommunityModel(userAccessVals);
	const model = base.getModel();
	return model;
};

/**
 * Iterates thru 'facilityIDs', inits & populates a new AdvUserCommunity record for each
 * @param {Array} facilityIDs - An array of target facility IDs to generate records for.
 * @param {Object} userAccessVals - An object of the required user access values (ie 'grantedBy', 'grantedDate', 'userID' etc.)
 * @returns {Array} - Returns an array of populated AdvUserCommunity models ready to saved to the db.
 */
const createFacilityAccessRecords = (facilityIDs = [], userAccessVals = {}) => {
	if (isEmptyArray(facilityIDs)) return null;

	return facilityIDs.map((targetID) => {
		const accessModel = updateFacilityAccessModel({
			...userAccessVals,
			facilityID: targetID,
		});
		return accessModel;
	});
};

// inits & populates 'UserLoginFacilityRecord'
const updateLoginFacilities = (vals = {}) => {
	const base = new UserLoginFacilityRecord(vals);
	return base.getModel();
};

/**
 * Updaters & utils for creating a new user in the 'New User Infra'
 */
const getFacilityMapByName = (allFacilities = []) => {
	return allFacilities.reduce((facilityMap, facility) => {
		const { communityName } = facility;
		// check if already exists, if not map it to facility's 'communityName'
		if (!facilityMap[communityName]) {
			facilityMap[communityName] = { ...facility };
			return facilityMap;
		}
		return facilityMap;
	}, {});
};

const getFacilityIDsFromSelections = (selections = [], facilityMap = {}) => {
	return selections.map((facilityName) => facilityMap[facilityName].facilityID);
};
// generates all 'userFacilities' records for a new and or existing user
const createLoginFacilities = (
	selections = [],
	facilities = [],
	userVals = {}
) => {
	const facilityMap = getFacilityMapByName(facilities);
	const facilityIDs = getFacilityIDsFromSelections(selections, facilityMap);
	const facilityLogins = facilityIDs.map((id) => {
		const model = updateLoginFacilities({
			...userVals,
			facilityID: id,
		});
		return model;
	});

	return facilityLogins;
};

// updates values to 'UserPhoneRecord' data-structure
const updateUserPhoneModel = (vals = {}) => {
	const base = new UserPhoneRecord(vals);
	const model = base.getModel();
	return model;
};

////////////////////////////////////////////////////////////////////////
///////////////////// SORTING/FILTERING USERS UTILS /////////////////////
////////////////////////////////////////////////////////////////////////

/**
 * Finds all super users from a list of 'processed' users.
 * - This requires a 'pre-processed' list using the 'processUserList()' util.
 */
const findSuperUsers = (allUsers = []) => {
	return allUsers.filter((user) => user?.isSuperUser);
};
/**
 * Finds all non-admins from a list of 'processed' users.
 * - This requires a 'pre-processed' list using the 'processUserList()' util.
 */
const findNonAdmins = (allUsers = []) => {
	return allUsers.filter((user) => {
		const isNonAdmin =
			!user?.isFacilityAdmin && !user?.isRegionalAdmin && !user?.isSuperUser;
		if (isNonAdmin) {
			return user;
		} else {
			return;
		}
	});
};
/**
 * Finds all admins from a list of 'processed' users.
 * - This requires a 'pre-processed' list using the 'processUserList()' util.
 */
const findAdmins = (allUsers = []) => {
	return allUsers.filter((user) => {
		if (user?.isFacilityAdmin || user?.isRegionalAdmin || user?.isSuperUser) {
			return user;
		} else {
			return;
		}
	});
};

const findSuspendedUsers = (allUsers = []) => {
	return allUsers.filter((user) => user?.isSuspended);
};

// sorts users by which have application access given an access list & user list
const sortUsersByAccess2 = (accessList = [], usersList = []) => {
	if (isEmptyArray(accessList)) return [...usersList];
	return usersList.sort((a, b) => {
		const AhasAccess = accessList.includes(a?.userID);
		const BhasAccess = accessList.includes(b?.userID);
		if (AhasAccess && BhasAccess) {
			return 0;
		} else if (AhasAccess) {
			return -1;
		} else {
			return 1;
		}
	});
};
const sortUsersByAccess = (accessType, usersList = []) => {
	if (isEmptyArray(usersList)) return usersList;
	switch (accessType) {
		case "CareTracker": {
			return usersList.filter((user) => user?.hasTrackerAccess);
		}
		case "SeniorCareEHR": {
			return usersList.filter((user) => user?.hasLegacyAccess);
		}
		case "AdminPortal": {
			return usersList.filter((user) => user?.hasPortalAccess);
		}

		default:
			return usersList;
	}
};

// 'UserAccessTable' sorting util wrapper:
// wraps all user sorters
const sortUsersBy = (sortType = "lastName", allUsers = []) => {
	switch (sortType) {
		case "firstNameASC": {
			return [...sortAlphaAscByKey("firstName", allUsers)];
		}
		case "firstNameDESC": {
			return [...sortAlphaDescByKey("firstName", allUsers)];
		}
		case "lastNameASC": {
			return [...sortAlphaAscByKey("lastName", allUsers)];
		}
		case "lastNameDESC": {
			return [...sortAlphaDescByKey("lastName", allUsers)];
		}
		case "titleASC": {
			return [...sortAlphaAscByKey("title", allUsers)];
		}
		case "titleDESC": {
			return [...sortAlphaDescByKey("title", allUsers)];
		}
		case "facility": {
			return [...sortAlphaAscByKey("facilityID", allUsers)];
		}
		case "admins": {
			return [...findAdmins(allUsers)];
		}
		case "nonAdmins": {
			return [...findNonAdmins(allUsers)];
		}
		case "superUsers": {
			return [...findSuperUsers(allUsers)];
		}
		case "Suspend": {
			return [...findSuspendedUsers(allUsers)];
		}
		default:
			return allUsers;
	}
};

/**
 * User Field-Value Utils:
 * - Read, parse and format various user-related fields
 * - These utils work w/ formatted users. (NOT server-formatted fields)
 */

const getUserName = (user = {}, maxLength = 30) => {
	const firstName = user?.firstName ?? "";
	const lastName = user?.lastName ?? "";
	const name = `${lastName}, ${firstName}`;
	const nameWithEllipsis = addEllipsis(name, maxLength);
	return nameWithEllipsis;
};

const getUserTitle = (user) => {
	if (isEmptyVal(user?.title)) return "";
	return `(${user?.title})`;
};
const getUserEmail = (user = {}) => {
	const email = user?.email ?? user?.username ?? "Not Found";
	return email;
};

// PROCESS 'USERINFO'
const processProfile = (profile = {}) => {
	return {
		firstName: profile?.FirstName ?? "",
		lastName: profile?.LastName ?? "",
		middleName: profile?.MiddleName ?? "",
		displayName: profile?.DisplayName ?? "",
		facilityID: profile?.FacilityId ?? "",
		createdDate: profile?.CreatedDate ?? "",
		profileID: profile?.UserProfileID ?? 0,
		isActive: profile?.IsActive ?? true,
		jobTitleID: profile?.UserTitleID ?? 0,
		userTypeID: profile?.UserTypeID ?? 0,
		isLockedOut: profile?.IsLockOut ?? false,
		lockoutDate: profile?.LockOutDate ?? profile?.LastLockOutDate ?? "",
	};
};
const processUserApps = (apps) => {
	if (isEmptyArray(apps)) return [];

	return apps.map((app) => {
		const { ApplicationID: id } = app;
		return {
			...app,
			ApplicationName: appNames[id],
			Alias: appAliases[id],
		};
	});
};

const processUserInfo = (username, userInfo = {}) => {
	const {
		AdvUserCommunities,
		AdvUsers,
		User2FAs,
		UserAlerts,
		UserApps,
		UserAvatars,
		UserEmails,
		UserLogins,
		UserPhones,
		UserProfile,
		UserRoles,
		UserRoleGroups,
		UserSecurityAnswers,
		UserSecurityQuestions,
	} = userInfo;

	const profile = processProfile(UserProfile);
	const apps = processUserApps(UserApps); // injects the 'ApplicationName'
	// filter out wrong records - added at 10:44 AM
	// - Removed at 4:00 PM
	const logins = getMatchingLoginFromUsername(username, UserLogins);
	const advUsers = getMatchingAdvUserFromUsername(username, AdvUsers);

	const advUser = advUsers?.[0] ?? {};
	const advUserRecord = AdvUsers?.[0] ?? {};
	const adv = isEmptyObj(advUser) || !advUser ? advUserRecord : advUser;

	return {
		advUser: adv, // NEW CHANGES
		userLogins: logins, // NEW CHANGES
		// advUser: AdvUsers?.[0] ?? {}, // OLD VERSION
		// userLogins: UserLogins, // OLD VERSION
		user2FAs: User2FAs,
		userAlerts: UserAlerts,
		userApps: apps,
		userEmails: UserEmails,
		userPhones: UserPhones,
		userAvatars: UserAvatars,
		userRoles: UserRoles,
		roleGroups: UserRoleGroups,
		userFacilities: AdvUserCommunities,
		userProfile: profile,
		userSecurityQuestions: UserSecurityQuestions,
		userSecurityAnswers: UserSecurityAnswers,
		userQAs: mergeSecurityQuestionsAndAnswers(
			UserSecurityQuestions,
			UserSecurityAnswers
		),
	};
};
// finds matching 'UserLogin' record by matching 'username'
const getMatchingLoginFromUsername = (username, userLogins = []) => {
	username = username?.toLowerCase();
	return userLogins.filter(
		(login) =>
			login?.LoginName?.toLowerCase() === username ||
			login?.LoginNameByEmail?.toLowerCase() === username
	);
};
// finds matching 'ADVUSER' record by matching 'username'
const getMatchingAdvUserFromUsername = (username, advUsers = []) => {
	return advUsers.filter(
		(user) => user?.strEmail?.toLowerCase() === username?.toLowerCase()
	);
};

// extracts user phoneNumber, ext & phoneID from formatted security info
const getUserPhoneFromInfo = (userInfo = {}) => {
	const { userPhones } = userInfo;
	if (!isEmptyArray(userPhones)) {
		const record = userPhones?.[0];
		return {
			phoneNumber: record?.Phone,
			phoneExt: record?.Extension,
			phoneID: record?.UserPhoneID,
		};
	} else {
		return {
			phoneNumber: "",
			phoneExt: "",
			phoneID: 0,
		};
	}
};

// USER TITLE & TYPE MATCHERS //

/**
 * Matches client-formatted job title from a job title ID.
 * @param {Number} id - User's job title ID (eg. 'UserTitleID')
 * @param {Array} allTitles - Array of user title records.
 * @returns {Object} - Returns job title record ('UserTitle' record).
 */
const matchJobTitleFromID = (id, allTitles = []) => {
	if (!id) return {};
	if (isEmptyArray(allTitles)) return {};

	return allTitles.reduce((match, titleRecord) => {
		const { titleID } = titleRecord;
		if (id === titleID) {
			match = { ...titleRecord };
			return match;
		}
		return match;
	}, {});
};

/**
 *
 * @param {String} strTitle - A string user title (eg. 'Accountant', 'Care Staff' etc.)
 * @param {Array} allTitles - An array of user titles records.
 */
const matchUserTitleFromStr = (strTitle, allTitles = []) => {
	if (isEmptyArray(allTitles)) return {};

	return allTitles.reduce((match, typeRecord) => {
		const { name, desc } = typeRecord;

		if (strTitle === name || strTitle === desc) {
			match = { ...typeRecord };
			return match;
		}
		return match;
	}, {});
};
/**
 *
 * @param {String} strType - A string user type (eg. 'Accountant', 'Care Staff' etc.)
 * @param {Array} allTypes - An array of user type records.
 */
const matchUserTypeFromStr = (strType, allTypes = []) => {
	if (isEmptyArray(allTypes)) return {};

	return allTypes.reduce((match, typeRecord) => {
		const { name, desc } = typeRecord;

		if (strType === desc || strType === name) {
			match = { ...typeRecord };
			return match;
		}
		return match;
	}, {});
};

/**
 *
 * @param {String} strTitle - A string user title (eg. 'Accountant', 'Care Staff' etc.)
 * @param {Array} allTitles - An array of user titles records.
 */
const matchTitleFromStr = (strTitle, allTitles = []) => {
	if (isEmptyArray(allTitles)) return {};

	return allTitles.reduce((match, typeRecord) => {
		const { Name, Description } = typeRecord;

		if (strTitle === Name || strTitle === Description) {
			match = { ...typeRecord };
			return match;
		}
		return match;
	}, {});
};
/**
 *
 * @param {String} strType - A string user type (eg. 'Accountant', 'Care Staff' etc.)
 * @param {Array} allTypes - An array of user type records.
 */
const matchTypeFromStr = (strType, allTypes = []) => {
	if (isEmptyArray(allTypes)) return {};

	return allTypes.reduce((match, typeRecord) => {
		const { Name } = typeRecord;

		if (strType === Name) {
			match = { ...typeRecord };
			return match;
		}
		return match;
	}, {});
};
/**
 * Finds matching record from a title ID.
 * @param {Number} titleID - A numeric title ID.
 * @param {Array} allTitles - An array of user title records.
 * @returns {Object} - Returns matching title record.
 */
const matchUserTitleFromID = (titleID, allTitles = []) => {
	if (!titleID) return {};
	if (isEmptyArray(allTitles)) return {};

	return allTitles.reduce((match, titleRecord) => {
		const { UserTitleID } = titleRecord;
		if (titleID === UserTitleID) {
			match = { ...titleRecord };
			return match;
		}
		return match;
	}, {});
};
/**
 * Finds matching record from a type ID.
 * @param {Number} typeID - A numeric type ID.
 * @param {Array} allTypes - An array of user type records.
 */
const matchUserTypeFromID = (typeID, allTypes = []) => {
	if (!typeID) return {};
	if (isEmptyArray(allTypes)) return {};

	return allTypes.reduce((match, titleRecord) => {
		const { UserTypeID } = titleRecord;
		if (typeID === UserTypeID) {
			match = { ...titleRecord };
			return match;
		}
		return match;
	}, {});
};

// SAVE USER 'TYPES' & 'TITLES' to LOCAL STORAGE //

const saveTypesAndTitlesToCache = (data = {}) => {
	const { userTypes, userTitles } = data;
	saveToStorage("UserTypes", userTypes);
	saveToStorage("UserTitles", userTitles);
};
// checks cache for titles list & matches title if available
const matchTitleFromCache = (id) => {
	const cachedTitles = getFromStorage("UserTitles");

	const titleRecord = matchJobTitleFromID(id, cachedTitles);
	return titleRecord;
};

// formats & extracts 'Description' for user titles
const formatAndSortTitles = (userTitles = []) => {
	if (isEmptyArray(userTitles)) return [];
	return userTitles.map(({ Description }) => Description);
};
// format user types
const formatAndSortTypes = (userTypes = []) => {
	if (isEmptyArray(userTypes)) return [];
	return userTypes.map(({ Name }) => Name);
};
// formats & extracts 'Description' for user titles
const formatAndSortUserTitles = (userTitles = []) => {
	if (isEmptyArray(userTitles)) return [];
	return userTitles.map(({ desc }) => desc);
};
// format user types - client format
const formatAndSortUserTypes = (userTypes = []) => {
	// if no user types in global state use default types.
	if (isEmptyArray(userTypes)) {
		const sorted = sortAlphaAscByKey("name", [...USER_TYPES_FORMATTED]);
		return sorted.map(({ name }) => name);
	} else {
		const sorted = sortAlphaAscByKey("name", userTypes);
		return sorted.map(({ name }) => name);
	}
};

/**
 * Processes & inits client-formatted user titles.
 * @returns {Array} - Returns an array of formatted objects.
 */
const processUserTitles = (allTitles = []) => {
	if (isEmptyArray(allTitles)) return [];
	return allTitles.reduce((userTitles, titleRecord) => {
		const { Name, Description, UserTitleID } = titleRecord;
		const newObj = { name: Name, desc: Description, titleID: UserTitleID };
		userTitles = [...userTitles, newObj];
		return userTitles;
	}, []);
};
/**
 * Processes & inits client-formatted user types.
 * @returns {Array} - Returns an array of formatted objects.
 */
const processUserTypes = (allTypes = []) => {
	if (isEmptyArray(allTypes)) return [];
	return allTypes.reduce((userTypes, typeRecord) => {
		const { Name, Description, UserTypeID } = typeRecord;
		const newObj = { name: Name, desc: Description, typeID: UserTypeID };
		userTypes = [...userTypes, newObj];
		return userTypes;
	}, []);
};
/**
 * Processes & inits client-formatted user types and titles.
 * @returns {Object} - Returns an object of 'userTypes' & 'userTitles'.
 * @property {Array} userTypes - Formatted user types.
 * @property {Array} userTitles - Formatted user titles.
 */
const processTypesAndTitles = (allTypes = [], allTitles = []) => {
	const types = processUserTypes(allTypes);
	const titles = processUserTitles(allTitles);

	return {
		userTypes: types,
		userTitles: titles,
	};
};

// user formatting
const formatAndSortUsers = (users = []) => {
	return users
		.sort((a, b) => {
			return a?.lastName?.localeCompare(b?.lastName);
		})
		.map(({ email, firstName, lastName, userID }) => {
			return `${firstName} ${lastName} - ${userID}`;
			// return `${email} - ${userID}`;
		});
};

/**
 * Extracts the userID from a formatted user string w/ the userID.
 * @param {String} userStr - A formatted user str (eg. 'Steven G. - xxxx-xxxx-xxxxx')
 * @returns {String} - Returns the userID as a string.
 */
const getUserIDFromStr = (userStr) => {
	const separator = /(\s-\s)/;
	const tail = userStr.split(separator)[2];

	return tail;
};

/**
 * Extracts an array of userIDs from formatted user strings & returns only the IDs.
 * @param {Array} userStrings - An array of formatted user strings w/ userIDs.
 * @returns {Array} - Returns an array of userIDs.
 */
const getAllUserIDsFromStr = (userStrings = []) => {
	if (isEmptyArray(userStrings)) return [];
	return userStrings.map((user) => {
		const userID = getUserIDFromStr(user);
		return userID;
	});
};

// FIRST LOGIN REQUIREMENTS //

// checks if a user has yet to add security questions/answers
const requiresSecurityQuestions = (userSecurity = {}) => {
	const logins = userSecurity?.UserLogins ?? userSecurity?.userLogins;
	const login = logins?.[0] ?? {};
	const supportsQAs = login?.IsPwdResetByQuestions ?? false;
	const questions =
		userSecurity?.UserSecurityQuestions ?? userSecurity?.userSecurityQuestions;
	const answers =
		userSecurity?.UserSecurityAnswers ?? userSecurity?.userSecurityAnswers;

	const hasNoQuestions = isEmptyArray(questions);
	const hasNoAnswers = isEmptyArray(answers);

	return supportsQAs && (hasNoAnswers || hasNoQuestions);
};

// user-request(s) utils
export {
	getAllUsers,
	getUsersByFacility,
	getUserProfile,
	getUserProfileByEmail,
	getUserPhone,
	getUserFileList,
	getUserFacilityList,
	getUserLoginByUsername,
	getUserLoginsByUsername,
	getUserLoginByUsernameType,
	// create user
	createNewUser,
	createAndSaveNewUser,
	generateAndCreateNewUserLogin,
	// create user via new infra
	createNewUserProfile,
	createNewUserLogin,
};

export {
	disableUser,
	deleteUser,
	deactivateUser,
	deleteUserLogin,
	virtualDeleteUser,
	virtualDeleteUserLogin,
	virtualDeleteUserProfile,
	saveUserChanges,
};

// user-related utils
export {
	hasMultiFacility,
	isEmailDeleted,
	processUserList,
	createNewUserMgmtObj,
};

// new user utils
export {
	updateUserModel,
	updateAdvUserModel,
	updateFacilityAccessModel,
	createFacilityAccessRecords,
	grantFacilityAccess,
	changeUserPassword,
	// new user updaters & utils
	updateLoginFacilities,
	getFacilityIDsFromSelections,
	getFacilityMapByName,
	createLoginFacilities,
};

// user sorting/filtering utils
export {
	findAdmins,
	findSuperUsers,
	findNonAdmins,
	findSuspendedUsers,
	sortUsersBy,
	sortUsersByAccess,
};
// misc user-model utils
export { updateUserPhoneModel, updateCustomUserModel };

// user-field utils
export { getUserName, getUserTitle, getUserEmail, getUserPhoneFromInfo };

// search user utils
export { searchForUser, searchForUserBy, searchModernForUser };

// user-type/title matchers
export {
	// user types
	matchTypeFromStr, // server format
	matchUserTypeFromStr, // client format
	matchUserTypeFromID,
	// user titles
	matchTitleFromStr, // server format
	matchUserTitleFromStr, // client format
	matchUserTitleFromID,
	matchJobTitleFromID, // client-formatted
	// server-formatted
	formatAndSortTitles,
	formatAndSortTypes,
	// client-formatted
	formatAndSortUserTitles,
	formatAndSortUserTypes,
	// both types & titles
	processUserTitles,
	processUserTypes,
	processTypesAndTitles,
	// save types & titles to cache
	saveTypesAndTitlesToCache,
	matchTitleFromCache,
};

// format users
export { formatAndSortUsers, getUserIDFromStr, getAllUserIDsFromStr };

export { processUserInfo, processUserMgmtList };

// profile & login related changes handler(s)
export { handleUserProfileChanges, handleUserNameChanges };

// username/email/title etc changes
export { changeUsername, changeUserTitle };

// user security questions/answers checker
export { requiresSecurityQuestions };
