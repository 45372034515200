import React, { useContext } from "react";
import styles from "../css/pages/DocsPage.module.scss";
import { GlobalStateContext } from "../state/GlobalStateContext";
import { PropTypes } from "prop-types";
import ViewContainer from "../components/app/ViewContainer";
import MaintenanceMode from "../components/app/MaintenanceMode";

// REQUIREMENTS:
// - User's Documents
// - Admins *can* have access to ALL facility files, if requested

const maintenanceDetails = {
	title: `Under-Construction`,
	desc: `Check back later!`,
};

const viewDetails = {
	title: `Your Documents`,
	desc: `Quick access & management for various user, resident & facility documents & storage.`,
};

const DocsPage = ({ history }) => {
	const { state: globalState, dispatch: dispatchToState } = useContext(
		GlobalStateContext
	);

	return (
		<>
			<ViewContainer title={viewDetails.title} desc={viewDetails.desc}>
				<div className={styles.DocsPage}>
					<MaintenanceMode
						title={maintenanceDetails.title}
						desc={maintenanceDetails.desc}
					/>
				</div>
			</ViewContainer>
		</>
	);
};

export default DocsPage;

DocsPage.defaultProps = {};

DocsPage.propTypes = {
	history: PropTypes.object,
};
