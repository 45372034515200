import React, { useRef } from "react";
import styles from "../../css/facility/FacilityAccessActions.module.scss";
import sprite from "../../assets/icons/settings.svg";
import { PropTypes } from "prop-types";
import {
	enableFeatureViaInternalList,
	featureFlags,
} from "../../helpers/utils_permissions";
import { isEmptyVal } from "../../helpers/utils_types";

const FacilityAccessActions = ({
	currentUser,
	searchVal,
	handleSearch,
	initCustomSearch,
	clearSearch,
}) => {
	const searchRef = useRef();

	// clears search & refocus input
	const enterHandler = (e) => {
		if (e.key === "Enter") {
			clearSearch();
			return searchRef?.current?.focus();
		} else {
			return;
		}
	};

	return (
		<div className={styles.FacilityAccessActions}>
			<div className={styles.FacilityAccessActions_search}>
				<svg className={styles.FacilityAccessActions_search_icon}>
					<use xlinkHref={`${sprite}#icon-search`}></use>
				</svg>
				<input
					type="text"
					name="searchUsers"
					id="searchUsers"
					value={searchVal}
					onChange={handleSearch}
					placeholder="Search by name, location, id..."
					className={styles.FacilityAccessActions_search_input}
					ref={searchRef}
				/>
				{!isEmptyVal(searchVal) && (
					<button
						className={styles.FacilityAccessActions_search_clear}
						onClick={clearSearch}
						onKeyDown={enterHandler}
						tabIndex={0}
					>
						<svg className={styles.FacilityAccessActions_search_clear_icon}>
							<use xlinkHref={`${sprite}#icon-clearclose`}></use>
						</svg>
					</button>
				)}
			</div>

			{/* {featureFlags?.facility?.enableCustomSearch && ( */}
			{enableFeatureViaInternalList(currentUser?.userID) && (
				<div className={styles.FacilityAccessActions_customSearch}>
					<button
						type="button"
						className={styles.FacilityAccessActions_customSearch_button}
						onClick={initCustomSearch}
					>
						Advanced Search
					</button>
				</div>
			)}
		</div>
	);
};

export default FacilityAccessActions;

FacilityAccessActions.defaultProps = {};

FacilityAccessActions.propTypes = {
	searchVal: PropTypes.string.isRequired,
	handleSearch: PropTypes.func.isRequired,
};
