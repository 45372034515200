import React from "react";
import styles from "../../css/tabs/FullPageTabButtons.module.scss";
import { PropTypes } from "prop-types";

// CONTAINER FOR EACH TAB BUTTON CONTROL.

const FullPageTabButtons = ({
	handleTabButton,
	activeIndex,
	disabledIndices = [],
	hiddenIndices = [],
	children,
	customStyles = {},
}) => {
	const withProps = React.Children.map(children, (child, i) =>
		React.cloneElement(child, {
			handleTabButton: handleTabButton,
			tabIndex: i,
			activeIndex: activeIndex,
			isDisabled: disabledIndices.includes(i),
			isHidden: hiddenIndices.includes(i),
		})
	);
	return (
		<section className={styles.FullPageTabButtons} style={customStyles}>
			{withProps}
		</section>
	);
};

export default FullPageTabButtons;

FullPageTabButtons.defaultProps = {
	disabledIndices: [],
	hiddenIndices: [],
};

FullPageTabButtons.propTypes = {
	children: PropTypes.any,
	activeIndex: PropTypes.number,
	disabledIndices: PropTypes.arrayOf(PropTypes.number),
	hiddenIndices: PropTypes.arrayOf(PropTypes.number),
	handleTabButton: PropTypes.func,
};
