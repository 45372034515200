import React from "react";
import styles from "../../css/facility/FacilityAccessList.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
import FacilityAccessEntry from "./FacilityAccessEntry";
import Spinner from "../shared/Spinner";

const FacilityAccessList = ({
	isLoading = false,
	accessList = [],
	removeEntry,
	currentUser,
}) => {
	return (
		<ul className={styles.FacilityAccessList}>
			{isLoading && (
				<div className={styles.FacilityAccessList_loading}>
					<Spinner />
				</div>
			)}
			{!isEmptyArray(accessList) &&
				accessList.map((entry, idx) => {
					return (
						<FacilityAccessEntry
							key={idx}
							removeFacilityAccess={() => removeEntry(entry)}
							accessRecord={entry}
							currentUser={currentUser}
						/>
					);
				})}
		</ul>
	);
};

export default FacilityAccessList;

FacilityAccessList.defaultProps = {
	accessList: [],
};

FacilityAccessList.propTypes = {
	accessList: PropTypes.array,
	removeEntry: PropTypes.func,
};
