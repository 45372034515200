import React from "react";
import styles from "../../css/loc/LOCApiValidatorSummary.module.scss";
import { PropTypes } from "prop-types";
import LOCApiValidatorResults from "./LOCApiValidatorResults";

// SHOWS VALIDATION FOR A SINGLE FLOOR UNIT W/ A TITLE HEADING

const LOCApiValidatorSummary = ({ unitType, validationSummary = {} }) => {
	const { errors } = validationSummary;

	return (
		<div className={styles.LOCApiValidatorSummary}>
			<div className={styles.LOCApiValidatorSummary_tableName}>{unitType}</div>
			<div className={styles.LOCApiValidatorSummary_details}>
				<LOCApiValidatorResults
					key={`RESULTS-${errors?.length}`}
					floorUnit={unitType}
					unitValidation={validationSummary}
				/>
			</div>
		</div>
	);
};

export default LOCApiValidatorSummary;

LOCApiValidatorSummary.defaultProps = {};

LOCApiValidatorSummary.propTypes = {
	unitType: PropTypes.string.isRequired,
	validationSummary: PropTypes.shape({
		hasGap: PropTypes.bool,
		hasOverlap: PropTypes.bool,
		isBaseValid: PropTypes.bool,
		isLastValid: PropTypes.bool,
		errors: PropTypes.arrayOf(PropTypes.string),
	}),
};
