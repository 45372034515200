import React, { useRef, useEffect } from "react";
import styles from "../../css/loc/LOCInfoModal.module.scss";
import sprite from "../../assets/icons/tables.svg";
import { PropTypes } from "prop-types";
import { useOutsideClick } from "../../utils/useOutsideClick";
import { useKeyboardShortcut } from "../../utils/useKeyboardShortcut";
import { useLockBodyScroll } from "../../utils/useLockBodyScroll";
// components
import LOCTip from "./LOCTip";

const tips = {
	edit: {
		title: "Edit A Care Level",
		desc: "Update or change any care levels values as desired.",
		steps: [
			"Click any cell to edit it's data",
			"When done editing click outside the cell to save it OR press Enter",
		],
	},
	addRow: {
		title: "Add New Care Level",
		desc: "Adding a new care level allows for custom values. You may have a maximum of 10 levels of care.",
		steps: ["Click 'Add Row'", "Click each cell to edit it's data"],
	},
	reset: {
		title: "Reset the Levels of Care",
		desc: "Resetting your 'Levels of Care' will reset all levels and their values to previous settings before changes were made.",
		steps: [
			"Click the 'Reset' button",
			"Care levels will be reset to your previous settings (ie. before changes were made).",
		],
	},
};
const { edit, addRow, reset } = tips;

const LOCInfoModal = ({ closeModal }) => {
	const modalRef = useRef();
	const { isOutside } = useOutsideClick(modalRef);
	const wasEscaped = useKeyboardShortcut(["Escape"]);
	useLockBodyScroll();

	// close modal
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (isOutside || wasEscaped) {
			closeModal();
		}

		return () => {
			isMounted = false;
		};
	}, [closeModal, wasEscaped, isOutside]);

	return (
		<aside aria-label="modal" className={styles.LOCInfoModal} ref={modalRef}>
			<div className={styles.LOCInfoModal_top}>
				<div className={styles.LOCInfoModal_top_title}>Help & Info</div>
				<svg className={styles.LOCInfoModal_top_icon} onClick={closeModal}>
					<use xlinkHref={`${sprite}#icon-clear`}></use>
				</svg>
			</div>
			<div className={styles.LOCInfoModal_main}>
				<LOCTip
					title={edit.title}
					desc={edit.desc}
					steps={edit.steps}
					gif={edit.gif}
				/>
				<LOCTip
					title={addRow.title}
					desc={addRow.desc}
					steps={addRow.steps}
					gif={addRow.gif}
				/>
				<LOCTip
					title={reset.title}
					desc={reset.desc}
					steps={reset.steps}
					gif={reset.gif}
				/>
				{/*  */}
			</div>
		</aside>
	);
};

export default LOCInfoModal;

LOCInfoModal.defaultProps = {};

LOCInfoModal.propTypes = {
	closeModal: PropTypes.func,
};
