import React, { useState } from "react";
import styles from "../../css/residents/ResidentSearchWindow.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import { blueGrey, red } from "../../helpers/utils_styles";
import { isEmptyVal } from "../../helpers/utils_types";
import { processUserList } from "../../helpers/utils_user";
import { formatAndSortUserFacilities } from "../../helpers/utils_facility";
import {
	processResidentsList,
	residentUnitTypes,
	searchForResidentBy,
} from "../../helpers/utils_residents";
// components
import FormSection from "../forms/FormSection";
import TextInput from "../shared/TextInput";
import CustomCheckbox from "../shared/CustomCheckbox";
import CustomDropdown from "../shared/CustomDropdown";
import ButtonSM from "../shared/ButtonSM";
import ResidentSearchResults from "./ResidentSearchResults";
import NumberInput from "../shared/NumberInput";

// REQUIREMENTS:
// - Search Options:
// 		- First Name
// 		- Last Name
// 		- Age
// 		- Unit Type
// 		- Resident ID
// 		- Facility (facility residents)

const customCSS = {
	checkbox: {
		marginBottom: ".6rem",
	},
	searchBtn: {
		padding: ".7rem 1.4rem",
		borderRadius: "5rem",
		backgroundColor: blueGrey[600],
		color: "#ffffff",
		fontSize: "1.4rem",
		fontWeight: "500",
	},
	cancelBtn: {
		padding: ".7rem 1.4rem",
		borderRadius: "5rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.4rem",
		fontWeight: "500",
		marginRight: "1rem",
	},
	clearBtn: {
		padding: ".7rem 1.4rem",
		borderRadius: "5rem",
		backgroundColor: red[600],
		color: "#ffffff",
		fontSize: "1.4rem",
		fontWeight: "500",
		marginRight: "auto",
	},
	dropdown: {
		width: "35rem",
	},
	unitType: {
		width: "35rem",
	},
};

const noTypeSelected = (vals = {}) => {
	const {
		// bools
		byAge,
		byFirstName,
		byLastName,
		byResidentID,
		byFacility,
		byUnitType,
		byRoomNum,
		// fields
		residentAgeSearch,
		residentRoomSearch,
		firstNameSearch,
		lastNameSearch,
		facilitySearch,
		residentIDSearch,
	} = vals;
	// no search type or search value supplied
	const hasNoType =
		!byAge &&
		!byFirstName &&
		!byLastName &&
		!byFacility &&
		!byResidentID &&
		!byRoomNum &&
		!byUnitType;
	const hasNoSearch =
		isEmptyVal(residentAgeSearch) &&
		isEmptyVal(firstNameSearch) &&
		isEmptyVal(lastNameSearch) &&
		isEmptyVal(facilitySearch) &&
		isEmptyVal(residentRoomSearch) &&
		isEmptyVal(residentIDSearch);

	return hasNoSearch && hasNoType;
};

const ResidentSearch = ({
	vals = {},
	handleChange,
	handleSettings,
	allFacilities = [],
}) => {
	// handles which to render
	const renderSearch = (vals) => {
		switch (true) {
			case vals?.byFirstName: {
				return (
					<div className={styles.ResidentSearch}>
						<TextInput
							key={`RESIDENT_SEARCH_BY_FIRST_NAME`}
							label="Search By First Name"
							name="firstNameSearch"
							id="firstNameSearch"
							placeholder="Enter User's First Name..."
							val={vals.firstNameSearch}
							handleChange={handleChange}
						/>
					</div>
				);
			}
			case vals?.byLastName: {
				return (
					<div className={styles.ResidentSearch}>
						<TextInput
							key={`RESIDENT_SEARCH_BY_LAST_NAME`}
							label="Search By Last Name"
							name="lastNameSearch"
							id="lastNameSearch"
							placeholder="Enter User's Last Name..."
							val={vals.lastNameSearch}
							handleChange={handleChange}
						/>
					</div>
				);
			}
			case vals?.byFacility: {
				return (
					<div className={styles.ResidentSearch}>
						<CustomDropdown
							key={`RESIDENT_SEARCH_BY_FACILITY`}
							name="facilitySearch"
							id="facilitySearch"
							label="Choose a facility"
							placeholder="Select a facility"
							selection={vals.facilitySearch}
							setSelection={handleSettings}
							options={[...formatAndSortUserFacilities(allFacilities)]}
							customStyles={customCSS.dropdown}
						/>
					</div>
				);
			}
			case vals?.byResidentID: {
				return (
					<div className={styles.ResidentSearch}>
						<TextInput
							key={`RESIDENT_SEARCH_BY_RESIDENTID`}
							label="Search By Resident ID"
							name="residentIDSearch"
							id="residentIDSearch"
							placeholder="Enter User's resident ID..."
							val={vals.residentIDSearch}
							handleChange={handleChange}
						/>
					</div>
				);
			}
			case vals?.byRoomNum: {
				return (
					<div className={styles.ResidentSearch}>
						<TextInput
							key={`RESIDENT_SEARCH_BY_ROOM`}
							label="Search By Room Number"
							name="residentRoomSearch"
							id="residentRoomSearch"
							placeholder="Enter User's Room Number..."
							val={vals.residentRoomSearch}
							handleChange={handleChange}
						/>
					</div>
				);
			}
			case vals?.byAge: {
				return (
					<div className={styles.ResidentSearch}>
						<NumberInput
							key={`RESIDENT_SEARCH_BY_AGE`}
							label="Search By Resident Age (numbers only)"
							name="residentAgeSearch"
							id="residentAgeSearch"
							placeholder="Enter Resident's Age..."
							val={vals.residentAgeSearch}
							handleChange={handleChange}
						/>
					</div>
				);
			}
			case vals?.byUnitType: {
				return (
					<div className={styles.ResidentSearch}>
						<CustomDropdown
							key={`RESIDENT_BY_UNIT_TYPE`}
							label="Search By Unit Type"
							name="residentUnitSearch"
							id="residentUnitSearch"
							placeholder="Select a unit type..."
							selection={vals.residentUnitSearch}
							options={[...residentUnitTypes]}
							setSelection={handleSettings}
							customStyles={customCSS.unitType}
						/>
					</div>
				);
			}
			default:
				return null;
		}
	};

	return <>{renderSearch(vals)}</>;
};

const ResidentSearchWindow = ({
	currentUser = {},
	facilityResidents = [],
	trackerAccess = [],
	legacyAccess = [],
	allFacilities = [],
	setSelectedResident,
	closeWindow,
}) => {
	const { formState, setFormState, handleCheckbox, handleChange, handleReset } =
		useForm({
			// by user field
			byAge: false,
			byFirstName: false,
			byLastName: false,
			byResidentID: false,
			byUnitType: false,
			byFacility: false,
			byRoomNum: false,
			// by user type
			byALAAdmin: false,
			byMedTechRestricted: false,
			bySuperUser: false,
			// searches
			firstNameSearch: "",
			lastNameSearch: "",
			residentIDSearch: "",
			residentAgeSearch: "",
			residentUnitSearch: "",
			residentRoomSearch: "",
		});
	const { values } = formState;
	const [searchResults, setSearchResults] = useState(null);
	const [isLoadingResults, setIsLoadingResults] = useState(false);

	const handleSettings = (name, val) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: val,
			},
		});
	};

	// fires off request w/ search values
	const runSearch = async (e) => {
		const { token } = currentUser;
		setIsLoadingResults(true);
		const results = await searchForResidentBy(token, allFacilities, values);

		if (results) {
			setIsLoadingResults(false);
			return setSearchResults(results);
		} else {
			return setIsLoadingResults(false);
		}
	};
	// clear form & close search window
	const cancelSearch = (e) => {
		handleReset(e);
		closeWindow();
	};
	// clear our form fields
	const clearSearch = (e) => {
		handleReset(e);
		setSearchResults([]);
	};

	return (
		<div className={styles.ResidentSearchWindow}>
			<header className={styles.ResidentSearchWindow_header}>
				<h2 className={styles.ResidentSearchWindow_header_title}>
					Search for a Resident By Name, Age, ID, Unit Type, Facility etc.
				</h2>
				<h6 className={styles.ResidentSearchWindow_header_desc}>
					Enter a custom search via the below settings and select one of the
					resulting residents that displays.
				</h6>
			</header>
			<section className={styles.ResidentSearchWindow_main}>
				<FormSection
					title="Search By:"
					icon="checkbox"
					hoverText="Search by a resident-related value."
				>
					<CustomCheckbox
						isDisabled={
							values?.byFirstName ||
							values?.byLastName ||
							values?.byFacility ||
							values?.byRoomNum ||
							values?.byUnitType ||
							values?.byResidentID
						}
						label="By Age"
						name="byAge"
						id="byAge"
						val={values?.byAge}
						handleCheckbox={handleCheckbox}
						customStyles={customCSS.checkbox}
					/>
					<CustomCheckbox
						isDisabled={
							values?.byAge ||
							values?.byLastName ||
							values?.byFacility ||
							values?.byRoomNum ||
							values?.byUnitType ||
							values?.byResidentID
						}
						label="By First Name"
						name="byFirstName"
						id="byFirstName"
						val={values?.byFirstName}
						handleCheckbox={handleCheckbox}
						customStyles={customCSS.checkbox}
					/>
					<CustomCheckbox
						isDisabled={
							values?.byAge ||
							values?.byFirstName ||
							values?.byFacility ||
							values?.byRoomNum ||
							values?.byUnitType ||
							values?.byResidentID
						}
						label="By Last Name"
						name="byLastName"
						id="byLastName"
						val={values?.byLastName}
						handleCheckbox={handleCheckbox}
						customStyles={customCSS.checkbox}
					/>
					<CustomCheckbox
						isDisabled={
							values?.byAge ||
							values?.byFirstName ||
							values?.byLastName ||
							values?.byRoomNum ||
							values?.byFacility ||
							values?.byUnitType
						}
						label="By ResidentID"
						name="byResidentID"
						id="byResidentID"
						val={values?.byResidentID}
						handleCheckbox={handleCheckbox}
						customStyles={customCSS.checkbox}
					/>
					<CustomCheckbox
						isDisabled={
							values?.byAge ||
							values?.byFirstName ||
							values?.byLastName ||
							values?.byRoomNum ||
							values?.byResidentID ||
							values?.byUnitType
						}
						label="By Facility"
						name="byFacility"
						id="byFacility"
						val={values?.byFacility}
						handleCheckbox={handleCheckbox}
						customStyles={customCSS.checkbox}
					/>
					<CustomCheckbox
						isDisabled={
							values?.byAge ||
							values?.byFirstName ||
							values?.byLastName ||
							values?.byResidentID ||
							values?.byRoomNum ||
							values?.byFacility
						}
						label="By Unit Type"
						name="byUnitType"
						id="byUnitType"
						val={values?.byUnitType}
						handleCheckbox={handleCheckbox}
						customStyles={customCSS.checkbox}
					/>
					<CustomCheckbox
						isDisabled={
							values?.byAge ||
							values?.byFirstName ||
							values?.byLastName ||
							values?.byResidentID ||
							values?.byUnitType ||
							values?.byFacility
						}
						label="By Room Number"
						name="byRoomNum"
						id="byRoomNum"
						val={values?.byRoomNum}
						handleCheckbox={handleCheckbox}
						customStyles={customCSS.checkbox}
					/>
					{/* CUSTOM SEARCH INPUT RENDERER */}
					<ResidentSearch
						vals={values}
						handleChange={handleChange}
						handleSettings={handleSettings}
						allFacilities={allFacilities}
					/>
				</FormSection>

				<div className={styles.ResidentSearchWindow_main_actions}>
					<ButtonSM customStyles={customCSS.clearBtn} handleClick={clearSearch}>
						<span>Clear Search</span>
					</ButtonSM>
					<ButtonSM
						customStyles={customCSS.cancelBtn}
						handleClick={cancelSearch}
					>
						<span>Cancel Search</span>
					</ButtonSM>
					<ButtonSM
						isDisabled={noTypeSelected(values)}
						customStyles={customCSS.searchBtn}
						handleClick={runSearch}
					>
						<svg className={styles.ResidentSearchWindow_main_actions_icon}>
							<use xlinkHref={`${sprite}#icon-search2`}></use>
						</svg>
						<span>Run Search</span>
					</ButtonSM>
				</div>
			</section>
			{/* SEARCH RESULTS */}
			<section className={styles.ResidentSearchWindow_results}>
				<ResidentSearchResults
					isLoading={isLoadingResults}
					searchResults={[...processResidentsList(searchResults)]}
					setSelectedResident={setSelectedResident}
				/>
			</section>
		</div>
	);
};

export default ResidentSearchWindow;

ResidentSearchWindow.defaultProps = {
	usersList: [],
	trackerAccess: [],
	legacyAccess: [],
};

ResidentSearchWindow.propTypes = {
	usersList: PropTypes.array,
	trackerAccess: PropTypes.array,
	legacyAccess: PropTypes.array,
};
