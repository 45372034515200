import React, { useState } from "react";
import styles from "../../css/userTypes/AddUserType.module.scss";
import { PropTypes } from "prop-types";
import TextInput from "../shared/TextInput";
import Textarea from "../shared/Textarea";
import ButtonSM from "../shared/ButtonSM";
import { purple, red } from "../../helpers/utils_styles";
import { isEmptyVal } from "../../helpers/utils_types";

const customCSS = {
	name: {
		backgroundColor: "#ffffff",
	},
	desc: {
		backgroundColor: "#ffffff",
	},
	save: {
		padding: "1rem 1.5rem",
		backgroundColor: red[600],
		color: "#ffffff",
		fontSize: "1.6rem",
		fontWeight: "600",
	},
	cancel: {
		padding: "1rem 1.5rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.6rem",
		fontWeight: "600",
		marginRight: "1rem",
	},
};

const enableBtn = (vals) => {
	const { newUserTypeName, newUserTypeDesc } = vals;

	return !isEmptyVal(newUserTypeName) && !isEmptyVal(newUserTypeDesc);
};

const AddUserType = ({
	vals,
	handleChange,
	handleReset,
	saveNewType,
	cancelNewType,
}) => {
	const [showAddNew, setShowAddNew] = useState(false);

	const toggleShowNew = () => {
		setShowAddNew(!showAddNew);
	};

	return (
		<div className={styles.AddUserType}>
			<div className={styles.AddUserType_toggle}>
				<button
					type="button"
					onClick={toggleShowNew}
					className={styles.AddUserType_toggle_btn}
				>
					Wanna add a new user type?
				</button>
			</div>

			{showAddNew && (
				<form className={styles.AddUserType_form}>
					<div className={styles.AddUserType_form_top}>Add New User Type</div>
					<div className={styles.AddUserType_form_field}>
						<TextInput
							name="newUserTypeName"
							id="newUserTypeName"
							label="Enter user type 'name' (max 30 chars)"
							val={vals?.newUserTypeName}
							handleChange={handleChange}
							customStyles={customCSS.name}
						/>
					</div>
					<div className={styles.AddUserType_form_field}>
						<Textarea
							name="newUserTypeDesc"
							id="newUserTypeDesc"
							label="Description of user type"
							val={vals?.newUserTypeDesc}
							handleChange={handleChange}
							placeholder="Enter a brief description..."
							enableCharCount={true}
							maxChar={50}
							customStyles={customCSS.desc}
						/>
					</div>
					<div className={styles.AddUserType_form_actions}>
						<ButtonSM
							customStyles={customCSS.cancel}
							handleClick={cancelNewType}
						>
							Cancel
						</ButtonSM>
						<ButtonSM
							isDisabled={!enableBtn(vals)}
							customStyles={customCSS.save}
							handleClick={saveNewType}
						>
							Add New
						</ButtonSM>
					</div>
				</form>
			)}
		</div>
	);
};

export default AddUserType;

AddUserType.defaultProps = {};

AddUserType.propTypes = {};
