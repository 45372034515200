import React from "react";
import styles from "../../css/facility/FacilityAccessTableBody.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
import { isLocalhost } from "../../helpers/utils_env";
// components
import FacilityAccessRow from "./FacilityAccessRow";

// normalises access list
const normaliseIDs = (ids = []) => {
	return ids.map((id) => id.toLowerCase());
};

const checkAccess = (facilityID, accessList = []) => {
	const rawID = facilityID;
	const normalID = facilityID.toLowerCase();
	// list(s)
	const rawList = accessList;
	const normalList = normaliseIDs(accessList);
	// check in raw list & normalised list
	const existsInRaw = rawList.includes(rawID);
	const existsInNormalised = normalList.includes(normalID);

	return existsInRaw || existsInNormalised;
};

const FacilityAccessTableBody = ({
	currentUser = {},
	accessList = [],
	trackerAccessList = [],
	emarAccessList = [],
	ePayAccessList = [],
	allFacilities = [],
	parentsMap = {},
	updateAccessList,
	dispatchAlert,
}) => {
	return (
		<div className={styles.FacilityAccessTableBody}>
			{!isEmptyArray(allFacilities) &&
				allFacilities.map((facility, idx) => (
					<FacilityAccessRow
						parentsMap={parentsMap}
						currentUser={currentUser}
						key={facility.communityName + idx}
						facility={facility}
						hasAccess={accessList.includes(facility?.facilityID)}
						hasTrackerAccess={checkAccess(
							facility?.facilityID,
							trackerAccessList
						)}
						hasEmarAccess={checkAccess(facility?.facilityID, emarAccessList)}
						hasEPayAccess={checkAccess(facility?.facilityID, ePayAccessList)}
						updateAccessList={updateAccessList}
						dispatchAlert={dispatchAlert}
						enableFacilityType={isLocalhost()}
					/>
				))}
		</div>
	);
};

export default FacilityAccessTableBody;

FacilityAccessTableBody.defaultProps = {
	accessList: [],
	allFacilities: [],
};

FacilityAccessTableBody.propTypes = {
	accessList: PropTypes.arrayOf(PropTypes.string).isRequired,
	allFacilities: PropTypes.arrayOf(PropTypes.object).isRequired,
	updateAccessList: PropTypes.func.isRequired,
};
