import React from "react";
import styles from "../../css/loc/LOCLevelCell.module.scss";
import { PropTypes } from "prop-types";
import LOCTableCell from "./LOCTableCell";

// REPRESENTS 'LevelNumber' column

const LOCLevelCell = ({
	rowData,
	updateTableData,
	rowIdx,
	cellIdx,
	isEditable = false,
	allowedChars = /.*/gim,
}) => {
	return (
		<>
			<LOCTableCell
				key={`Row${rowIdx}--Cell-${cellIdx}`}
				disableEdit={!isEditable}
				rowIdx={rowIdx}
				cellIdx={cellIdx}
				// cellData={rowData["LevelNumber"]}
				cellData={rowData} // mutated in parent to show correct 'LevelNumber'
				cellKey="LevelNumber"
				updateTableData={updateTableData}
				allowedChars={allowedChars}
				inputMode="numeric"
			/>
		</>
	);
};

export default LOCLevelCell;

LOCLevelCell.defaultProps = {};

LOCLevelCell.propTypes = {};
