import React from "react";
import styles from "../../css/forms/Step.module.scss";
import sprite from "../../assets/icons/multistep.svg";
import { PropTypes } from "prop-types";
import SkipButton from "./SkipButton";

// 👇 WHERE CONTENT GOES 👇

const customCSS = {
	prev: {
		marginRight: ".5rem",
	},
	prevBtn: {
		marginRight: "auto",
	},
	next: {
		marginLeft: ".5rem",
	},
	nextBtn: {
		marginLeft: "auto",
	},
};

const iconsMap = {
	alarm: "access_alarmalarm",
	alarmClock: "alarm_on",
	alertDark: "notifications",
	alertLight: "notifications_none",
	alertOff: "notifications_off",
	alertShake: "notifications_active",
	arrowLeft: "arrow-left",
	arrowRight: "arrow-right",
	arrowUp: "arrow-up",
	arrowDown: "arrow-down",
	atSign: "email",
	atSign2: "alternate_email",
	caretUp: "keyboard_arrow_up",
	caretDown: "keyboard_arrow_down",
	caretLeft: "keyboard_arrow_left",
	caretRight: "keyboard_arrow_right",
	check: "check1",
	checkmark: "checkmark",
	checkCircle: "check_circle1",
	checkmarkOutline: "checkmark-outline",
	checkmarkCircleLight: "check_circle_outline",
	doneOutline: "done_outline",
	done: "done",
	doneAll: "done_all",
	close: "close",
	clock: "access_timequery_builderschedule1",
	cloudCheckmark: "cloud_done",
	cloudDownload: "cloud_download1",
	cloudUpload: "cloud_uploadbackup1",
	dotsHorizontal: "keyboard_control",
	dotsVertical: "more_vert",
	download: "file_downloadget_app1",
	upload: "file_upload1",
	envelope: "envelope",
	envelopeOpen: "drafts",
	exclamationOutline: "exclamation-outline",
	exclamationSolid: "exclamation-solid",
	face: "face",
	heartFilled: "favorite",
	heartEmpty: "favorite_outline",
	flag: "flagassistant_photo",
	flagOutline: "outlined_flag",
	infoOutline: "information-outline",
	infoSolid: "information-solid",
	save: "save-disk",
	save1: "save1",
	search: "search",
	tuner: "tuning",
	userMulti: "people_alt",
	user: "user",
	userAdd: "user-add",
	userCircle: "account_circle",
	userCheckmark: "how_to_reg",
	userLocation: "location_history",
	userGroup: "grouppeople",
	userGroupAdd: "group_add",
	userGroupOutline: "people_outline",
	userDark: "person",
	userDarkAdd: "person_add",
	userLight: "person_outlineperm_identity",
	userGroupDark: "supervisor_account",
	show: "view-show",
	hide: "view-hide",
	reply: "reply",
	forward: "forward",
	helpCircle: "help-with-circle1",
	imageOutline: "image",
	imageDark: "image-inverted",
	images: "images",
	infoCircle: "info-with-circle",
	info: "info",
	key: "key",
	lightBulbOutline: "light-bulb",
	lightBulbDark: "lightbulb",
	lockOpen: "lock-open",
	lockClosed: "lock",
	lockOpenLight: "lock_open",
	lockClosedLight: "lock_outline",
	settings: "settings11",
	mailDark: "mail",
	mailLight: "mail_outline",
	minus: "minus",
	plus: "plus",
	print: "print",
	starOutline: "star-outlined",
	star: "start",
	stopwatch: "stopwatch1",
	tools: "tools",
	idCard: "v-card",
	idCardEmail: "contact_mail",
	checkWithCircle: "check-circle",
	errorDark: "error",
	errorOutline: "error_outline1",
	warning: "warningreport_problem1",
	notAllowed: "not_interesteddo_not_disturb1",
	signature: "gesture1",
	securityBadge: "security",
	camera: "camera_altphoto_cameralocal_see",
	palette: "color_lenspalette",
	colorPicker: "colorize",
	portrait: "portrait",
	tuner2: "tune1",
	badgeCheckmark: "beenhere",
	tag: "local_offer",
	optionsUnfold: "unfold_less",
	optionsFold: "unfold_more",
	calendarCheck: "event_available",
	calendarBusy: "event_busy1",
	calendarNotes: "event_note1",
	worldLock: "vpn_lock",
	world: "public",
	wrench: "build",
	clockRewind: "historyrestore1",
	clockRepeat: "update1",
	undo: "undo2",
	redo: "redo2",
	vpnLock: "vpn_lock",
	facility: "how_to_reg",
};

const IconBadge = ({ icon }) => {
	return (
		<div className={styles.IconBadge}>
			<svg className={styles.IconBadge_icon}>
				<use xlinkHref={`${sprite}#icon-${iconsMap[icon]}`}></use>
			</svg>
		</div>
	);
};

const StepButton = ({
	isDisabled = false,
	children,
	handleClick,
	customStyles = {},
}) => {
	return (
		<button
			type="button"
			disabled={isDisabled}
			className={styles.StepButton}
			onClick={handleClick}
			style={customStyles}
		>
			{children}
		</button>
	);
};

/**
 * Initial 'stepDetails' data-structure:
 * - 'icon': name from 'iconsMap'
 * - 'title': string title to use
 * - 'desc': string description to use
 */
const initialDetails = {
	icon: "",
	title: "",
	desc: "",
};

const Step = ({
	currentStep,
	stepDetails = { ...initialDetails },
	step,
	goToStep,
	goToNext,
	goToPrevious,
	enableNext = false,
	enablePrev = false,
	hideNext = false,
	hidePrev = false,
	hideSkip = true,
	handleSkipStep,
	disabledSteps = [],
	children,
}) => {
	const { icon, title, desc } = stepDetails;

	const withProps = React.Children.map(children, (child, i) => {
		return React.cloneElement(child, {
			currentStep: currentStep,
			goToNext: goToNext,
			goToPrevious: goToPrevious,
			goToStep: goToStep,
			disabledSteps: disabledSteps,
		});
	});

	// hide step if 'disabled' or not the current step
	if (currentStep !== step || disabledSteps.includes(step)) {
		return null;
	}
	return (
		<div className={styles.Step}>
			<div className={styles.Step_icon}>
				<IconBadge icon={icon} />
			</div>
			<header className={styles.Step_header}>
				<h4 className={styles.Step_header_title}>{title}</h4>
				<p className={styles.Step_header_desc}>{desc}</p>
			</header>
			{/* STEP'S CUSTOM CONTENT */}
			{/* <div className={styles.Step_inner}>{children}</div> */}
			<div className={styles.Step_inner}>{withProps}</div>
			{!hideSkip && (
				<div className={styles.Step_skip}>
					<SkipButton handleSkip={() => handleSkipStep(step)}>
						Skip, do this later
					</SkipButton>
				</div>
			)}
			{/* STEP NAVIGATON */}
			<section className={styles.Step_buttons}>
				{/* 'PREVIOUS' BUTTON */}
				{!hidePrev && (
					<StepButton
						isDisabled={!enablePrev}
						handleClick={goToPrevious}
						customStyles={customCSS.prevBtn}
					>
						<svg className={styles.Step_buttons_icon} style={customCSS.prev}>
							<use xlinkHref={`${sprite}#icon-arrow-left`}></use>
						</svg>
						<span>Back</span>
					</StepButton>
				)}
				{!hideNext && (
					<StepButton
						isDisabled={!enableNext}
						handleClick={goToNext}
						customStyles={customCSS.nextBtn}
					>
						<span>Next</span>
						<svg className={styles.Step_buttons_icon} style={customCSS.next}>
							<use xlinkHref={`${sprite}#icon-arrow-right`}></use>
						</svg>
					</StepButton>
				)}
			</section>
		</div>
	);
};

Step.defaultProps = {
	enableNext: false,
	enablePrev: false,
	hideNext: false,
	hidePrev: false,
	isDisabled: false,
};

Step.propTypes = {
	currentStep: PropTypes.number,
	step: PropTypes.number,
	goToNext: PropTypes.func,
	goToPrevious: PropTypes.func,
	stepDetails: PropTypes.object,
	enableNext: PropTypes.bool,
	enablePrev: PropTypes.bool,
	hideNext: PropTypes.bool,
	hidePrev: PropTypes.bool,
	isDisabled: PropTypes.bool,
};

export default Step;

export { IconBadge, StepButton, iconsMap, customCSS };
