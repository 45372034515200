import React from "react";
import styles from "../../css/app/AnchorLink.module.scss";
import { PropTypes } from "prop-types";

const options = {
	prefixes: {
		email: `mailto:`,
		phone: `tel:`,
	},
};

const AnchorLink = ({ href, inNewTab = true, children, customStyles = {} }) => {
	return (
		<a
			href={href}
			target={inNewTab ? "_blank" : ""}
			rel={inNewTab ? "noopener noreferrer" : ""}
			className={styles.AnchorLink}
			style={customStyles}
			tabIndex={0}
		>
			{children}
		</a>
	);
};

export default AnchorLink;

AnchorLink.defaultProps = {};

AnchorLink.propTypes = {
	href: PropTypes.string,
};
