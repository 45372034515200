import React, { useState } from "react";
import styles from "../../css/otp/OTPPreviewer.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import HiddenPassword from "./HiddenPassword";

const show = "show";
const hide = "hide";

const OTPPreviewer = ({ otp, copyOTP, wasCopied = false }) => {
	const [showOTP, setShowOTP] = useState(false);

	return (
		<div className={styles.OTPPreviewer}>
			<div className={styles.OTPPreviewer_label}>OTP:</div>
			<div className={styles.OTPPreviewer_value}>
				<div className={styles.OTPPreviewer_value_hidden}>
					{!showOTP && <HiddenPassword value={otp} />}
					{showOTP && (
						<span className={styles.OTPPreviewer_value_visible}>{otp}</span>
					)}
				</div>
				<div
					className={styles.OTPPreviewer_value_toggle}
					onClick={() => setShowOTP(!showOTP)}
				>
					<svg className={styles.OTPPreviewer_value_toggle_icon}>
						<use
							xlinkHref={`${sprite}#icon-view-${showOTP ? show : hide}`}
						></use>
					</svg>
					<span className={styles.OTPPreviewer_value_toggle_label}>
						{showOTP ? "Hide" : "Show"} OTP
					</span>
				</div>
			</div>
			<div className={styles.OTPPreviewer_copy} onClick={copyOTP}>
				<svg className={styles.OTPPreviewer_copy_icon}>
					<use xlinkHref={`${sprite}#icon-content_copy`}></use>
				</svg>
				<span className={styles.OTPPreviewer_copy_label}>
					{wasCopied ? "Copied!" : "Copy OTP"}
				</span>
			</div>
		</div>
	);
};

export default OTPPreviewer;

OTPPreviewer.defaultProps = {};

OTPPreviewer.propTypes = {
	otp: PropTypes.string,
	copyOTP: PropTypes.func,
};
