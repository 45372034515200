import React, { useState, useEffect, useRef } from "react";
import styles from "../../css/facility/FacilityAccessController.module.scss";
import sprite from "../../assets/icons/carets-arrows.svg";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";
import { formatAndSortUserFacilities } from "../../helpers/utils_facility";
// components
import FacilityAccessDropdown from "./FacilityAccessDropdown";

// FACILITY ACCESS SELECTOR (mimics the <MultiSelect/> component)

const advancedSearch = (val, options) => {
	val = val?.toLowerCase();

	return options.filter((option) => {
		if (
			option?.toLowerCase()?.startsWith(val) ||
			option?.toLowerCase()?.includes(val)
		) {
			// matches
			return option;
		} else {
			return null;
		}
	});
};

const getPlaceholder = (placeholder, selections = [], options = []) => {
	if (!placeholder) {
		return `${selections?.length ?? 0} of ${options?.length} selected`;
	} else {
		return placeholder;
	}
};

const FacilityAccessController = ({
	handleMultiSelect,
	placeholder,
	initialSelections = [], // array of facility name strings
	options = [], // array of facility strings
	disabledOptions = [], // array of facility name strings
	customStyles = {},
}) => {
	// array of 'communityName's that have been selected
	const [selections, setSelections] = useState([...initialSelections]);
	const [showOptions, setShowOptions] = useState(false);
	// array of 'communityName's - raw options
	const [clonedOptions, setClonedOptions] = useState([...options]);
	const [searchVal, setSearchVal] = useState("");
	// show current access
	const [showCurrentAccess, setShowCurrentAccess] = useState(false);

	const handleSelect = (option) => {
		if (selections.includes(option)) {
			return setSelections([...selections.filter((x) => x !== option)]);
		}
		return setSelections([...selections, option]);
	};

	const handleSelectAll = () => {
		if (selections.length === options.length) {
			return setSelections([]);
		}
		return setSelections([...options]);
	};

	const handleSearch = (e) => {
		const { value } = e.target;
		setSearchVal(value);
		return searchOptions(value);
	};

	const toggleCurrentAccess = (e) => {
		const { checked } = e.target;
		if (checked) {
			// enable filter
			setShowCurrentAccess(checked);
			return setClonedOptions([...selections]);
		} else {
			// disable filter, show all
			setShowCurrentAccess(checked);
			return setClonedOptions([...options]);
		}
	};

	const searchOptions = (val) => {
		if (isEmptyVal(val)) {
			return setClonedOptions([...options]);
		}
		return setClonedOptions([...advancedSearch(val, options)]);
	};

	const clearSelections = () => {
		setSelections([]);
		handleMultiSelect([]);
	};

	const saveSelections = () => {
		setShowOptions(false);
	};

	const clearSearch = () => {
		setSearchVal("");
		searchOptions("");
	};

	// used to send "selections" to the parent via "handleMultiSelect"
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		handleMultiSelect(selections);
		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selections]);

	return (
		<div className={styles.FacilityAccessController}>
			<div
				className={styles.FacilityAccessController_input}
				onClick={showOptions ? null : () => setShowOptions(true)}
				style={customStyles}
			>
				<span className={styles.FacilityAccessController_input_placeholder}>
					{getPlaceholder(placeholder, selections, options)}
				</span>
				<svg
					className={styles.FacilityAccessController_input_downCaret}
					onClick={() => setShowOptions(true)}
				>
					<use xlinkHref={`${sprite}#icon-caret-down`}></use>
				</svg>
			</div>
			{showOptions && (
				<FacilityAccessDropdown
					searchVal={searchVal}
					disabledOptions={disabledOptions}
					options={clonedOptions}
					selections={selections}
					isAllSelected={selections.length === options.length}
					handleSelect={handleSelect}
					handleSelectAll={handleSelectAll}
					handleSearch={handleSearch}
					saveSelections={saveSelections}
					clearSelections={clearSelections}
					clearSearch={clearSearch}
					closeMenuOptions={() => setShowOptions(false)}
					toggleCurrentAccess={toggleCurrentAccess}
					showCurrentAccess={showCurrentAccess}
				/>
			)}
		</div>
	);
};

export default FacilityAccessController;

FacilityAccessController.defaultProps = {
	options: [],
	disabledOptions: [],
	initialSelections: [],
	customStyles: {},
};

FacilityAccessController.propTypes = {
	options: PropTypes.arrayOf(PropTypes.string),
	initialSelections: PropTypes.arrayOf(PropTypes.string),
	disabledOptions: PropTypes.arrayOf(PropTypes.string),
	customStyles: PropTypes.object,
	handleMultiSelect: PropTypes.func,
};
