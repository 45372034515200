import { useState, useEffect } from "react";
import {
	setParamsMany,
	removeParamsMany,
	clearAllParams,
	extractAllParams,
	hasParams,
} from "../helpers/utils_params";
import { deleteKeysMany } from "../helpers/utils_processing";

/**
 * Manage updates, deletions and removals of any and all query params.
 * @param {URL} url - A URL, typically 'window.location'. This does NOT leverage 'ReactRouterDOM', but rather the browser defaults.
 */
const useQueryParams = (url) => {
	const [queryParams, setQueryParams] = useState({
		...extractAllParams(url),
	});

	// extracts current params, merges w/ new, sets state
	const setParams = (url, params = {}) => {
		const allParams = {
			...extractAllParams(url),
			...params,
		};
		setQueryParams(allParams);
		return setParamsMany(url, params);
	};

	// extracts params, removes by 'keys', sets state
	const removeParams = (url, keys = []) => {
		const allParams = extractAllParams(url);
		const updatedParams = deleteKeysMany(allParams, keys);
		setQueryParams(updatedParams);

		return removeParamsMany(url, keys);
	};

	// removes all, sets state
	const clearParams = (url) => {
		setQueryParams({});
		return clearAllParams(url);
	};

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (hasParams(url)) {
			console.log("hasParams(url): ", hasParams(url));
		}
		return () => {
			isMounted = false;
		};
	}, [url]);

	return {
		queryParams,
		// utils
		setParams,
		removeParams,
		clearParams,
	};
};

export { useQueryParams };
