import React from "react";
import styles from "../../css/user/UserSecurityResetSummary.module.scss";
import sprite from "../../assets/icons/multistep.svg";
import { PropTypes } from "prop-types";

const ResetMethod = ({ label, isEnabled = false }) => {
	const on = "check-circle";
	const off = "clearclose1";

	return (
		<div className={styles.ResetMethod}>
			<div className={styles.ResetMethod_status}>
				{isEnabled && (
					<svg className={styles.ResetMethod_status_iconOn}>
						<use xlinkHref={`${sprite}#icon-${on}`}></use>
					</svg>
				)}
				{!isEnabled && (
					<svg className={styles.ResetMethod_status_iconOff}>
						<use xlinkHref={`${sprite}#icon-${off}`}></use>
					</svg>
				)}
				<span className={styles.ResetMethod_status_name}>{label}</span>
			</div>
		</div>
	);
};

const UserSecurityResetSummary = ({ vals = {} }) => {
	const { isPwdResetByAdmin, isPwdResetByEmail, isPwdResetByQuestions } = vals;

	return (
		<div className={styles.UserSecurityResetSummary}>
			<div className={styles.UserSecurityResetSummary_subtitle}>
				<div className={styles.UserSecurityResetSummary_subtitle_text}>
					Password Reset Methods:
				</div>
			</div>
			<div className={styles.UserSecurityResetSummary_main}>
				<div className={styles.UserSecurityResetSummary_main_methods}>
					<ResetMethod label="Reset By Admin" isEnabled={isPwdResetByAdmin} />
					<ResetMethod label="Reset By Email" isEnabled={isPwdResetByEmail} />
					<ResetMethod
						label="Reset By Security Questions"
						isEnabled={isPwdResetByQuestions}
					/>
				</div>
			</div>
		</div>
	);
};

export default UserSecurityResetSummary;

UserSecurityResetSummary.defaultProps = {};

UserSecurityResetSummary.propTypes = {};
