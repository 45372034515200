import React from "react";
import styles from "../../css/user/EditResetMethodPreference.module.scss";
import { PropTypes } from "prop-types";
import { formatResetMethods, resetMethodTypes } from "../../helpers/utils_otp";
// components
import Dropdown from "../shared/Dropdown";

const customCSS = {
	dropdown: {
		width: "15rem",
		height: "2.5rem",
	},
};

// REQUIREMENTS:
// - Reset Method Preference (requirements):
//    - 'ADMIN': no requirements, applicable to ALL users
//    - 'QUESTIONS': MUST have populated security questions/answers
//    - 'EMAIL': MUST have valid, working email address to receive resets at

// grabs 'Reset Methods' settings for the current user
const getActiveResetMethods = (currentUser) => {
	// DON'T DESTRUCTURE HERE!!!
	// - Somehow, this fn() is called before 'Logout' is called & since the user object is cleared, it throws an error
	const security = currentUser?.security ?? {};
	const userLogins = security?.userLogins ?? [];
	const loginRecord = userLogins?.[0] ?? {};
	const {
		IsPwdResetByAdmin = true,
		IsPwdResetByEmail = false,
		IsPwdResetByQuestions = true,
	} = loginRecord;

	return {
		isPwdResetByAdmin: IsPwdResetByAdmin,
		isPwdResetByEmail: IsPwdResetByEmail,
		isPwdResetByQuestions: IsPwdResetByQuestions,
	};
};

// returns status of reset method by name - REDUNDANT BEHAVIOR
const getResetMethodStatus = (method, currentUser) => {
	switch (method) {
		case "Admin": {
			const { isPwdResetByAdmin = true } = getActiveResetMethods(currentUser);
			return isPwdResetByAdmin;
		}
		case "Email": {
			const { isPwdResetByEmail = false } = getActiveResetMethods(currentUser);
			return isPwdResetByEmail;
		}
		case "Questions": {
			const { isPwdResetByQuestions = true } =
				getActiveResetMethods(currentUser);
			return isPwdResetByQuestions;
		}

		default:
			return false;
	}
};

const getDisabledOptions = (currentUser) => {
	const {
		isPwdResetByAdmin: byAdmin,
		isPwdResetByEmail: byEmail,
		isPwdResetByQuestions: byQuestions,
	} = getActiveResetMethods(currentUser);
	const options = [];

	if (!byAdmin) {
		options.push("Admin");
	}
	if (!byEmail) {
		options.push("Email");
	}
	if (!byQuestions) {
		options.push("Questions");
	}

	return options;
};

const EditResetMethodPreference = ({ currentUser, vals, handlePreference }) => {
	return (
		<div className={styles.EditResetMethodPreference}>
			<div className={styles.EditResetMethodPreference_preference}>
				<div className={styles.EditResetMethodPreference_preference_label}>
					Reset Preference
				</div>
				<div className={styles.EditResetMethodPreference_preference_selection}>
					<Dropdown
						key={`PREFERRED-RESET-METHOD`}
						name="preferredMethod"
						id="preferredMethod"
						label=""
						selection={vals.preferredMethod}
						setSelection={handlePreference}
						options={[...formatResetMethods(resetMethodTypes)]}
						disabledOptions={[...getDisabledOptions(currentUser)]}
						customStyles={customCSS.dropdown}
					/>
				</div>
			</div>
		</div>
	);
};

export default EditResetMethodPreference;

EditResetMethodPreference.defaultProps = {};

EditResetMethodPreference.propTypes = {
	currentUser: PropTypes.object,
	vals: PropTypes.object,
	handlePreference: PropTypes.func,
};
