import React, { useState } from "react";
import styles from "../../css/user/UserFacilitySummary.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";

const FacilityEntry = ({ facilityEntry = {} }) => {
	return <li className={styles.FacilityEntry}>{facilityEntry}</li>;
};

const UserFacilitySummary = ({ facilityAccessList = [] }) => {
	const [showAccessList, setShowAccessList] = useState(false);

	const toggleShowList = () => {
		setShowAccessList(!showAccessList);
	};

	return (
		<article className={styles.UserFacilitySummary}>
			<div className={styles.UserFacilitySummary_header}>
				<div className={styles.UserFacilitySummary_header_title}>
					Facility Access Summary
				</div>
			</div>
			<div className={styles.UserFacilitySummary_main}>
				<div className={styles.UserFacilitySummary_main_count}>
					<div className={styles.UserFacilitySummary_main_count_value}>
						This user has access to <b>{facilityAccessList?.length ?? 0}</b>{" "}
						facilities.
					</div>
				</div>
				<div className={styles.UserFacilitySummary_main_details}>
					<button
						type="button"
						onClick={toggleShowList}
						className={styles.UserFacilitySummary_main_details_view}
					>
						{showAccessList ? "Hide" : "Show"} Granted Facilities
					</button>
					{showAccessList && (
						<ul className={styles.UserFacilitySummary_main_details_list}>
							{isEmptyArray(facilityAccessList) && (
								<div className={styles.UserFacilitySummary_noList}>
									This user has not been granted facility access yet.
								</div>
							)}
							{!isEmptyArray(facilityAccessList) &&
								facilityAccessList.map((entry, idx) => (
									<FacilityEntry
										key={`${entry}--${idx}`}
										facilityEntry={entry}
									/>
								))}
						</ul>
					)}
				</div>
			</div>
		</article>
	);
};

export default UserFacilitySummary;

UserFacilitySummary.defaultProps = {
	facilityAccessList: [],
};

UserFacilitySummary.propTypes = {
	facilityAccessList: PropTypes.array,
};
