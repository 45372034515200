const endpoints = {
	auth: {
		login: "Security/Login",
		logout: "Security/Logout",
		loginStatus: "Security/SecurityTokenValid",
		validateToken: "Security/SecurityTokenValid",
		refreshToken: "Security/SecurityTokenRefresh",
		sessionDetails: "Security/GetSecurityTokenDetail",
		userAccessByID: "Security/GetUserAccess",
		userAccessByEmail: "Security/GetUserAccessByEmail",
		count: {
			logins: "ALADVSystem/CountLoginLog",
		},
	},
	generic: {
		count: "Data/Count",
		count2: "Data/Count2",
		get: "Data/Get",
		get2: "Data/Get2",
		delete: "Data/Delete",
		execute: "Data/Execute",
		insert: "Data/Insert",
		save: "Data/Save",
		update: "Data/Update",
	},
	uploads: {
		upload: "Upload/PutFile",
		uploadMany: "Upload/PutFileMany",
		saveFileRegistry: "Upload/SaveFileRegistry",
		saveFileRegistryMany: "Upload/SaveFileRegistryMany",
	},
	downloads: {
		getFile: "Download/GetFile",
		getFileMany: "Download/GetFileMany",
		getFileRegistry: {
			byUser: "Download/GetFileRegistryByUser",
			byResident: "Download/GetFileRegistryByResident",
			byFacility: "Download/GetFileRegistryByFacility",
			byMeta: "Download/GetFileRegistryByMeta",
		},
	},
	residentData: {
		getPhotos: "Advantage/GetResidentPhotos",
		getSummary: "Advantage/GetSummary",
		getAssessment: "Resident/GetResidentAssessment",
		getContacts: "Advantage/GetContacts",
		getProfile: "Resident/GetResidentProfile",
		getLOA: "Advantage/GetLeaveOfAbsence",
		getMeds: "Advantage/GetMedications",
		getInventory: "Advantage/GetResidentInventory",
		getResidentBM: "Advantage/GetResidentBowelMovements",
		getResidentWeight: "Advantage/GetResidentWeight",
		forTracker: {
			byDay: "Resident/GetResidentDayForAdvantageTracker",
			byWeek: "Resident/GetResidentWeekForAdvantageTracker",
			byDayMaster: "Resident/GetResidentDayAssessmentMaster",
			byWeekMaster: "Resident/GetResidentWeekAssessmentMaster",
			byDayDetails: "Resident/GetResidentDayAssessmentDetails",
			byWeekDetails: "Resident/GetResidentDayAssessmentDetails",
			purgeByDay: "Resident/DeleteResidentTrackingTasks",
		},
		// for adl shift schedule (ie shifts per adl)
		adlSchedule: {
			getSchedule: "Advantage/GetAssessmentResidentAdlShift",
			saveScheduleMany: "Resident/UpdateResidentsAdlShifts",
			getAdlShiftChanges: "Facility/GetFacilityAdlShifts",
		},
	},
	residents: {
		getResident: {
			get: "Data/Get2",
			search: "Data/Get2",
		},
	},
	resetMethods: {
		get: "ALADVSystem/GetResetMethodType",
		get2: "ALADVSystem/GetResetMethodType2",
		save: "ALADVSystem/SaveResetMethodType2",
		update: "ALADVSystem/UpdateResetMethodType",
		delete: "ALADVSystem/DeleteResetMethodType",
		byUser: {
			update: "ALADVSystem/UpdateUserLogin",
			methods: "Security/SetUserPasswordRules",
		},
	},
	emails: {
		sendEmail: "Security/SendUserEmail",
		sendEmails: "Security/SendUsersEmail",
		sendConfirmationEmail: "Security/SendConfirmationEmail",
	},
	// SECURITY MODULE
	security: {
		resetType: {
			save: "Security/SetUserPasswordRules",
			get: "Security/GetUserPasswordRules",
		},
		questions: {
			get: "ALADVSystem/GetSecurityQuestion",
			get2: "ALADVSystem/GetSecurityQuestion2",
			update: "ALADVSystem/UpdateSecurityQuestion",
			updateAnswers: "ALADVSystem/UpdateUserLoginSecurityAnswerMany",
			create: "Security/CreateUserSecurityQuestionAndAnswers",
			saveUserAnswer: "Security/SetUserSecurityAnswer",
			validate: {
				questionExists: "Security/ValidateSecurityQuestion",
				userQuestion: "Security/ValidateUserSecurityQuestion",
				userAnswer: "Security/ValidateUserSecurityAnswer",
			},
		},
		info: {
			get: "Security/GetUserInformation",
			getFacilitySecurityInfo: "Security/GetFacilityUserInformation",
		},
		userLogin: {
			getLogin2: "ALADVSystem/GetUserLogin2",
			getLogin: "ALADVSystem/GetUserLogin2",
			createNew: "Security/CreateUserLogin",
			validate: "Security/ValidateUserLoginExists",
			checkPasswordStrength: "Security/IsStrongPassword",
			suspendLogin: "Security/SetUserLoginSuspend",
			unsuspendLogin: "Security/UnsetUserLoginSuspend",
			updateUserLogin: "ALADVSystem/UpdateUserLogin",
		},
		userProfile: {
			createNew: "Security/CreateUserProfile",
			get: "ALADVSystem/GetUserProfile",
			save: "ALADVSystem/SaveUserProfile", // only updates the main 'UserProfile' record data
			deleteProfile: "ALADVSystem/UpdateUserProfile",
			update: {
				phone: "ALADVSystem/SaveUserPhone",
				email: "ALADVSystem/SaveUserEmail",
				avatar: "ALADVSystem/SaveUserAvatar",
				alert: "ALADVSystem/SaveUserAlert",
				userTitle: "ALADVSystem/SaveUserTitle",
				userType: "ALADVSystem/SaveUserType",
			},
		},
		userType: {
			get: "ALADVSystem/GetUserType",
			get2: "ALADVSystem/GetUserType2",
			save: "ALADVSystem/SaveUserType",
			saveMany: "ALADVSystem/SaveUserTypeMany",
		},
		userTitle: {
			get: "ALADVSystem/GetUserTitle",
			get2: "ALADVSystem/GetUserTitle2",
			save: "ALADVSystem/SaveUserTitle",
			create: "ALADVSystem/SaveUserTitle",
		},
		lockouts: {
			profile: {
				lock: "Security/SetUserProfileLockout",
				unlock: "Security/UnsetUserProfileLockout",
			},
			login: {
				lock: "Security/SetUserLoginLockout",
				unlock: "Security/UnsetUserLoginLockout",
				suspend: "Security/SetUserLoginSuspend",
				unsuspend: "Security/UnsetUserLoginSuspend",
			},
			otp: {
				isOtp: "Security/IsOtp",
				generate: "Security/GenerateOTP",
				generateAndSend: "Security/GenerateAndSendOTP",
				registerOtp: "Security/RegisterOTP",
			},
			summary: {
				getSummary: "Security/GetUserLockoutSummary",
				getLoginAttempts: "Security/GetFailedLoginAttempts",
			},
			confirmation: {
				sendConfirmationEmail: "Security/SendConfirmationEmail",
				registerConfirmationEmail: "Security/RegisterConfirmationEmail",
			},
		},
		facilityAccess: {
			getAccessList: "Facility/GetFacilityAccess", // returns list of facilityIDs
			registerAccess: "Facility/RegisterFacilityAccess", // sets access w/ custom array
			grantAccess: "Facility/GrantFacilityAccess",
			denyAccess: "Facility/DeniedFacilityAccess",
		},
		appAccess: {
			checkUser: "Security/CheckUserApplicationAccess",
		},
	},
	user: {
		// executes virtual delete for a user
		deactivateUser: "Security/DeactivateUser",
		getProfile: "Security/GetUserProfile",
		getProfileByEmail: "Security/GetUserProfileByEmail",
		getPhone: "ALADVSystem/GetUserPhone2",
		apps: {
			assignAppToFacilityUsers: "Facility/AssignAppToFacilityUsers",
			unassignAppToFacilityUsers: "Facility/UnAssignAppToFacilityUsers",
		},
		getUser: {
			get: "Data/Get2",
			search: "Data/Get2",
		},
		create: {
			user: "Security/CreateUser", // creates 'ADVUSER' NOT new user infa
			newUserProfile: "Security/CreateUserProfile",
			newUserLogin: "Security/CreateUserLogin",
			newUserRecords: "Security/CreateUserProfileAndLogin",
			sendNewUserTempEmail: "Security/SendUserEmail",
		},
		edit: {
			user: "Advantage/SaveAdvUser",
			changePassword: "Security/ChangeUserPassword",
			disableUser: "Advantage/SaveAdvUser",
			changeUsername: "Security/ChangeUserName",
			userTitle: "Security/ChangeUserTitle",
		},
		security: {
			changePassword: "Security/ChangeUserPassword",
		},
		delete: {
			user: "Advantage/DeleteAdvUser",
			userLogin: "ALADVSystem/DeleteUserLogin",
		},
		update: {
			user: "Advantage/UpdateAdvUser",
			users: "Advantage/UpdateAdvUserMany",
			userType: {
				asAdmin: "Security/SetUserTypeToAdministrator", // as 'ExecutiveAdministrator'
				asExecutiveAdmin: "Security/SetUserTypeToAdministrator", // as 'ExecutiveAdministrator'
				asContractor: "Security/SetUserTypeToContractor",
				asFacilityAdmin: "Security/SetUserTypeToFacilityAdministrator",
				asManager: "Security/SetUserTypeToManager",
				asStaff: "Security/SetUserTypeToStaff",
				asSuperUser: "Security/SetUserTypeToSuperUser",
				asMedTech: "Security/SetUserTypeToMedTech",
				asReadOnly: "Security/SetUserTypeToReadOnlyUser",
				setUserType: "Security/SetUserType",
			},
		},
		titles: {
			getAll: "ALADVSystem/GetUserTitle",
			update: "ALADVSystem/UpdateUserTitle",
			updateMany: "ALADVSystem/UpdateUserTitleMany",
			save: "ALADVSystem/SaveUserTitle",
			saveMany: "ALADVSystem/SaveUserTitleMany",
			findMatches: "Security/FindUserTitle",
		},
		access: {
			getFacilityList: "Community/GetCommunitiesByUserEmail",
			getFacilityList2: "Advantage/GetAdvUserCommunities2",
			grantAccess: "Advantage/SaveAdvUserCommunities",
			grantAccessMany: "Advantage/SaveAdvUserCommunitiesMany",
			removeAccess: "Advantage/DeleteAdvUserCommunities", // removes a single record/facility
		},
		roles: {
			get: "ALADVSystem/GetRole",
			get2: "ALADVSystem/GetRole2",
			save: "ALADVSystem/SaveRole",
			saveMany: "ALADVSystem/SaveRoleMany",
			update: "ALADVSystem/UpdateRole",
			updateMany: "ALADVSystem/UpdateRoleMany",
		},
		roleTypes: {
			get: "ALADVSystem/GetRoleType",
			get2: "ALADVSystem/GetRoleType2",
			save: "ALADVSystem/SaveRoleType",
			saveMany: "ALADVSystem/SaveRoleTypeMany",
			update: "ALADVSystem/UpdateRoleType",
			updateMany: "ALADVSystem/UpdateRoleTypeMany",
		},
		roleGroups: {
			get: "ALADVSystem/GetRoleGroup",
			get2: "ALADVSystem/GetRoleGroup2",
			save: "ALADVSystem/SaveRoleGroup",
			saveMany: "ALADVSystem/SaveRoleGroupMany",
			update: "ALADVSystem/UpdateRoleGroup",
			updateMany: "ALADVSystem/UpdateRoleGroupMany",
		},
		roleGroupTypes: {
			get: "ALADVSystem/GetRoleGroupType",
			get2: "ALADVSystem/GetRoleGroupType2",
			save: "ALADVSystem/SaveRoleGroupType",
			saveMany: "ALADVSystem/SaveRoleGroupTypeMany",
			update: "ALADVSystem/UpdateRoleGroupType",
			updateMany: "ALADVSystem/UpdateRoleGroupTypeMany",
		},
	},
	facility: {
		getFacilityData: "Advantage/GetFacility2",
		getCategories: "Advantage/GetAssessmentCategory",
		getShifts: "Advantage/GetAssessmentShift",
		getUsers: "Advantage/GetAdvUser2",
		getResidents: "Resident/GetResidentsByFacility",
		getFacilities: {
			byUser: "Community/GetCommunitiesByUser",
			byUserEmail: "Community/GetCommunitiesByUserEmail",
		},
		exceptions: {
			getExceptionType: "Advantage/GetAssessmentFacilityException",
			saveExceptionType: "Advantage/SaveAssessmentFacilityException",
			saveExceptionTypeMany: "Advantage/SaveAssessmentFacilityExceptionMany",
			deleteExceptionType: "Advantage/DeleteAssessmentFacilityException",
			deleteExceptionTypeMany:
				"Advantage/DeleteAssessmentFacilityExceptionMany",
		},
		cancellations: {
			getCancellationType: "Advantage/GetAssessmentFacilityCancellation",
			saveCancellationType: "Advantage/SaveAssessmentFacilityCancellation",
			saveCancellationTypeMany:
				"Advantage/SaveAssessmentFacilityCancellationMany",
			deleteCancellationType: "Advantage/DeleteAssessmentFacilityCancellation",
			deleteCancellationTypeMany:
				"Advantage/DeleteAssessmentFacilityCancellationMany",
		},
		notifications: {
			// incidents: "Notification/GetIncidentNotificationEmailList",
			incidents: "Notification/GetFacilityNotificationEmailList",
			assessments: "Notification/GetAssessmentChangeNotificationEmailList",
			adminLockout: "Notification/GetAdminLockoutNotificationEmailList",
			getList: {
				incidents: "Notification/GetIncidentNotificationEmailList",
				assessments: "Notification/GetAssessmentChangeNotificationEmailList",
				adminLockout: "Notification/GetAdminLockoutNotificationEmailList",
			},
			removeRecipient: {
				fromIncidents: "Notification/DeleteIncidentNotificationEmailList",
				fromAssessments:
					"Notification/DeleteAssessmentChangeNotificationEmailList",
				fromLockouts: "Notification/DeleteAdminLockoutNotificationEmailList",
			},
			updateList: {
				// incidents: "Notification/UpdateIncidentNotificationEmailList",
				incidents: "Notification/UpdateFacilityNotificationEmailList",
				assessments: "Notification/UpdateAssessmentChangeNotificationEmailList",
				lockouts: "Notification/UpdateAdminLockoutNotificationEmailList",
			},
			deleteList: {
				incidents: "Notification/DeleteIncidentNotificationEmailList",
				assessments: "Notification/DeleteAssessmentChangeNotificationEmailList",
				lockouts: "Notification/DeleteAdminLockoutNotificationEmailList",
			},
		},
		update: {
			facilityInfo: "Advantage/UpdateFacility",
		},
		getFacility: {
			search: "Data/Get2",
		},
		apps: {
			getAccessList: "Security/GetFacilitiesApplicationAccess",
			assignAppToFacility: "Facility/AssignAppToFacilityAccount",
			unassignAppToFacility: "Facility/UnAssignAppToFacilityAccount",
			assignAppToFacilityUsers: "Facility/AssignAppToFacilityUsers",
			unassignAppToFacilityUsers: "Facility/UnAssignAppToFacilityUsers",
			checkFacilityUsersAppAccess: "Facility/DoesAllFacilityUsersHaveAccess",
		},
		migrateUsers: "Security/MigrateFacilityUsers",
		migrationStatus: "ALADVSystem/GetSettingFacility",
		migrationStatus2: "ALADVSystem/GetSettingFacility2",
		// new APIs
		unsuspend: {
			facilityAccount: "Facility/UnSuspendFacilityAccount",
		},
		suspend: {
			facilityAccount: "Facility/SuspendFacilityAccount",
			getReasons: "Advantage/GetSuspendReasonType",
		},
		facilityTypes: {
			getParents: "Dao/Facility/GetParentFacilities",
			getChilds: "Dao/Facility/GetChildFacilities",
			getChildAndParent: "Dao/Facility/GetParentAndChildFacilities",
		},
	},
	messages: {
		get: {
			byApp: "ALADVSystem/GetApplicationMessage",
			byApp2: "ALADVSystem/GetApplicationMessage2",
		},
		update: {
			byApp: "ALADVSystem/UpdateApplicationMessage",
			byApp2: "ALADVSystem/UpdateApplicationMessageMany",
		},
		save: {
			byApp: "ALADVSystem/SaveApplicationMessage",
			byAppMany: "ALADVSystem/SaveApplicationMessageMany",
		},
		delete: {
			byApp: "ALADVSystem/DeleteApplicationMessage",
			byAppMany: "ALADVSystem/DeleteApplicationMessageMany",
		},
	},
	// APPLICATION/FACILITY SETTINGS
	settings: {
		dataRestriction: {
			get: "ALADVSystem/GetSettingApplication",
			save: "ALADVSystem/SaveSettingApplication",
			remove: "ALADVSystem/DeleteSettingApplication",
		},
		type: {
			get: "ALADVSystem/GetSettingType",
			save: "ALADVSystem/SaveSettingType",
			remove: "ALADVSystem/DeleteSettingType",
		},
		apps: {
			get: "ALADVSystem/GetApplication",
			save: "ALADVSystem/SaveApplication",
			remove: "ALADVSystem/DeleteApplication",
		},
		facility: {
			get: "ALADVSystem/GetSettingFacility",
			save: "ALADVSystem/SaveSettingFacility",
			remove: "ALADVSystem/DeleteSettingFacility",
		},
		exceptions: {
			get: "Advantage/GetAssessmentException",
			save: "Advantage/SaveAssessmentException",
			saveMany: "Advantage/SaveAssessmentExceptionMany",
		},
		migration: {
			getStatus: "ALADVSystem/GetSettingFacility",
			getStatus2: "ALADVSystem/GetSettingFacility2",
			getStatus3: "ALADVSystem/GetFacilityStatistic",
			getScheduler: "ALADVSystem/GetCommandScheduler",
			getScheduler2: "ALADVSystem/GetCommandScheduler2",
		},
	},
	permissions: {
		create: {
			byUser: "Security/CreateUserPermissions",
		},
		update: {
			byUser: "ALADVSystem/UpdateSecurityByUser",
			byUserMany: "ALADVSystem/UpdateSecurityByUserMany",
		},
		save: {
			byUser: "ALADVSystem/SaveSecurityByUser",
			byUserMany: "ALADVSystem/SaveSecurityByUserMany",
		},
		whitelist: {
			get: "ALADVSystem/GetUserLoginWhiteList",
			get2: "ALADVSystem/GetUserLoginWhiteList2",
			getInfo: "ALADVSystem/GetUserLogin2",
			save: "ALADVSystem/SaveUserLoginWhiteList",
			delete: "ALADVSystem/GetUserLoginWhiteList2",
		},
	},
	apps: {
		get: {
			appsList: "ALADVSystem/GetApplication",
			accessForAllFacilities: "Security/GetFacilitiesApplicationAccess",
			byFacility: "ALADVSystem/GetApplicationByFacility",
			byFacility2: "ALADVSystem/GetApplicationByFacility2",
			byUser: "ALADVSystem/GetApplicationByUser",
		},
		save: {
			app: "ALADVSystem/SaveApplication",
			byFacility: "ALADVSystem/SaveApplicationByFacility",
			byUser: "ALADVSystem/SaveApplicationByUser",
		},
	},
	// LEVELS OF CARE
	loc: {
		get: "Facility/GetLevelsOfCare",
		save: "Facility/SaveLevelsOfCare",
		saveSettings: "Advantage/UpdateFacility",
		isEditable: "Facility/IsEditableLevelsOfCare",
		reset: "Facility/ResetLevelsOfCare",
		flagSettings: "Facility/SetLevelsOfCareFlags",
		lockAll: "Facility/LockLevelsOfCareChildFacilities",
		unlockAll: "Facility/UnLockLevelsOfCareChildFacilities",
	},
	locTemplates: {
		create: {
			newTemplate: "Facility/CreateLevelsOfCareTemplate",
			newTemplateByDict: "Facility/CreateLevelsOfCareTemplateByDict",
		},
		get: {
			facilityTemplateNames: "Facility/GetLevelsOfCareTemplateHeaders",
			facilityTemplates: "Facility/GetLevelsOfCareTemplateHeadersAndStructures",
			facilityTemplate: "Facility/GetLevelsOfCareTemplate",
			facilityTemplateByID:
				"Facility/GetLevelsOfCareTemplateHeaderAndStructure",
		},
		update: {
			facilityTemplateNames: "Facility/UpdateLevelsOfCareTemplateHeaders",
			facilityTemplates:
				"Facility/UpdateLevelsOfCareTemplateHeadersAndStructures",
			facilityTemplate: "Facility/UpdateLevelsOfCareTemplate",
			facilityTemplateByID:
				"Facility/UpdateLevelsOfCareTemplateHeaderAndStructure",
		},
		save: {
			facilityTemplateNames: "Facility/SaveLevelsOfCareTemplateHeaders",
			facilityTemplates:
				"Facility/SaveLevelsOfCareTemplateHeadersAndStructures",
			facilityTemplate: "Facility/SaveLevelsOfCareTemplate",
			facilityTemplateByID:
				"Facility/SaveLevelsOfCareTemplateHeaderAndStructure",
		},
		saveBy: {
			facilityTemplate: "Facility/SaveLevelsOfCareByTemplate",
		},
		delete: {
			facilityTemplate: "Facility/DeleteLevelsOfCareTemplate",
		},
		validate: {
			levels: "Facility/ValidateLevelsOfCare",
		},
	},
	dms: {
		extract: {
			residentPhoto: "Documents/ExtractImageToFile",
			facilitysResidentPhotos: "Documents/ExtractImagesToFiles",
		},
	},
	vendors: {
		getVendor: "ALADVSystem/GetVendor2", // single vendor
		getVendors: "ALADVSystem/GetVendor", // vendors list
		getVendorDataMaps: "ALADVSystem/GetVendorDataMap",
		getVendorDataMaps2: "ALADVSystem/GetVendorDataMap2",
	},
	emar: {
		sso: "Interfaces/EmarSingleSignOn",
		facility: {
			addFacility: "Interfaces/EmarAddFacility",
			removeFacility: "Interfaces/EmarRemoveFacility",
			updateFacility: "Interfaces/EmarUpdateFacility",
			enableFacility: "Interfaces/EmarEnableFacility",
			disableFacility: "Interfaces/EmarDisableFacility",
			checkAccess: "Interfaces/EmarAccessibleForFacility",
			checkAllUsersAccess: "Facility/DoesAllFacilityUsersHaveAccess",
			enableAllUsers: "Facility/AssignAppToFacilityUsers",
			disableAllUsers: "Facility/UnAssignAppToFacilityUsers",
			getErrorSummary: "Interfaces/GetApplicationErrorStats",
			getErrors: "Interfaces/GetApplicationErrors",
		},
		user: {
			addUser: "Interfaces/EmarAddUser",
			removeUser: "Interfaces/EmarRemoveUser",
			updateUser: "Interfaces/EmarUpdateUser",
			enableUser: "Interfaces/EmarEnableUser",
			disableUser: "Interfaces/EmarDisableUser",
			checkAccess: "Interfaces/EmarAccessibleForUser",
		},
		resident: {
			addResident: "Interfaces/AddResident",
			removeResident: "Interfaces/RemoveResident",
			updateResident: "Interfaces/UpdateResident",
			enableResident: "Interfaces/EnableResident",
			disableResident: "Interfaces/DisableResident",
			checkAccess: "Interfaces/EmarAccessibleForResident",
		},
	},
	interfaces: {
		chartMeds: {},
		accuflo: {},
		eldermark: {},
	},
	// SERVICE BANNERS
	banners: {
		// USER-LEVEL/USER-FACING
		getFacilityBanners: "Facility/GetFacilityBanners",
		// FOR INTERNAL PORTAL: DISPLAYS ACTIVE BANNERS
		getCurrentBanners: "Advantage/GetFacilityBanner",
		getCurrentBanners2: "Advantage/GetFacilityBanner2",
		getBannersByFacility: "Advantage/GetFacilityBanner2",
		saveNewBanner: "Advantage/SaveFacilityBanner",
		updateBanner: "Advantage/SaveFacilityBanner",
		deleteBanner: "Advantage/DeleteFacilityBanner",
	},
	acknowledge: {
		byUserID: "Security/AcknowledgeBy",
		userLogin: "Security/UserLoginAcknowledge",
		getUserLoginAcknowledge: "ALADVSystem/GetUserLoginAcknowledge",
		getUserLoginAcknowledge2: "ALADVSystem/GetUserLoginAcknowledge2",
		saveUserLoginAcknowledge: "ALADVSystem/SaveUserLoginAcknowledge",
		saveUserLoginAcknowledgeMany: "ALADVSystem/SaveUserLoginAcknowledgeMany",
		updateUserLoginAcknowledge: "ALADVSystem/UpdateUserLoginAcknowledge",
	},
	// STAX APIs
	stax: {
		facility: {
			addFacility: "Interfaces/StaxAddFacility",
			removeFacility: "Interfaces/StaxRemoveFacility",
			updateFacility: "Interfaces/StaxUpdateFacility",
			enableFacility: "Interfaces/StaxEnableFacility",
			disableFacility: "Interfaces/StaxDisableFacility",
			// 'Interfaces' Module
			getAppID: "Interfaces/StaxApplicationIdForFacility",
			isAccessible: "Interfaces/StaxAccessibleForFacility",
			assignTo: "Interfaces/StaxAssignToFacility",
			addFacility2: "Interfaces/StaxAddFacility",
		},
		resident: {
			addResident: "Interfaces/StaxAddResident",
			addResident2: "Interfaces/StaxAddResident",
			removeResident: "Interfaces/StaxRemoveResident",
			updateResident: "Interfaces/StaxUpdateResident",
			enableResident: "Interfaces/StaxEnableResident",
			disableResident: "Interfaces/StaxDisableResident",
			transferResident: "Interfaces/StaxTransferResident",
			// 'Interfaces' Module
			isAccessible: "Interfaces/StaxAccessibleForResident",
		},
		user: {
			addUser: "Interfaces/StaxAddUser",
			removeUser: "Interfaces/StaxRemoveUser",
			updateUser: "Interfaces/StaxUpdateUser",
			enableUser: "Interfaces/StaxEnableUser",
			disableUser: "Interfaces/StaxDisableUser",
			// 'Interfaces' Module
			isAccessible: "Interfaces/StaxAccessibleForUser",
			assignTo: "Interfaces/StaxAssignToUser",
			addUser2: "Interfaces/StaxAddUser",
			removeUser2: "Interfaces/StaxRemoveUser",
			updateUser2: "Interfaces/StaxUpdateUser",
		},
		sso: {
			authSSO: "Interfaces/StaxSingleSignOn",
		},
	},
	stax2: {
		facility: {
			addFacility: "StaxInterface/AddFacility",
			removeFacility: "StaxInterface/RemoveFacility",
			updateFacility: "StaxInterface/UpdateFacility",
			enableFacility: "StaxInterface/EnableFacility",
			disableFacility: "StaxInterface/DisableFacility",
			// 'Interfaces' Module
			getAppID: "Interfaces/StaxApplicationIdForFacility",
			isAccessible: "Interfaces/StaxAccessibleForFacility",
			assignTo: "Interfaces/StaxAssignToFacility",
			addFacility2: "Interfaces/StaxAddFacility",
		},
		resident: {
			addResident: "StaxInterface/AddResident",
			addResident2: "Interfaces/StaxAddResident",
			removeResident: "StaxInterface/RemoveResident",
			updateResident: "StaxInterface/UpdateResident",
			enableResident: "StaxInterface/EnableResident",
			disableResident: "StaxInterface/DisableResident",
			transferResident: "StaxInterface/TransferResident",
			// 'Interfaces' Module
			isAccessible: "Interfaces/StaxAccessibleForResident",
		},
		user: {
			addUser: "StaxInterface/AddUser",
			removeUser: "StaxInterface/RemoveUser",
			updateUser: "StaxInterface/UpdateUser",
			enableUser: "StaxInterface/EnableUser",
			disableUser: "StaxInterface/DisableUser",
			// 'Interfaces' Module
			isAccessible: "Interfaces/StaxAccessibleForUser",
			assignTo: "Interfaces/StaxAssignToUser",
			addUser2: "Interfaces/StaxAddUser",
			removeUser2: "Interfaces/StaxRemoveUser",
			updateUser2: "Interfaces/StaxUpdateUser",
		},
		sso: {
			authSSO: "StaxInterface/SingleSignOn",
		},
	},
};

const {
	auth,
	generic,
	uploads,
	downloads,
	downloads: { getFileRegistry },
	messages,
	residents,
	residentData,
	emails,
	security,
	security: {
		lockouts,
		lockouts: { otp, summary },
		questions,
		userLogin,
		userProfile,
		userType,
		userTitle,
		info,
		facilityAccess,
	},
	resetMethods,
	user,
	facility,
	facility: { getFacilities, notifications, facilityTypes },
	settings,
	permissions,
	apps,
	loc,
	loc: { templates: locTemplates, srg: locSRG, unique: locUnique },
	locTemplates: templates,
	dms,
	vendors,
	emar,
	interfaces,
	banners,
	acknowledge,
	stax,
} = endpoints;

export {
	auth,
	generic,
	uploads,
	downloads,
	getFileRegistry,
	residentData,
	user,
	facility,
	facilityTypes,
	settings,
	apps,
	getFacilities,
	notifications,
	security,
	resetMethods,
	emails,
	residents,
	messages,
	// security sub-endpoints
	lockouts,
	otp,
	summary,
	questions,
	userLogin,
	userProfile,
	userType,
	userTitle,
	info,
	facilityAccess,
	permissions,
	loc,
	locTemplates,
	locSRG,
	locUnique,
	templates,
	dms,
	vendors,
	emar,
	interfaces,
	banners,
	acknowledge,
	stax,
};
