import React from "react";
import styles from "../../css/epay/EPayUserAccess.module.scss";
import { PropTypes } from "prop-types";
import ToggleSwitch from "../shared/ToggleSwitch";

const EPayUserAccess = ({
	appName,
	isEnabled = false,
	isLocked = false,
	isDisabled = false,
	handleAccess,
}) => {
	const custom = {
		opacity: isDisabled || isLocked ? ".5" : "1",
		cursor: isDisabled || isLocked ? "not-allowed" : "pointer",
	};

	return (
		<div className={styles.EPayUserAccess}>
			<div className={styles.EPayUserAccess_app} style={custom}>
				{appName}
				{isLocked && (
					<span className={styles.EPayUserAccess_app_isLocked}> - Locked</span>
				)}
			</div>
			<div className={styles.EPayUserAccess_switch}>
				<ToggleSwitch
					name={appName}
					id={appName}
					val={isEnabled}
					isDisabled={isLocked || isDisabled}
					handleToggle={handleAccess}
				/>
			</div>
		</div>
	);
};

export default EPayUserAccess;

EPayUserAccess.defaultProps = {};

EPayUserAccess.propTypes = {};
