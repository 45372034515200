import React, { useState, useEffect, useRef } from "react";
import styles from "../../css/summary/UserLockouts.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import {
	getUserLockoutSummary,
	processLockoutsList,
	sortLockoutsByTime,
} from "../../helpers/utils_lockouts";
import { noFacilityLoaded } from "../../helpers/utils_facility";
import { sendPageTracking } from "../../helpers/utils_tracking";
import {
	enableFeatureViaWhiteList,
	featureFlags,
} from "../../helpers/utils_permissions";
import { isDevOrTest } from "../../helpers/utils_env";
// components
import LockoutBanner from "./LockoutBanner";
import LockoutsList from "./LockoutsList";
import Spinner from "../shared/Spinner";
import Placeholder from "../shared/Placeholder";

// ##TODOS:
// - Rework search functionality (THE STATE LOGIC IS INCORRECT!!!)

/**
 * Searches lockout entries by:
 * - 'email', 'username', 'first', 'last', 'userID', 'title'
 * @param {String} val - Search value
 * @param {Object[]} lockouts - Array of 'lockout' objects
 * @returns {Object[]} - Returns array of matching 'lockout' objects
 */
const advancedSearch = (val, lockouts = [], rawLockouts = []) => {
	val = val?.toLowerCase();

	if (isEmptyVal(val)) return rawLockouts;

	return lockouts.filter((lockout) => {
		if (
			lockout?.email?.startsWith(val) ||
			lockout?.email?.includes(val) ||
			lockout?.username?.startsWith(val) ||
			lockout?.username?.includes(val) ||
			lockout?.firstName?.startsWith(val) ||
			lockout?.firstName?.includes(val) ||
			lockout?.lastName?.startsWith(val) ||
			lockout?.lastName?.includes(val) ||
			lockout?.userID?.startsWith(val) ||
			lockout?.userID?.includes(val) ||
			lockout?.title?.startsWith(val) ||
			lockout?.title?.includes(val)
		) {
			return lockout;
		} else {
			return null;
		}
	});
};

const SearchInput = ({
	searchRef,
	searchVal,
	handleSearch,
	handleKeyDown,
	handleClear,
}) => {
	const enterHandler = (e) => {
		if (e.key === "Enter") {
			return handleClear(e);
		} else {
			return;
		}
	};

	return (
		<div className={styles.SearchInput}>
			<svg className={styles.SearchInput_searchIcon}>
				<use xlinkHref={`${sprite}#icon-search2`}></use>
			</svg>
			<input
				ref={searchRef}
				type="text"
				name="searchLockouts"
				id="searchLockouts"
				value={searchVal}
				className={styles.SearchInput_input}
				onChange={handleSearch}
				onKeyDown={handleKeyDown}
				placeholder="Search by name, email, userID etc..."
			/>
			{!isEmptyVal(searchVal) && (
				<button
					type="button"
					className={styles.SearchInput_clear}
					onClick={handleClear}
					onKeyDown={enterHandler}
					tabIndex={0}
				>
					<svg className={styles.SearchInput_clear_icon}>
						<use xlinkHref={`${sprite}#icon-clearclose`}></use>
					</svg>
				</button>
			)}
		</div>
	);
};

const UserLockouts = ({
	globalState = {},
	dispatchToState,
	currentUser = {},
	currentFacility = {},
	selectedFacility,
}) => {
	const searchRef = useRef();
	const [isRefreshing, setIsRefreshing] = useState(false);
	const [userLockouts, setUserLockouts] = useState(() => {
		return sortLockoutsByTime(currentFacility?.lockouts);
	});
	const [searchVal, setSearchVal] = useState("");
	const [isSearching, setIsSearching] = useState(false);
	const [filteredLockouts, setFilteredLockouts] = useState(() => {
		return sortLockoutsByTime(currentFacility?.lockouts);
	});

	const handleSearch = (e) => {
		const { value } = e.target;
		setSearchVal(value);

		if (isEmptyVal(value)) {
			setIsSearching(false);
			return setFilteredLockouts([]);
		} else {
			setIsSearching(true);
			return setFilteredLockouts([...advancedSearch(value, userLockouts)]);
		}
	};

	const handleClear = (e) => {
		setSearchVal("");
		setIsSearching(false);
		setFilteredLockouts([]);
		searchRef?.current?.focus();
	};

	const refreshLockouts = async () => {
		const { token } = currentUser;
		const { facilityID } = currentFacility;
		setIsRefreshing(true);
		const lockouts = await getUserLockoutSummary(token, facilityID);

		if (isEmptyArray(lockouts?.UserLockOuts)) {
			setIsRefreshing(false);
			setUserLockouts([]);
			return dispatchToState({
				type: "SET_LOCKOUTS",
				data: {
					facilityLockouts: [],
				},
			});
		} else {
			const freshLockouts = [...processLockoutsList(lockouts?.UserLockOuts)];
			dispatchToState({
				type: "SET_LOCKOUTS",
				data: {
					facilityLockouts: freshLockouts,
				},
			});
			setIsRefreshing(false);
			return setUserLockouts(sortLockoutsByTime(freshLockouts));
		}
	};

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		sendPageTracking(currentUser?.username, {
			pagename: `AdminPanel-Lockouts`,
			path: `/portal/access?activeTab=lockouts`,
		});

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.UserLockouts}>
			{!noFacilityLoaded(currentFacility) && (
				<LockoutBanner numOfLockouts={currentFacility?.lockouts?.length ?? 0} />
			)}
			{noFacilityLoaded(currentFacility) && (
				<Placeholder msg={`Please select a facility (above).`} />
			)}
			<section className={styles.UserLockouts_header}>
				<div className={styles.UserLockouts_header_title}>Current Lockouts</div>
				<div className={styles.UserLockouts_header_refresh}>
					<button
						type="button"
						className={styles.UserLockouts_header_refresh_btn}
						onClick={refreshLockouts}
					>
						Refresh Lockouts
					</button>
				</div>
			</section>
			{/* {featureFlags.facility.enableLockoutSearch && ( */}
			{enableFeatureViaWhiteList(currentUser?.userID) && isDevOrTest() && (
				<section className={styles.UserLockouts_search}>
					<SearchInput
						searchRef={searchRef}
						searchVal={searchVal}
						handleSearch={handleSearch}
						handleKeyDown={() => setIsSearching(true)}
						handleBlur={() => setIsSearching(false)}
						handleClear={handleClear}
					/>
					{isSearching && (
						<div className={styles.UserLockouts_search_results}>
							<span>
								Showing {filteredLockouts?.length} of {userLockouts?.length}
							</span>
						</div>
					)}
				</section>
			)}
			<section className={styles.UserLockouts_lockouts}>
				{isRefreshing && <Spinner />}
				<LockoutsList
					userLockouts={!isSearching ? userLockouts : filteredLockouts}
					currentFacility={currentFacility}
					currentUser={currentUser}
					key={`${userLockouts?.length}_${isSearching}_${currentFacility?.lockouts?.length}`}
				/>
			</section>
		</div>
	);
};

export default UserLockouts;

UserLockouts.defaultProps = {
	globalState: {},
	currentFacility: {},
	currentUser: {},
};

UserLockouts.propTypes = {
	globalState: PropTypes.object,
	dispatchToState: PropTypes.func,
	currentFacility: PropTypes.object,
	currentUser: PropTypes.object,
	selectedFacility: PropTypes.string,
};
