import React, { useContext, useEffect, useRef, useReducer } from "react";
import styles from "../../css/widget/FeedbackWidget.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { useLockBodyScroll } from "../../utils/useLockBodyScroll";
import { useOutsideClick } from "../../utils/useOutsideClick";
import { useForm } from "../../utils/useForm";
import { GlobalStateContext } from "../../state/GlobalStateContext";
import { FEEDBACK_LIST, sendUsersEmail } from "../../helpers/utils_emails";
import { generateHtmlEmail } from "../../helpers/utils_templates";
// components
import FeedbackOption from "./FeedbackOption";
import FeedbackWidgetStep from "./FeedbackWidgetStep";
import FeedbackForm from "./FeedbackForm";
import FeedbackSuccess from "./FeedbackSuccess";
import FeedbackError from "./FeedbackError";

/**
 * "<FeedbackWidget/>": simple widget for quickly sending feedback, bugs and feature requests.
 * @param {Function} closeWidget - Function from parent to trigger closing the widget
 * @param {Object} stepsConfig - An object of steps for the widget.
 * @param {Object} extraData - An object used as extra data to forward to the component or the email content to be sent.
 */

const SUCCESS_MS = 4000; // ms to show 'Success' message
const LOADING_MS = 3000; // ms to show 'loading' state

const STEPS_CONFIG = {
	Home: {
		title: `What's on your mind?`,
	},
	Issue: {
		name: `issueMsg`,
		id: `issueMsg`,
		title: `Report an issue`,
		placeholder: `I noticed that...`,
	},
	Idea: {
		name: `ideaMsg`,
		id: `ideaMsg`,
		title: `Share an idea`,
		placeholder: `I would love...`,
	},
	Other: {
		name: `otherMsg`,
		id: `otherMsg`,
		title: `Tell us anything!`,
		placeholder: `What do you want us to know?`,
	},
	Success: {
		title: "",
		msg: `Thanks! We received your feedback.`,
	},
	Error: {
		title: `An Error Occurred!`,
		msg: `Ooops! We didn't get that! Please try again!`,
	},
};

const { Idea, Issue, Other, Success, Error: ErrorStep } = STEPS_CONFIG;

const initialState = {
	isSubmitting: false,
	wasSent: false,
	currentStep: "Home",
	defaultTitle: "",
};

const reducer = (state, action) => {
	switch (action.type) {
		case "NEXT": {
			const { option } = action.data;
			return {
				...state,
				currentStep: option,
			};
		}
		case "PREVIOUS": {
			return {
				...state,
				currentStep: "Home",
			};
		}
		case "SEND_FEEDBACK": {
			return {
				...state,
				isSubmitting: true,
			};
		}
		case "SHOW_SUCCESS": {
			const { option } = action.data;
			return {
				...state,
				currentStep: option,
				isSubmitting: false,
				wasSent: true,
			};
		}
		case "SHOW_ERROR": {
			const { option } = action.data;
			return {
				...state,
				isSubmitting: false,
				currentStep: option,
				wasSent: false,
			};
		}
		case "RESET_SUBMIT": {
			return {
				...state,
				isSubmitting: false,
				wasSent: false,
			};
		}

		default:
			return { ...state };
	}
};

/**
 * '<WidgetHeading/>': header with title & back button.
 */
const WidgetHeader = ({ stepsConfig = {}, currentStep, closeWidget }) => {
	return (
		<div className={styles.WidgetHeader}>
			<div className={styles.WidgetHeader_title}>
				{stepsConfig[currentStep]?.title}
			</div>
			<div className={styles.WidgetHeader_close}>
				<svg className={styles.WidgetHeader_close_icon} onClick={closeWidget}>
					<use xlinkHref={`${sprite}#icon-clearclose`}></use>
				</svg>
			</div>
		</div>
	);
};
/**
 * '<WidgetBottom/>': footer with link to home site, accepts dynamic content.
 */
const WidgetFooter = ({ children }) => {
	return <div className={styles.WidgetFooter}>{children}</div>;
};

const FeedbackWidget = ({
	closeWidget,
	stepsConfig = { ...STEPS_CONFIG },
	children,
}) => {
	// state data for email
	const { state: globalState } = useContext(GlobalStateContext);
	const { currentUser: user, currentFacility } = globalState;

	useLockBodyScroll();
	const widgetRef = useRef();
	const { isOutside } = useOutsideClick(widgetRef);
	const [state, dispatch] = useReducer(reducer, initialState);
	const { formState, handleChange } = useForm({
		referenceURL: window?.location?.href,
		issueMsg: "",
		ideaMsg: "",
		otherMsg: "",
	});
	const { values } = formState;

	const goToOption = (option) => {
		dispatch({
			type: "NEXT",
			data: {
				option: option,
			},
		});
	};

	const goBack = () => {
		dispatch({
			type: "PREVIOUS",
		});
	};

	const sendFeedback = async () => {
		const { token } = user;
		// fire off 'loading' state
		dispatch({ type: "SEND_FEEDBACK" });

		const emailModel = generateHtmlEmail(user, currentFacility, {
			...values,
			feedbackType: state?.currentStep,
		});
		const template = emailModel.getEmailTemplate();
		const wasSent = await sendUsersEmail(token, {
			userLoginIds: [...FEEDBACK_LIST],
			subject: `Feedback: "${state?.currentStep}"`,
			body: template,
			isBodyHtml: true,
			isAdminNotify: false,
		});

		// response handlers
		if (wasSent) {
			return showSuccess();
		} else {
			return showError();
		}
	};

	const showSuccess = () => {
		dispatch({
			type: "SHOW_SUCCESS",
			data: {
				option: "Success",
			},
		});
	};
	const showError = () => {
		dispatch({
			type: "SHOW_ERROR",
			data: {
				option: "Error",
			},
		});
	};

	// shows Success page for 7s, then back to 'Home'
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		// reset steps back to 'Home'
		let timerID;
		if (state?.currentStep === "Success") {
			timerID = setTimeout(() => {
				goToOption("Home");
			}, SUCCESS_MS);
		}

		return () => {
			isMounted = false;
			clearTimeout(timerID);
		};
	}, [state]);

	// click outside to close
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (isOutside) {
			closeWidget();
		}

		return () => {
			isMounted = false;
		};
	}, [closeWidget, isOutside]);

	return (
		<>
			<aside className={styles.FeedbackWidget} ref={widgetRef}>
				<WidgetHeader
					stepsConfig={stepsConfig}
					currentStep={state?.currentStep}
					closeWidget={closeWidget}
				/>
				{/* STEPS */}
				<div className={styles.FeedbackWidget_main}>
					{/* HOME STEP */}
					<FeedbackWidgetStep isActive={state?.currentStep === "Home"}>
						<FeedbackOption
							goToStep={() => goToOption("Issue")}
							stepIcon="bug"
							stepTitle="Issue"
						/>
						<FeedbackOption
							goToStep={() => goToOption("Idea")}
							stepIcon="lightbulb"
							stepTitle="Idea"
						/>
						<FeedbackOption
							goToStep={() => goToOption("Other")}
							stepIcon="other"
							stepTitle="Other"
						/>
					</FeedbackWidgetStep>

					{/* "ISSUE" STEP */}
					<FeedbackWidgetStep
						isActive={state?.currentStep === "Issue"}
						goBack={goBack}
						showBackArrow={true}
					>
						<FeedbackForm
							name={Issue.name}
							id={Issue.id}
							val={values[Issue.name]}
							handleMsg={handleChange}
							hideScreenShotBtn={true}
							placeholder={Issue.placeholder}
							sendFeedback={sendFeedback}
							isSubmitting={state?.isSubmitting}
						/>
					</FeedbackWidgetStep>

					{/* "IDEA" STEP */}
					<FeedbackWidgetStep
						isActive={state?.currentStep === "Idea"}
						goBack={goBack}
						showBackArrow={true}
					>
						<FeedbackForm
							name={Idea.name}
							id={Idea.id}
							val={values[Idea.name]}
							handleMsg={handleChange}
							hideScreenShotBtn={true}
							placeholder={Idea.placeholder}
							sendFeedback={sendFeedback}
							isSubmitting={state?.isSubmitting}
						/>
					</FeedbackWidgetStep>

					{/* "OTHER" STEP */}
					<FeedbackWidgetStep
						isActive={state?.currentStep === "Other"}
						goBack={goBack}
						showBackArrow={true}
					>
						<FeedbackForm
							name={Other.name}
							id={Other.id}
							val={values[Other.name]}
							handleMsg={handleChange}
							placeholder={Other.placeholder}
							hideScreenShotBtn={true}
							sendFeedback={sendFeedback}
							isSubmitting={state?.isSubmitting}
						/>
					</FeedbackWidgetStep>

					{/* SUCCESS */}
					<FeedbackWidgetStep isActive={state?.currentStep === "Success"}>
						<FeedbackSuccess
							msg={Success.msg}
							submitMore={() => goToOption("Home")}
						/>
					</FeedbackWidgetStep>
					{/* ERROR */}
					<FeedbackWidgetStep isActive={state?.currentStep === "Error"}>
						<FeedbackError msg={ErrorStep.msg} tryAgain={sendFeedback} />
					</FeedbackWidgetStep>
				</div>
				<WidgetFooter>
					<span>
						Widget by{" "}
						<a
							href="https://www.aladvantage.com"
							target="_blank"
							rel="noopener noreferrer"
						>
							AL Advantage LLC.
						</a>
					</span>
				</WidgetFooter>
			</aside>
		</>
	);
};

export default FeedbackWidget;

FeedbackWidget.defaultProps = {};

FeedbackWidget.propTypes = {};
