import React, { useRef, useEffect } from "react";
import styles from "../../css/shared/DropdownOptionsMenu.module.scss";
import { PropTypes } from "prop-types";
import { useOutsideClick } from "../../utils/useOutsideClick";
import { isEmptyArray } from "../../helpers/utils_types";
import DropdownOption from "./DropdownOption";

const DropdownOptionsMenu = ({
	name,
	closeHandler,
	handleSelection,
	handleSelectionByKey,
	handleFocus,
	hasFocus,
	selection,
	options = [],
	disabledOptions = [],
}) => {
	const menuRef = useRef();
	const { isOutside } = useOutsideClick(menuRef);

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (isOutside) {
			return closeHandler(false);
		}
		return () => {
			isMounted = false;
		};
	}, [isOutside, closeHandler]);

	return (
		<aside className={styles.DropdownOptionsMenu} ref={menuRef}>
			<ul className={styles.DropdownOptionsMenu_list}>
				{!isEmptyArray(options) &&
					options.map((option, index) => (
						<DropdownOption
							key={option + index}
							option={option}
							handleSelection={() => handleSelection(name, option)}
							handleFocus={() => handleFocus(option)}
							handleSelectionByKey={handleSelectionByKey}
							isSelected={selection === option}
							isDisabled={disabledOptions.includes(option)}
							hasFocus={hasFocus === option}
						/>
					))}
			</ul>
		</aside>
	);
};

export default DropdownOptionsMenu;

DropdownOptionsMenu.defaultProps = {
	options: [],
};
DropdownOptionsMenu.defaultProps = {
	name: PropTypes.string.isRequired,
	closeHandler: PropTypes.func.isRequired,
	handleSelection: PropTypes.func.isRequired, // "onClick" handler for value selection
	options: PropTypes.array.isRequired, // menu options
};
