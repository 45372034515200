import React from "react";
import styles from "../../css/admin/UserPermissions.module.scss";
import { PropTypes } from "prop-types";
import ViewContainer from "../app/ViewContainer";

const UserPermissions = () => {
	return (
		<div className={styles.UserPermissions}>
			<div className={styles.UserPermissions_title}>User Permissions</div>
			{/*  */}
			{/*  */}
		</div>
	);
};

export default UserPermissions;

UserPermissions.defaultProps = {};

UserPermissions.propTypes = {};
