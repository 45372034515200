import React from "react";
import styles from "../../css/migration/MigrationPanelSelector.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";
import { formatAndSortUsers } from "../../helpers/utils_user";
import { formatAndSortUserFacilities } from "../../helpers/utils_facility";
// components
import ProtectedFeature from "../permissions/ProtectedFeature";
import MultiSelect from "../shared/MultiSelect";
import CustomDropdown from "../shared/CustomDropdown";
import CustomCheckbox from "../shared/CustomCheckbox";

const customCSS = {
	selector: {
		width: "100%",
		maxWidth: "45rem",
	},
	multiSelect: {
		width: "100%",
		maxWidth: "45rem",
	},
	multiSelectDropdown: {
		width: "100%",
		maxWidth: "45rem",
	},
};

const showUserSelector = (
	migrateAll = false,
	currentUser = {},
	currentFacility = {}
) => {
	if (migrateAll) return false;
	// if (!currentUser?.isSuperUser) return false;
	// if (isEmptyVal(currentFacility?.facilityID)) return false;
	return true;
};

const MigrationPanelSelector = ({
	currentFacility = {},
	currentUser = {},
	migrateAll = false,
	selectedFacility,
	selectedUsers,
	handleFacility,
	handleUsers,
	handleCheckbox,
}) => {
	return (
		<div className={styles.MigrationPanelSelector}>
			<div className={styles.MigrationPanelSelector_selector}>
				<CustomDropdown
					name="selectedFacility"
					id="selectedFacility"
					label="Select the facility you'd like to migrate:"
					selection={selectedFacility}
					setSelection={handleFacility}
					placeholder="Choose a facility..."
					customStyles={customCSS.selector}
					options={[...formatAndSortUserFacilities(currentUser?.facilities)]}
				/>
			</div>
			<div className={styles.MigrationPanelSelector_selectAll}>
				<CustomCheckbox
					name="migrateAll"
					id="migrateAll"
					label="Migrate All Users"
					val={migrateAll}
					handleCheckbox={handleCheckbox}
				/>
			</div>
			<ProtectedFeature
				isEnabled={showUserSelector(migrateAll, currentUser, currentFacility)}
			>
				<div className={styles.MigrationPanelSelector_residents}>
					<div className={styles.MigrationPanelSelector_residents_label}>
						Select which users to migrate:
					</div>
					<MultiSelect
						initialSelections={selectedUsers}
						enableSelectAll={true}
						handleMultiSelect={handleUsers}
						customStyles={customCSS.multiSelect}
						dropdownStyles={customCSS.multiSelectDropdown}
						options={[...formatAndSortUsers(currentFacility?.users)]}
					/>
				</div>
			</ProtectedFeature>
		</div>
	);
};

export default MigrationPanelSelector;

MigrationPanelSelector.defaultProps = {};

MigrationPanelSelector.propTypes = {};
