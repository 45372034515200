import React, { useContext, useState } from "react";
import styles from "../css/pages/MigrationToolPage.module.scss";
import sprite from "../assets/icons/data.svg";
import { PropTypes } from "prop-types";
import { GlobalStateContext } from "../state/GlobalStateContext";
import { initialSettings, useAlerts } from "../utils/useAlerts";
// components
import ViewContainer from "../components/app/ViewContainer";
import MigrationPanel from "../components/migration/MigrationPanel";

const viewDetails = {
	title: `Migrate Your Facility's Users`,
	desc: `Migrate all users at a given facility over to our new ALA Applications portal.`,
};

const MigrateIcon = () => {
	<div className={styles.MigrateIcon}>
		<svg className={styles.MigrateIcon_icon}>
			<use xlinkHref={`${sprite}#icon-cloud-storage-3`}></use>
		</svg>
		<svg className={styles.MigrateIcon_icon}>
			<use xlinkHref={`${sprite}#icon-data-in-both-directions-2`}></use>
		</svg>
		<svg className={styles.MigrateIcon_icon}>
			<use xlinkHref={`${sprite}#icon-cloud-storage-2`}></use>
		</svg>
	</div>;
};

const MigrationToolPage = () => {
	const { state: globalState, dispatch: dispatchToState } =
		useContext(GlobalStateContext);
	const { currentUser, currentFacility } = globalState;
	const { AlertsHandler, dispatchAlert } = useAlerts(initialSettings);

	return (
		<>
			<ViewContainer title={viewDetails?.title} desc={viewDetails?.desc}>
				<div className={styles.MigrationToolPage}>
					<section className={styles.MigrationToolPage_panel}>
						<MigrationPanel
							currentUser={currentUser}
							currentFacility={currentFacility}
							dispatchToState={dispatchToState}
							dispatchAlert={dispatchAlert}
						/>
					</section>
				</div>
			</ViewContainer>

			{AlertsHandler}
		</>
	);
};

export default MigrationToolPage;

MigrationToolPage.defaultProps = {};

MigrationToolPage.propTypes = {};
