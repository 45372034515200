import React from "react";
import styles from "../../css/user/CreateUserStep1.module.scss";
import { PropTypes } from "prop-types";
import CustomDropdown from "../shared/CustomDropdown";
import {
	getAllowedUserTypes,
	USER_TYPE_DESC as userTypeDesc,
} from "../../helpers/utils_userTypes";
import { isEmptyVal } from "../../helpers/utils_types";

// ##TODOS:
// - Sort 'UserTypes' alphabetically

const customCSS = {
	dropdown: {
		width: "100%",
		maxWidth: "100%",
	},
};

const removeDisabled = (disabledTypes = [], allTypes = []) => {
	const purgedList = allTypes.filter((type) => {
		return !disabledTypes.includes(type);
	});
	return purgedList;
};

const UserTypeDesc = ({ selectedType }) => {
	const type = userTypeDesc?.[selectedType];
	return (
		<div className={styles.UserTypeDesc}>
			<div className={styles.UserTypeDesc_name}>{type?.name}</div>
			<p className={styles.UserTypeDesc_desc}>{type?.desc}</p>
		</div>
	);
};

const CreateUserStep1 = ({
	vals,
	currentUser,
	userTypes = [],
	disabledTypes = [],
	handleSelection,
}) => {
	return (
		<div className={styles.CreateUserStep1}>
			<p className={styles.CreateUserStep1_desc}>
				Try your best to categorize the type and/or purpose of the user being
				created from the list below.
			</p>
			<CustomDropdown
				name="userType"
				id="userType"
				placeholder="Search or select a user type..."
				selection={vals.userType}
				setSelection={handleSelection}
				// options={removeDisabled(disabledTypes, userTypes)}
				options={[...getAllowedUserTypes(currentUser, userTypes)]}
				customStyles={customCSS.dropdown}
				inputMode="none"
			/>

			{!isEmptyVal(vals.userType) && (
				<UserTypeDesc selectedType={vals?.userType} />
			)}
		</div>
	);
};

export default CreateUserStep1;

CreateUserStep1.defaultProps = {
	userTypes: [],
	disabledTypes: [],
};

CreateUserStep1.propTypes = {
	vals: PropTypes.object.isRequired,
	userTypes: PropTypes.array.isRequired,
	disabledTypes: PropTypes.array.isRequired,
	handleSelection: PropTypes.func.isRequired,
};
