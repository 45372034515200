import React, { useRef, useEffect, useState } from "react";
import styles from "../../css/shared/Dropdown.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";
import { main } from "../../helpers/utils_styles";
import DropdownOptionsMenu from "./DropdownOptionsMenu";

const ENTER = "Enter" || 13;
const ESCAPE = "Escape" || 27;

const upCaret = `caret-up`;
const downCaret = `caret-down`;

const closeIcon = `clearclose`;

const getIcon = (selection, showOptions) => {
	if (showOptions) {
		return !isEmptyVal(selection) ? closeIcon : upCaret;
	} else {
		return !isEmptyVal(selection) ? closeIcon : downCaret;
	}
};

const Dropdown = ({
	name,
	id,
	label,
	options = [],
	disabledOptions = [],
	placeholder,
	selection,
	setSelection,
	autoFocus = false,
	customStyles = {},
}) => {
	const inputRef = useRef();
	const [showOptions, setShowOptions] = useState(false); // menu options
	const [listOptions, setListOptions] = useState([...options]); // for filtering/searching
	const [hasFocus, setHasFocus] = useState("");
	const [searchVal, setSearchVal] = useState("");

	// handles "click" selection for a menu option
	const handleSelection = (name, option) => {
		setSelection(name, option);
	};

	// sets active focused item
	// allows custom focus styles to be applied
	const handleFocus = (option) => {
		setHasFocus(option);
	};

	// enables keyboard selections, prevents disabled items from selection
	const handleSelectionByKey = (e) => {
		const currentEl = e.target.textContent;

		const hasMatch = (val) => {
			return listOptions.includes(val) && !disabledOptions.includes(val);
		};
		if (hasMatch(currentEl) && e.key === ENTER) {
			return handleSelection(name, currentEl);
		}
		if (e.key === ESCAPE) {
			return setShowOptions(false);
		}
		return;
	};

	// handles "onChange" search
	// if 'selection' exists, then clear it and continue w/ 'handleSearch', as normal
	const handleSearch = (e) => {
		const { value } = e.target;
		if (!isEmptyVal(selection)) {
			clearSelection();
			setSearchVal(value);
			return setListOptions([
				...options.filter((x) => x.toLowerCase().includes(value.toLowerCase())),
			]);
		} else {
			setSearchVal(value);
			setListOptions([
				...options.filter((x) => x.toLowerCase().includes(value.toLowerCase())),
			]);
		}
	};

	const clearSelection = () => {
		handleSelection(name, "");
		handleFocus("");
	};

	// close options menu when selection is made
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (!isEmptyVal(selection)) {
			return setShowOptions(false);
		}

		return () => {
			isMounted = false;
		};
	}, [selection]);

	return (
		<div className={styles.Dropdown}>
			<label htmlFor={id} className={styles.Dropdown_label}>
				{label}
			</label>
			<div className={styles.Dropdown_inputWrapper} style={customStyles}>
				<input
					ref={inputRef}
					type="text"
					value={isEmptyVal(selection) ? searchVal : selection}
					name={name}
					id={id}
					placeholder={placeholder}
					onChange={handleSearch}
					onClick={() => setShowOptions(true)}
					onFocus={() => setShowOptions(true)}
					className={styles.Dropdown_inputWrapper_input}
					autoComplete="off"
					autoFocus={autoFocus}
				/>
				{showOptions && (
					<DropdownOptionsMenu
						name={name}
						selection={selection}
						handleSelection={handleSelection}
						handleSelectionByKey={handleSelectionByKey}
						handleFocus={handleFocus}
						hasFocus={hasFocus}
						closeHandler={setShowOptions}
						options={listOptions}
						disabledOptions={disabledOptions}
					/>
				)}

				<svg
					className={styles.Dropdown_inputWrapper_closeIcon}
					onClick={() =>
						isEmptyVal(selection) ? setShowOptions(true) : clearSelection(name)
					}
					style={
						!isEmptyVal(selection) && !showOptions ? { fill: main.red } : null
					}
				>
					<use
						xlinkHref={`${sprite}#icon-${getIcon(selection, showOptions)}`}
					></use>
				</svg>
			</div>
		</div>
	);
};

export default Dropdown;

Dropdown.defaultProps = {
	options: [],
};

Dropdown.propTypes = {
	name: PropTypes.string,
	id: PropTypes.string,
	label: PropTypes.string,
	options: PropTypes.array,
	selection: PropTypes.string.isRequired, // input state value
	setSelection: PropTypes.func.isRequired, // state setter for input
};
