import React from "react";
import styles from "../../css/modules/ModulePermissionsPanel.module.scss";
import { PropTypes } from "prop-types";

const ModulePermissionsPanel = ({
	title = "Easily set permissions",
	subtitle,
	isAllSelected = false,
	selectAll,
	deselectAll,
	children,
}) => {
	const handleSelector = (e) => {
		if (isAllSelected) {
			return deselectAll(e);
		} else {
			return selectAll(e);
		}
	};

	return (
		<div className={styles.ModulePermissionsPanel}>
			<div className={styles.ModulePermissionsPanel_header}>
				<div className={styles.ModulePermissionsPanel_header_title}>
					{title}
				</div>
				<p className={styles.ModulePermissionsPanel_header_desc}>{subtitle}</p>
			</div>
			<div className={styles.ModulePermissionsPanel_main}>{children}</div>
			<div className={styles.ModulePermissionsPanel_footer}>
				<button
					className={
						isAllSelected
							? styles.ModulePermissionsPanel_footer_deselectAll
							: styles.ModulePermissionsPanel_footer_selectAll
					}
					type="button"
					onClick={handleSelector}
				>
					{isAllSelected ? "Deselect All" : "Select All"}
				</button>
			</div>
		</div>
	);
};

export default ModulePermissionsPanel;

ModulePermissionsPanel.defaultProps = {};

ModulePermissionsPanel.propTypes = {
	title: PropTypes.string,
	children: PropTypes.any,
};
