import React, { useEffect, useState } from "react";
import styles from "../../css/forms/CopyText.module.scss";
import sprite from "../../assets/icons/placeholders.svg";
import { PropTypes } from "prop-types";

const CopyText = ({
	text,
	successMsg = "Copied!",
	hoverTitle = "Click to copy!",
	msgTimeout = 3000,
	customStyles = {},
	successStyles = {},
}) => {
	const [copySuccess, setCopySuccess] = useState(false);
	const [showIcon, setShowIcon] = useState(false);

	const copyToClipboard = () => {
		setCopySuccess(true);
		return navigator.clipboard.writeText(text);
	};

	// reset & hide 'copySuccess' message after X secs. defined by 'msgTimeout'
	useEffect(() => {
		let isMounted = true;
		let timerID;

		if (!isMounted) {
			return;
		}

		if (copySuccess) {
			timerID = setTimeout(() => {
				setCopySuccess(false);
			}, msgTimeout);
		}

		return () => {
			isMounted = false;
			clearTimeout(timerID);
		};
	}, [copySuccess, msgTimeout]);

	return (
		<div
			className={styles.CopyText}
			onClick={copyToClipboard}
			onMouseOver={() => setShowIcon(true)}
			onMouseOut={() => setShowIcon(false)}
			style={customStyles}
			title={hoverTitle}
		>
			{copySuccess ? "" : text}
			{copySuccess && (
				<div className={styles.CopyText_successMsg} style={successStyles}>
					{successMsg}
				</div>
			)}
			{showIcon && (
				<svg className={styles.CopyText_icon}>
					<use xlinkHref={`${sprite}#icon-clipboard`}></use>
				</svg>
			)}
		</div>
	);
};

export default CopyText;

CopyText.defaultProps = {
	successMsg: "Copied!",
	msgTimeout: 3000,
	hoverTitle: "Click to copy!",
	customStyles: {},
};

CopyText.propTypes = {
	text: PropTypes.string,
	successMsg: PropTypes.string,
	msgTimeout: PropTypes.number,
	hoverTitle: PropTypes.string,
	customStyles: PropTypes.object,
};
