import React, { useEffect, useRef } from "react";
import styles from "../../css/summary/AccountUnlockDialog.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { useOutsideClick } from "../../utils/useOutsideClick";
import { useKeyboardShortcut } from "../../utils/useKeyboardShortcut";
import { useLockBodyScroll } from "../../utils/useLockBodyScroll";

const AccountUnlockDialog = ({
	title = "Account Unlock",
	closeDialog,
	children,
}) => {
	const dialogRef = useRef();
	const { isOutside } = useOutsideClick(dialogRef);
	const wasEscaped = useKeyboardShortcut(["Escape"]);
	useLockBodyScroll();

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (isOutside || wasEscaped) {
			closeDialog();
		}
		return () => {
			isMounted = false;
		};
	}, [closeDialog, isOutside, wasEscaped]);

	return (
		<aside className={styles.AccountUnlockDialog} ref={dialogRef}>
			<div className={styles.AccountUnlockDialog_top}>
				<div className={styles.AccountUnlockDialog_top_title}>{title}</div>
				<svg
					className={styles.AccountUnlockDialog_top_closeIcon}
					onClick={closeDialog}
				>
					<use xlinkHref={`${sprite}#icon-clearclose`}></use>
				</svg>
			</div>
			<div className={styles.AccountUnlockDialog_inner}>{children}</div>
		</aside>
	);
};

export default AccountUnlockDialog;

AccountUnlockDialog.defaultProps = {
	title: "Account Unlock",
};

AccountUnlockDialog.propTypes = {
	title: PropTypes.string,
	closeDialog: PropTypes.func,
	children: PropTypes.any,
};
