import React, { useCallback, useEffect, useState } from "react";
import styles from "../../css/app/ResetPasswordByQuestions.module.scss";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import { red } from "../../helpers/utils_styles";
import {
	formatSecurityQuestions,
	getAllSecurityQuestions2,
	validateAllAnswers,
	validateAllQuestions,
} from "../../helpers/utils_security";
import { generateOTP } from "../../helpers/utils_lockouts";
// components
import UserSecurityQuestion from "../user/UserSecurityQuestion";
import UserSecurityAnswer from "../user/UserSecurityAnswer";
import ButtonSM from "../shared/ButtonSM";
import Spinner from "../shared/Spinner";
import ValidationLoader from "./ValidationLoader";
import ChangePassword from "./ChangePassword";

// ##TODOS:
// - Create QA map to store results & status of validation
// 		- This way if there's an error it can be specified which one caused it.
// - Upon successful QA validation, fire off request to get OTP
// 		- This will be used as the 'oldPassword' when the user changes their password

const customCSS = {
	verifyBtn: {
		padding: ".7rem 1.5rem",
		marginTop: "1rem",
		fontSize: "1.3rem",
		fontWeight: "600",
	},
	resetBtn: {
		padding: ".9rem 1.5rem",
		fontSize: "1.3rem",
		fontWeight: "600",
	},
	cancelBtn: {
		padding: ".7rem 1.5rem",
		fontSize: "1.3rem",
		fontWeight: "600",
		backgroundColor: "transparent",
		color: red[600],
		marginRight: "1rem",
	},
};

const isFormCompleted = (vals = {}) => {
	const {
		// security questions:
		confirmSecurityQuestion1,
		confirmSecurityQuestion2,
		confirmSecurityQuestion3,
		// security answers
		confirmSecurityAnswer1,
		confirmSecurityAnswer2,
		confirmSecurityAnswer3,
	} = vals;

	const questionsConfirmed =
		!isEmptyVal(confirmSecurityQuestion1) &&
		!isEmptyVal(confirmSecurityQuestion2) &&
		!isEmptyVal(confirmSecurityQuestion3);
	const answersConfirmed =
		!isEmptyVal(confirmSecurityAnswer1) &&
		!isEmptyVal(confirmSecurityAnswer2) &&
		!isEmptyVal(confirmSecurityAnswer3);

	return questionsConfirmed && answersConfirmed;
};

const checkValidationResults = (results = {}) => {
	const keys = Object.keys(results);
	const isValid = keys.every((key) => results[key]);
	return isValid;
};

const QuestionNumber = ({ number }) => {
	return <div className={styles.QuestionNumber}>{number}</div>;
};

const ResetPasswordByQuestions = ({
	authData = {},
	systemUser = {},
	usersQuestions = [],
	usernameIsConfirmed = false,
	accountSecurity = {},
	closeModal,
	dispatchAlert,
}) => {
	const { formState, setFormState, handleChange, handleReset } = useForm({
		// security questions:
		confirmSecurityQuestion1: "",
		confirmSecurityQuestion2: "",
		confirmSecurityQuestion3: "",
		// security answers
		confirmSecurityAnswer1: "",
		confirmSecurityAnswer2: "",
		confirmSecurityAnswer3: "",
	});
	const { values } = formState;
	const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);
	const [isValidatingQuestions, setIsValidatingQuestions] = useState(false);
	const [allQuestionsConfirmed, setAllQuestionsConfirmed] = useState(false);
	const [securityQuestions, setSecurityQuestions] = useState([]);
	const [tempOTP, setTempOTP] = useState(null);

	const handleQuestion = (name, val) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: val,
			},
		});
	};

	// validates username & fetches questions
	const getQuestions = async () => {
		const { token } = systemUser;
		const allQuestions = await getAllSecurityQuestions2(token, {
			index: 0,
			rows: 100,
		});
		setIsLoadingQuestions(true);

		if (!isEmptyArray(allQuestions)) {
			setSecurityQuestions(allQuestions);
			return setIsLoadingQuestions(false);
		} else {
			return setIsLoadingQuestions(false);
		}
	};

	// if validation is successful, then request OTP for pwd reset
	const validateQAs = async (e) => {
		const { userID } = accountSecurity;
		const { token } = systemUser;
		setIsValidatingQuestions(true);
		const { questionResult1, questionResult2, questionResult3 } =
			await validateAllQuestions(token, userID, {
				securityQuestion1: values.confirmSecurityQuestion1,
				securityQuestion2: values.confirmSecurityQuestion2,
				securityQuestion3: values.confirmSecurityQuestion3,
			});
		const { answerResult1, answerResult2, answerResult3 } =
			await validateAllAnswers(token, userID, securityQuestions, values);

		const isAllValid = checkValidationResults({
			questionResult1,
			questionResult2,
			questionResult3,
			answerResult1,
			answerResult2,
			answerResult3,
		});
		if (isAllValid) {
			setIsValidatingQuestions(false);
			return setAllQuestionsConfirmed(true);
		} else {
			setIsValidatingQuestions(false);
			return setAllQuestionsConfirmed(false);
		}
	};

	const cancelReset = (e) => {
		handleReset(e);
		closeModal();
	};

	// fetch OTP (for 'oldPassword') when 'allQuestionsConfirmed' is true
	const getOTP = useCallback(async () => {
		const { token } = systemUser;
		const { userID } = accountSecurity;
		const otp = await generateOTP(token, userID);
		if (!isEmptyVal(otp)) {
			return setTempOTP(otp);
		} else {
			return null;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// fetch questions onMount
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		getQuestions();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// runs when questions confirmed
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (allQuestionsConfirmed) {
			getOTP();
		}
		return () => {
			isMounted = false;
		};
	}, [allQuestionsConfirmed, getOTP]);

	return (
		<>
			<div className={styles.ResetPasswordByQuestions}>
				{!allQuestionsConfirmed && (
					<div className={styles.ResetPasswordByQuestions_header}>
						<div className={styles.ResetPasswordByQuestions_header_title}>
							Confirm Security Questions to Reset Password
						</div>
						<p className={styles.ResetPasswordByQuestions_header_desc}>
							Please select each of your 3 security questions and provide their
							respective answers. Questions do NOT need to be in their original
							order to be valid.
						</p>
					</div>
				)}

				{/* SPINNER */}
				{isLoadingQuestions && (
					<div className={styles.ResetPasswordByQuestions_loader}>
						<Spinner />
						<div className={styles.ResetPasswordByQuestions_loader_text}>
							Verifying username...loading security questions
						</div>
					</div>
				)}

				{/* ALL SECURITY QUESTIONS TO CONFIRM */}
				{usernameIsConfirmed && !allQuestionsConfirmed && (
					<>
						<section className={styles.ResetPasswordByQuestions_allQuestions}>
							{/* #1: QUESTION & ANSWER */}
							<div
								className={
									styles.ResetPasswordByQuestions_allQuestions_question
								}
							>
								<QuestionNumber number="1" />

								<UserSecurityQuestion
									key={`_QUESTION_#1`}
									vals={values}
									label="Confirm Security Question #1"
									name="confirmSecurityQuestion1"
									id="confirmSecurityQuestion1"
									placeholder="Select question #1..."
									handleQuestion={handleQuestion}
									securityQuestions={[
										...formatSecurityQuestions(securityQuestions),
									]}
									hideEditButtons={true}
								/>
								<UserSecurityAnswer
									vals={values}
									key={`_ANSWER_#3`}
									label="Confirm Answer"
									name="confirmSecurityAnswer1"
									id="confirmSecurityAnswer1"
									placeholder="Answer for question one..."
									handleAnswer={handleChange}
								/>
							</div>

							{/* #2: QUESTION & ANSWER */}
							<div
								className={
									styles.ResetPasswordByQuestions_allQuestions_question
								}
							>
								<QuestionNumber number="2" />
								<UserSecurityQuestion
									key={`_QUESTION_#2`}
									vals={values}
									label="Confirm Security Question #2"
									name="confirmSecurityQuestion2"
									id="confirmSecurityQuestion2"
									placeholder="Select question #2..."
									handleQuestion={handleQuestion}
									securityQuestions={[
										...formatSecurityQuestions(securityQuestions),
									]}
									hideEditButtons={true}
								/>
								<UserSecurityAnswer
									vals={values}
									key={`_ANSWER_#3`}
									label="Confirm Answer"
									name="confirmSecurityAnswer2"
									id="confirmSecurityAnswer2"
									placeholder="Answer for question two..."
									handleAnswer={handleChange}
								/>
							</div>

							{/* #3: QUESTION & ANSWER */}
							<div
								className={
									styles.ResetPasswordByQuestions_allQuestions_question
								}
							>
								<QuestionNumber number="3" />
								<UserSecurityQuestion
									key={`_QUESTION_#3`}
									vals={values}
									label="Confirm Security Question #3"
									name="confirmSecurityQuestion3"
									id="confirmSecurityQuestion3"
									placeholder="Select question #3..."
									handleQuestion={handleQuestion}
									securityQuestions={[
										...formatSecurityQuestions(securityQuestions),
									]}
									hideEditButtons={true}
								/>
								<UserSecurityAnswer
									key={`_ANSWER_#3`}
									vals={values}
									label="Confirm Answer"
									name="confirmSecurityAnswer3"
									id="confirmSecurityAnswer3"
									placeholder="Answer for question three..."
									handleAnswer={handleChange}
								/>
							</div>
						</section>

						{/* ACTION BUTTONS */}
						<section className={styles.ResetPasswordByQuestions_submit}>
							<ButtonSM
								isDisabled={!securityQuestions}
								handleClick={cancelReset}
								customStyles={customCSS.cancelBtn}
							>
								Cancel
							</ButtonSM>
							<ButtonSM
								isDisabled={!isFormCompleted(values)}
								handleClick={validateQAs}
								customStyles={customCSS.resetBtn}
							>
								{isValidatingQuestions ? "Validating..." : "Validate Questions"}
							</ButtonSM>
						</section>
					</>
				)}

				{isValidatingQuestions && <ValidationLoader />}

				{/* SHOW CHANGE PASSWORD UI */}
				{allQuestionsConfirmed && (
					<ChangePassword
						oldPassword={tempOTP?.otp}
						authData={authData}
						systemUser={systemUser}
						accountSecurity={accountSecurity}
						dispatchAlert={dispatchAlert}
						closeModal={closeModal}
					/>
				)}
			</div>
		</>
	);
};

export default ResetPasswordByQuestions;

ResetPasswordByQuestions.defaultProps = {};

ResetPasswordByQuestions.propTypes = {};
