import { getUserAccessByEmail } from "./utils_auth";
import {
	getFacilitiesByUserEmail,
	getFacilityByUserType,
	getParents,
	getParentsMap,
	processFacilityList,
} from "./utils_facility";
import { isEmptyVal } from "./utils_types";
import {
	getUserPhoneFromInfo,
	hasMultiFacility,
	processUserInfo,
} from "./utils_user";
import { getUserSecurityInfo, processAppsAccess } from "./utils_security";
import {
	hasAlaAdminPerms,
	hasFacilityAdminPerms,
	hasMedTechPerms,
	hasReadOnlyPerms,
	hasRegionalAdminPerms,
	hasSuperUserPerms,
} from "./utils_userTypes";
import { sortAlphaAscByKey } from "./utils_processing";

// ##TODOS:
// - Refactor 'getInitialResource()':
// 		- "getUserProfileByEmail" & "getUserSecurityInfo" contains duplicate data
// 		- "Security Info": consider removing "getUserProfileByEmail" as all it's data is included in 'Security Info'

/**
 * Fetches app's initial user data:
 * - 'Facilities' for the user
 * - 'Access Records' for the user
 * - 'User Profile' for the user (includes 'legacy' and 'login' data)
 * - 'Security Info' for the user
 * - 'All Parents': array of all parent 'FACILITY' records. Used to produce a map of ids, names & raw records.
 */
const getInitialResource = async (auth) => {
	if (isEmptyVal(auth?.token) || isEmptyVal(auth?.username)) return;

	const { token, username } = auth;
	// MOST-RECENT REQUEST CALL as of 4-13-2022 at 7:44 AM \\
	// const [facilityList, userAccess, securityInfo] = await Promise.all([
	// 	getFacilitiesByUserEmail(token, username),
	// 	getUserAccessByEmail(token, username),
	// 	getUserSecurityInfo(token, username),
	// ]);

	// UPDATED REQUEST CALL as of 4-13-2022 at 7:45 AM \\
	const [facilityList, userAccess, securityInfo, allParents] =
		await Promise.all([
			getFacilitiesByUserEmail(token, username),
			getUserAccessByEmail(token, username),
			getUserSecurityInfo(token, username),
			getParents(token),
		]);

	return {
		facilityList: facilityList,
		userAccess: userAccess,
		auth: auth,
		securityInfo: securityInfo,
		parents: allParents,
	};
};

const populateState = (newData, state) => {
	const { facilityList, userAccess, auth, securityInfo, parents } = newData;

	// process 'parentsMap'
	const sorted = sortAlphaAscByKey("CommunityName", parents);
	const parentsMap = getParentsMap(sorted);

	// contains client-formatted 'userProfile' data w/ select info & new-infra data
	const userInfo = processUserInfo(auth?.username, securityInfo);

	// fields that may have to pull from 'ADVUSER' or 'USER-INFRA'
	const userLogins = userInfo.userLogins;
	// const userID = !userInfo?.advUser?.guidUser
	// 	? userLogins?.[0]?.UserLoginID
	// 	: userInfo?.advUser?.guidUser;
	const userID = isEmptyVal(userInfo?.advUser?.guidUser)
		? userInfo?.userLogins?.UserLoginID
		: userInfo?.advUser?.guidUser;
	// CHECK FOR TITLES IN CACHE & MATCH ACCORDINGLY

	const title = userInfo?.advUser?.strTitle;
	const facilityID = !userInfo?.advUser?.guidFacility
		? userInfo?.userProfile?.facilityID
		: userInfo?.advUser?.guidFacility;
	// names
	const first =
		userInfo?.userProfile?.firstName ?? userInfo?.advUser?.strFirstName;
	const last =
		userInfo?.userProfile?.lastName ?? userInfo?.advUser?.strLastName;

	// app-access
	const {
		hasTrackerAccess: tracker,
		hasLegacyAccess: legacy,
		hasPortalAccess: portal,
	} = processAppsAccess(securityInfo);

	const userPhoneInfo = getUserPhoneFromInfo(userInfo);

	// get user's app-access
	const hasTrackerAccess = tracker;
	const hasLegacyAccess = legacy;
	const hasPortalAccess = portal;

	// 'User Type' perms
	const isSuper = hasSuperUserPerms(
		userInfo?.advUser,
		userInfo?.userProfile,
		userInfo?.userMerge
	);
	const isFacAdmin = hasFacilityAdminPerms(
		userInfo?.advUser,
		userInfo?.userProfile
	);
	const isRegional = hasRegionalAdminPerms(
		userInfo?.advUser,
		userInfo?.userProfile,
		facilityList
	);
	const isAdmin = hasAlaAdminPerms(userInfo?.advUser, userInfo?.userProfile);
	const isMedTech = hasMedTechPerms(userInfo?.advUser, userInfo?.userProfile);
	const isReadOnly = hasReadOnlyPerms(userInfo?.userProfile);

	const newState = {
		...state,
		app: {
			...state.app,
			hasLoaded: true,
		},
		// ##TODOS:
		// - Process 'userAccess?.AccessibleApps' to filter out 'IsEndUserFacing' apps
		// 		- Wait for Jose to respond regarding this solution.
		apps: userAccess?.AccessibleApps, // THIS WAS THE WORKING VERSION: 12/7/2021 at 11:08 AM
		// apps: userInfo?.userApps, // prev 'userInfo?.userApps'
		currentUser: {
			...state.currentUser,
			firstName: first,
			lastName: last,
			middleName: userInfo?.userProfile?.middleName,
			userID: userID,
			profileID: securityInfo?.UserProfile?.UserProfileID,
			username: auth?.username,
			password: auth?.password,
			email: userInfo?.advUser?.strEmail,
			token: auth?.token,
			// new perms settings
			isSuperUser: isSuper,
			isFacilityAdmin: isFacAdmin,
			// isALAAdmin: isAdmin,
			isAdmin: isAdmin,
			isRegionalAdmin: isRegional,
			isMedTechRestricted: isMedTech,
			// NEW 'READ-ONLY' USER TYPE
			isReadOnly: isReadOnly,
			// isSuperUser: userInfo?.advUser?.superUser ?? false,
			// isAdmin: userInfo?.advUser?.alaAdmin ?? false,
			// isFacilityAdmin: userInfo?.advUser?.bitFacilityAdministrator,
			hasMultiFacility: hasMultiFacility(facilityList),
			title: title,
			userTypeID: userInfo?.userProfile?.userTypeID,
			jobTitleID: userInfo?.userProfile?.jobTitleID,
			facilityID: facilityID,
			facilities: processFacilityList(facilityList),
			hasTrackerAccess: hasTrackerAccess,
			hasLegacyAccess: hasLegacyAccess,
			hasPortalAccess: hasPortalAccess,
			security: { ...userInfo },
			phoneNumber: userPhoneInfo?.phoneNumber,
			phoneExt: userPhoneInfo?.phoneExt,
			phoneID: userPhoneInfo?.phoneID,
			// new fields
			badLogins: userInfo?.advUser?.BadLogins,
			encryptedPassword: userInfo?.advUser?.EncryptedPassword,
			pwLastChanged: userInfo?.advUser?.PWLastChanged,
		},
		currentFacility: getFacilityByUserType(facilityList),
		facilities: processFacilityList(newData?.facilityList),
		parentsMap: parentsMap,
	};

	return { ...newState };
};

export { getInitialResource, populateState };
