import React from "react";
import styles from "../../css/loc/LOCHelpButton.module.scss";
import sprite from "../../assets/icons/tables.svg";
import { PropTypes } from "prop-types";

const LOCHelpButton = ({ handleHelp }) => {
	return (
		<button type="button" onClick={handleHelp} className={styles.LOCHelpButton}>
			<svg className={styles.LOCHelpButton_icon}>
				<use xlinkHref={`${sprite}#icon-help1`}></use>
			</svg>
		</button>
	);
};

export default LOCHelpButton;

LOCHelpButton.defaultProps = {};

LOCHelpButton.propTypes = {};
