import React from "react";
import styles from "../../css/alerts/RedirectedNotice.module.scss";
import { PropTypes } from "prop-types";
import AlertBanner from "./AlertBanner";
import { getReferrerUrl } from "../../helpers/utils_params";

const locations = {
	"https://trackertest.aladvantage.com": "Advantage Tracker (TEST)",
	"https://tracker.aladvantage.com": "Advantage Tracker",
	"http://localhost:3001": "Localhost Tracker (TEST)",
};

const RedirectedNotice = ({
	alertHeading = "You were redirected!",
	alertSubheading = getReferrerUrl(),
	alertType = "INFO",
	closeAlert,
}) => {
	return (
		<aside className={styles.RedirectedNotice}>
			<AlertBanner
				heading={alertHeading}
				subheading={`From: ${locations[alertSubheading]}`}
				type={alertType}
				closeAlert={closeAlert}
			/>
		</aside>
	);
};

export default RedirectedNotice;

RedirectedNotice.defaultProps = {};

RedirectedNotice.propTypes = {};
