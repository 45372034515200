import React from "react";
import styles from "../../css/tabs/ControllableTabsButtons.module.scss";
import { PropTypes } from "prop-types";

const ControllableTabsButtons = ({
	handleTabButton,
	activeIndex,
	disabledIndices = [],
	hiddenIndices = [],
	children,
	customStyles = {},
}) => {
	const withProps = React.Children.map(children, (child, i) =>
		React.cloneElement(child, {
			handleTabButton: handleTabButton,
			tabIndex: i,
			activeIndex: activeIndex,
			isDisabled: disabledIndices.includes(i),
			isHidden: hiddenIndices.includes(i),
		})
	);

	return (
		<section className={styles.ControllableTabsButtons} style={customStyles}>
			{withProps}
		</section>
	);
};

export default ControllableTabsButtons;

ControllableTabsButtons.defaultProps = {};

ControllableTabsButtons.propTypes = {};
