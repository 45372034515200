import React, { useEffect, useState } from "react";
import styles from "../../css/user/UserSecurity.module.scss";
import sprite from "../../assets/icons/multistep.svg";
import { PropTypes } from "prop-types";
import { purple } from "../../helpers/utils_styles";
import { getAllSecurityQuestions } from "../../helpers/utils_security";
import { isEmptyArray } from "../../helpers/utils_types";
// components
import ChangePassword from "./ChangePassword";
import ChangeQuestions from "./ChangeQuestions";

// card :focus/:hover shadow
const shadow = "-2px 5px 50px -5px rgba(139, 92, 246, 0.95)";

const UserSecurityCard = ({
	isSelected = false,
	label,
	icon = "",
	selectAction,
}) => {
	const customCSS = {
		backgroundColor: isSelected ? purple[600] : "#ffffff",
		boxShadow: isSelected ? shadow : "",
		color: isSelected ? purple[100] : purple[600],
	};

	const iconCSS = {
		fill: isSelected ? purple[100] : purple[600],
	};

	return (
		<div
			className={styles.UserSecurityCard}
			onClick={selectAction}
			style={customCSS}
		>
			<svg className={styles.UserSecurityCard_icon} style={iconCSS}>
				<use xlinkHref={`${sprite}#icon-${icon}`}></use>
			</svg>
			<span className={styles.UserSecurityCard_text}>{label}</span>
		</div>
	);
};

const UserSecurity = ({ globalState = {}, dispatchToState, dispatchAlert }) => {
	const { currentUser } = globalState;
	const { security: userInfo } = currentUser;
	const [securityAction, setSecurityAction] = useState(null);
	const [securityQuestions, setSecurityQuestions] = useState([]);

	// switches between actions (eg. 'ChangePassword' & 'ChangeQuestions')
	const selectAction = (action) => {
		if (securityAction === "changePassword" && action === "changePassword") {
			return setSecurityAction(null);
		} else if (
			securityAction === "changeQuestions" &&
			action === "changeQuestions"
		) {
			return setSecurityAction(null);
		} else {
			return setSecurityAction(action);
		}
	};

	// fetch ALL security questions
	const fetchQuestions = async () => {
		const { token } = currentUser;
		const questions = await getAllSecurityQuestions(token);
		if (!isEmptyArray(questions)) {
			return setSecurityQuestions(questions);
		} else {
			return;
		}
	};

	// fetch QAs on load/mount
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (isEmptyArray(securityQuestions)) {
			fetchQuestions();
		}
		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.UserSecurity}>
			<section className={styles.UserSecurity_actionCards}>
				<UserSecurityCard
					icon="key"
					selectAction={() => selectAction("changePassword")}
					label="Change Password"
					isSelected={securityAction === "changePassword"}
				/>

				<UserSecurityCard
					icon="vpn_lock"
					selectAction={() => selectAction("changeQuestions")}
					label="Change Security Question(s)"
					isSelected={securityAction === "changeQuestions"}
				/>
			</section>
			{/* UI TO RENDER CONDITIONALLY */}
			<section className={styles.UserSecurity_options}>
				{securityAction === "changePassword" && (
					<ChangePassword
						currentUser={currentUser}
						dispatchAlert={dispatchAlert}
						dispatchToState={dispatchToState}
					/>
				)}
				{securityAction === "changeQuestions" && (
					<ChangeQuestions
						currentUser={currentUser}
						dispatchAlert={dispatchAlert}
						dispatchToState={dispatchToState}
						securityQuestions={securityQuestions}
						userQAs={userInfo?.userQAs}
					/>
				)}
			</section>
		</div>
	);
};

export default UserSecurity;

UserSecurity.defaultProps = {};

UserSecurity.propTypes = {
	currentUser: PropTypes.object,
	currentFacility: PropTypes.object,
};
