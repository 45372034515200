import { currentEnv } from "./utils_env";
import { auth } from "./utils_endpoints";
import { SERVICES } from "./utils_apps";
import { isEmptyVal } from "./utils_types";
import { isExpiredSession } from "./utils_auth";
import { clearStorage, getFromStorage, saveToStorage } from "./utils_caching";
import addHours from "date-fns/add_hours/index.js";

/**
 * 'SystemUser': an administrative user that performs & executes actions on behalf of users, especially un-authenticated users.
 */
const SYSTEM_USER = {
	username: `${process.env.REACT_APP_SYSTEM_USER_LOGIN}`,
	password: `${process.env.REACT_APP_SYSTEM_USER_PASSWORD}`,
	isAuthenticated: false,
	token: null,
	expiry: null,
};

const systemLogin = async (username, password, appName, callback = null) => {
	let url = currentEnv.base + auth.login;
	url += "?" + new URLSearchParams({ loginId: username });
	url += "&loginPwd=" + encodeURIComponent(password);
	url += "&" + new URLSearchParams({ loginApp: appName });
	// DEPRECATED USAGE: ('URLSearchParams' uses its own URL encoding that screws things up)
	// url += "&" + new URLSearchParams({ loginPwd: encodeURIComponent(password) });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		if (callback) return callback();

		return response.Data;
	} catch (err) {
		console.log("❌ System Failed to 'start':", err);
		return err;
	}
};

/**
 * Authenticates the 'SYSTEM_USER' account & returns security data & user info.
 * @returns {Object} - Returns authenticated 'SYSTEM_USER' data object
 */
const getSystemUserAuth = async () => {
	const { username, password } = SYSTEM_USER;
	const { AdminPortal } = SERVICES;
	// decoded password: seems to work w/ & w/o???
	// const decodedPassword = decodeURIComponent(
	// 	process.env.REACT_APP_SYSTEM_USER_PASSWORD
	// );

	const token = await systemLogin(username, password, AdminPortal.alias);

	// NEW 'SYSTEM' initializer handling as of 7/22/2021 at 8:57 AM
	// - Includes early return for failures
	if (token instanceof Error || isEmptyVal(token) || !token) {
		clearStorage("SYSTEM");
		return {
			username: username,
			password: password,
			token: null,
			isAuthenticated: false,
			expiry: null,
		};
	} else {
		console.log(`✅ 'SYSTEM' Was Initialized...`);
		const freshAuth = {
			username: username,
			password: password,
			token: token,
			isAuthenticated: true,
			expiry: addHours(Date.now(), 3),
		};
		// duplicate to 'cache'
		saveToStorage("SYSTEM", {
			token: freshAuth.token,
			expiry: freshAuth.expiry,
		});

		return { ...freshAuth };
	}
};

/**
 * Determines whether 'SYSTEM_USER' needs re-authentication.
 * @param {String} cacheKey - Unique 'SYSTEM' identifier for cache.
 * @returns {Boolean} - Returns whether auth should be refreshed.
 */
const shouldRefreshSystem = (cacheKey = "SYSTEM") => {
	const cache = getFromStorage(cacheKey);

	// if cache exists, check expiry
	if (!isEmptyVal(cache?.expiry)) {
		const { isExpired } = isExpiredSession(cache.expiry);

		return {
			shouldRefresh: isExpired,
			data: cache,
		};
	} else {
		// otherwise refresh, since cache doesn't exist
		return {
			shouldRefresh: true,
			data: null,
		};
	}
};

/**
 * Checks if 'SYSTEM_USER' has been authenticated via cache or requires fresh authentication.
 * - Add handling for "soon-to-expire" instances:
 *    - Scenarios where expiry is in 10 minutes or less
 *
 */
const getSystemAuth = async () => {
	const { shouldRefresh, data } = shouldRefreshSystem("SYSTEM");

	if (shouldRefresh) {
		const freshAuth = await getSystemUserAuth();
		return freshAuth;
	} else {
		return data;
	}
};

export { SYSTEM_USER };
export { getSystemUserAuth, getSystemAuth, shouldRefreshSystem };
