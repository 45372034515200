import React from "react";
import styles from "../../css/shared/ValidationMsg.module.scss";
import sprite from "../../assets/icons/settings.svg";
import { PropTypes } from "prop-types";
import {
	blue,
	red,
	green,
	orange,
	yellow,
	blueGrey,
	purple,
} from "../../helpers/utils_styles";

const icons = {
	invalid: "clearclose",
	valid: "check_circle",
};

const colors = {
	red: red[500],
	blue: blue[600],
	purple: purple[700],
	green: green[500],
	orange: orange[400],
	yellow: yellow[400],
	blueGrey: blueGrey[700],
	lightGrey: blueGrey[300],
};

const ValidationMsg = ({ icon, msg, customColor = "red" }) => {
	return (
		<div className={styles.ValidationMsg}>
			<svg
				className={styles.ValidationMsg_icon}
				style={{ fill: colors[customColor] }}
			>
				<use xlinkHref={`${sprite}#icon-${icons[icon]}`}></use>
			</svg>
			<span
				className={styles.ValidationMsg_msg}
				style={{ color: colors[customColor] }}
			>
				{msg}
			</span>
		</div>
	);
};

export default ValidationMsg;

ValidationMsg.defaultProps = {};

ValidationMsg.propTypes = {};
