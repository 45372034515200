import React, { useState } from "react";
import styles from "../../css/modules/ModulePermission.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { blue } from "../../helpers/utils_styles";
import { isOdd } from "../../helpers/utils_processing";

// const enabled = `check_circle`;
const enabled = `done`;

const enabledCss = {
	backgroundColor: blue[600],
	fill: "#ffffff",
};
const disabledCss = {
	backgroundColor: "#ffffff",
	fill: "#ffffff",
};

const ModuleEnabler = ({ moduleID, isEnabled = false, handleModule }) => {
	return (
		<div
			className={styles.ModuleEnabler}
			onClick={handleModule}
			style={isEnabled ? enabledCss : disabledCss}
			id={moduleID}
		>
			{isEnabled && (
				<svg className={styles.ModuleEnabler_icon}>
					<use xlinkHref={`${sprite}#icon-${enabled}`}></use>
				</svg>
			)}
		</div>
	);
};

const randomize = (idx) => {
	const enable = isOdd(idx);
	return enable;
};

const ModulePermission = ({ moduleIdx, moduleName, moduleID }) => {
	const [isEnabled, setIsEnabled] = useState(randomize(moduleIdx));
	const [showInfo, setShowInfo] = useState(false);

	const moduleHandler = (e) => {
		setIsEnabled(!isEnabled);
	};

	const toggleShowInfo = () => {
		setShowInfo(!showInfo);
	};

	return (
		<div className={styles.ModulePermission}>
			<label
				htmlFor={moduleID}
				className={styles.ModulePermission_label}
				onClick={moduleHandler}
			>
				{moduleName}
			</label>
			<ModuleEnabler
				moduleID={moduleID}
				isEnabled={isEnabled}
				handleModule={moduleHandler}
			/>
		</div>
	);
};

export default ModulePermission;

ModulePermission.defaultProps = {};

ModulePermission.propTypes = {};
