import React, { useState } from "react";
import styles from "../../css/user/UserNotifications.module.scss";
import sprite from "../../assets/icons/multistep.svg";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
// components
import TextInput from "../shared/TextInput";
import AddAltEmail from "./AddAltEmail";
import Divider from "../forms/Divider";

// REQUIREMENTS:
// - Task Notifications:
// 		- IF a task is PAST-DUE
// 		- IF a task is coming due soon
// 		- IF a task is marked w/ EXCEPTION
// 		- IF a NEW task is created (for admins)
// 		- IF a task is marked for REASESSMENT

// - Include 'Add Alternate Email Address'

const customCSS = {
	divider: {
		display: "block",
		margin: "3rem 0",
	},
	input: {
		backgroundColor: "#ffffff",
	},
};

const hasExistingAltEmail = (currentUser = {}) => {
	// check if user has already entered an alt email
	return false;
};

const UserNotifications = ({
	currentUser = {},
	currentFacility = {},
	userAlerts = [],
}) => {
	const { formState, handleChange, handleReset } = useForm({
		altEmail: "",
	});
	const { values } = formState;

	const cancelChanges = (e) => {
		handleReset(e);
	};

	return (
		<div className={styles.UserNotifications}>
			<div className={styles.UserNotifications_addEmail}>
				<AddAltEmail
					vals={values}
					handleChange={handleChange}
					handleReset={handleReset}
				/>
			</div>
			<Divider customStyles={customCSS.divider} />
			<div className={styles.UserNotifications_today}>
				{/*  */}
				{/*  */}
				{/*  */}
			</div>
		</div>
	);
};

export default UserNotifications;

UserNotifications.defaultProps = {
	currentUser: {},
	currentFacility: {},
};

UserNotifications.propTypes = {
	currentUser: PropTypes.object.isRequired,
	currentFacility: PropTypes.object.isRequired,
};
