import React from "react";
import styles from "../../css/messages/AppMessage.module.scss";
import { PropTypes } from "prop-types";
import Textarea from "../shared/Textarea";
import DatePickerSM from "../shared/DatePickerSM";
import CustomDropdown from "../shared/CustomDropdown";
import CustomCheckbox from "../shared/CustomCheckbox";
import { capitalizeCharByIdx } from "../../helpers/utils_processing";

// REQUIREMENTS:
// - Editable priority
// - Editable expiry
// - Editable text message

const customCSS = {
	priority: {
		width: "40rem",
	},
	expiry: {
		width: "40rem",
	},
};

const getStateName = (name) => {
	const cap = capitalizeCharByIdx(0, name);
	const newName = `enable${cap}`;
	return newName;
};

const priorties = [
	"High Priority (red)",
	"Medium Priority (orange)",
	"Low Priority (grey)",
];

const AppMessage = ({
	vals = {},
	name = "",
	id = "",
	handleChange,
	handlePriority,
	handleExpiry,
	handleCheckbox,
}) => {
	const priorityName = `${name}Priority`;
	const dateName = `${name}Expiry`;
	const stateName = getStateName(name);

	return (
		<div className={styles.AppMessage}>
			<div className={styles.AppMessage_form}>
				<Textarea
					label="Enter Message"
					name={name}
					id={id}
					val={vals?.[name]}
					maxChar={250}
					enableCharCount={true}
					handleChange={handleChange}
				/>
				<div className={styles.AppMessage_form_item}>
					<CustomDropdown
						label="Message Priority"
						name={priorityName}
						id={priorityName}
						options={priorties}
						setSelection={handlePriority}
						selection={vals?.[priorityName]}
						customStyles={customCSS.priority}
					/>
				</div>
				<div className={styles.AppMessage_form_item}>
					<DatePickerSM
						label="Message Expiry (message will disappear after this date)"
						name={dateName}
						id={dateName}
						val={vals?.[dateName]}
						handleDate={handleExpiry}
						focusMode={true}
						customStyles={customCSS.expiry}
					/>
				</div>
				<div className={styles.AppMessage_form_toggle}>
					<CustomCheckbox
						name={stateName}
						id={stateName}
						val={vals?.[stateName]}
						label="Enable Message"
						handleCheckbox={handleCheckbox}
					/>
				</div>
			</div>
		</div>
	);
};

export default AppMessage;

AppMessage.defaultProps = {};

AppMessage.propTypes = {};
