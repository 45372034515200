import React from "react";
import styles from "../../css/admin/UserAccessHeader.module.scss";
import { PropTypes } from "prop-types";

const UserAccessHeader = ({
	title = "Manage User Access",
	subtitle,
	usersList = [],
	isLegacyOnly = false,
}) => {
	return (
		<>
			<div className={styles.UserAccessHeader}>
				<header className={styles.UserAccessHeader}>
					<div className={styles.UserAccessHeader_left}>
						<h2 className={styles.UserAccessHeader_left_title}>{title}</h2>
						<div className={styles.UserAccessHeader_left_count}>{subtitle}</div>
					</div>
					<div className={styles.UserAccessHeader_right}>
						<div className={styles.UserAccessHeader_right_count}>
							Showing <b>{usersList?.length}</b> users
						</div>
						{isLegacyOnly && (
							<div className={styles.UserAccessHeader_right_legacyOnly}>
								<b>*</b>Legacy users ONLY! Some features may be disabled!
							</div>
						)}
					</div>
				</header>
			</div>
		</>
	);
};

export default UserAccessHeader;

UserAccessHeader.defaultProps = {
	usersList: [],
};

UserAccessHeader.propTypes = {
	usersList: PropTypes.arrayOf(PropTypes.object).isRequired,
};
