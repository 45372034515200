import React from "react";
import styles from "../../css/shared/Placeholder.module.scss";
import { PropTypes } from "prop-types";
import {
	purple,
	red,
	blue,
	green,
	teal,
	orange,
	yellow,
	pink,
	blueGrey,
	grey,
} from "../../helpers/utils_styles";

const colors = {
	red: red[600],
	purple: purple[600],
	green: green[500],
	blue: blue[600],
	teal: teal[500],
	orange: orange[500],
	yellow: yellow[500],
	pink: pink[500],
	blueGrey: blueGrey[700],
	grey: grey[700],
};

const sizes = {
	XSM: "1.4rem",
	SM: "1.6rem",
	MD: "2rem",
	LG: "3rem",
	XLG: "3.5rem",
};

// used for error message and other placeholder messages

const Placeholder = ({
	size = "MD",
	color = "red",
	msg = "",
	customStyles = {},
	children,
}) => {
	const msgStyles = {
		fontSize: sizes[size],
		color: colors[color],
		...customStyles,
	};

	return (
		<aside className={styles.Placeholder}>
			<div className={styles.Placeholder_msg} style={msgStyles}>
				{msg}
			</div>
			{children}
		</aside>
	);
};

export default Placeholder;

Placeholder.defaultProps = {
	customStyles: {},
};
Placeholder.propTypes = {
	size: PropTypes.string,
	msg: PropTypes.string.isRequired,
	customStyles: PropTypes.object,
	children: PropTypes.any,
};
