import React from "react";
import styles from "../../css/migration/MigrationPanelSummary.module.scss";
import { PropTypes } from "prop-types";
import FacilitySummary from "../facility/FacilitySummary";

const MigrationPanelSummary = ({ currentFacility = {}, currentUser = {} }) => {
	return (
		<div className={styles.MigrationPanelSummary}>
			<FacilitySummary facility={currentFacility} />
			<div className={styles.MigrationPanelSummary_summary}>
				{/* MIGRATION SUMMARY & DETAILS */}
				{/* MIGRATION SUMMARY & DETAILS */}
				{/* MIGRATION SUMMARY & DETAILS */}
			</div>
		</div>
	);
};

export default MigrationPanelSummary;

MigrationPanelSummary.defaultProps = {};

MigrationPanelSummary.propTypes = {};
