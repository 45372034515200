import React from "react";
import styles from "../../css/loctemplate/LOCTemplateTableFooter.module.scss";
import sprite from "../../assets/icons/tables.svg";
import { PropTypes } from "prop-types";

const AddRowButton = ({ handleAddRow, isDisabled }) => {
	return (
		<button
			type="button"
			onClick={handleAddRow}
			disabled={isDisabled}
			className={styles.AddRowButton}
			title={
				isDisabled ? "You have reached the max levels of care" : "Add new row"
			}
		>
			<svg className={styles.AddRowButton_icon}>
				<use xlinkHref={`${sprite}#icon-add`}></use>
			</svg>
			<span className={styles.AddRowButton_text}>Add Row</span>
		</button>
	);
};

const ResetButton = ({ handleReset, isDisabled = false }) => {
	return (
		<button
			type="button"
			onClick={handleReset}
			disabled={isDisabled}
			className={styles.ResetButton}
			title={"Reset table to default settings"}
		>
			<svg className={styles.ResetButton_icon}>
				<use xlinkHref={`${sprite}#icon-refresh`}></use>
			</svg>
			<span className={styles.ResetButton_text}>Reset</span>
		</button>
	);
};

// disables 'Reset' button
const disableReset = (hasChanges, isEditable) => {
	if (!isEditable) return true;
	return !hasChanges;
};
// disables 'Add Row' button
const disableAdd = (hasMaxLevels, isEditable) => {
	if (!isEditable) return true;
	return hasMaxLevels;
};

const LOCTemplateTableFooter = ({
	isEditable = true,
	hasMaxLevels = false,
	hasChanges = false,
	enableReset = false,
	handleAddRow,
	handleReset,
	template = {},
}) => {
	return (
		<div className={styles.LOCTemplateTableFooter}>
			{enableReset && (
				<ResetButton
					key={`RESET-BUTTON`}
					isDisabled={disableReset(hasChanges, isEditable)}
					handleReset={handleReset}
				/>
			)}
			<AddRowButton
				key={`ADD-ROW-BUTTON`}
				isDisabled={disableAdd(hasMaxLevels, isEditable)}
				handleAddRow={handleAddRow}
			/>
		</div>
	);
};

export default LOCTemplateTableFooter;

LOCTemplateTableFooter.defaultProps = {};

LOCTemplateTableFooter.propTypes = {};
