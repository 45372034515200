import React from "react";
import styles from "../../css/user/UserSecuritySummary.module.scss";
import { PropTypes } from "prop-types";
// components
import UserSecurityQuestionsSummary from "./UserSecurityQuestionsSummary";
import UserSecurityResetSummary from "./UserSecurityResetSummary";

// REQUIREMENTS:
// - Show Security Questions & Answers
// - Show Password Reset Methods

const UserSecuritySummary = ({ vals = {} }) => {
	return (
		<div className={styles.UserSecuritySummary}>
			<div className={styles.UserSecuritySummary_header}>
				<div className={styles.UserSecuritySummary_header_title}>
					User Security Summary
				</div>
			</div>
			{/* QUESTIONS & RESET METHODS SUMMARIES */}
			<UserSecurityQuestionsSummary vals={vals} />
			<UserSecurityResetSummary vals={vals} />
		</div>
	);
};

export default UserSecuritySummary;

UserSecuritySummary.defaultProps = {
	vals: {},
};

UserSecuritySummary.propTypes = {
	vals: PropTypes.object,
};
