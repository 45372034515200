import React, { useState } from "react";
import styles from "../../css/admin/FacilityBadgesLegend.module.scss";
import sprite from "../../assets/icons/us-states.svg";
import { PropTypes } from "prop-types";
import { pink, purple, teal } from "../../helpers/utils_styles";

const sampleTypes = {
	INDEPENDENT: {
		color: pink[600],
		label: "I",
	},
	PARENT: {
		color: purple[700],
		label: "P",
	},
	CHILD: {
		color: teal[600],
		label: "C",
	},
};

const FacilityBadge = ({ state = "NA" }) => {
	return (
		<div className={styles.FacilityBadge}>
			<svg className={styles.FacilityBadge_icon}>
				<use xlinkHref={`${sprite}#icon-state-${state}`}></use>
			</svg>
		</div>
	);
};

const FacilityTypeBadge = ({ type }) => {
	const renderBadge = (type) => {
		switch (type) {
			case "INDEPENDENT": {
				return (
					<div className={styles.IndyBadge}>
						<div className={styles.IndyBadge_label}>I</div>
					</div>
				);
			}
			case "PARENT": {
				return (
					<div className={styles.ParentBadge}>
						<div className={styles.ParentBadge_label}>P</div>
					</div>
				);
			}
			case "CHILD": {
				return (
					<div className={styles.ChildBadge}>
						<div className={styles.ChildBadge_label}>C</div>
					</div>
				);
			}

			default:
				break;
		}
	};

	return <>{renderBadge(type)}</>;
};

const FacilityBadgeExample = ({ state = "CA", type = "CHILD" }) => {
	return (
		<div className={styles.FacilityBadgeExample}>
			<FacilityBadge state={state} />
			<FacilityTypeBadge type={type} />
		</div>
	);
};

const FacilityBadgesLegend = () => {
	const { Independent, Parent, Child } = sampleTypes;

	const [showLegend, setShowLegend] = useState(false);

	return (
		<div className={styles.FacilityBadgesLegend}>
			<div className={styles.FacilityBadgesLegend_toggle}>
				<button
					type="button"
					onClick={() => setShowLegend(!showLegend)}
					className={styles.FacilityBadgesLegend_toggle_btn}
				>
					{showLegend ? "Hide" : "Show"} Badges Legend
				</button>
			</div>

			{showLegend && (
				<div className={styles.FacilityBadgesLegend_details}>
					<div className={styles.FacilityBadgesLegend_details_example}>
						<FacilityBadgeExample
							key={`INDEPENDENT-EXAMPLE`}
							type="INDEPENDENT"
							state="CA"
						/>
						<div
							className={styles.FacilityBadgesLegend_details_example_label}
							id="independent"
						>
							Independent
						</div>
					</div>
					<div className={styles.FacilityBadgesLegend_details_example}>
						<FacilityBadgeExample
							key={`PARENT-EXAMPLE`}
							type="PARENT"
							state="CA"
						/>
						<div
							className={styles.FacilityBadgesLegend_details_example_label}
							id="parent"
						>
							Parent
						</div>
					</div>
					<div className={styles.FacilityBadgesLegend_details_example}>
						<FacilityBadgeExample
							key={`CHILD-EXAMPLE`}
							type="CHILD"
							state="CA"
						/>
						<div
							className={styles.FacilityBadgesLegend_details_example_label}
							id="child"
						>
							Child
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default FacilityBadgesLegend;

FacilityBadgesLegend.defaultProps = {};

FacilityBadgesLegend.propTypes = {};
