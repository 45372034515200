import React from "react";
import styles from "../../css/shared/Empty.module.scss";
import { PropTypes } from "prop-types";
import sprite from "../../assets/icons/placeholders.svg";

const icons = {
	box: "box",
	envelope: "envelope",
	sortNumbers: "sort-numerically",
	sortLetters: "sort-alphabetically",
	flag: "flag",
	archive: "archive",
	check: "check",
	checkWithCircle: "check-circle",
	checkWithSquare: "check-square",
	inbox: "inbox",
	add: "add",
	close: "clearclose",
	openEnvelope: "drafts",
	flagRigidDark: "flagassistant_photo",
	flagRigidLight: "outlined_flag",
	inventory: "inventory",
	inventorDark: "box1",
	check1: "check1",
	notAllowed: "do_not_disturb_alt",
	thumbsDown: "thumb_down_alt",
	checkFilled: "check_circle",
	checkAll: "done_all",
	lightbulb: "lightbulb_outline",
	album: "album",
	arrowDown: "arrow-down",
	arrowUp: "arrow-thin-up",
	arrowLeft: "arrow-left",
	arrowRight: "arrow-right",
	bug: "bug",
	buoy: "buoy",
	checkThick: "checkmark",
	checkRough: "checkmark-outline",
	closeOutline: "close-outline",
	closeSolid: "close-solid",
	hand: "hand-stop",
	inbox2: "inbox2",
	inboxCheck: "inbox-check",
	inboxDownload: "inbox-download",
	inboxFull: "inbox-full",
	lightbulb2: "light-bulb",
	portfolio: "portfolio",
	save: "save-disk",
	timer: "timer",
	hide: "view-hide",
	show: "view-show",
};

const sizes = {
	XSM: {
		icon: {
			width: "1.2rem",
			height: "1.2rem",
		},
		heading: {
			fontSize: "1.1rem",
		},
		subheading: {
			fontSize: ".8rem",
		},
	},
	SM: {
		icon: {
			width: "1.8rem",
			height: "1.8rem",
		},
		heading: {
			fontSize: "1.4rem",
		},
		subheading: {
			fontSize: "1.1rem",
		},
	},
	MD: {
		icon: {
			width: "2.5rem",
			height: "2.5rem",
		},
		heading: {
			fontSize: "1.6rem",
		},
		subheading: {
			fontSize: "1.3rem",
		},
	},
	LG: {
		icon: {
			width: "5rem",
			height: "5rem",
		},
		heading: {
			fontSize: "2rem",
		},
		subheading: {
			fontSize: "1.7rem",
		},
	},
	XLG: {
		icon: {
			width: "7rem",
			height: "7rem",
		},
		heading: {
			fontSize: "2.2rem",
		},
		subheading: {
			fontSize: "1.8rem",
		},
	},
};

const Empty = ({
	icon = "box",
	size = "MD",
	heading,
	subheading,
	children,
	customStyles = {},
	hideIcon = false,
}) => {
	const iconCSS = {
		...sizes[size]?.icon,
		...customStyles?.icon,
	};
	const headingCSS = {
		...sizes[size]?.heading,
		...customStyles?.heading,
	};
	const subheadingCSS = {
		...sizes[size]?.subheading,
		...customStyles?.subheading,
	};

	return (
		<div className={styles.Empty}>
			<div className={styles.Empty_inner}>
				{!hideIcon && (
					<svg className={styles.Empty_inner_icon} style={iconCSS}>
						<use xlinkHref={`${sprite}#icon-${icons[icon]}`}></use>
					</svg>
				)}
				<div className={styles.Empty_inner_text}>
					<h4 className={styles.Empty_inner_text_heading} style={headingCSS}>
						{heading}
					</h4>
					<h6
						className={styles.Empty_inner_text_subheading}
						style={subheadingCSS}
					>
						{subheading}
					</h6>
				</div>
				<div className={styles.Empty_inner_optional}>{children}</div>
			</div>
		</div>
	);
};

export default Empty;

Empty.defaultProps = {
	icon: "box",
	size: "MD",
	customStyles: {},
};

Empty.propTypes = {
	icon: PropTypes.string,
	size: PropTypes.string,
	heading: PropTypes.string,
	subheading: PropTypes.string,
	customStyles: PropTypes.object,
};
