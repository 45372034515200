import {
	subQuarters,
	subYears,
	subDays,
	subWeeks,
	subMonths,
	isWithinRange,
	startOfDay,
	startOfWeek,
	startOfQuarter,
	endOfQuarter,
	startOfMonth,
	endOfMonth,
	startOfYear,
	format,
	isThisMonth,
	isValid,
	differenceInMonths,
	differenceInWeeks,
	differenceInDays,
	differenceInHours,
	differenceInMinutes,
	differenceInSeconds,
	isBefore,
	differenceInMilliseconds,
} from "date-fns";
import { isEmptyVal } from "./utils_types";

export const months = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
];

/**
 * Calculates the difference in time between two dates by a custom unit such as: 'months', 'weeks', 'days', 'hours', 'mins' or 'secs'.
 * @param {String} unit - A string-form time identifier for the return unit type (eg. 'months', 'days', 'mins' etc.)
 * @param {Object} dates - An object with the 'startDate' and the 'expiryDate' to calculate for.
 * @returns {Object} - Returns an object with the raw numeric value and a human-readable formatted string of the difference
 */
const calcTimeToExpiryByUnit = (unit = "days", dates = {}) => {
	const { startDate, expiryDate } = dates;

	switch (unit) {
		case "months": {
			const diffInMonths = differenceInMonths(expiryDate, startDate);
			const formattedStart = format(startDate, "M/D/YYYY h:mm A");
			const formattedExpiry = format(expiryDate, "M/D/YYYY h:mm A");

			return {
				value: diffInMonths,
				formatted: `${diffInMonths} months between ${formattedStart} and ${formattedExpiry}`,
			};
		}
		case "weeks": {
			const diffInWeeks = differenceInWeeks(expiryDate, startDate);
			const formattedStart = format(startDate, "M/D/YYYY h:mm A");
			const formattedExpiry = format(expiryDate, "M/D/YYYY h:mm A");

			return {
				value: diffInWeeks,
				formatted: `${diffInWeeks} weeks between ${formattedStart} and ${formattedExpiry}`,
			};
		}
		case "days": {
			const diffInDays = differenceInDays(expiryDate, startDate);
			const formattedStart = format(startDate, "M/D/YYYY h:mm A");
			const formattedExpiry = format(expiryDate, "M/D/YYYY h:mm A");

			return {
				value: diffInDays,
				formatted: `${diffInDays} days between ${formattedStart} and ${formattedExpiry}`,
			};
		}
		case "hours": {
			const diffInHours = differenceInHours(expiryDate, startDate);
			const formattedStart = format(startDate, "M/D/YYYY h:mm A");
			const formattedExpiry = format(expiryDate, "M/D/YYYY h:mm A");

			return {
				value: diffInHours,
				formatted: `${diffInHours} days between ${formattedStart} and ${formattedExpiry}`,
			};
		}
		case "mins": {
			const diffInMins = differenceInMinutes(expiryDate, startDate);
			const formattedStart = format(startDate, "M/D/YYYY h:mm A");
			const formattedExpiry = format(expiryDate, "M/D/YYYY h:mm A");

			return {
				value: diffInMins,
				formatted: `${diffInMins} days between ${formattedStart} and ${formattedExpiry}`,
			};
		}
		case "secs": {
			const diffInSecs = differenceInSeconds(expiryDate, startDate);
			const formattedStart = format(startDate, "M/D/YYYY h:mm A");
			const formattedExpiry = format(expiryDate, "M/D/YYYY h:mm A");

			return {
				value: diffInSecs,
				formatted: `${diffInSecs} days between ${formattedStart} and ${formattedExpiry}`,
			};
		}
		case "ms": {
			const diffInMs = differenceInMilliseconds(expiryDate, startDate);
			const formattedStart = format(startDate, "M/D/YYYY h:mm A");
			const formattedExpiry = format(expiryDate, "M/D/YYYY h:mm A");

			return {
				value: diffInMs,
				formatted: `${diffInMs} ms between ${formattedStart} and ${formattedExpiry}`,
			};
		}

		default:
			return {
				value: null,
				formatted: null,
			};
	}
};

// checks if the current date is before a provided 'expiryDate'
const isTimerExpired = (expiryDate) => {
	if (!isValidDate(expiryDate)) return true;

	return isBefore(new Date(), expiryDate);
};

// checks for valid date
const isValidDate = (date) => {
	return isValid(date);
};

// conversion utils

// convert minutes to milliseconds
const convertMinsToMs = (mins) => {
	return mins * 60000;
};
// convert seconds to milliseconds
const convertSecsToMs = (secs) => {
	return secs * 1000;
};
// hours to mins
const convertHoursToMins = (hrs) => {
	// '+' converts string to number via coercion
	return +hrs * 60;
};
// convert days to hours
const convertDaysToHours = (days) => {
	// '+' converts string to number via coercion
	return +days * 24;
};
// convert days to mins
const convertDaysToMins = (days) => {
	// '+' converts string to number via coercion
	return +days * 24 * 60;
};
// convert mins to hours
const convertMinsToHours = (mins) => {
	// '+' converts string to number via coercion
	return +mins / 60;
};
// convert mins to days
const convertMinsToDays = (mins) => {
	// '+' converts string to number via coercion
	return +mins / 1440;
};
// converts mins to weeks
const convertMinsToWeeks = (mins) => {
	return +mins / 10080;
};
// converts mins to months
const convertMinsToMonths = (mins) => {
	return +mins / 43800;
};

// determines which time unit to display time elapsed since lockout
const DEPRECATED_determineLockoutUnit = (mins) => {
	switch (true) {
		// greater than a day
		case mins > 1440: {
			const unit = convertMinsToDays(mins).toFixed(2);
			return `${unit} days`;
		}
		// less than a day, but greater than 90mins
		case mins < 1440 && mins > 90: {
			// in hours
			const unit = convertMinsToHours(mins).toFixed(2);
			return `${unit} hours`;
		}
		case mins < 1440 && mins <= 90: {
			// in mins
			return `${mins} mins`;
		}
		default:
			return `${mins} mins`;
	}
};
// determines which time unit to display based off elapsed time:
// - Mins > 10080 => XX weeks
// - Mins > 1440 & < 10080 => XX days
// - Mins > 60 & < 1440 => XX hours
// - Mins < 60 => XX mins
const determineLockoutUnit = (mins) => {
	switch (true) {
		// months
		case mins > 40320: {
			const months = convertMinsToMonths(mins).toFixed(2);
			return `${months} months`;
		}
		// weeks
		case mins > 10080: {
			const days = convertMinsToDays(mins);
			const weeks = days / 7;
			return `${weeks.toFixed(2)} weeks`;
		}
		// days
		case mins > 1440: {
			const days = convertMinsToDays(mins).toFixed(2);
			return `${days} days`;
		}
		// hours
		case mins > 60: {
			const hours = convertMinsToHours(mins).toFixed(2);
			return `${hours} hours`;
		}
		// mins
		default:
			return `${mins} mins`;
	}
};

// date format converter utils

// converts times in 24hr format to 12hr format (ie 'meridiem' format)
const convertToMeridiem = (time) => {
	const numTime = Number(time);
	if (numTime > 12) {
		return numTime - 12;
	}
	return numTime;
};

// enables custom 'locale' & 'timeZone' & returns time string
const convertToLocaleTime = (date, options = { timeZone: "UTC" }) => {
	return date.toLocaleTimeString("en-US", options);
};
// enables custom 'locale' & 'timeZone' & returns date string
const convertToLocaleDate = (date, options = { timeZone: "UTC" }) => {
	return date.toLocaleDateString("en-US", options);
};

// converts utc date to local date
const utcToLocal = (utcDate) => {
	const localDate = new Date(utcDate).toString();
	return localDate;
};

/**
 * Date Processing & parsing utils
 */

// parses selection from <QuarterPicker/>
// 'Q1 2019' => { startDate: 01/01/2019, endDate: 03/31/2019 }
const parseQuarter = (selection) => {
	if (isEmptyVal(selection)) return;
	const qtr = selection.split(" ")[0].trim();
	const yr = Number(selection.split(" ")[1].trim());
	if (qtr === "Q1") {
		return {
			startDate: format(startOfQuarter(new Date(yr, 0, 1)), "MM/DD/YYYY"),
			endDate: format(endOfQuarter(new Date(yr, 0, 1)), "MM/DD/YYYY"),
		};
	}
	if (qtr === "Q2") {
		return {
			startDate: format(startOfQuarter(new Date(yr, 3, 1)), "MM/DD/YYYY"),
			endDate: format(endOfQuarter(new Date(yr, 3, 1)), "MM/DD/YYYY"),
		};
	}
	if (qtr === "Q3") {
		return {
			startDate: format(startOfQuarter(new Date(yr, 6, 1)), "MM/DD/YYYY"),
			endDate: format(endOfQuarter(new Date(yr, 6, 1)), "MM/DD/YYYY"),
		};
	}
	if (qtr === "Q4") {
		return {
			startDate: format(startOfQuarter(new Date(yr, 9, 1)), "MM/DD/YYYY"),
			endDate: format(endOfQuarter(new Date(yr, 9, 1)), "MM/DD/YYYY"),
		};
	}
	throw new Error("❌ Oops. Invalid quarter given", qtr);
};

// parses selection from <MonthPicker/> into a start & end date
// 'Jun 2019' => { startDate: 06/01/2019, endDate: 06/31/2019 }
const parseMonth = (selection) => {
	if (isEmptyVal(selection)) return;

	const mo = selection.split(" ")[0].trim();
	const yr = Number(selection.split(" ")[1].trim());

	const monthIdx = months.indexOf(mo);
	const monthStart = format(
		startOfMonth(new Date(yr, monthIdx, 1)),
		"MM/DD/YYYY"
	);
	const monthEnd = format(endOfMonth(new Date(yr, monthIdx, 1)), "MM/DD/YYYY");

	return {
		startDate: monthStart,
		endDate: monthEnd,
	};
};

// checks if a date is outside a given range
const isOutsideRange = (targetDate, rangeStart, rangeEnd) => {
	return !isWithinRange(targetDate, rangeStart, rangeEnd);
};

/**
 * Date Restriction Utils
 */

/**
 * @description - Used to determine whether to restrict a date's availabiliity or not, given a date range. Used in <DatePicker/> and <DateRangePicker/>
 * @param {Date} targetDate - The date to test whether it should be restricted or available for selection, given a range.
 * @param {Date} rangeStart - Start date of 'allowed' range.
 * @param {Date} rangeEnd - End date of 'allowed' range.
 */
const isRestricted = (targetDate, rangeStart, rangeEnd) => {
	return isOutsideRange(targetDate, rangeStart, rangeEnd);
};

/**
 * @description - Used to determine whether to restrict a month's availability or not, given a date range. Used in <MonthPicker/>
 * @param {String} targetMonth - A string-form month and year (ie 'Jul 2020', 'Sep 2020' etc)
 * @param {Date} rangeStart - Start date of 'allowed' range.
 * @param {Date} rangeEnd - End date of 'allowed' range.
 * @returns {Boolean} - Returns 'true' if month should be restricted or disabled.
 */
const isMonthRestricted = (targetMonth, rangeStart, rangeEnd) => {
	const { startDate: monthStart, endDate: monthEnd } = parseMonth(targetMonth);

	// check if both start and end of month are in range,
	// that determines if month should be shown,
	// if either monthStart/monthEnd fail, restrict the month
	const isStartInRange = isWithinRange(monthStart, rangeStart, rangeEnd);
	const isEndInRange = isWithinRange(monthEnd, rangeStart, rangeEnd);

	// test for current month, if true, then don't restrict
	const isMonthSpillOver = isThisMonth(monthStart) || isThisMonth(monthEnd);

	return (!isStartInRange || !isEndInRange) && !isMonthSpillOver;
};

/**
 * @description - Accepts the 'RestrictionValue' from the app's settings record and calculates the available range of dates.
 * @param {Number} rangeVal - The 'RestrictionValue' in months to base the available date range on.
 * @returns {Object} - Returns the 'rangeStart' and 'rangeEnd' as date instances.
 */
const calculateRestrictionRange = (rangeVal = 3) => {
	const startMonth = subMonths(new Date(), rangeVal);
	const rangeStart = startOfMonth(startMonth);
	const rangeEnd = new Date();
	return {
		rangeStart,
		rangeEnd,
	};
};

// ##TODOS:
// - Create handlers for 'RestrictionUnit' types:
// 		✓ - Month
// 		✓ - Week
// 		✓ - Day
// 		✓ - Year
// 		✓ - Quarter
// 		X - Time
// 		X - Hours
// 		X - Minutes
const calculateMonthRestriction = (rangeVal = 3) => {
	const startMonth = subMonths(new Date(), rangeVal);
	const rangeStart = startOfMonth(startMonth);
	const rangeEnd = new Date();
	return {
		rangeStart,
		rangeEnd,
	};
};
const calculateWeekRestriction = (rangeVal = 12) => {
	const startWeek = subWeeks(new Date(), rangeVal);
	const rangeStart = startOfWeek(startWeek);
	const rangeEnd = new Date();
	return {
		rangeStart,
		rangeEnd,
	};
};
const calculateDayRestriction = (rangeVal = 30) => {
	const startDay = subDays(new Date(), rangeVal);
	const rangeStart = startOfDay(startDay);
	const rangeEnd = new Date();
	return {
		rangeStart,
		rangeEnd,
	};
};
const calculateYearRestriction = (rangeVal = 1) => {
	const startYr = subYears(new Date(), rangeVal);
	const rangeStart = startOfYear(startYr);
	const rangeEnd = new Date();

	return {
		rangeStart,
		rangeEnd,
	};
};
const calculateQuarterRestriction = (rangeVal = 2) => {
	const startQtr = subQuarters(new Date(), rangeVal);
	const rangeStart = startOfQuarter(startQtr);
	const rangeEnd = new Date();
	return {
		rangeStart,
		rangeEnd,
	};
};
const calculateTimeRestriction = (rangeVal = 12) => {
	// ##TODOS:
	// - Figure out how to handle time restrictions
	const rangeStart = "";
	const rangeEnd = "";
	return {
		rangeStart,
		rangeEnd,
	};
};
const calculateHourRestriction = (rangeVal = 8) => {
	// ##TODOS:
	// - Figure out how to handle hour restrictions
	const rangeStart = "";
	const rangeEnd = "";
	return {
		rangeStart,
		rangeEnd,
	};
};
const calculateMinutesRestriction = (rangeVal = 60) => {
	// ##TODOS:
	// - Figure out how to handle minute restrictions
	const rangeStart = "";
	const rangeEnd = "";
	return {
		rangeStart,
		rangeEnd,
	};
};

export {
	// checks date is valid
	isValidDate,
	// comparison utils
	isTimerExpired,
	calcTimeToExpiryByUnit,
	// convert between different time units
	convertMinsToMs,
	convertSecsToMs,
	convertHoursToMins,
	convertDaysToHours,
	convertDaysToMins,
	convertMinsToHours,
	convertMinsToDays,
	convertMinsToWeeks,
	convertMinsToMonths,
	// unit determinator
	determineLockoutUnit,
};

export {
	convertToMeridiem,
	convertToLocaleDate,
	convertToLocaleTime,
	utcToLocal,
};

// processing, converting & parsing utils
export { parseMonth, parseQuarter, isOutsideRange };

// restriction utils
export {
	isRestricted,
	isMonthRestricted,
	calculateDayRestriction,
	calculateHourRestriction,
	calculateMinutesRestriction,
	calculateMonthRestriction,
	calculateQuarterRestriction,
	calculateTimeRestriction,
	calculateWeekRestriction,
	calculateYearRestriction,
	calculateRestrictionRange,
};
