import React from "react";
import styles from "../../css/app/MobileSidebarList.module.scss";
import { PropTypes } from "prop-types";

const MobileSidebarList = ({ history, children }) => {
	const withState = React.Children.map(children, (child, i) => {
		return React.cloneElement(child, {
			history: history,
		});
	});

	return <ul className={styles.MobileSidebarList}>{withState}</ul>;
};

export default MobileSidebarList;

MobileSidebarList.defaultProps = {};

MobileSidebarList.propTypes = {
	history: PropTypes.object,
	children: PropTypes.any,
};
