import { appIDs } from "./utils_apps";
import {
	User2FARecord,
	UserAlertRecord,
	UserAppsRecord,
	UserAvatarRecord,
	UserEmailRecord,
	UserLoginModel,
	UserLoginRecord,
	UserPhoneRecord,
	UserProfileModel,
	UserProfileRecord,
	UserRoleGroupsRecord,
	UserRolesRecord,
	UserSecurityAnswerRecord,
} from "./utils_models";
import {
	getFacilityIDsFromSelections,
	getFacilityMapByName,
	matchUserTitleFromStr,
	matchUserTypeFromStr,
	updateLoginFacilities,
} from "./utils_user";
import { matchQuestionIDFromStr } from "./utils_security";
import { currentEnv } from "./utils_env";
import { user } from "./utils_endpoints";
import { generateTempPasswordEmail } from "./utils_templates";
import { isValidEmail, matchesPattern } from "./utils_validation";

/**
 * Creates a new user's profile and login records at once.
 * @param {String} token - Security token
 * @param {Object} newUser - All new user records for both 'UserProfileModel' & 'UserLoginModel'
 * @returns {Object} - Returns the status of the request and meta data about the response.
 */
const createNewUserProfileAndLogin = async (token, newUser = {}) => {
	let url = currentEnv.base + user.create.newUserRecords;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(newUser),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// REQUIREMENTS FOR 'GENERATEANDSENDOTP':
// - userLoginId, emailToAddress, subject, body, isBodyHtml
const sendNewUserTempPasswordEmail = async (token, data = {}) => {
	const { email, firstName, lastName, tempPassword } = data;
	const emailTemplate = generateTempPasswordEmail({
		firstName: firstName,
		lastName: lastName,
		email: email,
		otp: tempPassword,
	});
	return emailTemplate;
};

// ##TODOS:
// - Update naming conventions for:
//    - "UserLogin" model & records
//    - "UserProfile" model & records
//    - "UserEmail" model & allRecords

////////////////////////////////////////////////////////////////////
//////////////////////// ~ "UserLoginModel" ////////////////////////
////////////////////////////////////////////////////////////////////

const updateUserLoginRecord = (vals = {}) => {
	const base = new UserLoginRecord(vals);
	const model = base.getModel();

	return model;
};
const updateUser2FARecord = (vals = {}) => {
	const base = new User2FARecord(vals);
	const model = base.getModel();
	return model;
};
const updateUserAppsRecord = (vals = {}) => {
	const base = new UserAppsRecord(vals);
	const model = base.getModel();
	return model;
};
const generateUserAppsRecords = (vals = {}) => {
	// 'AdvantageTracker' model
	const modelAT = updateUserAppsRecord({
		...vals,
		isAccessible: vals?.hasTrackerAccess,
		appID: appIDs?.["AdvantageTracker"] ?? 2,
	});
	// 'SeniorCareVB' model
	const modelSCVB = updateUserAppsRecord({
		...vals,
		isAccessible: vals?.hasLegacyAccess,
		appID: appIDs?.["SeniorCareVB"] ?? 1,
	});
	// 'AdminPortal' model
	const modelAP = updateUserAppsRecord({
		...vals,
		isAccessible: vals?.hasPortalAccess ?? true,
		appID: appIDs?.["AdminPortal"] ?? 3,
	});

	// tracker, legacy, portal
	return [modelAT, modelSCVB, modelAP];
};
const updateUserRolesRecord = (vals = {}) => {
	const base = new UserRolesRecord(vals);
	const model = base.getModel();
	return model;
};
const updateUserRoleGroupsRecord = (vals = {}) => {
	const base = new UserRoleGroupsRecord(vals);
	const model = base.getModel();
	return model;
};
const updateUserSecurityAnswerRecord = (vals = {}) => {
	const base = new UserSecurityAnswerRecord(vals);
	const model = base.getModel();
	return model;
};
const generateSecurityAnswersRecords = (allQuestions = [], vals = {}) => {
	const answerRecord1 = updateUserSecurityAnswerRecord({
		...vals,
		securityAnswer: vals?.securityAnswer1,
		securityQuestionID: matchQuestionIDFromStr(
			vals?.securityQuestion1,
			allQuestions
		),
		userLoginID: vals?.targetUserID,
		createdByID: vals?.createdByID,
	});
	const answerRecord2 = updateUserSecurityAnswerRecord({
		...vals,
		securityAnswer: vals?.securityAnswer2,
		securityQuestionID: matchQuestionIDFromStr(
			vals?.securityQuestion2,
			allQuestions
		),
		userLoginID: vals?.targetUserID,
		createdByID: vals?.createdByID,
	});
	const answerRecord3 = updateUserSecurityAnswerRecord({
		...vals,
		securityAnswer: vals?.securityAnswer3,
		securityQuestionID: matchQuestionIDFromStr(
			vals?.securityQuestion3,
			allQuestions
		),
		userLoginID: vals?.targetUserID,
		createdByID: vals?.createdByID,
	});

	return [answerRecord1, answerRecord2, answerRecord3];
};
/**
 * Generates all 'userFacilities' records for the updated 'UserLoginModel'
 * @param {Array} selections - An array of facility names to grante access to.
 * @param {Array} facilities - AN array of user-formatted facilities.
 * @param {Object} userVals - An object of new user values
 */
const generateUserLoginFacilities = (
	selections = [],
	facilities = [],
	userVals = {}
) => {
	const facilityMap = getFacilityMapByName(facilities);
	const facilityIDs = getFacilityIDsFromSelections(selections, facilityMap);
	const facilityLogins = facilityIDs.map((id) => {
		const model = updateLoginFacilities({
			...userVals,
			facilityID: id,
		});
		return model;
	});
	return facilityLogins;
};
/**
 * Inits & populates all sub-records for the 'UserLogin' model.
 * @param {Array} allApps - An array of 'Application' records.
 * @param {Array} allQuestions - An array of 'UserSecurityQuestion' records.
 * @param {Object} vals - An object of values from the create user form.
 * @returns {Object} - Returns an object w/ ALL the required records for 'UserLoginModel'
 */
const generateAllUserLoginRecords = (
	allFacilities = [],
	allApps = [],
	allQuestions = [],
	vals = {}
) => {
	const userLoginRecord = updateUserLoginRecord(vals);
	const user2FARecord = updateUser2FARecord(vals);
	const userAppsRecords = generateUserAppsRecords(vals);
	const userRolesRecord = [];
	const userRoleGroupsRecord = [];
	const userSecurityAnswersRecord = generateSecurityAnswersRecords(
		allQuestions,
		vals
	);
	const userFacilities = generateUserLoginFacilities(
		vals?.facilitySelections,
		allFacilities,
		vals
	);

	return {
		UserLogin: userLoginRecord,
		User2FA: user2FARecord,
		UserApps: userAppsRecords,
		UserRoles: userRolesRecord,
		UserRoleGroups: userRoleGroupsRecord,
		UserSecurityAnswers: userSecurityAnswersRecord,
		UserFacilities: userFacilities,
	};
};

/**
 * Inits & updates ALL 'UserLoginModel' sub-records & applies to the 'UserLoginModel'.
 * @param {Array} allApps - An array of 'Application' records.
 * @param {Array} allQuestions - An array of 'SecurityQuestion' records.
 * @param {Object} vals - An object of new user values.
 */
const initAndUpdateUserLoginModel = (
	allFacilities = [],
	allApps = [],
	allQuestions = [],
	vals = {}
) => {
	const loginRecords = generateAllUserLoginRecords(
		allFacilities,
		allApps,
		allQuestions,
		vals
	);
	const base = new UserLoginModel(loginRecords);
	const model = base.getModel();

	return model;
};

////////////////////////////////////////////////////////////////////
////////////////////// ~ "UserProfileModel" //////////////////////
////////////////////////////////////////////////////////////////////

const updateCustomProfileRecord = (
	allTypes = [],
	allTitles = [],
	vals = {}
) => {
	// const userType = matchUserTypeFromStr(vals?.userType, allTypes);
	const userTitle = matchUserTitleFromStr(vals?.jobTitle, allTitles);

	const customProfileModel = {
		UserProfileID: vals?.profileID,
		// UserTypeID: userType?.typeID ?? vals?.typeID ?? 2,
		UserTypeID: vals?.typeID ?? 2,
		UserTitleID: userTitle?.titleID ?? 27,
	};

	return { ...customProfileModel };
};

/**
 * Inits & populates a new 'UserProfileRecord' for the 'UserProfileModel'
 * @param {Array} allTypes - An array of 'UserType' records.
 * @param {Array} allTitles - An array of 'UserTitle' records.
 * @param {Object} vals - New user values (eg. 'username', 'email' etc)
 * @returns {Object} - Returns the populated 'UserProfileRecord' model.
 */
const updateUserProfileRecord = (allTypes = [], allTitles = [], vals = {}) => {
	const userType = matchUserTypeFromStr(vals?.userType, allTypes);
	const userTitle = matchUserTitleFromStr(vals?.jobTitle, allTitles);

	// ##TODOS:
	// - Add support for custom job title (ie. newly created title record)
	// 		- Ignore 'userTitle' record match & use stored ID.
	if (vals?.hasCustomJobTitle) {
		const base = new UserProfileRecord({
			...vals,
			userTypeID: userType?.typeID ?? 2, // 'Staff'
			userTitleID: vals?.jobTitleID ?? 27, // 'CSR'
		});
		const model = base.getModel();
		return model;
	} else {
		const base = new UserProfileRecord({
			...vals,
			userTypeID: userType?.typeID ?? 2, // 'Staff'
			userTitleID: userTitle?.titleID ?? 27, // 'CSR'
		});
		const model = base.getModel();
		return model;
	}
};
const updateUserAlertsRecord = (vals = {}) => {
	const base = new UserAlertRecord(vals);
	const model = base.getModel();
	return model;
};
const updateUserAvatarsRecord = (vals = {}) => {
	const base = new UserAvatarRecord(vals);
	const model = base.getModel();
	return model;
};
const updateUserEmailsRecord = (vals = {}) => {
	const base = new UserEmailRecord(vals);
	const model = base.getModel();
	return model;
};
const updateUserPhonesRecord = (vals = {}) => {
	const base = new UserPhoneRecord(vals);
	const model = base.getModel();
	return model;
};
/**
 * Generates all 'UserProfileModel' sub-records & applies new user values to them.
 * @param {Array} userTypes - An array of 'UserType' records.
 * @param {Array} userTitles - An array of 'UserTitle' records.
 * @param {Object} vals - An object of new user values.
 * @returns {Object} - Returns an object w/ all sub-records a their own properties.
 */
const generateAllUserProfileRecords = (
	userTypes = [],
	userTitles = [],
	vals = {}
) => {
	const profileRecord = updateUserProfileRecord(userTypes, userTitles, vals);
	const alertsRecord = updateUserAlertsRecord(vals);
	const avatarsRecord = updateUserAvatarsRecord(vals);
	const emailsRecord = updateUserEmailsRecord(vals);
	const phonesRecord = updateUserPhonesRecord(vals);

	return {
		UserProfile: profileRecord,
		UserAlerts: [alertsRecord],
		UserAvatars: [avatarsRecord],
		UserEmails: [emailsRecord],
		UserPhones: [phonesRecord],
	};
};

/**
 * Inits & populates a new 'UserProfileModel' for a new user.
 * @param {Object} vals - All requisite values for creating a new user.
 */
const initAndUpdateUserProfileModel = (
	allTypes = [],
	allTitles = [],
	vals = {}
) => {
	const allRecords = generateAllUserProfileRecords(allTypes, allTitles, vals);
	const base = new UserProfileModel(allRecords);
	const model = base.getModel();

	return model;
};

/**
 * Generates ALL NEW USER data structures to support both "UserProfile" and "UserLogin" entries.
 * @param {Object} userVals - AN object of new user values, including 'facilitySelections' to grant access to.
 * @param {Object} allData - An object of various table data such as: 'allApps', 'allQuestions', 'allFacilities', 'allUserTypes', 'allUserTitles'
 * @property {Array} allData.allApps - An array of 'Application' records.
 * @property {Array} allData.allQuestions - An array of all 'UserSecurityQuestions'.
 * @property {Array} allData.allFacilities - AN array of all user facilities (only ones user has access to).
 * @property {Array} allData.allUserTypes - AN array of all user types.
 * @property {Array} allData.allUserTitles - An array of ALL available user titles.
 * @returns {Object} - Returns an object w/ 'PROFILE_RECORDS' and 'LOGIN_RECORDS' as respective properties.
 */
const generateAllNewUserRecords = (allData = {}, userVals = {}) => {
	// ##TODOS:
	// - Update to support 'SendTempPasswordEmail' field within the NewUserModel
	const { allFacilities, allApps, allQuestions, allUserTypes, allUserTitles } =
		allData;

	// MUST be created 1st, then 'UserLogin' after
	const profileRecords = generateAllUserProfileRecords(
		allUserTypes,
		allUserTitles,
		userVals
	);
	const loginRecords = generateAllUserLoginRecords(
		allFacilities,
		allApps,
		allQuestions,
		userVals
	);

	return {
		SendTempPasswordEmail: requiresTempPasswordEmail(userVals),
		UserLoginModel: loginRecords,
		UserProfileModel: profileRecords,
	};
};

// determines if the user being created requires a temp password email to be sent
const requiresTempPasswordEmail = (vals = {}) => {
	const { userType, email: adminEmail, isValidEmail } = vals;
	const adminTypes = [
		`Administrator`,
		`SuperUser`,
		`Facility Administrator`,
		`FacilityAdministrator`,
	];

	const shouldSendEmail = isValidEmail || adminTypes.includes(userType);

	return shouldSendEmail;
};

// ERROR HANDLING FOR CREATE USER //

const cleanError = (errMsg) => {
	// remove 'Error,' from text string
	const cleaned = errMsg.split("Error, ")[1];
	return cleaned;
};

const getUserFailureErrors = (messages = []) => {
	const errReg = /^(Error)/gim;
	// finds all 'Error' strings & removes 'Error' from each
	const errors = messages.filter((error) => matchesPattern(error, errReg));
	const cleaned = errors.map((error) => cleanError(error));
	return cleaned;
};

// request for new user
export { createNewUserProfileAndLogin };

// USER SECURITY MODEL UTILS //
// Updaters for 'UserLogin' records
export {
	generateUserLoginFacilities,
	updateUserLoginRecord,
	updateUser2FARecord,
	updateUserAppsRecord,
	updateUserRolesRecord,
	updateUserRoleGroupsRecord,
	updateUserSecurityAnswerRecord,
	generateUserAppsRecords,
	// populates ALL 'UserLoginModel' records
	generateAllUserLoginRecords,
	initAndUpdateUserLoginModel,
};

// Updaters for 'UserProfile' records
export {
	// custom profile records
	updateCustomProfileRecord,
	// base profile record
	updateUserProfileRecord,
	updateUserAlertsRecord,
	updateUserAvatarsRecord,
	updateUserEmailsRecord,
	updateUserPhonesRecord,
	// populates ALL 'UserProfileModel' records
	generateAllUserProfileRecords,
	initAndUpdateUserProfileModel,
};

// Updaters for ALL New User records
export { generateAllNewUserRecords };

export { getUserFailureErrors };
