import React, { useRef, useState } from "react";
import styles from "../../css/loc/LOCReset.module.scss";
import { PropTypes } from "prop-types";
import ButtonSM from "../shared/ButtonSM";
import { red } from "../../helpers/utils_styles";
import { isEmptyArray, isEmptyObj } from "../../helpers/utils_types";

const customCSS = {
	cancel: {
		padding: "1.2rem 2rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.6rem",
		fontWeight: "600",
		marginRight: "1rem",
	},
	confirm: {
		padding: "1.2rem 2rem",
		backgroundColor: red[600],
		color: "#ffffff",
		fontSize: "1.6rem",
		fontWeight: "600",
	},
};

const getLOCUnits = (levels = {}) => {
	if (isEmptyObj(levels)) return [];
	const types = Object.keys(levels);
	return types;
};

const LOCReset = ({
	careLevels = {},
	confirmResetLevels,
	cancelResetLevels,
}) => {
	// effected floor units (eg. unit types)
	const types = useRef(getLOCUnits(careLevels));

	return (
		<div className={styles.LOCReset}>
			<div className={styles.LOCReset_info}>Be Aware:</div>
			<div className={styles.LOCReset_details}>
				This will reset all floor unit care levels back to their default levels.
				This will erase your current levels of care and is not reversible.
			</div>
			<div className={styles.LOCReset_more}>
				Effected Floor Units:
				<ul className={styles.LOCReset_more_list}>
					{!isEmptyArray(types?.current) &&
						types?.current.map((type, idx) => (
							<li
								key={`TYPE-${type}-${idx}`}
								className={styles.LOCReset_more_list_unit}
							>
								{type}
							</li>
						))}
				</ul>
			</div>
			<div className={styles.LOCReset_actions}>
				<ButtonSM
					handleClick={cancelResetLevels}
					customStyles={customCSS.cancel}
				>
					Cancel
				</ButtonSM>
				<ButtonSM
					handleClick={confirmResetLevels}
					customStyles={customCSS.confirm}
				>
					Yes, Reset All
				</ButtonSM>
			</div>
		</div>
	);
};

export default LOCReset;

LOCReset.defaultProps = {};

LOCReset.propTypes = {};
