import React, { useState } from "react";
import styles from "../../css/user/UserManagement.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import {
	getUserName,
	getUserTitle,
	getUserEmail,
} from "../../helpers/utils_user";
import { red, blueGrey } from "../../helpers/utils_styles";
// components
import CopyText from "../forms/CopyText";
import FormSection from "../forms/FormSection";
import Divider from "../forms/Divider";
import Dialog from "../shared/Dialog";
import ButtonSM from "../shared/ButtonSM";
import AboutUser from "./AboutUser";
import UserAccessSwitch from "./UserAccessSwitch";
import ProtectedFeature from "../permissions/ProtectedFeature";
import UserSuspensionSwitch from "../admin/UserSuspensionSwitch";
import { getUserSecurityInfo } from "../../helpers/utils_security";

// REQUIREMENTS:
// - Delete a user
// - Set user permissions
// - Change user's access
//    - App Access
//    - Facility Access
// - User Security Settings:
// 		- Reset by email
// 		- Reset by questions
// 		- Reset by admin

const customCSS = {
	deleteBtn: {
		backgroundColor: red[600],
		color: "#ffffff",
		padding: ".7rem 1.3rem",
		borderRadius: "5rem",
		fontSize: "1.4rem",
		fontWeight: "600",
	},
	confirmDeleteBtn: {
		padding: ".7rem 1.3rem",
		borderRadius: ".5rem",
		backgroundColor: red[600],
		color: red[100],
		fontSize: "1.4rem",
		fontWeight: "600",
	},
	cancelDeleteBtn: {
		padding: ".7rem 1.3rem",
		borderRadius: ".5rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.4rem",
		fontWeight: "600",
		border: `1px solid ${red[400]}`,
		marginRight: "1rem",
	},
	grantAccessBtn: {
		padding: ".6rem 1.1rem",
		borderRadius: "5rem",
		backgroundColor: blueGrey[700],
		color: blueGrey[100],
		fontSize: "1.4rem",
		fontWeight: "600",
	},
	cancelGrantBtn: {
		padding: ".6rem 1.1rem",
		borderRadius: "5rem",
		backgroundColor: "transparent",
		color: blueGrey[600],
		fontSize: "1.4rem",
		fontWeight: "600",
		border: `1px solid ${blueGrey[400]}`,
		marginLeft: "1rem",
	},
	copyText: {
		fontSize: "1.4rem",
	},
};

const UserManagement = ({
	currentFacility = {},
	currentUser = {},
	selectedUser = {},
}) => {
	const [appAccess, setAppAccess] = useState({
		AdvantageTracker: false,
		SeniorCareVB: false,
		AdminPortal: true,
	});
	const [suspendUser, setSuspendUser] = useState(false);

	const [showDeleteUserConfirmation, setShowDeleteUserConfirmation] =
		useState(false);

	const fetchUserInfo = async () => {
		const { token } = currentUser;
		const { email } = selectedUser;
		const userInfo = await getUserSecurityInfo(token, email);

		// fetch userinfo
		// fetch access
		// fetch questions
	};

	const handleAccess = () => {};

	const handleSuspendUser = (e) => {
		const { checked } = e.target;

		setSuspendUser(checked);
	};

	const handleDeleteUser = () => {};

	const confirmDeleteUser = () => {};
	const cancelDeleteUser = () => {};

	return (
		<>
			<div className={styles.UserManagement}>
				<header className={styles.UserManagement_header}>
					<h2 className={styles.UserManagement_header_title}>
						User: <b>{getUserName(selectedUser)}</b>
					</h2>
					<div className={styles.UserManagement_header_id}>
						<span>User ID:</span>
						<CopyText
							text={selectedUser?.userID}
							customStyles={customCSS.copyText}
						/>
					</div>
				</header>
				<Divider />
				<section className={styles.UserManagement_main}>
					{/* ABOUT USER */}
					<FormSection
						title="About"
						icon="news"
						hideInfoIcon={true}
						hoverText="Information about this user."
					>
						<AboutUser
							user={selectedUser}
							allFacilities={currentUser?.facilities}
						/>
					</FormSection>
					<Divider />
					{/* APPLICATION ACCESS */}
					<ProtectedFeature
						isEnabled={currentUser?.isAdmin || currentUser?.isSuperUser}
					>
						<FormSection
							icon="lock"
							title="Application Access"
							hoverText="Manage a users application access."
						>
							<p className={styles.UserManagement_main_desc}>
								Disabling app access will prevent a user from accessing a given
								application. This will not delete any user-related data. Users
								can be re-enabled at any time.
							</p>
							<UserAccessSwitch
								appName="SeniorCareVB"
								isEnabled={appAccess?.SeniorCareVB}
								isLocked={true}
								handleAccess={handleAccess}
							/>
							<br style={{ margin: "2rem 0" }} />
							<UserAccessSwitch
								appName="AdvantageTracker"
								isEnabled={appAccess?.AdvantageTracker}
								isLocked={false}
								handleAccess={handleAccess}
							/>
						</FormSection>
						<Divider />
					</ProtectedFeature>

					{/* SUSPEND/DEACTIVATE USER */}
					<FormSection
						title="Suspend  User (temporary)"
						icon="error"
						hoverText="User suspension will temporarily disable access for a user."
						iconColor={red[600]}
						titleColor={red[600]}
					>
						<p className={styles.UserManagement_main_desc}>
							Suspending a user will temporaily disable their access to all ALA
							applications, but will not delete any user-related data. They may
							be re-enabled/un-suspended at any time.
						</p>
						<UserSuspensionSwitch
							suspendUserVal={suspendUser}
							handleSuspendUser={handleSuspendUser}
						/>
					</FormSection>

					{/* DELETE USER */}
					<ProtectedFeature
						isEnabled={currentUser?.isAdmin || currentUser?.isSuperUser}
					>
						<Divider />
						<FormSection
							title="Delete  User"
							icon="error"
							hoverText="Deleting a user is permanent and is not reversible."
							iconColor={red[600]}
							titleColor={red[600]}
						>
							<p className={styles.UserManagement_main_desc}>
								Deleting a user will permanently remove all user-related data
								from our system. This is not reversible.
							</p>
							<ButtonSM
								handleClick={handleDeleteUser}
								customStyles={customCSS.deleteBtn}
							>
								<svg className={styles.UserManagement_main_deleteIcon}>
									<use xlinkHref={`${sprite}#icon-delete`}></use>
								</svg>
								<span>Delete User</span>
							</ButtonSM>
						</FormSection>
					</ProtectedFeature>
				</section>
			</div>

			{showDeleteUserConfirmation && (
				<Dialog
					icon="ERROR"
					title={`Delete User`}
					heading={`Are you sure you want to delete this user?`}
					text={`User deletions are NOT reversible. Historical data will persist.`}
					closeModal={() => setShowDeleteUserConfirmation(false)}
				>
					<div className={styles.UserManagement_main_confirmDeleteDialog}>
						<ButtonSM
							handleClick={cancelDeleteUser}
							customStyles={customCSS.cancelDeleteBtn}
						>
							<span>Cancel</span>
						</ButtonSM>
						<ButtonSM
							handleClick={confirmDeleteUser}
							customStyles={customCSS.confirmDeleteBtn}
						>
							<span>Delete User</span>
						</ButtonSM>
					</div>
				</Dialog>
			)}
		</>
	);
};

export default UserManagement;

UserManagement.defaultProps = {
	selectedUser: {},
};

UserManagement.propTypes = {
	selectedUser: PropTypes.object,
};
