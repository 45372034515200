import React from "react";
import styles from "../../css/loc/LOCTableColumns.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
import LOCTableHeading from "./LOCTableHeading";

const LOCTableColumns = ({ cols = [], handleColumnSort }) => {
	return (
		<header className={styles.LOCTableColumns}>
			<div className={styles.LOCTableColumns_inner}>
				{!isEmptyArray(cols) &&
					cols.map((col, colIdx) => (
						<LOCTableHeading
							key={`Column:${col}-${colIdx}`}
							column={col}
							columnIdx={colIdx}
							handleColumn={() => handleColumnSort(col)}
						/>
					))}
			</div>
		</header>
	);
};

export default LOCTableColumns;

LOCTableColumns.defaultProps = {};

LOCTableColumns.propTypes = {};
