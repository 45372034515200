import React, { useEffect, useState } from "react";
import styles from "../../css/summary/LockoutBanner.module.scss";
import sprite from "../../assets/icons/alerts.svg";
import { PropTypes } from "prop-types";
import AlertBanner from "../alerts/AlertBanner";

// REQUIREMENTS:
// - Message: "5 Users Need Assistance!!"

const banner = {
	error: {
		heading: `Users Need Help!`,
		subheading: `You have lockout notices.`,
		type: "ERROR",
	},
	success: {
		heading: `You're all set!`,
		subheading: `No alerts right now. Check back later!!`,
		type: "SUCCESS",
	},
	warning: {
		heading: `Warning!`,
		subheading: `3 Users have 1 or more failed login attempts.`,
		type: "WARNING",
	},
	info: {
		heading: `Info!`,
		subheading: `We've made some improvements. Learn more!`,
		type: "INFO",
	},
};

const getLockouts = (count) => {
	if (count === 0 || !count) {
		const mainMsg = `You're all set!`;
		const subMsg = `No alerts right now. Check back later!`;
		return {
			type: "SUCCESS",
			heading: mainMsg,
			subheading: subMsg,
		};
	} else {
		const user = `${count > 1 ? "Users" : "User"}`; // 'User(s)' text logic
		const needs = `${count > 1 ? "Need" : "Needs"}`; // 'Need(s)' text logic
		const mainMsg = `${count} ${user} ${needs} Help!`;
		const subMsg = `You have ${count} lockout notice${count > 1 ? "s" : ""}.`;
		return {
			type: "ERROR",
			heading: mainMsg,
			subheading: subMsg,
		};
	}
};

const LockoutBanner = ({ numOfLockouts = 0 }) => {
	const [showAlertBanner, setShowAlertBanner] = useState(true);
	const [userBanner, setUserBanner] = useState(() => {
		return getLockouts(numOfLockouts);
	});

	// when 'numOfLockouts' changes
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		setUserBanner(() => {
			return getLockouts(numOfLockouts);
		});

		return () => {
			isMounted = false;
		};
	}, [numOfLockouts]);

	return (
		<div className={styles.LockoutBanner}>
			{showAlertBanner && (
				<AlertBanner
					type={userBanner.type}
					heading={userBanner.heading}
					subheading={userBanner.subheading}
					closeAlert={() => setShowAlertBanner(false)}
				/>
			)}
		</div>
	);
};

export default LockoutBanner;

LockoutBanner.defaultProps = {
	numOfLockouts: 0,
};

LockoutBanner.propTypes = {
	numOfLockouts: PropTypes.number,
};
