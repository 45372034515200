import React, { useState, useEffect } from "react";
import styles from "../../css/firstlogin/CreateNewQuestions.module.scss";
import { PropTypes } from "prop-types";
import {
	getRemainingQuestions,
	hasQuestionAndAnswer,
} from "../../helpers/utils_security";
import { purple } from "../../helpers/utils_styles";
// components
import Divider from "../forms/Divider";
import UserSecurityQuestion from "../user/UserSecurityQuestion";
import UserSecurityAnswer from "../user/UserSecurityAnswer";
import SuccessIndicator from "../shared/SuccessIndicator";

const customCSS = {
	divider: {
		opacity: ".8",
	},
};

const QuestionNumber = ({ number }) => {
	return <div className={styles.QuestionNumber}>{number}</div>;
};

const CreateNewQuestions = ({
	vals = {},
	securityQuestions = [],
	handleQuestion,
	handleChange,
}) => {
	const [remainingQuestions, setRemainingQuestions] = useState([
		...securityQuestions,
	]);
	const {
		securityQuestion1,
		securityQuestion2,
		securityQuestion3,
		securityAnswer1,
		securityAnswer2,
		securityAnswer3,
	} = vals;

	// removes selected questions from 'remainingQuestions' list
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		setRemainingQuestions(() => {
			return getRemainingQuestions(vals, securityQuestions);
		});

		return () => {
			isMounted = false;
		};
	}, [securityQuestions, vals]);
	return (
		<div className={styles.CreateNewQuestions}>
			<div className={styles.CreateNewQuestions}>
				<div className={styles.CreateNewQuestions_heading}>
					Choose Security Questions:
				</div>
				<div className={styles.CreateNewQuestions_questions}>
					{/* QUESTION #1 */}
					<div className={styles.CreateNewQuestions_questions_item}>
						<QuestionNumber number="1" />
						<UserSecurityQuestion
							name="securityQuestion1"
							id="securityQuestion1"
							vals={vals}
							label="Security Question #1:"
							handleQuestion={handleQuestion}
							securityQuestions={securityQuestions}
							hideEditButtons={true}
							lockQuestion={false}
						/>
						<UserSecurityAnswer
							vals={vals}
							label="Security Answer #1:"
							name="securityAnswer1"
							id="securityAnswer1"
							handleAnswer={handleChange}
						/>
						{hasQuestionAndAnswer(securityQuestion1, securityAnswer1) && (
							<SuccessIndicator
								msg="Success!"
								iconColor={purple[600]}
								msgColor={purple[600]}
							/>
						)}
					</div>
					<Divider customStyles={customCSS.divider} />
					{/* QUESTION #2 */}
					<div className={styles.CreateNewQuestions_questions_item}>
						<QuestionNumber number="2" />
						<UserSecurityQuestion
							key={remainingQuestions?.length}
							name="securityQuestion2"
							id="securityQuestion2"
							vals={vals}
							label="Security Question #2:"
							handleQuestion={handleQuestion}
							securityQuestions={remainingQuestions}
							hideEditButtons={true}
							lockQuestion={false}
						/>
						<UserSecurityAnswer
							vals={vals}
							label="Security Answer #2:"
							name="securityAnswer2"
							id="securityAnswer2"
							handleAnswer={handleChange}
						/>
						{hasQuestionAndAnswer(securityQuestion2, securityAnswer2) && (
							<SuccessIndicator
								msg="Success!"
								iconColor={purple[600]}
								msgColor={purple[600]}
							/>
						)}
					</div>
					<Divider customStyles={customCSS.divider} />
					{/* QUESTION #3 */}
					<div className={styles.CreateNewQuestions_questions_item}>
						<QuestionNumber number="3" />
						<UserSecurityQuestion
							key={remainingQuestions?.length + 1}
							name="securityQuestion3"
							id="securityQuestion3"
							vals={vals}
							label="Security Question #3:"
							handleQuestion={handleQuestion}
							securityQuestions={remainingQuestions}
							hideEditButtons={true}
							lockQuestion={false}
						/>
						<UserSecurityAnswer
							vals={vals}
							label="Security Answer #3:"
							name="securityAnswer3"
							id="securityAnswer3"
							handleAnswer={handleChange}
						/>
						{hasQuestionAndAnswer(securityQuestion3, securityAnswer3) && (
							<SuccessIndicator
								msg="Success!"
								iconColor={purple[600]}
								msgColor={purple[600]}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CreateNewQuestions;

CreateNewQuestions.defaultProps = {};

CreateNewQuestions.propTypes = {};
