import React from "react";
import styles from "../../css/user/UserAccessSwitch.module.scss";
import { PropTypes } from "prop-types";
import ToggleSwitch from "../shared/ToggleSwitch";

const UserAccessSwitch = ({
	appName,
	isEnabled = false,
	isLocked = false,
	isDisabled = false,
	handleAccess,
}) => {
	const custom = {
		opacity: isDisabled || isLocked ? ".5" : "1",
		cursor: isDisabled || isLocked ? "not-allowed" : "pointer",
	};

	return (
		<div className={styles.UserAccessSwitch}>
			<div className={styles.UserAccessSwitch_app} style={custom}>
				{appName}
				{isLocked && (
					<span className={styles.UserAccessSwitch_app_isLocked}>
						{" "}
						- Locked
					</span>
				)}
			</div>
			<div className={styles.UserAccessSwitch_switch}>
				<ToggleSwitch
					name={appName}
					id={appName}
					val={isEnabled}
					isDisabled={isLocked || isDisabled}
					handleToggle={handleAccess}
				/>
			</div>
		</div>
	);
};

export default UserAccessSwitch;

UserAccessSwitch.defaultProps = {};

UserAccessSwitch.propTypes = {
	appName: PropTypes.string,
	isEnabled: PropTypes.bool,
	isLocked: PropTypes.bool,
	handleAccess: PropTypes.func,
};
