import React, { useRef, useState } from "react";
import styles from "../../css/user/AddAltEmail.module.scss";
import sprite from "../../assets/icons/multistep.svg";
import { PropTypes } from "prop-types";
import { blueGrey } from "../../helpers/utils_styles";
import { isEmptyVal } from "../../helpers/utils_types";
// components
import ButtonSM from "../shared/ButtonSM";
import EmailValidator from "../forms/EmailValidator";

const customCSS = {
	altEmail: {
		width: "100%",
		backgroundColor: "#ffffff",
	},
	cancelBtn: {
		padding: ".7rem 1.7rem",
		borderRadius: "5rem",
		backgroundColor: "transparent",
		color: blueGrey[700],
		marginRight: ".5rem",
		fontSize: "1.5rem",
	},
	saveBtn: {
		padding: ".7rem 1.7rem",
		borderRadius: "5rem",
		backgroundColor: blueGrey[700],
		color: blueGrey[100],
		fontSize: "1.5rem",
	},
};

const baseMsg = `An alternate email address will be used to receive notifications and
						alerts. All active notifications will be sent to your primary email
						address and any alternate email address(s).`;

const AddAltEmail = ({
	val = "",
	name = "altEmail",
	id = "altEmail",
	btnText = "Add Alternate Email",
	msg = baseMsg,
	handleChange,
	handleReset,
	saveAddEmail,
	customStyles = {},
}) => {
	const [addAltEmail, setAddAltEmail] = useState(false);
	const inputRef = useRef();

	const toggleAddEmail = () => {
		setAddAltEmail(!addAltEmail);
	};

	const cancelChanges = (e) => {
		if (!handleReset) return setAddAltEmail(false);
		handleReset(e);
	};

	return (
		<div className={styles.AddAltEmail}>
			<div className={styles.AddAltEmail_addEmail}>
				<button
					type="button"
					onClick={toggleAddEmail}
					className={styles.AddAltEmail_addEmail_btn}
				>
					<svg className={styles.AddAltEmail_addEmail_btn_icon}>
						<use xlinkHref={`${sprite}#icon-email`}></use>
					</svg>
					<span>{btnText}</span>
				</button>
				<div className={styles.AddAltEmail_addEmail_desc}>
					<svg className={styles.AddAltEmail_addEmail_desc_icon}>
						<use xlinkHref={`${sprite}#icon-light-bulb`}></use>
					</svg>
					<p className={styles.AddAltEmail_addEmail_desc_text}>{msg}</p>
				</div>
			</div>
			{addAltEmail && (
				<div className={styles.AddAltEmail_altEmail}>
					<EmailValidator
						val={val}
						name={name}
						id={id}
						label="Enter an email address"
						placeholder="Enter your email..."
						handleChange={handleChange}
						customStyles={{ ...customCSS.altEmail, ...customStyles }}
						refFromParent={inputRef}
					/>

					<div className={styles.AddAltEmail_altEmail_actions}>
						<ButtonSM
							handleClick={cancelChanges}
							customStyles={customCSS.cancelBtn}
						>
							<span>Cancel</span>
						</ButtonSM>
						<ButtonSM
							handleClick={saveAddEmail}
							customStyles={customCSS.saveBtn}
							isDisabled={isEmptyVal(val)}
						>
							<span>Save Changes</span>
						</ButtonSM>
					</div>
				</div>
			)}
		</div>
	);
};

export default AddAltEmail;

AddAltEmail.defaultProps = {
	vals: {},
};

AddAltEmail.propTypes = {
	vals: PropTypes.object,
	handleChange: PropTypes.func,
	handleReset: PropTypes.func,
};
