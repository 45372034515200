import React from "react";
import styles from "../../css/user/SuccessMsg.module.scss";
import sprite from "../../assets/icons/multistep.svg";
import { PropTypes } from "prop-types";

const icons = {
	check: "done",
	checks: "done_all",
	checkCircle: "checkmark-outline",
	checkCircle2: "check-circle",
	checkCircleDark: "check_circle1",
	checkCircle3: "check_circle_outline",
	checkOutline: "done_outline",
};

const SuccessMsg = ({ msg, icon = "checkCircle", children }) => {
	return (
		<div className={styles.SuccessMsg}>
			<div className={styles.SuccessMsg_badge}>
				<svg className={styles.SuccessMsg_badge_icon}>
					<use xlinkHref={`${sprite}#icon-${icons[icon]}`}></use>
				</svg>
			</div>
			<div className={styles.SuccessMsg_msgWrapper}>
				<div className={styles.SuccessMsg_msgWrapper_msg}>{msg}</div>
			</div>
			<div className={styles.SuccessMsg_content}>{children}</div>
		</div>
	);
};

export default SuccessMsg;

SuccessMsg.defaultProps = {
	icon: "checkCircle",
};

SuccessMsg.propTypes = {
	msg: PropTypes.string,
	icon: PropTypes.string,
	children: PropTypes.any,
};
