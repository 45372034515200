import React from "react";
import styles from "../../css/app/ContactUs.module.scss";
import sprite from "../../assets/icons/contactus.svg";
import { PropTypes } from "prop-types";

// REQUIREMENTS:
// - Email: support@aladvantage.com
// - Phone:
//      - Sales: 877-630-6709
//      - Support: 877-727-2640
// - Address: 14300 N Northsight Blvd.
// Suite #126
// Scottsdale, AZ 85260

const icons = {
	email: "email",
	phone: "phone_in_talk",
	address: "location_pin",
	form: "support_agent",
};

const cardDetails = {
	email: {
		msg: `Emails Us Now!`,
		text: {
			sales: `sales@aladvantage.com`,
			support: `support@aladvantage.com`,
		},
		links: {
			sales: `mailto:sales@aladvantage.com?subject=Inquiry: AL Advantage&body=Hello,`,
			support: `mailto:support@aladvantage.com?subject=Inquiry: AL Advantage&body=I was wondering...`,
		},
	},
	phone: {
		msg: `Call Us Now!`,
		text: {
			sales: `877-630-6709`,
			support: `877-727-2640`,
		},
		links: {
			sales: `tel:877-630-6709`,
			support: `tel:877-727-2640`,
		},
	},
	form: {
		msg: `Send Us A Message!`,
		text: {
			sales: `Contact Sales Now!`,
			support: `Ask Us A Question Now!`,
		},
		links: {
			sales: `https://www.aladvantage.com/owner-benefits-elder-care-software/contact-us/`,
			support: `https://www.aladvantage.com/owner-benefits-elder-care-software/contact-us/`,
		},
	},
};

const ContactCard = ({ icon, children, ...rest }) => {
	return (
		<div className={styles.ContactCard} {...rest}>
			<div className={styles.ContactCard_wrapper}>
				<svg className={styles.ContactCard_wrapper_icon}>
					<use xlinkHref={`${sprite}#icon-${icons[icon]}`}></use>
				</svg>
			</div>
			<div className={styles.ContactCard_content}>{children}</div>
		</div>
	);
};

const EmailCard = () => {
	const { email } = cardDetails;
	return (
		<ContactCard icon="email">
			{/* EMAIL: SALES */}
			<div className={styles.EmailCard_item}>
				<svg className={styles.EmailCard_item_icon}>
					<use xlinkHref={`${sprite}#icon-email`}></use>
				</svg>
				<div className={styles.EmailCard_item_label}>Sales: </div>
				<a
					href={email.links.sales}
					target="_blank"
					rel="noopener noreferrer"
					className={styles.EmailCard_item_link}
				>
					{email.text.sales}
				</a>
			</div>
			{/* EMAIL: SUPPORT */}
			<div className={styles.EmailCard_item}>
				<svg className={styles.EmailCard_item_icon}>
					<use xlinkHref={`${sprite}#icon-email`}></use>
				</svg>
				<div className={styles.EmailCard_item_label}>Support: </div>
				<a
					href={email.links.support}
					target="_blank"
					rel="noopener noreferrer"
					className={styles.EmailCard_item_link}
				>
					{email.text.support}
				</a>
			</div>
			{/* CALL-TO-ACTION BUTTON */}
			<div className={styles.EmailCard_actions}>
				<button className={styles.EmailCard_actions_callNow}>
					{email.msg}
				</button>
			</div>
		</ContactCard>
	);
};
const PhoneCard = () => {
	const { phone } = cardDetails;
	return (
		<ContactCard icon="phone">
			{/* EMAIL: SALES */}
			<div className={styles.PhoneCard_item}>
				<svg className={styles.PhoneCard_item_icon}>
					<use xlinkHref={`${sprite}#icon-phone`}></use>
				</svg>
				<div className={styles.PhoneCard_item_label}>Sales: </div>
				<a
					href={phone.links.sales}
					target="_blank"
					rel="noopener noreferrer"
					className={styles.PhoneCard_item_link}
				>
					{phone.text.sales}
				</a>
			</div>
			{/* EMAIL: SUPPORT */}
			<div className={styles.PhoneCard_item}>
				<svg className={styles.PhoneCard_item_icon}>
					<use xlinkHref={`${sprite}#icon-phone_in_talk`}></use>
				</svg>
				<div className={styles.PhoneCard_item_label}>Support: </div>
				<a
					href={phone.links.support}
					target="_blank"
					rel="noopener noreferrer"
					className={styles.PhoneCard_item_link}
				>
					{phone.text.support}
				</a>
			</div>
			{/* CALL-TO-ACTION BUTTON */}
			<div className={styles.PhoneCard_actions}>
				<button className={styles.PhoneCard_actions_callNow}>
					{phone.msg}
				</button>
			</div>
		</ContactCard>
	);
};
const RedirectCard = () => {
	const { form } = cardDetails;
	return (
		<ContactCard icon="form">
			{/* EMAIL: SALES */}
			<div className={styles.FormCard_item}>
				<svg className={styles.FormCard_item_icon}>
					<use xlinkHref={`${sprite}#icon-contact_mail`}></use>
				</svg>
				<div className={styles.FormCard_item_label}>Sales: </div>
				<a
					href={form.links.sales}
					target="_blank"
					rel="noopener noreferrer"
					className={styles.FormCard_item_link}
				>
					{form.text.sales}
				</a>
			</div>
			{/* EMAIL: SUPPORT */}
			<div className={styles.FormCard_item}>
				<svg className={styles.FormCard_item_icon}>
					<use xlinkHref={`${sprite}#icon-phone_in_talk`}></use>
				</svg>
				<div className={styles.FormCard_item_label}>Support: </div>
				<a
					href={form.links.support}
					target="_blank"
					rel="noopener noreferrer"
					className={styles.FormCard_item_link}
				>
					{form.text.support}
				</a>
			</div>
			{/* CALL-TO-ACTION BUTTON */}
			<div className={styles.FormCard_actions}>
				<button className={styles.FormCard_actions_callNow}>{form.msg}</button>
			</div>
		</ContactCard>
	);
};

const ContactUs = () => {
	return (
		<div className={styles.ContactUs}>
			<EmailCard />
			<PhoneCard />
			<RedirectCard />
		</div>
	);
};

export default ContactUs;

ContactUs.defaultProps = {};

ContactUs.propTypes = {};
