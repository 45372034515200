import React, { useEffect, useState } from "react";
import styles from "../../css/tabs/FullPageTabsController.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";

const FullPageTabsController = ({
	defaultIndex = 0,
	forceCurrentTab = null,
	disabledIndices = [],
	hiddenIndices = [],
	children,
	customStyles = {},
}) => {
	const [activeIndex, setActiveIndex] = useState(() => {
		if (!forceCurrentTab) return defaultIndex;
		return forceCurrentTab;
	});

	const handleTabButton = (tabIndex) => {
		return setActiveIndex(tabIndex);
	};

	// forces current tab from parent
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (!isEmptyVal(forceCurrentTab)) {
			handleTabButton(forceCurrentTab);
		}

		return () => {
			isMounted = false;
		};
	}, [forceCurrentTab]);

	const withHandler = React.Children.map(children, (child, i) =>
		React.cloneElement(child, {
			handleTabButton: handleTabButton,
			activeIndex: activeIndex,
			disabledIndices: disabledIndices,
			hiddenIndices: hiddenIndices,
		})
	);
	return (
		<article className={styles.FullPageTabsController} style={customStyles}>
			{withHandler}
		</article>
	);
};

export default FullPageTabsController;

FullPageTabsController.defaultProps = {};

FullPageTabsController.propTypes = {
	defaultIndex: PropTypes.number,
	disabledIndices: PropTypes.arrayOf(PropTypes.number),
	hiddenIndices: PropTypes.arrayOf(PropTypes.number),
	children: PropTypes.any,
};
