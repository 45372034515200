/////////////////////////////////////////////////////////////////////////////
/////////////////////////////// THEME COLORS ///////////////////////////////
/////////////////////////////////////////////////////////////////////////////

/**
 * Mirrors the values in src/sass/_variables.scss
 */
const themeColors = {
	brand: {
		lightBlue: "hsla(192, 100%, 46%, 1)", // #00BCEB
		darkBlue: "#0F579F",
		altLightBlue: "hsla(210, 52%, 47%, 1)", // #3A78B6
		lightGrey: "hsla(204, 12%, 92%, 1)",
		altLightGrey: "hsla(210, 45%, 96%, 1)",
		mainWhite: "hsla(0, 0%, 100%, 1)",
		mainGreen: "hsla(79, 71%, 48%, 1)",
		lightGreen: "hsla(89, 54%, 85%, 1)",
	},
	main: {
		main: "hsla(242, 89%, 64%, 1)",
		blue: "hsla(197, 100%, 50%, 1)",
		green: "hsla(170, 100%, 39%, 1)",
		red: "hsla(352, 70%, 50%, 1)",
		orange: "hsla(11, 100%, 75%, 1)",
		yellow: "hsla(60, 92%, 71%, 1)",
		mustard: "hsla(46, 100%, 50%, 1)",
		charcoal: "hsla(268, 10%, 30%, 1)",
		grey: "hsla(216, 14%, 93%, 1)",
		blackBlue: "hsla(220, 18%, 20%, 1)",
		violet: "hsla(292, 65%, 68%, 1)",
		teal: "hsla(186, 100%, 50%, 1)",
		pink: "hsla(332, 100%, 74%, 1)",
		neonGreen: "hsla(151, 100%, 45%, 1)",
		altYellow: "hsla(39, 100%, 70%, 1)",
		altRed: "hsla(352, 70%, 60%, 1)",
	},
	flat: {
		main: "hsla(259, 77%, 64%, .4)",
		blue: "hsla(197, 100%, 50%, .3)",
		purple: "hsla(222, 89%, 64%, .3)",
		vibe: "hsla(259, 77%, 64%, .4)",
		green: "hsla(144, 69%, 63%, .4)",
		red: "hsla(330, 100%, 41%, .22)",
		yellow: "hsla(60, 92%, 71%, .7)",
		orange: "hsla(11, 100%, 75%, .4)",
		violet: "hsla(292, 65%, 68%, .3)",
		teal: "hsla(186, 100%, 50%, .4)",
		neonGreen: "hsla(151, 100%, 45%, .4)",
		blackBlue: "hsla(220, 18%, 20%, .4)",
		altRed: "hsla(352, 70%, 60%, .3)",
	},
	blueGreys: {
		main: "hsla(214, 32%, 91%, 1)",
		saturated: "hsla(211, 25%, 84%, 1)",
		text: "hsla(216, 15%, 52%, 1)",
		headings: "hsla(218, 17%, 35%, 1)",
		subheadings: "hsla(218, 17%, 65%, 1)",
		light: "hsla(204, 46%, 98%, 1)",
		lightened: "hsla(234, 32%, 91%, 0.4)",
	},
	greys: {
		dark: "hsla(0, 0%, 13%, 1)",
		medium: "hsla(0, 0%, 29%, 1)",
		mediumGrey: "hsla(0, 0%, 45%, 1)",
		lightGrey: "hsla(214, 20%, 69%, 1)",
		extraLightGrey: "hsla(211, 25%, 84%, 1)",
		whiteish: "hsla(240, 14%, 97.3%, 1)",
		chalk: "hsla(0, 0%, 91%, 1)",
	},
};

const { brand, main, flat, blueGreys, greys } = themeColors;

/////////////////////////////////////////////////////////////////////////////
///////////////////////////// COLOR-SHADE MAPS /////////////////////////////
/////////////////////////////////////////////////////////////////////////////

const COLORS_MAP = {
	purple: {
		50: "rgb(245, 243, 255)",
		100: "rgb(237, 233, 254)",
		200: "rgb(221, 214, 254)",
		300: "rgb(196, 181, 253)",
		400: "rgb(167, 139, 250)",
		500: "rgb(139, 92, 246)",
		600: "rgb(124, 58, 237)",
		700: "rgb(109, 40, 217)",
		800: "rgb(91, 33, 182)",
		900: "rgb(76, 29, 149)",
	},
	blue: {
		50: "rgb(239, 246, 255)",
		100: "rgb(219, 234, 254)",
		200: "rgb(191, 219, 254)",
		300: "rgb(147, 197, 253)",
		400: "rgb(96, 165, 250)",
		500: "rgb(59, 130, 246)",
		600: "rgb(37, 99, 235)",
		700: "rgb(29, 78, 216)",
		800: "rgb(30, 64, 175)",
		900: "rgb(30, 58, 138)",
	},
	red: {
		50: "rgb(254, 242, 242)",
		100: "rgb(254, 226, 226)",
		200: "rgb(254, 202, 202)",
		300: "rgb(252, 165, 165)",
		400: "rgb(248, 113, 113)",
		500: "rgb(239, 68, 68)",
		600: "rgb(220, 38, 38)",
		700: "rgb(185, 28, 28)",
		800: "rgb(153, 27, 27)",
		900: "rgb(127, 29, 29)",
	},
	pink: {
		100: "rgb(255, 245, 247)",
		200: "rgb(254, 215, 226)",
		300: "rgb(251, 182, 206)",
		400: "rgb(246, 135, 179)",
		500: "rgb(237, 100, 166)",
		600: "rgb(213, 63, 140)",
		700: "rgb(184, 50, 128)",
		800: "rgb(151, 38, 109)",
		900: "rgb(112, 36, 89)",
	},
	green: {
		50: "rgb(236, 253, 245)",
		100: "rgb(209, 250, 229)",
		200: "rgb(167, 243, 208)",
		300: "rgb(110, 231, 183)",
		400: "rgb(52, 211, 153)",
		500: "rgb(16, 185, 129)",
		600: "rgb(5, 150, 105)",
		700: "rgb(4, 120, 87)",
		800: "rgb(6, 95, 70)",
		900: "rgb(6, 78, 59)",
	},
	yellow: {
		50: "rgb(255, 251, 235)",
		100: "rgb(254, 243, 199)",
		200: "rgb(253, 230, 138)",
		300: "rgb(252, 211, 77)",
		400: "rgb(251, 191, 36)",
		500: "rgb(245, 158, 11)",
		600: "rgb(217, 119, 6)",
		700: "rgb(180, 83, 9)",
		800: "rgb(146, 64, 14)",
		900: "rgb(120, 53, 15)",
	},
	orange: {
		50: "rgb(255, 247, 237)",
		100: "rgb(255, 237, 213)",
		200: "rgb(254, 215, 170)",
		300: "rgb(253, 186, 116)",
		400: "rgb(251, 146, 60)",
		500: "rgb(249, 115, 22)",
		600: "rgb(234, 88, 12)",
		700: "rgb(194, 65, 12)",
		800: "rgb(154, 52, 18)",
		900: "rgb(124, 45, 18)",
	},
	grey: {
		50: "rgb(250, 250, 250)",
		100: "rgb(244, 244, 245)",
		200: "rgb(228, 228, 231)",
		300: "rgb(212, 212, 216)",
		400: "rgb(161, 161, 170)",
		500: "rgb(113, 113, 122)",
		600: "rgb(82, 82, 91)",
		700: "rgb(63, 63, 70)",
		800: "rgb(39, 39, 42)",
		900: "rgb(24, 24, 27)",
	},
	blueGrey: {
		50: "rgb(248, 250, 252)",
		100: "rgb(241, 245, 249)",
		200: "rgb(226, 232, 240)",
		300: "rgb(203, 213, 225)",
		400: "rgb(148, 163, 184)",
		500: "rgb(100, 116, 139)",
		600: "rgb(71, 85, 105)",
		700: "rgb(51, 65, 85)",
		800: "rgb(30, 41, 59)",
		900: "rgb(15, 23, 42)",
	},
	teal: {
		100: "rgb(230, 255, 250)",
		200: "rgb(178, 245, 234)",
		300: "rgb(129, 230, 217)",
		400: "rgb(79, 209, 197)",
		500: "rgb(56, 178, 172)",
		600: "rgb(49, 151, 149)",
		700: "rgb(44, 122, 123)",
		800: "rgb(40, 94, 97)",
		900: "rgb(35, 78, 82)",
	},
};

const { purple, blue, red, pink, green, yellow, orange, grey, blueGrey, teal } =
	COLORS_MAP;

/////////////////////////////////////////////////////////////////////////////
///////////////////////////// STATE-COLOR MAPS /////////////////////////////
/////////////////////////////////////////////////////////////////////////////

const baseFont = {
	fontFamily: "Raleway, Open Sans, Roboto, Arial, sans-serif",
	fontSize: "1.4rem",
	fontWeight: "600",
	color: main.blackBlue,
};

/**
 * Default 'state/status' colors for various application's state.
 * - 'ERROR'
 * - 'WARNING'
 * - 'SUCCESS'
 */
const stateColors = {
	warning: {
		...baseFont,
		backgroundColor: main.altYellow,
	},
	error: {
		...baseFont,
		backgroundColor: flat.red,
	},
	success: {
		...baseFont,
		backgroundColor: flat.green,
	},
	info: {
		...baseFont,
		backgroundColor: flat.main,
	},
};

/////////////////////////////////////////////////////////////////////////////
/////////////////////////////// ALERT COLORS ///////////////////////////////
/////////////////////////////////////////////////////////////////////////////

const BORDERS = {
	ERROR: `5px solid ${themeColors.main.red}`,
	SUCCESS: `5px solid ${themeColors.main.green}`,
	WARN: `5px solid ${themeColors.main.altYellow}`,
	INFO: `5px solid ${themeColors.main.main}`,
};

const ICONS = {
	ERROR: "exclamation-outline",
	WARN: "warning",
	SUCCESS: "checkmark-outline",
	INFO: "information-outline",
};

const FILLS = {
	SUCCESS: themeColors.main.green,
	WARN: themeColors.main.altYellow,
	INFO: themeColors.main.main,
	ERROR: themeColors.main.red,
};

/////////////////////////////////////////////////////////////////////////////
/////////////////////////////// DIALOG MAPS ///////////////////////////////
/////////////////////////////////////////////////////////////////////////////

const DIALOG_ICONS = {
	ERROR: "exclamation-outline",
	WARN: "warningreport_problem",
	WARN2: "warningreport_problem1",
	SUCCESS: "check_circle",
	INFO: "info-with-circle",
	INFO2: "information-outline",
	REPORT: "insert_chart_outlined",
	PRINT: "local_print_shopprint",
	SAVE: "save11",
	CHECKMARK: "check_circle",
	SIGNATURE: "gesture",
	ALARM: "access_alarmalarm",
	EDIT: "createmode_editedit",
	HELP: "live_help",
	MEDS: "local_hospital",
	CALENDAR: "event_note",
	CALENDAR_DONE: "event_available",
	CALENDAR_MISSED: "event_busy",
	USER: "account_circle",
	SETTINGS: "settings1",
	CHART: "chart-bar",
	ALERT: "notifications1",
	SHOW: "view-show",
	IMAGES: "images",
	// NEW!!! ICONS
	CANCEL: "clearclose",
	CHART2: "insert_chartpollassessment",
	CLOUD: "cloud_done",
	CLOUD_DOWNLOAD: "cloud_download",
	CLOUD_UPLOAD: "cloud_uploadbackup",
	DOWNLOAD: "file_downloadget_app",
	UPLOAD: "file_upload",
	FOLDER: "folder_open",
	FOLDER_PERSON: "folder_shared",
	FIRSTAID: "local_hospital",
	DINNER: "local_restaurantrestaurant_menu",
	HELP1: "not_listed_location",
};

/////////////////////////////////////////////////////////////////////////////
//////////////////////////// MOBILE-SCREEN SIZES ////////////////////////////
/////////////////////////////////////////////////////////////////////////////

/**
 * BASE SCREEN SIZES TO DETERMINE MOBILE:
 * - Used for <SidebarWrapper/> logic
 * - Determines whether to render horizontal or vertical sidebar
 */
const mobileSize = {
	width: 780, // px
	height: 650, // px ~ Updated 10/13/2021 at 12:07 PM
};

/////////////////////////////////////////////////////////////////////////////
//////////////////////////// USER-PAGE TAB ICONS ////////////////////////////
/////////////////////////////////////////////////////////////////////////////

/**
 * Icon's map for tab buttons
 */
const USERPAGE_ICONS = {
	facilities: "v-card",
	profile: "person_outlineperm_identity",
	notifications: "notifications_none",
	security: "security",
	preferences: "settings11",
	default: "info_outline1",
	checkmark: "checkmark",
	checkmarkOutline: "checkmark-outline",
	close: "close",
	envelope: "envelope",
	exclaimOutline: "exclamation-outline",
	exclaimSolid: "exclamation-solid",
	infoOutline: "information-outline",
	infoSolid: "information-solid",
	save: "save-disk",
	save2: "save1",
	tuning: "tuning",
	search: "search",
	user: "user",
	userAdd: "user-add",
	hide: "view-hide",
	show: "view-show",
	atSign: "email",
	forward: "forward",
	reply: "reply",
	help: "help-with-circle1",
	imageFilled: "image-inverted",
	imageOutline: "image",
	images: "images",
	lightbulb: "light-bulb",
	lockOpen: "lock-open",
	lockClosed: "lock",
	mail: "mail",
	minus: "minus",
	plus: "plus",
	print: "print",
	tools: "tools",
};

// theme-colors map(s)
export { themeColors, brand, main, flat, blueGreys, greys };

// alert-colors map(s)
export { BORDERS, ICONS, FILLS };

// dialog-component map(s)
export { DIALOG_ICONS };

// state/status-color map(s)
export { stateColors };

// color-shades map
export { COLORS_MAP };
export { purple, blue, red, pink, green, yellow, orange, grey, blueGrey, teal };

// mobile sizes
export { mobileSize };

// <UserPage/> tab icons
export { USERPAGE_ICONS };
