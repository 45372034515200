import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { currentEnvName } from "./helpers/utils_env";

/**
 * Initilized "Google Analytics" tracker via tracking ID.
 * - Supports pageview tracking
 * - Supports 'Universal Analytics' via GA
 */

const getTrackingID = (env) => {
	switch (env) {
		case "test": {
			// test UID
			return "GA4 (276213331)";
		}
		case "production":
		case "prod": {
			// prod UID
			return `UA-180266919-2`;
		}
		default:
			return;
	}
};

// updates document title based on environment (ENV)
const getDocTitle = () => {
	const envName = currentEnvName;
	if (envName === "prod" || envName === "production") {
		return (document.title = "Portal");
	} else {
		return (document.title = "Portal ~ 👉 TEST 👈 ");
	}
};

getDocTitle();

// const trackingID = "UA-180266919-2";
const trackingID = getTrackingID(currentEnvName);
ReactGA.initialize(trackingID, {
	debug: false,
	gaOptions: {},
});
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
