import React, { useState } from "react";
import styles from "../../css/facility/DisabledBadge.module.scss";
import sprite from "../../assets/icons/placeholders.svg";
import { PropTypes } from "prop-types";

const DisabledBadge = () => {
	const [showStatus, setShowStatus] = useState(false);
	return (
		<div
			className={styles.DisabledBadge}
			onMouseOver={() => setShowStatus(true)}
			onMouseLeave={() => setShowStatus(false)}
		>
			{!showStatus && (
				<svg className={styles.DisabledBadge_icon}>
					<use xlinkHref={`${sprite}#icon-clearclose`}></use>
				</svg>
			)}
			{showStatus && <div className={styles.DisabledBadge_text}>Disabled</div>}
		</div>
	);
};

export default DisabledBadge;

DisabledBadge.defaultProps = {};
DisabledBadge.propTypes = {};
