import React from "react";
import styles from "../../css/tabs/FullPageTabButton.module.scss";
import { PropTypes } from "prop-types";

const getCSS = (activeIndex, tabIndex) => {
	if (activeIndex === tabIndex) {
		return styles.FullPageTabButton_isActive;
	} else {
		return styles.FullPageTabButton;
	}
};

// tab button control w/ "onClick" handler injected into each child
const FullPageTabButton = ({
	handleTabButton,
	activeIndex,
	tabIndex,
	isDisabled = false,
	isHidden = false,
	children,
}) => {
	const withProps = React.Children.map(children, (child, idx) => {
		return React.cloneElement(child, {
			activeIndex: activeIndex,
			tabIndex: tabIndex,
			isActive: activeIndex === tabIndex,
			isDisabled: isDisabled,
			isHidden: isHidden,
		});
	});

	if (isHidden) {
		return null;
	}
	return (
		<button
			className={getCSS(activeIndex, tabIndex)}
			onClick={() => handleTabButton(tabIndex)}
			disabled={isDisabled}
		>
			{withProps}
		</button>
	);
};

export default FullPageTabButton;

FullPageTabButton.defaultProps = {
	isDisabled: false,
	isHidden: false,
};

FullPageTabButton.propTypes = {
	children: PropTypes.any.isRequired,
	tabIndex: PropTypes.number, // this button's assigned index
	activeIndex: PropTypes.number, // the current active index
	handleTabButton: PropTypes.func, // click handler for switching tabs
};
