import React, { useEffect, useMemo, useState } from "react";
import styles from "../../css/user/UserDetails.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import { ADVUSERModel } from "../../helpers/utils_models";
import {
	deactivateUser,
	getUserFacilityList,
	getUserName,
	saveUserChanges,
} from "../../helpers/utils_user";
import {
	formatAndSortUserFacilities,
	processFacilityList,
} from "../../helpers/utils_facility";
import {
	blue,
	blueGrey,
	green,
	orange,
	pink,
	red,
	yellow,
} from "../../helpers/utils_styles";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import {
	appIDs,
	getAppAccessByUser,
	getAppAccessByUserID,
	matchAppAccessByAppID,
	saveUserAccessByApp,
} from "../../helpers/utils_apps";
import {
	disableByUserTypeAndAccess,
	enableFeatureByRankAndAccess,
	enableFeatureByTargetUserType,
	enableFeatureByUserRank,
	enableFeatureViaInternalList,
	enableFeatureViaWhiteList,
	enableSuspendPermission,
	featureFlags,
	featuresWhiteList,
	isAccessEnabled,
} from "../../helpers/utils_permissions";
import {
	getUserSecurityInfo,
	initAndUpdateResetModel,
	processAccountSecurity,
	processUserSecurity,
	saveUserResetTypes,
} from "../../helpers/utils_security";
import {
	checkForUserLockout,
	getPasswordResetTypes,
	lockUserLogin,
	unlockUserLogin,
	updateSuspendStatus,
} from "../../helpers/utils_lockouts";
import {
	isAdmin,
	isAdminType,
	isExecutiveAdmin,
	isSuperUser,
} from "../../helpers/utils_userTypes";
import {
	addUserToEmar,
	checkFacilityEmarAccess,
	checkUserEmarAccess,
	disableUserToEmar,
} from "../../helpers/utils_emar";
import { getSuspendedBy, getSuspendedDate } from "../../helpers/utils_suspend";
import { format } from "date-fns";
// components
import FormActions from "../forms/FormActions";
import FormSection from "../forms/FormSection";
import CopyText from "../forms/CopyText";
import Divider from "../forms/Divider";
import AboutUser from "./AboutUser";
import UserAccessSwitch from "./UserAccessSwitch";
import ButtonSM from "../shared/ButtonSM";
import ToggleSwitch from "../shared/ToggleSwitch";
import UserTypes from "./UserTypes";
import Dialog from "../shared/Dialog";
import AccountUnlockSwitch from "../summary/AccountUnlockSwitch";
import ProtectedFeature from "../permissions/ProtectedFeature";
import LockoutStatus from "../summary/LockoutStatus";
import UserResetMethods from "./UserResetMethods";
import ResetUsersPassword from "./ResetUsersPassword";
import EditUserInfo from "./EditUserInfo";
import ResetMethodWarnings from "./ResetMethodWarnings";
import EditUsername from "./EditUsername";
// new changes
import { UserTypePill } from "../admin/UserBadge";
import EditFacilityAccess from "./EditFacilityAccess";
import ChangeUserType from "../admin/ChangeUserType";
import LockoutIcon from "./LockoutIcon";
import EmarUserAccess from "../emar/EmarUserAccess";
import EPayUserAccess from "../epay/EPayUserAccess";
// disabled/hidden components
import MakeUserAdmin from "../admin/MakeUserAdmin";
import JobTitleHandler from "./JobTitleHandler";
import {
	addStaxUser,
	checkFacilityStaxAccess,
	disableStaxUser,
} from "../../helpers/utils_stax";

const customCSS = {
	deleteBtn: {
		backgroundColor: red[600],
		color: "#ffffff",
		padding: ".7rem 1.3rem",
		borderRadius: "5rem",
		fontSize: "1.4rem",
		fontWeight: "600",
	},
	confirmDeleteBtn: {
		padding: ".7rem 1.3rem",
		borderRadius: ".5rem",
		backgroundColor: red[600],
		color: red[100],
		fontSize: "1.4rem",
		fontWeight: "600",
	},
	cancelDeleteBtn: {
		padding: ".7rem 1.3rem",
		borderRadius: ".5rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.4rem",
		fontWeight: "600",
		border: `1px solid ${red[400]}`,
		marginRight: "1rem",
	},
	grantAccessBtn: {
		padding: ".6rem 1.1rem",
		borderRadius: "5rem",
		backgroundColor: blueGrey[700],
		color: blueGrey[100],
		fontSize: "1.4rem",
		fontWeight: "600",
	},
	cancelGrantBtn: {
		padding: ".6rem 1.1rem",
		borderRadius: "5rem",
		backgroundColor: "transparent",
		color: blueGrey[600],
		fontSize: "1.4rem",
		fontWeight: "600",
		border: `1px solid ${blueGrey[400]}`,
		marginLeft: "1rem",
	},
};

const ENABLE_ACCESS_MSG = false;

const showEmarAccessSwitch = (currentUser, facilityHasEmar) => {
	// CONDITIONS TO SHOW SWITCH:
	// 1. Check feature flag
	// 2. Then, check facility has access
	// 3. Then check if logged-in user has access and is ExecutiveAdmin (also, SuperUser or Internal Whitelisted)
	const hasFeature = featureFlags?.user?.enableEmar;
	const isWhiteListed = enableFeatureViaInternalList(currentUser?.userID);
	const hasFacilityAccess = facilityHasEmar;
	const hasUserAccess =
		isExecutiveAdmin(currentUser) || isSuperUser(currentUser) || isWhiteListed;
	const showSwitch = hasFeature && hasUserAccess && hasFacilityAccess;

	return showSwitch;
};
const showEPayAccessSwitch = (currentUser, facilityEPay) => {
	// CONDITIONS TO SHOW SWITCH:
	// 1. Check feature flag
	// 2. Then, check facility has access
	// 3. Then check if logged-in user has access and is ExecutiveAdmin (also, SuperUser or Internal Whitelisted)
	const hasFeature = featureFlags?.user?.enableEmar;
	const isWhiteListed = enableFeatureViaInternalList(currentUser?.userID);
	const hasFacilityAccess = facilityEPay;
	const hasUserAccess =
		isExecutiveAdmin(currentUser) || isSuperUser(currentUser) || isWhiteListed;
	const showSwitch = hasFeature && hasUserAccess && hasFacilityAccess;

	return showSwitch;
};

// locks app access switch: enabled for supers, admins & whitelisted
const lockAppAccessSwitch_OLD = (currentUser) => {
	const hasOverride = featuresWhiteList.includes(currentUser.userID);
	const isAdminType =
		currentUser?.isAdmin ||
		currentUser?.isFacilityAdmin ||
		currentUser?.isALAAdmin;

	if (hasOverride || isAdminType) return false;
	return true;
};

const lockAppAccessSwitch = (selectedUser, currentUser) => {
	const hasOverride = featuresWhiteList.includes(currentUser.userID);
	if (hasOverride) return false;

	const shouldLock = !enableFeatureByUserRank(selectedUser, currentUser);

	return shouldLock;
};

const lockTrackerAccessSwitch_OLD = (appAccess = {}, currentUser) => {
	const enabledUser = !lockAppAccessSwitch(currentUser);
	const hasAccess = currentUser?.hasTrackerAccess ?? false;
	const enabledApp = appAccess?.AdvantageTracker ?? false;
	// double-check this logic?????
	const enableSwitch = enabledApp || enabledUser || hasAccess;
	const shouldLock = !enableSwitch;

	return shouldLock;
};
const lockTrackerAccessSwitch = (appAccess, selectedUser, currentUser) => {
	const enabledApp = appAccess?.AdvantageTracker ?? false;
	const userHasAccess = currentUser?.hasTrackerAccess ?? false;
	const shouldLockByUserType = lockAppAccessSwitch(selectedUser, currentUser);

	const shouldEnable = shouldLockByUserType || !enabledApp || userHasAccess;
	const shouldLock = !shouldEnable;

	return shouldLock;
};

const getUserLastLogin = (user = {}) => {
	const base = user?.lastLogin ?? "Unknown";
	const lastLogin = format(base, "MM/DD/YYYY");
	const loginTime = format(base, "h:mm A");
	if (!lastLogin || lastLogin === `Invalid Date`) {
		return ``;
	} else {
		return `${lastLogin} at ${loginTime}`;
	}
};

const getUserDateCreated = (user = {}) => {
	const base = user?.dateCreated ?? "Unknown";
	const dateCreated = format(base, "MM/DD/YYYY");
	const createdTime = format(base, "h:mm A");
	if (!dateCreated || dateCreated === `Invalid Date`) {
		return ``;
	} else {
		return `${dateCreated} at ${createdTime}`;
	}
};

const getUserCreatedBy = (createdBy) => {
	const by = !isEmptyVal(createdBy) ? createdBy : "";

	return `${by}`;
};

const AboutText = ({ label, text }) => {
	return (
		<div className={styles.AboutText}>
			<span className={styles.AboutText_label}>{label}</span>
			<CopyText text={text} />
		</div>
	);
};

const Locked = () => {
	return (
		<div className={styles.Locked}>
			(<LockoutIcon />
			<span>ACCOUNT LOCKED</span>)
		</div>
	);
};

const Suspended = () => {
	return <span className={styles.Suspended}>(SUSPENDED)</span>;
};

const SuspendedDate = ({ suspendedDate }) => {
	return (
		<div className={styles.SuspendedDate}>
			<span className={styles.SuspendedDate_label}>Suspended on</span>
			<span className={styles.SuspendedDate_date}>
				{getSuspendedDate(suspendedDate)}
			</span>
		</div>
	);
};
const SuspendedBy = ({ suspendedBy }) => {
	return (
		<div className={styles.SuspendedBy}>
			<span className={styles.SuspendedBy_label}>Suspended by</span>
			<span className={styles.SuspendedBy_suspendedBy}>
				<CopyText text={getSuspendedBy(suspendedBy)} />
			</span>
		</div>
	);
};

const UserSuspensionSwitch = ({ suspendUserVal, handleSuspendUser }) => {
	return (
		<div className={styles.UserDetails_suspendUser}>
			<div className={styles.UserDetails_suspendUser_label}>
				{suspendUserVal ? "Suspended" : "Suspend"}
			</div>
			<ToggleSwitch
				size="MD"
				name="suspendUser"
				id="suspendUser"
				val={suspendUserVal}
				handleToggle={handleSuspendUser}
				customColor={red[500]}
			/>
			{suspendUserVal && (
				<div className={styles.UserDetails_suspendUser_isSuspended}>
					This user is suspended!
				</div>
			)}
		</div>
	);
};

const UserDetails = ({
	user = {},
	currentUser = {},
	currentFacility = {},
	hasPortalAccess = false,
	hasTrackerAccess = false,
	hasLegacyAccess = false,
	hasEmarAccess = false,
	hasEPayAccess = false,
	isDeactivated = false,
	isLegacyOnly = false,
	appsList = [],
	allFacilities = [],
	dispatchAlert,
	dispatchToState,
	tableDispatch,
	syncLockStatus,
	syncSuspendStatus,
	updateAccessList, // syncs changes from 'UserDetails' to user table
	deleteUser,
	closeModal,
}) => {
	const [showDeleteUserConfirmation, setShowDeleteUserConfirmation] =
		useState(false);
	const { formState, setFormState, handleCheckbox } = useForm({
		// user types' vals
		// consider 'Facility' & 'Regional' Admin(s)
		isSuperUser: user?.isSuperUser ?? false,
		isRegionalAdmin: user?.isRegionalAdmin ?? false,
		isALAAdmin: user?.isALAAdmin ?? false,
		isFacilityAdmin: user?.isFacilityAdmin ?? false,
		isAdmin: user?.isAdmin ?? false,
		isMedTechRestricted: user?.isMedTechRestricted ?? false,
		// user status vals
		suspendUser: user?.isSuspended ?? false,
		disableUser: !user?.isActive ?? false,
		// lockouts
		isLockedOut:
			checkForUserLockout(user?.userID, currentFacility?.lockouts) ||
			user?.isLockedOut,
		// reset methods
		isPwdResetByEmail: user?.isPwdResetByEmail,
		isPwdResetByQuestions: user?.isPwdResetByQuestions,
		isPwdResetByAdmin: user?.isPwdResetByAdmin,
		// for external user password resets (admin only)
	});
	const { values } = formState;
	// facility access granted to 'user' NOT 'currentUser'
	const [facilityAccessList, setFacilityAccessList] = useState([]);
	// removed facility access list
	const [removedAccessList, setRemovedAccessList] = useState([]);
	const [userData, setUserData] = useState(user);
	const [facilityHasEmar, setFacilityHasEmar] = useState(null);
	const [facilityHasEPay, setFacilityHasEPay] = useState(null);
	// ##TODOS:
	// - Add 'AdminPortal' access
	// - Update processing utils & fetch utils

	// user-specific app access values
	const [appAccess, setAppAccess] = useState({
		SeniorCareVB: hasLegacyAccess,
		SeniorCareEHR: hasLegacyAccess,
		AdvantageTracker: hasTrackerAccess,
		AdminPortal: hasPortalAccess,
		ChartMeds: hasEmarAccess,
		ePay: hasEPayAccess,
	});
	// enables ALL protected features by user's rank
	// - Checks selected 'user's rank & 'currentUser's rank...
	// - ...and determines if 'currentUser' should have permission or not
	const enableProtectedFeatures = useMemo(() => {
		// return enableFeatureByUserRank(user, currentUser);
		const isEnabled = enableFeatureByRankAndAccess(user, currentUser);
		// fallback for nullish data results
		if (isEnabled === null || isEnabled === undefined) return false;

		return isEnabled;
	}, [currentUser, user]);

	// ##TODOS:
	// LIFT 'handleLockOut' to '<UserAccessRow/>'

	// handle 'Lock/Unlock' button & request
	const handleLockOut = (e) => {
		setFormState({
			...formState,
			values: {
				...values,
				isLockedOut: !values?.isLockedOut,
			},
		});
		syncLockStatus(!values?.isLockedOut);
		return saveLockoutChange(!values?.isLockedOut);
	};

	// changes access to an ALA app
	const handleAccess = (e) => {
		const { name, checked } = e.target;
		setAppAccess({
			...appAccess,
			[name]: checked,
		});

		// fire off 'saveAppAccessChange(appName, accessVal)'
		saveAppAccessChange(name, checked);
	};

	// handles emar toggle access switch
	const handleEmarAccess = (e) => {
		const { name, checked } = e.target;
		setAppAccess({
			...appAccess,
			[name]: checked,
		});

		return saveEmarAccess(checked);
	};
	// handles emar toggle access switch
	const handleEPayAccess = (e) => {
		const { name, checked } = e.target;
		setAppAccess({
			...appAccess,
			[name]: checked,
		});

		return saveEPayAccess(checked);
	};

	// suspends/unsuspends a user
	const handleSuspension = (e) => {
		const { checked } = e.target;
		handleCheckbox(e);
		// fire off request
		saveUserSuspendChanges(checked);
	};
	// deletes a single user
	const handleDeleteUser = () => {
		setShowDeleteUserConfirmation(true);
	};

	// 'virtual' deletes a user - requires confirmation
	const confirmDeleteUser = async () => {
		const { token } = currentUser;
		const { userLoginID } = user;
		// de-facto virtual delete
		// const wasDeleted = true; // FOR TESTING LOCAL STATE UPDATES!!!
		const wasDeleted = await deactivateUser(token, userLoginID);

		if (wasDeleted) {
			setShowDeleteUserConfirmation(false);
			deleteUser(user?.userID); // RE-ENABLE AND TEST
			closeModal();
			return dispatchAlert("INFO", {
				heading: "User was DELETED!!",
			});
		} else {
			setShowDeleteUserConfirmation(false);
			return dispatchAlert("ERROR", {
				heading: "Ooops!",
				subheading: "User deletion failed. Please try again.",
			});
		}
	};
	// cancels 'virtual' delete
	const cancelDeleteUser = () => {
		setShowDeleteUserConfirmation(false);
	};

	// changes user type (eg. 'Admin', 'MedTech...', 'SuperUser' etc)
	const changeUserType = async () => {
		const { token } = currentUser;
		const userBase = new ADVUSERModel({
			...user,
			isMedTechRestricted: values?.isMedTechRestricted,
			isSuperUser: values?.isSuperUser,
			isFacilityAdmin: values?.isAdmin,
			isRegionalAdmin: false,
			isFormerEmployee: false,
		});
		const updatedUserModel = userBase.getModel();
		const success = await saveUserChanges(token, updatedUserModel);

		if (success) {
			// dispatch alert here...
			dispatchAlert("success", {
				heading: `Success!`,
				subheading: `User changes were saved.`,
			});
			return;
		} else {
			// dispatch alert here...
			return dispatchAlert("error", {
				heading: `Error!`,
				subheading: `Changes were NOT saved.`,
			});
		}
	};

	// cancels user type changes
	const cancelUserType = () => {
		setFormState({
			...formState,
			values: {
				...values,
				isFacilityAdmin: user?.isFacilityAdmin,
				isRegionalAdmin: false,

				isAdmin: user?.isAdmin,
				isSuperUser: user?.isSuperUser,
				isMedTechRestricted: user?.isMedTechRestricted,
			},
		});
	};

	// saves password reset methods' changes
	const saveResetMethods = async (e) => {
		const { token } = currentUser;
		const { userID, password } = user;
		const resetSettings = initAndUpdateResetModel({
			...values,
			password: password,
		});
		const wasSaved = await saveUserResetTypes(token, userID, resetSettings);

		if (wasSaved) {
			return dispatchAlert("INFO", {
				heading: `Changes were saved!!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Ooops!`,
				subheading: `Changes were NOT saved.! Please, try again.`,
			});
		}
	};
	const cancelResetMethods = () => {};

	// save emar access for user - ChartMeds specific (currently!!!)
	const saveEmarAccess = async (accessVal) => {
		const { token } = currentUser;
		const { userID } = user;
		const { facilityID } = currentFacility;

		switch (true) {
			// DISABLE-ACCESS: already has access, now disabling
			case !accessVal && hasEmarAccess: {
				const wasDisabled = await disableUserToEmar(token, facilityID, userID);

				if (wasDisabled) {
					closeModal();
					return dispatchAlert("SUCCESS", {
						heading: `EMAR Access was disabled!`,
					});
				} else {
					return dispatchAlert("ERROR", {
						heading: `There was an issue!`,
						subheading: `Please try again.`,
					});
				}
			}
			// ENABLE-ACCESS: enabling access via AddUser API
			case accessVal && !hasEmarAccess: {
				const wasAdded = await addUserToEmar(token, facilityID, userID);

				if (wasAdded) {
					closeModal();
					return dispatchAlert("SUCCESS", {
						heading: `EMAR Access was enabled!`,
					});
				} else {
					return dispatchAlert("ERROR", {
						heading: `There was an issue!`,
						subheading: `Please try again.`,
					});
				}
			}
			default:
				return;
		}
	};

	// handles updating ePay access status for user
	const saveEPayAccess = async (accessVal) => {
		const { token } = currentUser;
		const { userID } = user;
		const { facilityID } = currentFacility;

		switch (true) {
			// DISABLE-ACCESS: already has access, now disabling
			case !accessVal && hasEPayAccess: {
				const wasDisabled = await disableStaxUser(token, {
					facilityID,
					userID,
				});

				if (wasDisabled) {
					closeModal();
					return dispatchAlert("SUCCESS", {
						heading: `ePay Access was disabled!`,
					});
				} else {
					return dispatchAlert("ERROR", {
						heading: `There was an issue!`,
						subheading: `Please try again.`,
					});
				}
			}
			// ENABLE-ACCESS: enabling access via AddUser API
			case accessVal && !hasEPayAccess: {
				const wasAdded = await addStaxUser(token, {
					facilityID,
					userID,
				});

				if (wasAdded) {
					closeModal();
					return dispatchAlert("SUCCESS", {
						heading: `ePay Access was enabled!`,
					});
				} else {
					return dispatchAlert("ERROR", {
						heading: `There was an issue!`,
						subheading: `Please try again.`,
					});
				}
			}
			default:
				return;
		}
	};

	// saves application access record/change
	const saveAppAccessChange = async (appName, accessVal) => {
		const { token } = currentUser;
		const { userID } = user;
		// fetch user access model here - ONLY tracker is available
		const appID = appIDs[appName];
		const accessModels = await getAppAccessByUser(token, userID);
		const accessModel = matchAppAccessByAppID(appID, accessModels);

		// const response = false;
		const response = await saveUserAccessByApp(token, {
			ApplicationByUserID: accessModel?.ApplicationByUserID ?? 0,
			ApplicationID: accessModel?.ApplicationID ?? appID,
			UserID: userID,
			IsAccessible: accessVal,
		});

		if (!isEmptyVal(response)) {
			updateAccessList(appName, {
				isEnabled: accessVal,
				userID: userID,
			});
			return dispatchAlert("INFO", {
				heading: "Success!",
				subheading: "User's access has been updated!",
			});
		} else {
			updateAccessList(appName, {
				isEnabled: accessVal,
				userID: userID,
			});
			return dispatchAlert("ERROR", {
				heading: "Ooops!",
				subheading: "User changes could NOT be saved!",
			});
		}
	};

	const fetchCurrentFacilityAccess = async () => {
		const { email, userID } = user;
		const { token } = currentUser;
		const { facilityID } = currentFacility;
		// const facilityList = await getUserFacilityList(token, email);
		const [facilityList, facilityEmar, userEmar] = await Promise.all([
			getUserFacilityList(token, email),
			checkFacilityEmarAccess(token, facilityID),
			checkUserEmarAccess(token, facilityID, userID),
			checkFacilityStaxAccess(token, facilityID),
		]);

		if (!isEmptyArray(facilityList)) {
			setFacilityHasEmar(facilityEmar);
			setAppAccess({
				...appAccess,
				ChartMeds: userEmar,
			});
			return setFacilityAccessList([...processFacilityList(facilityList)]);
		} else {
			setFacilityHasEmar(null);
			return setFacilityAccessList([]);
		}
	};

	const syncFacilityAccessChanges = (updatedAccessList) => {
		setFacilityAccessList(updatedAccessList);
	};

	// fires off request to suspend/un-suspend user
	const saveUserSuspendChanges = async (suspendUser = false) => {
		const { token } = currentUser;
		const { userID } = user;
		updateSuspendStatus(token, userID, suspendUser);
		// syncs local state to user row state
		syncSuspendStatus(suspendUser);
	};

	// save lockout change
	const saveLockoutChange = async (isLocked = false) => {
		const { token } = currentUser;
		const { userID } = user;

		if (!isLocked) {
			const isNowUnlocked = await unlockUserLogin(token, userID);

			return isNowUnlocked;
		} else {
			const isNowLocked = await lockUserLogin(token, userID);

			return isNowLocked;
		}
	};

	// fetches user's password reset methods & merges into state & 'user'
	// 'user' should already have everything else: app access, user profile etc
	const getUserDetails = async () => {
		const { token } = currentUser;
		const { email } = user;
		// fetch reset methods & user security
		// const rawSecurity = await getUserSecurityInfo(token, email);
		// const userSecurity = processUserSecurity(rawSecurity);
		// const { resetMethods: resetRules } = userSecurity;

		const rawRules = await getPasswordResetTypes(token, email);
		const resetRules = processAccountSecurity(rawRules);

		setUserData({
			// previous version
			...userData,
			...resetRules,
			// new version
			// ...userSecurity,
		});
		setFormState({
			...formState,
			values: {
				...values,
				isPwdResetByAdmin: resetRules?.isPwdResetByAdmin,
				isPwdResetByEmail: resetRules?.isPwdResetByEmail,
				isPwdResetByQuestions: resetRules?.isPwdResetByQuestions,
			},
		});
	};

	// fetches user's details (eg. reset types, etc)
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		getUserDetails();
		fetchCurrentFacilityAccess();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div
				className={styles.UserDetails}
				style={isDeactivated ? { opacity: ".4" } : {}}
			>
				<header className={styles.UserDetails_header}>
					<h2 className={styles.UserDetails_header_title}>
						User: <b>{getUserName(user)}</b>
						{values.suspendUser && <Suspended />}
						{values.isLockedOut && <Locked />}
					</h2>
					{values?.suspendUser && (
						<SuspendedDate suspendedDate={user?.suspendedDate} />
					)}
					{values?.suspendUser && (
						<SuspendedBy
							usersList={currentFacility?.users}
							suspendedBy={user?.suspendedBy}
						/>
					)}

					<AboutText label="User ID:" text={user?.userID} />
					<AboutText label="Facility ID:" text={currentFacility?.facilityID} />
					<AboutText label="Created Date:" text={getUserDateCreated(user)} />
					<AboutText
						label="Created By:"
						text={getUserCreatedBy(user?.createdBy)}
					/>
					<AboutText label="Last Login:" text={getUserLastLogin(user)} />
					<div className={styles.UserDetails_header_userType}>
						<span>User Type:</span>
						<span className={styles.UserDetails_header_userType_label}>
							<UserTypePill user={user} />
						</span>
					</div>
				</header>
				{ENABLE_ACCESS_MSG && !enableProtectedFeatures && (
					<div className={styles.UserDetails_msg}>
						<svg className={styles.UserDetails_msg_icon}>
							<use xlinkHref={`${sprite}#icon-error1`}></use>
						</svg>
						<span>You don't have access to edit this user account!</span>
					</div>
				)}
				<Divider />
				<main className={styles.UserDetails_main}>
					{/* ABOUT USER */}
					<FormSection
						title="About"
						icon="news"
						hideInfoIcon={true}
						hoverText="Information about this user."
					>
						<AboutUser user={user} allFacilities={allFacilities} />
					</FormSection>
					<Divider />

					{/* USER-LOCKOUT */}
					<ProtectedFeature
						isEnabled={enableProtectedFeatures && !isLegacyOnly}
					>
						<FormSection
							title={
								values?.isLockedOut
									? `Account Status: LOCKED`
									: `Account Status: UNLOCKED`
							}
							hoverText="Lock or unlock a user's account."
							icon={values?.isLockedOut ? "lock" : "lockOpen"}
							titleColor={values?.isLockedOut ? red[600] : green[500]}
							iconColor={values?.isLockedOut ? red[600] : green[500]}
						>
							<div className={styles.UserDetails_desc}>
								Update this user's account status. Locking an account will
								disable their ability to login to all ALA applications.
								Unlocking will enable them to reset their password, once
								unlocked.
							</div>
							<LockoutStatus isLockedOut={values?.isLockedOut} />
							<AccountUnlockSwitch
								isLocked={values?.isLockedOut}
								handleUnlockSwitch={handleLockOut}
							/>
						</FormSection>
						<Divider />
					</ProtectedFeature>

					{/* PASSWORD RESET METHODS */}
					<ProtectedFeature
						isEnabled={enableProtectedFeatures && !isLegacyOnly}
					>
						<FormSection
							title="Password Reset Methods"
							icon="login"
							hoverText="Update how a user can reset their password."
						>
							<UserResetMethods vals={values} handleCheckbox={handleCheckbox} />

							<ResetMethodWarnings formState={formState} />
							<FormActions
								saveTxt="Save Changes"
								cancelTxt="Cancel"
								isDisabled={
									!values.isPwdResetByAdmin &&
									!values.isPwdResetByEmail &&
									!values.isPwdResetByQuestions
								}
								saveHandler={saveResetMethods}
								cancelHandler={cancelResetMethods}
							/>
						</FormSection>
						<Divider />
					</ProtectedFeature>

					{/* GRANT/DENY/EDIT FACILITY ACCESS */}
					{/* 
						- We disable this feature when the 'user' (eg selected user) has a user type of FA or higher.
						- Internal/Whitelist overrides exist for this feature
					
					*/}
					<ProtectedFeature
						isEnabled={enableProtectedFeatures && !isLegacyOnly}
					>
						<FormSection
							title="Edit Facility Access"
							icon="building"
							hoverText="Edit access to one or more facilities."
							isDisabled={isLegacyOnly}
						>
							<EditFacilityAccess
								key={`FACILITY-LIST:${facilityAccessList?.length}`}
								targetUser={user}
								currentUser={currentUser}
								facilityAccessList={[
									...formatAndSortUserFacilities(facilityAccessList),
								]}
								allFacilities={[...allFacilities]}
								dispatchAlert={dispatchAlert}
								dispatchToState={dispatchToState}
								syncFacilityAccessChanges={syncFacilityAccessChanges}
								closeModal={closeModal}
							/>
						</FormSection>
						<Divider />
					</ProtectedFeature>

					{/* APPLICATION ACCESS */}
					<ProtectedFeature isEnabled={!isLegacyOnly}>
						<FormSection
							icon="lock"
							title="Application Access"
							hoverText="Manage a users application access."
							isDisabled={isLegacyOnly}
						>
							<p className={styles.UserDetails_desc}>
								Disabling app access will prevent a user from accessing a given
								application. This will not delete any user-related data. Users
								can be re-enabled at any time.
							</p>
							<UserAccessSwitch
								appName="SeniorCareEHR"
								isEnabled={appAccess?.SeniorCareEHR}
								isLocked={lockAppAccessSwitch(user, currentUser)}
								handleAccess={handleAccess}
							/>
							<br style={{ margin: "2rem 0" }} />
							<UserAccessSwitch
								appName="AdvantageTracker"
								isEnabled={appAccess?.AdvantageTracker}
								// isLocked={lockAppAccessSwitch(currentUser)} // CURRENT LOCKER - 3/16/2023 at 11:16 AM
								isLocked={lockAppAccessSwitch(user, currentUser)}
								handleAccess={handleAccess}
							/>
							<UserAccessSwitch
								appName="AdminPortal"
								isEnabled={appAccess?.AdminPortal}
								isLocked={lockAppAccessSwitch(user, currentUser)}
								handleAccess={handleAccess}
							/>
						</FormSection>
						<Divider />
					</ProtectedFeature>

					{/* EMAR ACCESS (CHART-MEDS) - EXECUTIVE ADMINS ONLY!!! */}
					{/* CONDITIONS: 
							- Check logged-in user's user type (MUST BE EXECUTIVE-ADMIN TYPE FOR FEATURE TOGGLE TO SHOW) 
							- Then, check facility's access
							- Then, check selected user's access and set state accordingly
					*/}
					<ProtectedFeature
						// isEnabled={showEmarAccessSwitch(currentUser, facilityHasEmar)}
						isEnabled={false}
					>
						<FormSection
							icon="emar"
							title="EMAR Access"
							hoverText="Manage a user's EMAR access."
							isDisabled={isLegacyOnly}
							titleColor={blue[600]}
							iconColor={orange[500]}
						>
							<p className={styles.UserDetails_desc}>
								Manage this user's access to your community's assigned EMAR
								system.
							</p>

							<EmarUserAccess
								appName="Chart-Meds (EMAR)"
								isEnabled={appAccess?.ChartMeds}
								isLocked={false}
								handleAccess={handleEmarAccess}
							/>
						</FormSection>
						<Divider />
					</ProtectedFeature>

					{/* EPAY ACCESS - EXECUTIVE ADMINS ONLY!! */}
					{/* CONDITIONS:
							- Check logged-in user's user type (MUST BE EA OR GREATER FOR FEATURE TO SHOW)
							- Then, check facility's access
							- Then, check selected user's access & set state
							- CONDITIONAL LOGIC IS IDENTICAL TO EMAR LOGIC!!!
					
					*/}
					<ProtectedFeature
						isEnabled={showEPayAccessSwitch(currentUser, facilityHasEmar)}
					>
						<FormSection
							icon="ePay"
							title="ePay Access (Stax)"
							hoverText="Manage a user's ePay access."
							isDisabled={isLegacyOnly}
							titleColor={blue[600]}
							iconColor={orange[500]}
						>
							<p className={styles.UserDetails_desc}>
								Manage this user's access to your community's assigned ePay
								system.
							</p>

							<EPayUserAccess
								appName="ePay"
								isEnabled={appAccess?.ePay}
								isLocked={false}
								handleAccess={handleEPayAccess}
							/>
						</FormSection>
						<Divider />
					</ProtectedFeature>

					{/* EDIT USER'S INFO (FIRST NAME & LAST NAME ETC.) ~ ADMIN ONLY */}
					<ProtectedFeature
						isEnabled={enableProtectedFeatures && !isLegacyOnly}
					>
						<>
							<FormSection
								title="Edit User's Profile Info"
								icon="user"
								hoverText="Change user's name on file."
							>
								<EditUserInfo
									dispatchAlert={dispatchAlert}
									dispatchToState={dispatchToState}
									currentUser={currentUser}
									targetUser={user}
									tableDispatch={tableDispatch}
								/>
							</FormSection>
							<Divider />
						</>
					</ProtectedFeature>

					{/* CHANGE USERNAME LOGIN ~ (WHITELIST ONLY) */}
					<ProtectedFeature
						isEnabled={enableProtectedFeatures && !isLegacyOnly}
					>
						<FormSection
							title="Edit User's Username/Email"
							icon="user"
							hoverText="Change user's username/email on file."
						>
							<EditUsername
								dispatchAlert={dispatchAlert}
								dispatchToState={dispatchToState}
								currentUser={currentUser}
								targetUser={user}
								tableDispatch={tableDispatch}
							/>
						</FormSection>
						<Divider />
					</ProtectedFeature>

					{/* MAKE THIS USER AN: ADMIN, FACILITY-ADMIN ETC. */}
					<ProtectedFeature
						isEnabled={enableProtectedFeatures && !isLegacyOnly}
					>
						<FormSection
							icon="user"
							title="Make this User an Admin, Facility Admin etc."
							hoverText="Assign admin status to 1 or more users"
							iconColor={red[600]}
							titleColor={red[600]}
						>
							<ChangeUserType
								dispatchAlert={dispatchAlert}
								dispatchToState={dispatchToState}
								currentUser={currentUser}
								targetUser={user}
								tableDispatch={tableDispatch}
							/>
						</FormSection>
						<Divider />
					</ProtectedFeature>

					{/* CHANGE USER TYPE */}
					<ProtectedFeature isEnabled={false}>
						<FormSection
							title="Change User Type"
							titleColor={yellow[400]}
							icon="warning"
							iconColor={yellow[400]}
							hoverText="Change the user type to add further controls."
							isDisabled={isLegacyOnly}
						>
							<UserTypes vals={values} handleUserTypes={handleCheckbox} />

							<FormActions
								saveTxt="Change User Type"
								cancelTxt="Cancel"
								saveHandler={changeUserType}
								cancelHandler={cancelUserType}
							/>
						</FormSection>
						<Divider />
					</ProtectedFeature>

					{/* PASSWORD RESET - ENABLED FOR ALL ADMINS */}
					<ProtectedFeature
						isEnabled={enableProtectedFeatures && !isLegacyOnly}
					>
						<>
							<FormSection
								title="Reset User's Password"
								icon="lock"
								hoverText="Reset this user's password for them."
							>
								<ResetUsersPassword
									isLockedOut={values?.isLockedOut}
									currentUser={currentUser}
									targetUser={user}
									dispatchAlert={dispatchAlert}
								/>
							</FormSection>
							<Divider />
						</>
					</ProtectedFeature>

					{/* SUSPEND/DISABLE USER */}
					<ProtectedFeature
						isEnabled={enableProtectedFeatures && !isLegacyOnly}
					>
						<FormSection
							title="Suspend  User (temporary)"
							icon="error"
							hoverText="User suspension will temporarily disable access for a user."
							iconColor={red[600]}
							titleColor={red[600]}
						>
							<p className={styles.UserDetails_suspendUser_details}>
								Suspending a user will temporarily disable their access to all
								ALA applications, but will not delete any user-related data.
								They may be re-enabled/un-suspended at any time.
							</p>
							<UserSuspensionSwitch
								suspendUserVal={values?.suspendUser}
								handleSuspendUser={handleSuspension}
							/>
						</FormSection>
						<Divider />
					</ProtectedFeature>

					{/* DELETE USER - ONLY ENABLED FOR WHITELISTED USERS */}
					<ProtectedFeature
						isEnabled={enableProtectedFeatures && !isLegacyOnly}
					>
						<FormSection
							title="Delete  User"
							icon="error"
							hoverText="Deleting a user is permanent and is not reversible."
							iconColor={red[600]}
							titleColor={red[600]}
						>
							<p className={styles.UserDetails_desc}>
								Deleting a user will permanently remove all user-related data
								from our system. This is not reversible.
							</p>
							<ButtonSM
								handleClick={handleDeleteUser}
								customStyles={customCSS.deleteBtn}
							>
								<svg className={styles.UserDetails_deleteIcon}>
									<use xlinkHref={`${sprite}#icon-delete`}></use>
								</svg>
								<span>Delete User</span>
							</ButtonSM>
						</FormSection>
					</ProtectedFeature>
				</main>
			</div>

			{showDeleteUserConfirmation && (
				<Dialog
					icon="ERROR"
					title={`Delete User`}
					heading={`Are you sure you want to delete this user?`}
					text={`User deletions are NOT reversible. Historical data will persist.`}
					closeModal={() => setShowDeleteUserConfirmation(false)}
				>
					<div className={styles.UserDetails_confirmDeleteDialog}>
						<ButtonSM
							handleClick={cancelDeleteUser}
							customStyles={customCSS.cancelDeleteBtn}
						>
							<span>Cancel</span>
						</ButtonSM>
						<ButtonSM
							handleClick={confirmDeleteUser}
							customStyles={customCSS.confirmDeleteBtn}
						>
							<span>Delete User</span>
						</ButtonSM>
					</div>
				</Dialog>
			)}
		</>
	);
};

export default UserDetails;

UserDetails.defaultProps = {
	user: {},
	currentUser: {},
	allFacilities: [],
	appsList: [],
};

UserDetails.propTypes = {
	user: PropTypes.object,
	currentUser: PropTypes.object,
	hasTrackerAccess: PropTypes.bool,
	hasLegacyAccess: PropTypes.bool,
	isDeactivated: PropTypes.bool,
	appsList: PropTypes.array,
	allFacilities: PropTypes.array,
	dispatchAlert: PropTypes.func,
};
