import React from "react";
import styles from "../../css/user/LockoutIcon.module.scss";
import sprite from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";

const LockoutIcon = () => {
	return (
		<div className={styles.LockoutIcon}>
			<svg className={styles.LockoutIcon_icon}>
				<use xlinkHref={`${sprite}#icon-lock11`}></use>
			</svg>
		</div>
	);
};

export default LockoutIcon;

LockoutIcon.defaultProps = {};

LockoutIcon.propTypes = {};
