import React from "react";
import styles from "../../css/admin/UserDropdownOption.module.scss";
import sprite from "../../assets/icons/carets-arrows.svg";
import { PropTypes } from "prop-types";
import { getUserEmail, getUserName } from "../../helpers/utils_user";
import { purple } from "../../helpers/utils_styles";
import UserBadge from "../user/UserBadge";
// components

// styles ONLY the top-leve user's name (eg. first/last)
const getNameCSS = (isSelected, isDisabled) => {
	if (isDisabled) {
		return {
			opacity: ".6",
			cursor: "not-allowed",
		};
	} else if (isSelected) {
		return {
			color: purple[600],
		};
	} else {
		return {};
	}
};

const UserDropdownOption = ({
	isDisabled = false,
	isSelected = false,
	option = {},
	handleSelect,
}) => {
	const custom = {
		opacity: isDisabled ? ".4" : "1",
		cursor: isDisabled ? "not-allowed" : "pointer",
	};
	return (
		<li
			className={
				isSelected
					? styles.UserDropdownOption_isSelected
					: styles.UserDropdownOption
			}
			onClick={isDisabled ? null : () => handleSelect(option)}
			style={custom}
		>
			<div className={styles.UserDropdownOption_badge}>
				<UserBadge size="XSM" user={option} />
			</div>
			<div className={styles.UserDropdownOption_text}>
				<div
					className={styles.UserDropdownOption_text_name}
					style={getNameCSS(isSelected, isDisabled)}
				>
					{getUserName(option, 45)}
				</div>
				<div className={styles.UserDropdownOption_text_email}>
					{getUserEmail(option)}
				</div>
			</div>
			{isSelected && (
				<svg className={styles.UserDropdownOption_icon}>
					<use xlinkHref={`${sprite}#icon-checkmark-outline`}></use>
				</svg>
			)}
		</li>
	);
};

export default UserDropdownOption;

// single dropdown menu option
UserDropdownOption.defaultProps = {
	isSelected: false,
};
UserDropdownOption.propTypes = {
	isSelected: PropTypes.bool,
	option: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.object,
		PropTypes.any,
	]),
	handleSelect: PropTypes.func.isRequired,
};
