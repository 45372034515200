import React, { useState } from "react";
import styles from "../../css/user/UserSecurityQuestion.module.scss";
import sprite from "../../assets/icons/multistep.svg";
import { PropTypes } from "prop-types";
import CustomDropdown from "../shared/CustomDropdown";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";

// REQUIREMENTS:
// - Two initial states:
//    - Existing question(s)
//    - Non-existing question(s)
// - Add action buttons:
// 	- Edit (existing question)
// - Cancel Editing

const removeExistingQuestion = (vals = {}, questions = []) => {
	const { securityQuestion1, securityQuestion2 } = vals;
	return questions.filter((x) => {
		if (x !== securityQuestion1 && x !== securityQuestion2) {
			return x;
		} else {
			return null;
		}
	});
};

const customCSS = {
	dropdown: {
		width: "100%",
		minWidth: "100%",
	},
};

const EditButton = ({ initEditMode }) => {
	return (
		<div className={styles.EditButton} onClick={initEditMode}>
			<svg className={styles.EditButton_icon}>
				<use xlinkHref={`${sprite}#icon-createmode_editedit1`}></use>
			</svg>
		</div>
	);
};
const CancelButton = ({ cancelEditMode }) => {
	return (
		<div className={styles.CancelButton} onClick={cancelEditMode}>
			<svg className={styles.CancelButton_icon}>
				<use xlinkHref={`${sprite}#icon-clearclose1`}></use>
			</svg>
		</div>
	);
};

const isQuestionLocked = (name, vals = {}, isEditing, lockQuestion) => {
	if (!lockQuestion) return false;
	if (isEditing) return false;
	const question = vals[name];
	if (isEmptyVal(question)) return false;
	if (!isEmptyVal(question)) return true;
	return true;
};

const UserSecurityQuestion = ({
	vals = {},
	label = `Select a Security Question:`,
	name,
	id,
	placeholder,
	handleQuestion,
	getEditState,
	securityQuestions = [],
	hideEditButtons = false,
	lockQuestion = false,
	initialEditState = false,
}) => {
	const [isEditing, setIsEditing] = useState(initialEditState);

	// syncs 'isEditing' to parent
	const initEditMode = () => {
		setIsEditing(true);
		getEditState(true);
	};
	// syncs 'isEditing' to parent
	const cancelEditMode = () => {
		setIsEditing(false);
		getEditState(false);
	};

	if (isEmptyArray(securityQuestions)) {
		return null;
	}
	return (
		<div className={styles.UserSecurityQuestion}>
			{!hideEditButtons && (
				<div className={styles.UserSecurityQuestion_edit}>
					{!isEditing && <EditButton initEditMode={initEditMode} />}
					{isEditing && <CancelButton cancelEditMode={cancelEditMode} />}
				</div>
			)}
			<fieldset
				className={styles.UserSecurityQuestion_fieldset}
				disabled={isQuestionLocked(name, vals, isEditing, lockQuestion)}
			>
				<CustomDropdown
					label={label}
					name={name}
					id={id}
					placeholder={placeholder}
					selection={vals[name]}
					setSelection={handleQuestion}
					options={securityQuestions}
					customStyles={customCSS.dropdown}
					// isDisabled={!isEditing && !isEmptyVal(vals[name]) && lockQuestion}
					isDisabled={isQuestionLocked(name, vals, isEditing, lockQuestion)}
					inputMode="none"
				/>
			</fieldset>
		</div>
	);
};

export default UserSecurityQuestion;

UserSecurityQuestion.defaultProps = {};

UserSecurityQuestion.propTypes = {
	vals: PropTypes.object,
	label: PropTypes.string,
	name: PropTypes.string,
	id: PropTypes.string,
	handleQuestion: PropTypes.func,
	securityQuestions: PropTypes.array,
	lockQuestion: PropTypes.bool,
};
