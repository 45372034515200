import React from "react";
import styles from "../../css/user/UserPreferences.module.scss";
import { PropTypes } from "prop-types";

const UserPreferences = () => {
	return (
		<div className={styles.UserPreferences}>
			{/*  */}
			{/*  */}
			{/*  */}
			{/*  */}
		</div>
	);
};

export default UserPreferences;

UserPreferences.defaultProps = {};

UserPreferences.propTypes = {};
