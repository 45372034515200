import React, { useState } from "react";
import styles from "../../css/loc/LOCOptions.module.scss";
import sprite from "../../assets/icons/alerts.svg";
import { PropTypes } from "prop-types";
import { red } from "../../helpers/utils_styles";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
// components
import CustomCheckbox from "../shared/CustomCheckbox";
import ButtonSM from "../shared/ButtonSM";
import { isParentFacility } from "../../helpers/utils_facility";

// REQUIREMENTS:
// - Check if facility is a 'parent'
// 		- IF PARENT, then show flags as editable.
// 		- IF NOT PARENT, then show flags, but prevent edits.

const customCSS = {
	cancel: {
		padding: "1rem 1.8rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.6rem",
		marginRight: "1rem",
	},
	save: {
		padding: "1rem 1.8rem",
		backgroundColor: red[600],
		color: "#ffffff",
		fontSize: "1.6rem",
	},
};

const getSelectedVals = (vals = {}) => {
	const keys = Object.keys(vals);
	const selected = keys.filter((key) => vals[key] && key !== "Editable");
	return selected;
};

// checks if facility is a child community
const isChildFacility = (currentFacility) => {
	const { parentID } = currentFacility;

	return !isEmptyVal(parentID);
};

// disables 'LOCAreAdminLocked':
// - IF NOT 'Editable'
// - IF 'ApplyLOCToChildren' is true
// - IF 'DoNotAllowChildLOCOverride' is true
// - IF 'currentFacility' is a child community
const disableAdminLock = (vals, currentFacility) => {
	const { Editable, ApplyLOCToChildren, DoNotAllowChildLOCOverride } = vals;
	const isChild = isChildFacility(currentFacility);

	if (!isChild) return true;
	return false;
};

const desc = {
	ApplyLOCToChildren: {
		name: "Apply to all child communities",
		desc: "This setting will apply the current levels of care to all child communities under this parent.",
	},
	DoNotAllowChildLOCOverride: {
		name: "Do not allow child community override",
		desc: "This setting prevents child communities from making custom changes to their care levels.",
	},

	LOCAreAdminLocked: {
		name: "LOC Are Admin Locked",
		desc: "This setting will only allow Admins to edit/update care levels. Other user types may still view the care levels, but cannot change them.",
	},
};

const LOCNotice = ({
	settings = {},
	currentFacility = {},
	currentUser = {},
}) => {
	const { ApplyLOCToChildren, DoNotAllowChildLOCOverride, LOCAreAdminLocked } =
		settings;
	const isChild = isChildFacility(currentFacility);
	const { isFacilityAdmin } = currentUser;

	const renderMsg = () => {
		switch (true) {
			case ApplyLOCToChildren && DoNotAllowChildLOCOverride && isChild: {
				return (
					<div className={styles.LOCNotice_msg}>
						You cannot change these values because they're being controlled at
						the parent level.
					</div>
				);
			}
			case DoNotAllowChildLOCOverride && isChild && isFacilityAdmin: {
				return (
					<div className={styles.LOCNotice_msg}>
						To make levels of care changes, please contact the corporate admin.
					</div>
				);
			}
			default:
				return;
		}
	};

	return <div className={styles.LOCNotice}>{renderMsg()}</div>;
};

const Warning = () => {
	return (
		<div className={styles.Warning}>
			<div className={styles.Warning_main}>
				<div className={styles.Warning_main_iconWrapper}>
					<svg className={styles.Warning_main_iconWrapper_icon}>
						<use xlinkHref={`${sprite}#icon-warning`}></use>
					</svg>
				</div>
				<span className={styles.Warning_main_text}>
					Changes made to the above settings will be applied immediately and
					effect the current care levels.
				</span>
			</div>
			<div className={styles.Warning_alt}>
				NOTE: We recommend waiting until all care level changes have been saved,
				before making changes here.
			</div>
		</div>
	);
};

const Desc = ({ desc }) => {
	return (
		<div className={styles.Desc}>
			<div className={styles.Desc_name}>{desc?.name}:</div>
			<div className={styles.Desc_desc}>{desc?.desc}</div>
		</div>
	);
};

const LOCOptionsSummary = ({ selected = [] }) => {
	return (
		<div className={styles.LOCOptionsSummary}>
			<div className={styles.LOCOptionsSummary_label}>Descriptions:</div>
			<div className={styles.LOCOptionsSummary_desc}>
				{!isEmptyArray(selected) &&
					selected.map((key, idx) => (
						<Desc key={`DESC-${idx}`} desc={desc?.[key]} />
					))}
			</div>
		</div>
	);
};

const LOCOptions = ({
	vals = {},
	currentUser = {},
	currentFacility = {},
	parentsMap = {},
	handleCheckbox,
	saveSettings,
	cancelSettings,
	lockAllChildren,
	unlockAllChildren,
}) => {
	return (
		<div className={styles.LOCOptions}>
			<div className={styles.LOCOptions_top}>
				<span>Additional Care Level Settings</span>
			</div>
			<div className={styles.LOCOptions_options}>
				<div className={styles.LOCOptions_options_checkbox}>
					<CustomCheckbox
						name="LOCAreAdminLocked"
						id="LOCAreAdminLocked"
						label="LOC Are Admin Locked"
						val={vals.LOCAreAdminLocked}
						handleCheckbox={handleCheckbox}
						isDisabled={disableAdminLock(vals, currentFacility)}
					/>
				</div>
			</div>
			{/* {isParentFacility(currentFacility, parentsMap) && (
				<div className={styles.LOCOptions_lockOptions}>
					<div className={styles.LOCOptions_lockOptions_buttons}>
						<ButtonSM
							handleClick={unlockAllChildren}
							customStyles={customCSS.unlock}
						>
							Unlock All Children
						</ButtonSM>
						<ButtonSM
							handleClick={lockAllChildren}
							customStyles={customCSS.lock}
						>
							Lock All Children
						</ButtonSM>
					</div>
				</div>
			)} */}
			<Warning />
			<div className={styles.LOCOptions_actions}>
				<ButtonSM customStyles={customCSS.cancel} handleClick={cancelSettings}>
					Cancel
				</ButtonSM>
				<ButtonSM
					isDisabled={!vals?.Editable}
					customStyles={customCSS.save}
					handleClick={saveSettings}
				>
					Save Settings
				</ButtonSM>
			</div>
			<div className={styles.LOCOptions_options}>
				<LOCOptionsSummary selected={getSelectedVals(vals)} />
			</div>
		</div>
	);
};

export default LOCOptions;

LOCOptions.defaultProps = {};

LOCOptions.propTypes = {};
