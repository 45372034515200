import React from "react";
import styles from "../../css/user/EditUserProfile.module.scss";
import { PropTypes } from "prop-types";
// components
import TextInput from "../shared/TextInput";
import PasswordInput from "../shared/PasswordInput";
import PhoneInput from "../shared/PhoneInput";
import AppAccessSummary from "../summary/AppAccessSummary";
import UserFacilityAccess from "./UserFacilityAccess";
import SetResetMethodPreference from "./SetResetMethodPreference";
import { featureFlags } from "../../helpers/utils_permissions";
import JobTitleHandler from "./JobTitleHandler";
import LimitedTextInput from "../shared/LimitedTextInput";

// REQUIREMENTS:
// - Implement fix for "UserTypes" & "UserTitles"
// 		- Create code to auto-select "User-Type" based off job title (eg 'user-title')

const customCSS = {
	firstName: {
		backgroundColor: "#ffffff",
	},
	lastName: {
		backgroundColor: "#ffffff",
	},
	userType: {
		width: "100%",
		// backgroundColor: "#ffffff",
	},
	jobTitle: {
		width: "100%",
		backgroundColor: "#ffffff",
	},
	jobTitleWidth: {
		width: "100%",
	},
	phoneWidth: {
		width: "40%",
		minWidth: "25rem",
	},
	phoneNumber: {
		width: "40%",
		minWidth: "25rem",
		backgroundColor: "#ffffff",
	},
	username: {
		width: "100%",
		backgroundColor: "#ffffff",
	},
	password: {
		backgroundColor: "#ffffff",
		opacity: ".4",
	},
};

const EditUserProfile = ({
	vals,
	currentUser,
	currentFacility = {},
	handlePhone,
	handleChange,
	handleUserSettings,
	handleCustomJobTitle,
	dispatchAlert,
	facilityAccessList = [],
	allTitles = [],
	allTypes = [],
}) => {
	return (
		<div className={styles.EditUserProfile}>
			<section className={styles.EditUserProfile_row}>
				{/* FIRST NAME */}
				<div className={styles.EditUserProfile_row_tile}>
					<TextInput
						label="First Name"
						name="firstName"
						id="firstName"
						val={vals.firstName}
						handleChange={handleChange}
						customStyles={customCSS.firstName}
						readOnly={true}
					/>
				</div>
				{/* LAST NAME */}
				<div className={styles.EditUserProfile_row_tile}>
					<TextInput
						label="Last Name"
						name="lastName"
						id="lastName"
						val={vals.lastName}
						handleChange={handleChange}
						customStyles={customCSS.lastName}
						readOnly={true}
					/>
				</div>
			</section>

			{/* JOB/USER TITLE (eg 'Accountant', 'Temp Staff', 'Director of XXXX' etc) */}
			<section className={styles.EditUserProfile_row}>
				<JobTitleHandler
					label="Job Title (search or enter custom title)"
					currentUser={currentUser}
					vals={vals}
					userTitles={allTitles}
					handleJobTitle={handleUserSettings}
					handleChange={handleChange}
					handleCustomJobTitle={handleCustomJobTitle}
					customCSS={customCSS?.jobTitle}
					readOnly={currentUser?.isReadOnly}
				/>
			</section>
			{/* PHONE NUMBER */}
			<section className={styles.EditUserProfile_row}>
				<div className={styles.EditUserProfile_row_phone}>
					<PhoneInput
						key={`PHONE-NUMBER`}
						label="Phone Number"
						name="phoneNumber"
						id="phoneNumber"
						val={vals.phoneNumber}
						handlePhone={handlePhone}
						customWidth={customCSS.phoneWidth}
						customStyles={customCSS.phoneNumber}
						// autoComplete="new-phone-number"
						autoComplete="tel"
						readOnly={currentUser?.isReadOnly}
						isDisabled={false}
						placeholder="XXX-XXX-XXXX"
					/>
				</div>
				<div className={styles.EditUserProfile_row_phoneExt}>
					<LimitedTextInput
						key={`PHONE-EXT`}
						label="Extension (ext.)"
						name="phoneExt"
						id="phoneExt"
						val={vals.phoneExt}
						handleChange={handleChange}
						customWidth={customCSS.phoneWidth}
						customStyles={customCSS.phoneNumber}
						readOnly={currentUser?.isReadOnly}
						isDisabled={false}
						placeholder="Ext. XXXX"
					/>
				</div>
			</section>
			{/* USERNAME */}
			<section className={styles.EditUserProfile_row}>
				<TextInput
					label="Username"
					name="username"
					id="username"
					val={vals.username}
					handleChange={handleChange}
					customWidth={customCSS.jobTitleWidth}
					customStyles={customCSS.username}
					autoComplete="new-username"
					readOnly={true}
					isDisabled={true}
				/>
			</section>
			{/* PASSWORD (HIDDEN/DISABLED) */}
			<section className={styles.EditUserProfile_row}>
				<PasswordInput
					label="Password"
					name="currentPassword"
					id="currentPassword"
					val={vals.currentPassword}
					handleChange={handleChange}
					customStyles={customCSS.password}
					autoComplete="new-password"
					readOnly={true}
					isDisabled={true}
				/>
			</section>
			{/* APP ACCESS */}
			<section className={styles.EditUserProfile_section}>
				<div className={styles.EditUserProfile_section_title}>
					Application Access
				</div>
				<div className={styles.EditUserProfile_section_main}>
					<AppAccessSummary
						hasTrackerAccess={vals.hasTrackerAccess}
						hasLegacyAccess={vals.hasLegacyAccess}
						hasPortalAccess={vals.hasPortalAccess}
					/>
				</div>
			</section>
			{/* RESET METHODS & PREFERENCE */}
			{featureFlags?.user?.enableResetPreference && (
				<section className={styles.EditUserProfile_section}>
					<div className={styles.EditUserProfile_section_title}>
						Password Reset Methods
					</div>
					<div className={styles.EditUserProfile_section_main}>
						<SetResetMethodPreference
							dispatchAlert={dispatchAlert}
							currentUser={currentUser}
						/>
					</div>
				</section>
			)}
			{/* FACILITY ACCESS */}
			<section className={styles.EditUserProfile_section}>
				<div className={styles.EditUserProfile_section_title}>
					Facility Access
				</div>
				<div className={styles.EditUserProfile_section_main}>
					<UserFacilityAccess
						currentUser={currentUser}
						currentFacility={currentFacility}
						facilityAccessList={facilityAccessList}
					/>
				</div>
			</section>
		</div>
	);
};

export default EditUserProfile;

EditUserProfile.defaultProps = {};

EditUserProfile.propTypes = {
	currentUser: PropTypes.object,
};
