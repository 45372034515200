import React from "react";
import styles from "../../css/hints/Hint.module.scss";
import { PropTypes } from "prop-types";
import lightbulb from "../../assets/icons/lightbulb.svg";

const Hint = ({ hint }) => {
	return (
		<div className={styles.Hint}>
			<div className={styles.Hint_wrapper}>
				<img
					src={lightbulb}
					alt="Lighbulb Hint"
					className={styles.Hint_wrapper_icon}
				/>
			</div>
			<div className={styles.Hint_hint}>{hint}</div>
		</div>
	);
};

export default Hint;

Hint.defaultProps = {};

Hint.propTypes = {
	hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
