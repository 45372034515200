import React from "react";
import styles from "../../css/errors/AppError.module.scss";
import { PropTypes } from "prop-types";

const AppError = () => {
	return (
		<div className={styles.AppError}>
			{/*  */}
			{/*  */}
			{/*  */}
		</div>
	);
};

export default AppError;

AppError.defaultProps = {};

AppError.propTypes = {};
