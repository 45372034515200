import React, { useState, useEffect } from "react";
import styles from "../../css/residents/ResidentDetails.module.scss";
import { PropTypes } from "prop-types";
import {
	getResidentAdmissionDate,
	getResidentDetails,
	getResidentName,
	getResidentStatus,
	getResidentUnitType,
	transferResidentToFacility,
} from "../../helpers/utils_residents";
import { isEmptyObj, isEmptyVal } from "../../helpers/utils_types";
import { useForm } from "../../utils/useForm";
import { format, isToday } from "date-fns";
import { matchFacilityByName } from "../../helpers/utils_facility";
import { red } from "../../helpers/utils_styles";
// components
import CopyText from "../forms/CopyText";
import Divider from "../forms/Divider";
import FormSection from "../forms/FormSection";
import TransferResident from "./TransferResident";
import ResidentStatus from "./ResidentStatus";
import UnitTypeBadge from "./UnitTypeBadge";
import SuccessMsg from "../user/SuccessMsg";

// REQUIREMENTS:
// - Archive logic for residents
// - LOA enabling/disabling leave of absence

const SectionDesc = ({ children }) => {
	return <div className={styles.SectionDesc}>{children}</div>;
};

const ArchiveButton = ({ handleArchive, isDisabled = false }) => {
	return (
		<button
			type="button"
			disabled={isDisabled}
			onClick={handleArchive}
			className={styles.ArchiveButton}
		>
			Archive
		</button>
	);
};
const UnarchiveButton = ({ handleUnarchive, isDisabled = false }) => {
	return (
		<button
			type="button"
			disabled={isDisabled}
			onClick={handleUnarchive}
			className={styles.UnarchiveButton}
		>
			Un-Archive
		</button>
	);
};

const ResidentDetails = ({
	closeModal,
	resident,
	currentUser,
	currentFacility,
	dispatchAlert,
}) => {
	const [residentDetails, setResidentDetails] = useState({});
	const { formState, setFormState, handleChange, handleReset } = useForm({
		residentID: "",
		residentName: getResidentName(resident),
		targetFacilityID: "",
		targetFacilityName: "",
		parentFacilityName: "",
		targetDate: format(new Date(), "MM/DD/YYYY"),
	});
	const { values } = formState;
	const [isArchived, setIsArchived] = useState(resident?.Archive ?? false);
	// target facility & it's parent, if applicable
	const [target, setTarget] = useState({
		child: {},
		parent: {},
	});

	const handleSelection = (name, val) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: val,
			},
		});
	};

	const handleFacilitySelect = (name, val) => {
		if (isEmptyVal(val)) {
			setFormState({
				...formState,
				values: {
					...values,
					[name]: val,
					targetFacilityID: "",
				},
			});
		} else {
			const record = matchFacilityByName(val, currentUser.facilities);
			setFormState({
				...formState,
				values: {
					...values,
					[name]: val,
					targetFacilityID: record?.facilityID,
				},
			});
		}
	};

	const archiveResident = async () => {
		//
		//
	};
	const unarchiveResident = async () => {
		//
		//
	};

	// fetch 'RESIDENT' & 'LOA' record(s)
	const getDetails = async () => {
		const { token } = currentUser;
		const { residentID } = resident;
		const details = await getResidentDetails(token, residentID);

		if (!isEmptyObj(details)) {
			setIsArchived(details?.Archive ?? false);
			return setResidentDetails(details);
		} else {
			setIsArchived(details?.Archive ?? false);
			return setResidentDetails(details);
		}
	};

	// inits 'resident-transfer' to new facility
	const moveResidentToFacility = async () => {
		const { token } = currentUser;
		const { residentID, targetFacilityID, targetDate } = values;
		const date =
			isToday(targetDate) || isEmptyVal(targetDate)
				? null
				: new Date(targetDate).toISOString();

		const wasTransferred = await transferResidentToFacility(
			token,
			residentID,
			targetFacilityID,
			date
		);

		if (wasTransferred) {
			return dispatchAlert("SUCCESS", {
				heading: `Success!`,
				subheading: `Resident was transferred!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Error!`,
				subheading: `There was an error. Try again later, please.`,
			});
		}
	};

	// fetches onMount
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		getDetails();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className={styles.ResidentDetails}>
				<header className={styles.ResidentDetails_header}>
					<h2 className={styles.ResidentDetails_header_title}>
						Resident: <b>{getResidentName(resident)}</b>
					</h2>
					<div className={styles.ResidentDetails_header_id}>
						<span>Resident ID:</span>
						<CopyText text={resident?.residentID} />
					</div>

					<div className={styles.ResidentDetails_header_dateCreated}>
						Admission Date: <b>{getResidentAdmissionDate(residentDetails)}</b>
					</div>
					<div className={styles.ResidentDetails_header_status}>
						<span>Status:</span>
						<ResidentStatus resident={resident} />
					</div>
					<div className={styles.ResidentDetails_header_unitType}>
						{/* UNIT TYPE */}
						<span className={styles.ResidentDetails_header_unitType_label}>
							Unit Type:
						</span>
						<span>
							<UnitTypeBadge unitType={getResidentUnitType(resident)} />
						</span>
					</div>
				</header>
				<div className={styles.ResidentDetails_form}>
					<FormSection
						icon="building"
						title="Transfer Resident"
						hoverText="Transfer resident to different community"
					>
						<SectionDesc>
							Transfer a resident & all their data from community to another
							community.
						</SectionDesc>
						<div className={styles.ResidentDetails_form_label}>
							<TransferResident
								vals={values}
								currentUser={currentUser}
								currentResident={resident}
								currentFacility={currentFacility}
								handleSelection={handleSelection}
								handleFacilitySelect={handleFacilitySelect}
								moveResidentToFacility={moveResidentToFacility}
							/>
						</div>
					</FormSection>
					<Divider />
					<FormSection
						icon="flag"
						title="Archive Resident"
						hoverText="Archive resident's active status"
						iconColor={red[600]}
						titleColor={red[600]}
					>
						<SectionDesc>
							Archiving a resident is meant for residents no longer active with
							your community. No data will be lost or deleted. You may
							un-archive this resident at any time.
						</SectionDesc>
						{isArchived && (
							<UnarchiveButton
								isDisabled={!isArchived}
								handleUnarchive={unarchiveResident}
							/>
						)}
						{!isArchived && (
							<ArchiveButton
								isDisabled={isArchived}
								handleArchive={archiveResident}
							/>
						)}
					</FormSection>
				</div>
			</div>
		</>
	);
};

export default ResidentDetails;

ResidentDetails.defaultProps = {};

ResidentDetails.propTypes = {};
