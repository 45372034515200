import { currentEnv } from "./utils_env";
import { otp, resetMethods } from "./utils_endpoints";
import { generateTempPassword } from "./utils_processing";
import { isEmptyVal } from "./utils_types";

////////////////////////////////////////////////////////////////////////////////////
////////////////////////////// OTP REQUEST HANDLERS //////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

/**
 * Registers a OTP for a given user that is valid for a custom amount of time OR 15mins, as a default setting.
 * @param {String} token - Auth token
 * @param {Object} userVals - An object of user-security related values. (ie 'userLoginID', 'password' & 'expires')
 * @property {String} userVals.userLoginID - A user's UserLoginID
 * @property {String} userVals.password - Password
 * @property {Date|Null} userVals.expires - Expiry date for temp password OTP.
 * @returns {Boolean} - Returns whether registering of OTP was successful.
 */
const registerOtp = async (token, userVals = {}) => {
	// if 'expires' is null, then defaults to 15mins expiry
	const { userLoginID, password, expires = null } = userVals;

	let url = currentEnv.base + otp.registerOtp;
	url += "?" + new URLSearchParams({ userLoginId: userLoginID });
	url += "&" + new URLSearchParams({ password: password });
	url += "&" + new URLSearchParams({ expires: expires });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Generates a custom OTP and submits the 'register' request & returns the state.
 * @param {String} token - Auth token
 * @param {Object} userVals - User vals for otp request.
 * @property {String} userVals.userLoginID - A user's UserLoginID
 * @property {String} userVals.password - Password
 * @returns {Object} - Returns object contains state of 'register' request & tempOTP value
 */
const createAndRegisterOtp = async (token, userVals = {}) => {
	const tempOTP = generateTempPassword(6);
	const wasRegistered = await registerOtp(token, {
		...userVals,
		password: tempOTP,
	});

	if (wasRegistered) {
		return {
			wasRegistered,
			tempOTP,
		};
	} else {
		return {
			wasRegistered: `REQUEST TO REGISTER FAILED!`,
			tempOTP: null,
		};
	}
};

////////////////////////////////////////////////////////////////////////////////////
////////////////////////////// OTP VALIDATOR HANDLERS //////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

const mergeChars = (vals) => {
	const keys = Object.keys(vals);
	let merged;
	keys.reduce((joined, key) => {
		const val = vals[key];
		if (!val) return;
		merged = joined + val;
		return merged;
	}, "");

	//
	return merged;
};

const isCorrectOTP = (vals, otp) => {
	const userEntry = mergeChars(vals);
	return userEntry === otp;
};

const isCorrectChar = (charIdx, char, otp) => {
	if (isEmptyVal(char)) return false;
	// get array of chars
	const letters = otp.split("");
	const targetChar = letters[charIdx];

	if (char === targetChar) {
		return true;
	} else {
		return false;
	}
};

////////////////////////////////////////////////////////////////////////////////////
///////////////////////// PASSWORD RESET REQUEST HANDLERS /////////////////////////
////////////////////////////////////////////////////////////////////////////////////

/**
 * Fetches 'ResetMethodType's list of records.
 * @param {String} token - Auth token
 * @param {Object} params - An object of (optional) custom params.
 * @returns {Object[]} - Returns an array of 'ResetMethodType' objects
 */
const getResetMethods = async (token, params = {}) => {
	let url = currentEnv.base + resetMethods.get2;
	url += "?" + new URLSearchParams({ ...params });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Updates a single 'ResetMethodType's record.
 * @param {String} token - Auth token
 * @param {Object} updatedMethod - An object w/ updated values for a given record.
 * @returns {Boolean} - Returns whether update was successful or not.
 */
const updateResetMethod = async (token, updatedMethod = {}) => {
	let url = currentEnv.base + resetMethods.update;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(updatedMethod),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Updates a user's 'PreferredResetMethodID' via their 'UserLogin' record
 * @param {String} token - Auth token
 * @param {Object} resetRules - An object of 'UserLogin' values to be updated.
 * @returns {Boolean}
 */
const updateUsersPreferredMethod = async (token, userID, resetRules = {}) => {
	if (isEmptyVal(resetRules?.PreferredResetMethodID)) return null;

	// return;

	let url = currentEnv.base + resetMethods.byUser.methods;
	url += "?" + new URLSearchParams({ userLoginId: userID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(resetRules),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Finds matching 'ResetTypeID', then populates a custom model for updating.
 * @param {Object[]} allResetTypes - An array of 'ResetMethodTypes'
 * @param {Object} vals - user-selected values
 * @returns {Object} - Returns custom populated preferred method model
 */
const createPreferredModel = (allResetTypes = [], vals = {}) => {
	const { preferredMethod, userID } = vals;
	const preferredTypeRecord = matchResetMethodByName(
		preferredMethod,
		allResetTypes
	);
	const preferredModel = updatePreferredMethodModel({
		userID: userID,
		preferredTypeID: preferredTypeRecord?.ResetMethodTypeID,
	});

	return preferredModel;
};

// applies user's preferred reset method to a custom/partial 'UserLogin' model
const updatePreferredMethodModel = (vals) => {
	const preferredModel = {
		// UserLoginID: vals?.userID,
		PreferredResetMethodID: vals?.preferredTypeID,
	};

	return preferredModel;
};

////////////////////////////////////////////////////////////////////////////////////
////////////////////////////// PASSWORD RESET METHODS //////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

const matchResetMethodByID = (id, methods = []) => {
	const match = methods.filter((x) => x.ResetMethodTypeID === id);

	return match?.[0];
};
const matchResetMethodByName = (name, methods = []) => {
	const match = methods.filter((x) => x.Name === name);

	return match?.[0];
};

////////////////////////////////////////////////////////////////////////////////////
////////////////////////////// RESET METHODS RECORDS //////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

const resetMethodTypes = [
	{
		ResetMethodTypeID: 1,
		Name: "Admin",
		Description: "Admin reset method type",
		IsActive: true,
		CreatedDate: "2021-10-27T19:14:28.073Z",
		CreatedBy: null,
		CreatedLoginBy: "AdvantageAdmin",
		CreatedStation: "DEVCHARITY",
		ModifiedDate: "2021-10-27T19:14:28.073Z",
		ModifiedBy: null,
		ModifiedLoginBy: "AdvantageAdmin",
		ModifiedStation: "DEVCHARITY",
	},
	{
		ResetMethodTypeID: 2,
		Name: "Email",
		Description: "Email reset method type",
		IsActive: true,
		CreatedDate: "2021-10-27T19:14:28.08Z",
		CreatedBy: null,
		CreatedLoginBy: "AdvantageAdmin",
		CreatedStation: "DEVCHARITY",
		ModifiedDate: "2021-10-27T19:14:28.08Z",
		ModifiedBy: null,
		ModifiedLoginBy: "AdvantageAdmin",
		ModifiedStation: "DEVCHARITY",
	},
	{
		ResetMethodTypeID: 3,
		Name: "Questions",
		Description: "Questions reset method type",
		IsActive: true,
		CreatedDate: "2021-10-27T19:14:28.083Z",
		CreatedBy: null,
		CreatedLoginBy: "AdvantageAdmin",
		CreatedStation: "DEVCHARITY",
		ModifiedDate: "2021-10-27T19:14:28.083Z",
		ModifiedBy: null,
		ModifiedLoginBy: "AdvantageAdmin",
		ModifiedStation: "DEVCHARITY",
	},
];

// map by 'Name' of reset method types
const createResetMethodsMap = (records = []) => {
	const methodsMap = records.reduce((map, record) => {
		const {
			ResetMethodTypeID: typeID,
			Name: name,
			Description: desc,
			IsActive: isActive,
		} = record;
		const prefix = `isPwdResetBy`;
		const label = `${prefix}${name}`; // 'isPwdResetByXXXX'

		if (!map[name]) {
			map[name] = {
				name,
				typeID,
				desc,
				isActive,
				label,
			};
			return map;
		}
		return map;
	});

	return methodsMap;
};

// returns array of 'Name's
const formatResetMethods = (records = []) => {
	const formatted = records.map(({ Name }) => Name);

	return formatted;
};

// otp validator utils
export { mergeChars, isCorrectChar, isCorrectOTP };

// reset method - matcher utils
export { matchResetMethodByID, matchResetMethodByName };

export {
	createResetMethodsMap,
	formatResetMethods,
	updatePreferredMethodModel,
	createPreferredModel,
	// reset method types
	resetMethodTypes,
};

// user reset rules request utils
export { updateUsersPreferredMethod };

// reset method - request utils
export { getResetMethods, updateResetMethod };

export { registerOtp, createAndRegisterOtp };
