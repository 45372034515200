import React from "react";
import "./App.scss";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { AppProviders } from "./state/AppProviders";
import { logVersion } from "./helpers/utils_version";
import { MAINTENANCE_MODE as maintenanceMode } from "./helpers/utils_maintenanceMode";
import ReactGA from "react-ga";
import packageJSON from "../package.json";
// components
import Main from "./components/Main";
import ProtectedRoute from "./auth/ProtectedRoute";
// pages
import LoginPage from "./pages/LoginPage";
import PortalView from "./views/app/PortalView";
import AuthTimeout from "./auth/AuthTimeout";
import PageNotFound from "./pages/PageNotFound";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import MaintenanceModePage from "./components/app/MaintenanceModePage";
import ErrorPage from "./pages/ErrorPage";
import ContactUsPage from "./pages/ContactUsPage";
import VerifyEmailPage from "./pages/VerifyEmailPage";

// REQUIREMENTS:
// - "ResetPasswordPage" route:
// 		- The route MUST be: 'https://portaltest.aladvantage.com/reset/<valid_username>'

export const history = createBrowserHistory();

history.listen((location, action) => {
	ReactGA.set({ page: location?.pathname + location?.search });
});

// logs out Version Release
logVersion(packageJSON);

function App() {
	if (maintenanceMode) {
		return (
			<Router history={history}>
				<MaintenanceModePage />
			</Router>
		);
	}
	return (
		<Router history={history}>
			<AppProviders>
				<AuthTimeout>
					<div className="App">
						<Main>
							<Switch>
								<Route exact path="/" component={LoginPage} />
								<ProtectedRoute path="/portal" component={PortalView} />
								<Route path="/reset/:id" component={ResetPasswordPage} />
								<Route path="/verify/:id" component={VerifyEmailPage} />
								<Route path="/error" component={ErrorPage} />
								<Route path="/contact" component={ContactUsPage} />
								<Route component={PageNotFound} />
							</Switch>
						</Main>
					</div>
				</AuthTimeout>
			</AppProviders>
		</Router>
	);
}

export default App;
