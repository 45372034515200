import React, { useEffect, useRef } from "react";
import styles from "../../css/widget/FeedbackForm.module.scss";
import sprite from "../../assets/icons/settings.svg";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";
import SpinnerSM from "../shared/SpinnerSM";

const FeedbackForm = ({
	name,
	id,
	val,
	placeholder,
	handleMsg,
	sendFeedback,
	hideScreenShotBtn = false,
	isSubmitting = false,
}) => {
	const inputRef = useRef();

	// auto-focus input
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (inputRef?.current) {
			inputRef?.current?.focus();
		}
		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.FeedbackForm}>
			<textarea
				ref={inputRef}
				name={name}
				id={id}
				value={val}
				cols="auto"
				rows="auto"
				onChange={handleMsg}
				className={styles.FeedbackForm_textarea}
				placeholder={placeholder}
			></textarea>
			<div className={styles.FeedbackForm_bottom}>
				{!hideScreenShotBtn && (
					<button className={styles.FeedbackForm_bottom_screenshotBtn}>
						<svg className={styles.FeedbackForm_bottom_screenshotBtn_icon}>
							<use xlinkHref={`${sprite}#icon-camera`}></use>
						</svg>
					</button>
				)}
				<button
					type="button"
					disabled={isEmptyVal(val) || isSubmitting}
					onClick={sendFeedback}
					className={styles.FeedbackForm_bottom_sendBtn}
				>
					{isSubmitting && <SpinnerSM />}
					{!isSubmitting && <span>Send feedback</span>}
				</button>
			</div>
		</div>
	);
};

export default FeedbackForm;

FeedbackForm.defaultProps = {};

FeedbackForm.propTypes = {};
