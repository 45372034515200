import React, { useState } from "react";
import styles from "../../css/loc/LOCTableHeading.module.scss";
import sprite from "../../assets/icons/tables.svg";
import { PropTypes } from "prop-types";

const sorted = {
	transform: `rotate(180deg)`,
	transition: `transform .2s ease-in-out`,
};

const LOCTableHeading = ({
	column,
	columnIdx,
	handleColumn,
	disableSorting = true,
}) => {
	const [isSorted, setIsSorted] = useState(false);

	const handleSort = () => {
		if (disableSorting) return;
		handleColumn();
		setIsSorted(!isSorted);
	};

	return (
		<div
			className={styles.LOCTableHeading}
			data-columnidx={columnIdx}
			onClick={handleSort}
		>
			<div className={styles.LOCTableHeading_column}>{column}</div>
			{!disableSorting && (
				<svg
					className={styles.LOCTableHeading_icon}
					style={isSorted ? sorted : {}}
				>
					<use xlinkHref={`${sprite}#icon-keyboard_arrow_down`}></use>
				</svg>
			)}
		</div>
	);
};

export default LOCTableHeading;

LOCTableHeading.defaultProps = {};

LOCTableHeading.propTypes = {};
