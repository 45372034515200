import React from "react";
import styles from "../../css/app/Sidebar.module.scss";
import sprite from "../../assets/icons/settings.svg";
import { PropTypes } from "prop-types";
// components
import UserBadge from "../admin/UserBadge";
// import UserBadge from "../user/UserBadge";

const Sidebar = ({
	isCollapsed = false,
	setIsCollapsed,
	currentUser = {},
	history,
	children,
}) => {
	const withState = React.Children.map(children, (child, i) => {
		return React.cloneElement(child, {
			index: i,
			history: history,
			isCollapsed: isCollapsed,
		});
	});

	return (
		<aside className={isCollapsed ? styles.CollapsedSidebar : styles.Sidebar}>
			<div className={styles.Sidebar_top}>
				<svg
					className={styles.Sidebar_top_icon}
					onClick={() => setIsCollapsed(!isCollapsed)}
				>
					<use xlinkHref={`${sprite}#icon-menu1`}></use>
				</svg>
			</div>
			<div className={styles.Sidebar_list}>{withState}</div>
			<div className={styles.Sidebar_user}>
				<UserBadge
					user={currentUser}
					size={isCollapsed ? "SM" : "MD"}
					color="purple"
				/>
			</div>
		</aside>
	);
};

export default Sidebar;

Sidebar.defaultProps = {
	currentUser: {},
};

Sidebar.propTypes = {
	currentUser: PropTypes.object,
	history: PropTypes.object,
	children: PropTypes.any,
};
