import React from "react";
import styles from "../../css/residents/ResidentsTableColumns.module.scss";
import { PropTypes } from "prop-types";
import ResidentsTableColumn from "./ResidentsTableColumn";

const ArchiveColumn = () => {
	return (
		<div className={styles.ArchiveColumn}>
			<div className={styles.ArchiveColumn_label}>Archive</div>
		</div>
	);
};

const ResidentsTableColumns = ({ handleSort, isSorted, sortType }) => {
	return (
		<div className={styles.ResidentsTableColumns}>
			<div className={styles.ResidentsTableColumns_column1}>
				<ResidentsTableColumn
					column="Name"
					isSorted={isSorted}
					sortType={sortType}
					handleSort={() => handleSort("name")}
				/>
			</div>
			<div className={styles.ResidentsTableColumns_column2}>
				<ResidentsTableColumn
					column="Age"
					isSorted={isSorted}
					sortType={sortType}
					handleSort={() => handleSort("age")}
				/>
			</div>
			<div className={styles.ResidentsTableColumns_column3}>
				<ResidentsTableColumn
					column="Unit Type"
					isSorted={isSorted}
					sortType={sortType}
					handleSort={() => handleSort("unitType")}
				/>
			</div>
			<div className={styles.ResidentsTableColumns_column4}>
				<ArchiveColumn />
			</div>
		</div>
	);
};

export default ResidentsTableColumns;

ResidentsTableColumns.defaultProps = {};

ResidentsTableColumns.propTypes = {};
