import React from "react";
import styles from "../../css/hints/HintsContainer.module.scss";
import { PropTypes } from "prop-types";

const HintsContainer = ({ children }) => {
	return (
		<div className={styles.HintsContainer}>
			<div className={styles.HintsContainer_hints}>{children}</div>
		</div>
	);
};

export default HintsContainer;

HintsContainer.defaultProps = {};

HintsContainer.propTypes = {};
