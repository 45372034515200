import React from "react";
import styles from "../../css/app/SubListItem.module.scss";
import { PropTypes } from "prop-types";
import { NavLink } from "react-router-dom";

const SubListItem = ({ item = {}, isCollapsed = false, history }) => {
	if (isCollapsed) return null;
	if (isCollapsed) {
		return (
			<li className={styles.CollapsedSubListItem}>
				<NavLink
					to={{
						pathname: item?.path,
						state: {
							from: history?.location?.pathname,
						},
					}}
				>
					{item?.name}
				</NavLink>
			</li>
		);
	}
	return (
		<li className={styles.SubListItem}>
			<NavLink
				to={{
					pathname: item?.path,
					state: {
						from: history?.location?.pathname,
					},
				}}
			>
				{item?.name}
			</NavLink>
		</li>
	);
};

export default SubListItem;

SubListItem.defaultProps = {
	item: {},
	isCollapsed: false,
};

SubListItem.propTypes = {
	isCollapsed: PropTypes.bool,
	item: PropTypes.object,
};
