import React, { useState, useEffect } from "react";
import styles from "../../css/views/ManageFacilityAccessView.module.scss";
import { PropTypes } from "prop-types";
import {
	getAllFacilitiesAccessToApps,
	getFacilityAccess,
} from "../../helpers/utils_apps";
import { isEmptyArray, isEmptyObj } from "../../helpers/utils_types";
import { sortAlphaAscByKey } from "../../helpers/utils_processing";
import { sendPageTracking } from "../../helpers/utils_tracking";
import { isDevOrTest, isLocal } from "../../helpers/utils_env";
// components
import FacilityAccessTable from "../../components/facility/FacilityAccessTable";
import FacilityBadgesLegend from "../../components/admin/FacilityBadgesLegend";

const ManageFacilityAccessView = ({
	globalState = {},
	dispatchToState,
	dispatchAlert,
}) => {
	const { currentUser } = globalState;
	// tracker access list ONLY, since Legacy access is locked
	// ...& portal access should ONLY be changed at the user level...
	// ...except facility-level changes made by Seth via Internal Portal
	const [appAccessLists, setAppAccessLists] = useState({
		trackerAccess: [],
		emarAccess: [],
		ePayAccess: [],
	});
	// ↓ OLD DEPRECATED ACCESS STATE ↓ //
	const [accessList, setAccessList] = useState([]);
	// emar for facility access (this does NOT mean a user has access, only that the facility is enabled for EMAR)

	// fetches enabled facilities list & extracts them into a an array of facilityIDs
	const fetchAccessList = async () => {
		const { token } = currentUser;
		// const list = await getFacilityAccess(token);
		const lists = await getAllFacilitiesAccessToApps(token);

		if (!isEmptyObj(lists)) {
			// return setAccessList([...lists]);
			return setAppAccessLists({
				trackerAccess: lists?.trackerAccess ?? [],
				emarAccess: lists?.emarAccess ?? [],
				ePayAccess: lists?.ePayAccess ?? [],
			});
		} else {
			// return setAccessList([]);
			return setAccessList({
				trackerAccess: [],
				emarAccess: [],
				ePayAccess: [],
			});
		}
	};

	// handles additions & removals from 'accessList'
	const updateAccessList = (accessChanges = {}) => {
		const { isEnabled, facilityID } = accessChanges;
		const existsInList = accessList.includes(facilityID);

		if (!isEnabled) {
			// not enabled/turned off - IF exists then remove, otherwise return current list
			const newList = existsInList
				? [...accessList.filter((id) => id !== facilityID)]
				: [...accessList];

			return setAccessList([...newList]);
		} else {
			// is enabled/turned on
			const newList = existsInList
				? [...accessList]
				: [...accessList, facilityID];

			return setAccessList([...newList]);
		}
	};

	// fetch 'accessList' on mount
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		fetchAccessList();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// send page tracking info
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		sendPageTracking(currentUser?.username, {
			pagename: `AdminPanel-Facility`,
			path: `/portal/access?activeTab=facility`,
		});

		return () => {
			isMounted = false;
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.ManageFacilityAccessView}>
			{isLocal() && (
				<div className={styles.ManageFacilityAccessView_legend}>
					<FacilityBadgesLegend />
				</div>
			)}
			<div className={styles.ManageFacilityAccessView_main}>
				<FacilityAccessTable
					key={`FACILITY-ACCESS-${accessList?.trackerAccess?.length}`}
					parentsMap={globalState?.parentsMap}
					accessLists={appAccessLists}
					currentUser={globalState?.currentUser}
					updateAccessList={updateAccessList}
					dispatchAlert={dispatchAlert}
					allFacilities={[
						...sortAlphaAscByKey("communityName", globalState?.facilities),
					]}
				/>
			</div>
		</div>
	);
};

export default ManageFacilityAccessView;

ManageFacilityAccessView.defaultProps = {
	globalState: {},
};

ManageFacilityAccessView.propTypes = {
	globalState: PropTypes.object.isRequired,
	dispatchToState: PropTypes.func.isRequired,
	dispatchAlert: PropTypes.func.isRequired,
};
