import React, { useReducer, useState } from "react";
import styles from "../../css/residents/ResidentsTable.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
// components
import ResidentsTableHeader from "./ResidentsTableHeader";
import ResidentsTableBody from "./ResidentsTableBody";
import ResidentsTableActions from "./ResidentsTableActions";
import ResidentsTableColumns from "./ResidentsTableColumns";
// extra components
import ResidentSearchWindow from "./ResidentSearch";
import Spinner from "../shared/Spinner";
import ModalLG from "../shared/ModalLG";

const customCSS = {
	spinner: {
		display: "block",
		margin: "1rem auto",
	},
};

const initialState = {
	residents: [],
	isSorted: false,
};

const advancedSearch = (searchVal, residents = []) => {
	searchVal = searchVal?.toLowerCase();

	return residents.filter((resident) => {
		if (
			resident?.firstName?.toLowerCase()?.startsWith(searchVal) ||
			resident?.lastName?.toLowerCase()?.startsWith(searchVal) ||
			resident?.residentID?.toString()?.includes(searchVal) ||
			resident?.roomNum?.toString()?.startsWith(searchVal) ||
			resident?.roomNum?.toString()?.includes(searchVal)
		) {
			return resident;
		} else {
			return null;
		}
	});
};

const tableReducer = (state, action) => {
	switch (action.type) {
		case "UPDATE_RESIDENT": {
			return { ...state };
		}
		case "TRANSFER_RESIDENT": {
			return { ...state };
		}
		case "SORT_RESIDENTS": {
			return { ...state };
		}
		case "SEARCH_RESIDENTS": {
			const { searchVal, rawResidents } = action.data;
			const { residents } = state;

			if (isEmptyVal(searchVal)) {
				return {
					...state,
					residents: [...rawResidents],
				};
			} else {
				const newList = [...advancedSearch(searchVal, residents)];

				return {
					...state,
					residents: [...newList],
				};
			}
		}

		default:
			return { ...state };
	}
};

const ResidentsTable = ({
	facilityResidents = [],
	currentFacility,
	currentUser,
	isLoading,
	// new props for search window
	allFacilities,
	dispatchAlert,
}) => {
	const [tableState, dispatchAction] = useReducer(tableReducer, {
		...initialState,
		residents: [...facilityResidents],
		isSorted: false,
	});
	const [searchVal, setSearchVal] = useState("");
	const [showCustomSearch, setShowCustomSearch] = useState(false);
	const [selectedResident, setSelectedResident] = useState(null);

	const handleSearch = (e) => {
		const { value } = e.target;
		setSearchVal(value);
		dispatchAction({
			type: "SEARCH_RESIDENTS",
			data: {
				searchVal: value,
				rawResidents: [...facilityResidents],
			},
		});
	};

	const clearSearch = (e) => {
		setSearchVal("");
	};

	const initCustomSearch = (resident) => {
		setShowCustomSearch(true);
		setSelectedResident(resident);
	};

	return (
		<>
			<div className={styles.ResidentsTable}>
				<div className={styles.ResidentsTable_header}>
					<ResidentsTableHeader
						title="Manage Residents"
						subtitle="Edit, update, archive or transfer residents at this community."
						residents={tableState?.residents}
						isSorted={tableState?.isSorted}
					/>
					<ResidentsTableActions
						residents={tableState?.residents}
						isSorted={tableState?.isSorted}
						searchVal={searchVal}
						handleSearch={handleSearch}
						clearSearch={clearSearch}
						initCustomSearch={initCustomSearch}
					/>
				</div>
				<div className={styles.ResidentsTable_body}>
					{!isEmptyArray(tableState?.residents) && <ResidentsTableColumns />}
					{isLoading && (
						<div className={styles.ResidentsTable_loading}>
							<Spinner customStyles={customCSS.spinner} />
							<div className={styles.ResidentsTable_loading_msg}>
								Loading resident data...please wait..
							</div>
						</div>
					)}
					{!isEmptyArray(tableState?.residents) && !isLoading && (
						<ResidentsTableBody
							key={`RESIDENTS-${facilityResidents?.length}`}
							currentUser={currentUser}
							currentFacility={currentFacility}
							allResidents={tableState?.residents}
						/>
					)}
				</div>
			</div>

			{/* CUSTOM SEARCH WINDOW */}
			{showCustomSearch && (
				<ModalLG
					title="Custom Resident Search"
					closeModal={() => setShowCustomSearch(false)}
				>
					<ResidentSearchWindow
						allFacilities={allFacilities}
						currentUser={currentUser}
						facilityResidents={facilityResidents}
						closeWindow={() => setShowCustomSearch(false)}
						dispatchAlert={dispatchAlert}
						setSelectedResident={initCustomSearch}
					/>
				</ModalLG>
			)}
		</>
	);
};

export default ResidentsTable;

ResidentsTable.defaultProps = {};

ResidentsTable.propTypes = {};
