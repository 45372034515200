import React from "react";
import styles from "../../css/hints/CustomHints.module.scss";
import { isEmptyArray } from "../../helpers/utils_types";
// import {loginPageMessages} from '../../helpers/utils_messages'

const appMessages = {
	msg1: ``,
	msg2: ``,
};

const AppMessage = ({ msg }) => {
	return <div className={styles.AppMessage}>{msg}</div>;
};

const LoginPageMessages = ({ messages = [] }) => {
	return (
		<div className={styles.LoginPageMessages}>
			{/*  */}
			{/*  */}
		</div>
	);
};

/**
 * 'Legacy Hint':
 * - "How to access Legacy application"
 */
const legacyHint = (
	<span className={styles.CustomHints}>
		To access the <mark>Senior Care EHR</mark>, click on the{" "}
		<mark>'Your Apps'</mark> button above. Then select the Senior Care EHR on
		the 'Your Apps' page and you will be taken to the application.
	</span>
);

export { legacyHint, LoginPageMessages };
