import React, { useState, useEffect, useCallback } from "react";
import styles from "../../css/user/SetResetMethodPreference.module.scss";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import { isEmptyVal } from "../../helpers/utils_types";
import {
	createPreferredModel,
	matchResetMethodByID,
	resetMethodTypes,
	updateUsersPreferredMethod,
} from "../../helpers/utils_otp";
// components
import PasswordResetSummary from "../summary/PasswordResetSummary";
import EditResetMethodPreference from "./EditResetMethodPreference";

// REQUIREMENTS:
// - Reset Method Preference (requirements):
//    - 'ADMIN': no requirements, applicable to ALL users
//    - 'QUESTIONS': MUST have populated security questions/answers
//    - 'EMAIL': MUST have valid, working email address to receive resets at

// grabs 'Reset Methods' settings for the current user
const getActiveResetMethods = (currentUser) => {
	const security = currentUser?.security ?? {};
	const userLogins = security?.userLogins ?? [];
	const loginRecord = userLogins?.[0] ?? {};
	const { IsPwdResetByAdmin, IsPwdResetByEmail, IsPwdResetByQuestions } =
		loginRecord;

	return {
		isPwdResetByAdmin: IsPwdResetByAdmin,
		isPwdResetByEmail: IsPwdResetByEmail,
		isPwdResetByQuestions: IsPwdResetByQuestions,
	};
};

// returns status of reset method by name
const getResetMethodStatus = (method, currentUser) => {
	switch (method) {
		case "Admin": {
			const { isPwdResetByAdmin = true } = getActiveResetMethods(currentUser);
			return isPwdResetByAdmin;
		}
		case "Email": {
			const { isPwdResetByEmail = false } = getActiveResetMethods(currentUser);
			return isPwdResetByEmail;
		}
		case "Questions": {
			const { isPwdResetByQuestions = true } =
				getActiveResetMethods(currentUser);
			return isPwdResetByQuestions;
		}

		default:
			return false;
	}
};

const getPreferredMethod = (currentUser) => {
	const security = currentUser?.security ?? {};
	const userLogin = security?.userLogins?.[0] ?? {};
	const { PreferredResetMethodID: id } = userLogin;
	const match = matchResetMethodByID(id, resetMethodTypes);

	return match?.Name ?? "";
};

const wasChanged = (formState) => {
	const { touched, values } = formState;
	const wasTouched = touched?.preferredMethod ?? false;
	const isFilled = !isEmptyVal(values?.preferredMethod);

	return wasTouched && isFilled;
};

const SetResetMethodPreference = ({ currentUser, dispatchAlert }) => {
	const { formState, setFormState } = useForm({
		isPwdResetByAdmin: getResetMethodStatus("Admin", currentUser),
		isPwdResetByEmail: getResetMethodStatus("Email", currentUser),
		isPwdResetByQuestions: getResetMethodStatus("Questions", currentUser),
		// preference
		preferredMethod: getPreferredMethod(currentUser), // 'QUESTIONS', 'ADMIN', 'EMAIL'
	});
	const { values, touched } = formState;
	// changes were saved
	const [wasSaved, setWasSaved] = useState(false);

	const handlePreference = (name, val) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: val,
			},
			touched: {
				...touched,
				[name]: true,
			},
		});

		return savePreferredMethod(val);
	};

	const savePreferredMethod = useCallback(async (val) => {
		const { token, userID } = currentUser;
		// const { preferredMethod } = values;
		const preferredMethod = val;
		const { preferredMethod: wasTouched } = touched;
		if (isEmptyVal(preferredMethod) || !wasTouched) return;

		const preferredModel = createPreferredModel(resetMethodTypes, {
			userID: userID,
			preferredMethod: preferredMethod,
		});

		// fire off request
		const wasUpdated = await updateUsersPreferredMethod(
			token,
			userID,
			preferredModel
		);

		if (wasUpdated) {
			setWasSaved(true);
			return dispatchAlert("SUCCESS", {
				heading: `Your preferences were saved!`,
			});
		} else {
			setWasSaved(false);
			return dispatchAlert("ERROR", {
				heading: `Your changes were NOT saved.`,
				subheading: `Please try again later.`,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// fire off save request, when 'preferredMethod' changes
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (wasChanged(formState)) {
			savePreferredMethod();
		}

		return () => {
			isMounted = false;
		};
	}, [formState, savePreferredMethod]);

	return (
		<div className={styles.SetResetMethodPreference}>
			<div className={styles.SetResetMethodPreference_summary}>
				<PasswordResetSummary vals={values} />
			</div>
			<EditResetMethodPreference
				key={`RESET-PREFERENCE-PROFILE`}
				vals={values}
				handlePreference={handlePreference}
				currentUser={currentUser}
			/>
		</div>
	);
};

export default SetResetMethodPreference;

SetResetMethodPreference.defaultProps = {};

SetResetMethodPreference.propTypes = {
	currentUser: PropTypes.object,
	dispatchAlert: PropTypes.func,
};
