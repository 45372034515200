import { isEmptyVal } from "./utils_types";

/**
 * 'ICONS' naming map used for <HomeAlertsBanners/>
 */
const ICONS = {
	ERROR: "exclamation-outline",
	ERROR2: "alert-circle",
	WARN: "warning",
	// WARN2: "warningreport_problem1",
	WARN2: "warning",
	SUCCESS: "check_circle",
	INFO: "info",
};
/**
 * 'ICONS' naming map used for <HomeAlertsBanners/>.
 * - Used for alternate icons
 */
const ICONS_ALT = {
	INFO2: "information-outline",
	INFO3: "information-solid",
	INFO4: "info_outline",
	INFO5: "info-with-circle", // italic ~
	INFO6: "info2",
	INFO7: "info11",
	REPORT: "insert_chart_outlined",
	PRINT: "local_print_shopprint",
	SAVE: "save11",
	SIGNATURE: "gesture",
	ALARM: "access_alarmalarm",
	EDIT: "createmode_editedit",
	HELP: "help-circle",
	HELP1: "not_listed_location",
	// HELP: "live_help",
	// HELP1: "not_listed_location",
	MEDS: "local_hospital",
	CALENDAR: "event_note",
	CALENDAR_DONE: "event_available",
	CALENDAR_MISSED: "event_busy",
	CHECKMARK: "check_circle",
	CHECK: "checkmark",
	CHECK2: "checkmark-outline",
	USER: "account_circle",
	SETTINGS: "settings1",
	CHART: "chart-bar",
	ALERT: "notifications1",
	SHOW: "view-show",
	IMAGES: "images",
	// NEW!!! ICONS
	CANCEL: "clearclose",
	CHART2: "insert_chartpollassessment",
	CLOUD: "cloud_done",
	CLOUD_DOWNLOAD: "cloud_download",
	CLOUD_UPLOAD: "cloud_uploadbackup",
	DOWNLOAD: "file_downloadget_app",
	UPLOAD: "file_upload",
	FOLDER: "folder_open",
	FOLDER_PERSON: "folder_shared",
	FIRSTAID: "local_hospital",
	DINNER: "local_restaurantrestaurant_menu",
};

// formats 'alert.type' to match a valid alert
const getAlertType = (val) => {
	if (isEmptyVal(val)) return "SUCCESS";
	return val?.toUpperCase();
};

const hasMsg = (msg = {}) => {
	const hasHeading = !isEmptyVal(msg?.heading);
	const hasSubheading = !isEmptyVal(msg?.subheading);

	if (!hasHeading && !hasSubheading) {
		return false;
	} else {
		return true;
	}
};

// ALERTS FOR OFFLINE

export { getAlertType, hasMsg };

export { ICONS, ICONS_ALT };
