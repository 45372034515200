import React from "react";
import styles from "../../css/messages/LoginPageMessage.module.scss";
import { PropTypes } from "prop-types";
import { getMsgPriorityColor, isMsgActive } from "../../helpers/utils_messages";

const getMsgPriorityCss = (priority) => {
	const color = getMsgPriorityColor(priority);

	return {
		color: color,
	};
};

const LoginPageMessage = ({ message = {}, customMsgStyles = {} }) => {
	const { msg, msgPriority: priority } = message;

	if (!isMsgActive(message)) {
		return null;
	}
	return (
		<div
			className={styles.LoginPageMessage}
			style={{ ...getMsgPriorityCss(priority), ...customMsgStyles }}
		>
			{msg}
		</div>
	);
};

export default LoginPageMessage;

LoginPageMessage.defaultProps = {
	message: {
		msg: "",
		msgExpiry: null,
		msgPriority: null,
		enableMsg: false,
	},
};

LoginPageMessage.propTypes = {
	message: PropTypes.object,
};
