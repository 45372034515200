import React, { useState, useRef } from "react";
import styles from "../../css/views/CustomSearchView.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import { processUserList, searchForUserBy } from "../../helpers/utils_user";
import { isEmptyVal } from "../../helpers/utils_types";
import { blueGrey, red } from "../../helpers/utils_styles";
import { formatAndSortUserFacilities } from "../../helpers/utils_facility";
import { featureFlags } from "../../helpers/utils_permissions";
// components
import ButtonSM from "../../components/shared/ButtonSM";
import TextInput from "../../components/shared/TextInput";
import FormSection from "../../components/forms/FormSection";
import CustomDropdown from "../../components/shared/CustomDropdown";
import CustomCheckbox from "../../components/shared/CustomCheckbox";
import UserSearchResults from "../../components/user/UserSearchResults";
// 'MAINTENANCE' MODE
import ModalLG from "../../components/shared/ModalLG";
import UserSearchDetails from "../../components/user/UserSearchDetails";
import Divider from "../../components/forms/Divider";
import CustomFacilitySearch from "../../components/facility/CustomFacilitySearch";
import CustomResidentSearch from "../../components/residents/CustomResidentSearch";

// REQUIREMENTS:
// - Include custom searching for:
//    - Residents
//    - Facility(s)
//    - Users
//    - Audit Log (available v2)
// 		- UserID
// - Add searching by:
// 		- All currently suspended users

const customCSS = {
	checkbox: {
		marginBottom: ".6rem",
	},
	searchBtn: {
		padding: ".7rem 1.4rem",
		borderRadius: "5rem",
		backgroundColor: blueGrey[600],
		color: "#ffffff",
		fontSize: "1.4rem",
		fontWeight: "500",
	},
	cancelBtn: {
		padding: ".7rem 1.4rem",
		borderRadius: "5rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.4rem",
		fontWeight: "500",
		marginRight: "1rem",
	},
	clearBtn: {
		padding: ".7rem 1.4rem",
		borderRadius: "5rem",
		backgroundColor: red[600],
		color: "#ffffff",
		fontSize: "1.4rem",
		fontWeight: "500",
		marginRight: "auto",
	},
	dropdown: {
		width: "35rem",
	},
	searchInput: {
		backgroundColor: "#ffffff",
	},
	userIDSearch: {
		backgroundColor: "#ffffff",
	},
};

// checks if required selections have been made
const noTypeSelected = (vals = {}) => {
	const {
		// bools
		byEmail,
		byFirstName,
		byLastName,
		byFacility,
		byUserID,
		// user type bools
		byALAAdmin,
		byMedTechRestricted,
		bySuperUser,
		byFacilityAdmin,
		byRegionalAdmin,
		byReadOnly,
		// fields
		emailSearch,
		firstNameSearch,
		lastNameSearch,
		facilitySearch,
		userIDSearch,
	} = vals;

	// no search type or search value supplied
	const hasNoType =
		!byEmail &&
		!byFirstName &&
		!byLastName &&
		!byFacility &&
		!byUserID &&
		// user types
		!byALAAdmin &&
		!byMedTechRestricted &&
		!bySuperUser &&
		!byFacilityAdmin &&
		!byRegionalAdmin &&
		!byReadOnly;
	const hasNoSearch =
		isEmptyVal(emailSearch) &&
		isEmptyVal(firstNameSearch) &&
		isEmptyVal(lastNameSearch) &&
		isEmptyVal(facilitySearch) &&
		isEmptyVal(userIDSearch);

	return hasNoSearch && hasNoType;
};

const CustomSearch = ({
	vals = {},
	handleChange,
	handleSettings,
	allFacilities = [],
}) => {
	// handles which to render
	const renderSearch = (vals) => {
		switch (true) {
			case vals?.byEmail: {
				return (
					<div className={styles.CustomSearch}>
						<TextInput
							key={`SEARCH_BY_EMAIL`}
							label="Search By Email Address"
							name="emailSearch"
							id="emailSearch"
							placeholder="Enter an email..."
							val={vals.emailSearch}
							handleChange={handleChange}
							customStyles={customCSS.searchInput}
						/>
					</div>
				);
			}
			case vals?.byFirstName: {
				return (
					<div className={styles.CustomSearch}>
						<TextInput
							key={`SEARCH_BY_FIRST_NAME`}
							label="Search By First Name"
							name="firstNameSearch"
							id="firstNameSearch"
							placeholder="Enter User's First Name..."
							val={vals.firstNameSearch}
							handleChange={handleChange}
							customStyles={customCSS.searchInput}
						/>
					</div>
				);
			}
			case vals?.byLastName: {
				return (
					<div className={styles.CustomSearch}>
						<TextInput
							key={`SEARCH_BY_LAST_NAME`}
							label="Search By Last Name"
							name="lastNameSearch"
							id="lastNameSearch"
							placeholder="Enter User's Last Name..."
							val={vals.lastNameSearch}
							handleChange={handleChange}
							customStyles={customCSS.searchInput}
						/>
					</div>
				);
			}
			case vals?.byFacility: {
				return (
					<div className={styles.CustomSearch}>
						<CustomDropdown
							name="facilitySearch"
							id="facilitySearch"
							label="Choose a facility"
							placeholder="Select a facility"
							selection={vals.facilitySearch}
							setSelection={handleSettings}
							options={allFacilities}
							customStyles={customCSS.dropdown}
						/>
					</div>
				);
			}
			case vals?.byUserID: {
				return (
					<div className={styles.CustomSearch}>
						<TextInput
							key={`USER_ACCESS_SEARCH_BY_USERID`}
							label="Search By UserID"
							name="userIDSearch"
							id="userIDSearch"
							placeholder="Enter User's UserID..."
							val={vals.userIDSearch}
							handleChange={handleChange}
							customStyles={customCSS.userIDSearch}
						/>
					</div>
				);
			}
			default:
				return null;
		}
	};

	return <>{renderSearch(vals)}</>;
};

const CustomSearchView = ({
	currentUser = {},
	currentFacility = {},
	globalState = {},
	dispatchToState,
}) => {
	const { parentsMap = {}, facilities = [] } = globalState;
	const { formState, setFormState, handleChange, handleCheckbox, handleReset } =
		useForm({
			byFirstName: false,
			byLastName: false,
			byFacility: false,
			// by user type
			byALAAdmin: false,
			byMedTechRestricted: false,
			bySuperUser: false,
			byFacilityAdmin: false,
			byRegionalAdmin: false,
			byReadOnly: false,
			// searches
			emailSearch: "",
			firstNameSearch: "",
			lastNameSearch: "",
			facilitySearch: "",
		});
	const { values } = formState;

	const [searchResults, setSearchResults] = useState(null);
	const [isLoadingResults, setIsLoadingResults] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [showUserDetails, setShowUserDetails] = useState(false);

	const handleSettings = (name, val) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: val,
			},
		});
	};

	// fires off request w/ search values
	const runSearch = async (e) => {
		const { token, facilities } = currentUser;
		setIsLoadingResults(true);
		const results = await searchForUserBy(token, facilities, values);

		if (results) {
			setIsLoadingResults(false);
			return setSearchResults(results);
		} else {
			return setIsLoadingResults(false);
		}
	};
	// clear form & close search window
	const cancelSearch = (e) => {
		handleReset(e);
	};
	// clear our form fields
	const clearSearch = (e) => {
		handleReset(e);
		setSearchResults([]);
	};

	// selects search result & opens user in 'UserDetails' modal
	const viewCustomSearchUser = (user) => {
		setShowUserDetails(true);
		setSelectedUser(user);
	};

	return (
		<>
			<div className={styles.CustomSearchView}>
				{/* USER SEARCH */}
				<div className={styles.CustomSearchView_userSearch}>
					<header className={styles.CustomSearchView_header}>
						<h2 className={styles.CustomSearchView_header_title}>
							Search for a <b>User</b>
						</h2>
						<h6 className={styles.CustomSearchView_header_desc}>
							Search by email, name, userID, facility or user-type & select the
							results to view their details.
						</h6>
					</header>
					<section className={styles.CustomSearchView_main}>
						{/* SEARCH BY: EMAIL, NAME, FACILITY */}
						<FormSection
							title="Search By:"
							icon="checkbox"
							hoverText="Search by a user-related value."
						>
							<CustomCheckbox
								isDisabled={
									values?.byFirstName ||
									values?.byLastName ||
									values?.byFacility ||
									values?.byUserID
								}
								label="By Email"
								name="byEmail"
								id="byEmail"
								val={values?.byEmail}
								handleCheckbox={handleCheckbox}
								customStyles={customCSS.checkbox}
							/>
							<CustomCheckbox
								isDisabled={
									values?.byEmail ||
									values?.byLastName ||
									values?.byFacility ||
									values?.byUserID
								}
								label="By First Name"
								name="byFirstName"
								id="byFirstName"
								val={values?.byFirstName}
								handleCheckbox={handleCheckbox}
								customStyles={customCSS.checkbox}
							/>
							<CustomCheckbox
								isDisabled={
									values?.byFirstName ||
									values?.byEmail ||
									values?.byFacility ||
									values?.byUserID
								}
								label="By Last Name"
								name="byLastName"
								id="byLastName"
								val={values?.byLastName}
								handleCheckbox={handleCheckbox}
								customStyles={customCSS.checkbox}
							/>
							<CustomCheckbox
								isDisabled={
									values?.byFirstName || values?.byEmail || values?.byFacility
								}
								label="By UserID"
								name="byUserID"
								id="byUserID"
								val={values?.byUserID}
								handleCheckbox={handleCheckbox}
								customStyles={customCSS.checkbox}
							/>
							<CustomCheckbox
								isDisabled={
									values?.byEmail ||
									values?.byLastName ||
									values?.byFirstName ||
									values?.byUserID
								}
								label="By Facility"
								name="byFacility"
								id="byFacility"
								val={values?.byFacility}
								handleCheckbox={handleCheckbox}
								customStyles={customCSS.checkbox}
							/>
							{/* CUSTOM SEARCH INPUT RENDERER */}
							<CustomSearch
								vals={values}
								handleChange={handleChange}
								handleSettings={handleSettings}
								allFacilities={[
									...formatAndSortUserFacilities(currentUser?.facilities),
								]}
							/>
						</FormSection>

						{/* USER TYPES */}
						<FormSection
							title="Find By User Type"
							icon="userCircle"
							hoverText="Find By User Type"
						>
							<CustomCheckbox
								isDisabled={
									values?.byRegionalAdmin ||
									values?.bySuperUser ||
									values?.byMedTechRestricted ||
									values?.byFacilityAdmin ||
									values?.byReadOnly
								}
								label="ALA Admins"
								name="byALAAdmin"
								id="byALAAdmin"
								val={values?.byALAAdmin}
								handleCheckbox={handleCheckbox}
								customStyles={customCSS.checkbox}
							/>
							<CustomCheckbox
								isDisabled={
									values?.byALAAdmin ||
									values?.bySuperUser ||
									values?.byRegionalAdmin ||
									values?.byFacilityAdmin ||
									values?.byReadOnly
								}
								label="By Med-Tech Restricted"
								name="byMedTechRestricted"
								id="byMedTechRestricted"
								val={values?.byMedTechRestricted}
								handleCheckbox={handleCheckbox}
								customStyles={customCSS.checkbox}
							/>
							{featureFlags.search.byUserType && (
								<>
									<CustomCheckbox
										isDisabled={
											values?.byALAAdmin ||
											values?.byRegionalAdmin ||
											values?.byMedTechRestricted ||
											values?.byFacilityAdmin ||
											values?.byReadOnly
										}
										label="By Super User"
										name="bySuperUser"
										id="bySuperUser"
										val={values?.bySuperUser}
										handleCheckbox={handleCheckbox}
										customStyles={customCSS.checkbox}
									/>
									<CustomCheckbox
										isDisabled={
											values?.byALAAdmin ||
											values?.bySuperUser ||
											values?.byMedTechRestricted ||
											values?.byRegionalAdmin ||
											values?.byReadOnly
										}
										label="By Facility Admin"
										name="byFacilityAdmin"
										id="byFacilityAdmin"
										val={values?.byFacilityAdmin}
										handleCheckbox={handleCheckbox}
										customStyles={customCSS.checkbox}
									/>
									<CustomCheckbox
										isDisabled={
											values?.byALAAdmin ||
											values?.bySuperUser ||
											values?.byMedTechRestricted ||
											values?.byFacilityAdmin ||
											values?.byReadOnly
										}
										label="By Regional Admin"
										name="byRegionalAdmin"
										id="byRegionalAdmin"
										val={values?.byRegionalAdmin}
										handleCheckbox={handleCheckbox}
										customStyles={customCSS.checkbox}
									/>
									<CustomCheckbox
										isDisabled={
											values?.byALAAdmin ||
											values?.bySuperUser ||
											values?.byMedTechRestricted ||
											values?.byFacilityAdmin
										}
										label="By Read-Only"
										name="byReadOnly"
										id="byReadOnly"
										val={values?.byReadOnly}
										handleCheckbox={handleCheckbox}
										customStyles={customCSS.checkbox}
									/>
								</>
							)}
						</FormSection>

						{/* ACTIONS */}
						<div className={styles.CustomSearchView_main_actions}>
							<ButtonSM
								customStyles={customCSS.clearBtn}
								handleClick={clearSearch}
							>
								<span>Clear Search</span>
							</ButtonSM>
							<ButtonSM
								customStyles={customCSS.cancelBtn}
								handleClick={cancelSearch}
							>
								<span>Cancel Search</span>
							</ButtonSM>
							<ButtonSM
								isDisabled={noTypeSelected(values)}
								customStyles={customCSS.searchBtn}
								handleClick={runSearch}
							>
								<svg className={styles.CustomSearchView_main_actions_icon}>
									<use xlinkHref={`${sprite}#icon-search2`}></use>
								</svg>
								<span>Run Search</span>
							</ButtonSM>
						</div>
					</section>
					{/* USER SEARCH RESULTS */}
					<section className={styles.CustomSearchView_results}>
						<UserSearchResults
							key={`SEARCH_RESULTS_${isLoadingResults}-${searchResults?.length}`}
							isLoading={isLoadingResults}
							searchResults={[...processUserList(searchResults, false)]}
							setSelectedUser={viewCustomSearchUser}
							searchCSS={customCSS?.searchInput}
						/>
					</section>
				</div>
				<Divider />
				{/* FACILITY SEARCH */}
				<div className={styles.CustomSearchView_facilitySearch}>
					<header className={styles.CustomSearchView_header}>
						<h2 className={styles.CustomSearchView_header_title}>
							Search for a <b>Facility</b>
						</h2>
						<h6 className={styles.CustomSearchView_header_desc}>
							Enter a facility ID, parent ID, suspended status or find all child
							communities for a parent.
						</h6>
					</header>
					<section className={styles.CustomSearchView_facilitySearch_main}>
						{/* SEARCH BY: EMAIL, NAME, FACILITY */}
						<CustomFacilitySearch
							currentUser={currentUser}
							currentFacility={currentFacility}
							facilities={facilities}
							parentsMap={parentsMap}
							globalState={globalState}
						/>
					</section>
				</div>
				<Divider />
				{/* RESIDENT SEARCH */}
				<div className={styles.CustomSearchView_residentSearch}>
					<header className={styles.CustomSearchView_header}>
						<h2 className={styles.CustomSearchView_header_title}>
							Search for a <b>Resident</b>
						</h2>
						<h6 className={styles.CustomSearchView_header_desc}>
							Select a unit type, age, archive status & more to fetch all
							residents matching that criteria.
						</h6>
					</header>
					<section className={styles.CustomSearchView_residentSearch_main}>
						{/* SEARCH BY: EMAIL, NAME, FACILITY, ID, UNIT TYPE, ROOM NUMBER */}
						<CustomResidentSearch
							currentUser={currentUser}
							currentFacility={currentFacility}
							parentsMap={parentsMap}
							facilities={facilities}
							globalState={globalState}
						/>
					</section>
				</div>
			</div>

			{showUserDetails && (
				<ModalLG
					title={`Custom Search`}
					closeModal={() => setShowUserDetails(false)}
				>
					<UserSearchDetails
						selectedUser={selectedUser}
						currentFacility={currentFacility}
						currentUser={currentUser}
						allFacilities={facilities}
						closeModal={() => setShowUserDetails(false)}
						// dispatchAlert={dispatchAlert}
					/>
				</ModalLG>
			)}
		</>
	);
};

export default CustomSearchView;

CustomSearchView.defaultProps = {};

CustomSearchView.propTypes = {};
