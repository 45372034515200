import React, { useContext, useState, useEffect } from "react";
import styles from "../css/pages/UserSummaryPage.module.scss";
import { PropTypes } from "prop-types";
import { GlobalStateContext } from "../state/GlobalStateContext";
import { getUserLockoutSummary } from "../helpers/utils_lockouts";
import { isEmptyArray } from "../helpers/utils_types";
// components
import ViewContainer from "../components/app/ViewContainer";
import QuickSummary from "../components/summary/QuickSummary";
import UserLockouts from "../components/summary/UserLockouts";
import AlertBanner from "../components/alerts/AlertBanner";

// REQUIREMENTS:
// - Show quick summary of user's:
//    - Show total number of user lockouts
// - Enable easy user resolutions:
//    - Lockouts:
//      - Click to 'Unlock' and 'Generate OTP'
//      - Click to copy generated OTP

const viewDetails = {
	title: `Quick User Summary`,
	desc: `A quick & easy summary tool for managing your users.`,
};

const UserSummaryPage = () => {
	const { state: globalState, dispatch: dispatchToState } = useContext(
		GlobalStateContext
	);
	const { currentUser, currentFacility } = globalState;
	const [isLoadingSummary, setIsLoadingSummary] = useState(false);
	const [userLockoutSummary, setUserLockoutSummary] = useState([]);
	const [viewLockouts, setViewLockouts] = useState(false);

	// fetches summary
	const fetchLockSummary = async () => {
		const { token } = currentUser;
		const facilityID = currentFacility?.facilityID ?? currentUser?.facilityID;
		// fetch user lockout summary
		setIsLoadingSummary(true);
		const lockouts = await getUserLockoutSummary(token, facilityID);

		if (!isEmptyArray(lockouts)) {
			setIsLoadingSummary(false);
			return setUserLockoutSummary([...lockouts]);
		} else {
			setIsLoadingSummary(false);
			return setUserLockoutSummary([]);
		}
	};

	// fetch lockouts
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		// fetchLockSummary();
		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ViewContainer title={viewDetails?.title} desc={viewDetails?.desc}>
			<div className={styles.UserSummaryPage}>
				<header className={styles.UserSummaryPage_header}>
					<QuickSummary />
				</header>
				<div className={styles.UserSummarPage_main}>
					<UserLockouts
						globalState={globalState}
						dispatchToState={dispatchToState}
						userLockoutSummary={userLockoutSummary}
					/>
				</div>
			</div>
		</ViewContainer>
	);
};

export default UserSummaryPage;

UserSummaryPage.defaultProps = {};

UserSummaryPage.propTypes = {};
