import React from "react";
import styles from "../../css/residents/ResidentsTableHeader.module.scss";
import { PropTypes } from "prop-types";

// COLUMNS:
// - Name
// - Unit Type
// - Age
// - Archived/LOA

const ResidentsTableHeader = ({
	title = "Manage Residents",
	subtitle = "",
	residents = [],
}) => {
	return (
		<div className={styles.ResidentsTableHeader}>
			<div className={styles.ResidentsTableHeader_left}>
				<h2 className={styles.ResidentsTableHeader_left_title}>{title}</h2>
				<div className={styles.ResidentsTableHeader_left_subtitle}>
					{subtitle}
				</div>
			</div>
			<div className={styles.ResidentsTableHeader_right}>
				<div className={styles.ResidentsTableHeader_right_count}>
					Showing <b>{residents?.length}</b> residents
				</div>
			</div>
		</div>
	);
};

export default ResidentsTableHeader;

ResidentsTableHeader.defaultProps = {};

ResidentsTableHeader.propTypes = {};
