import React, { useState, useEffect } from "react";
import styles from "../../css/dashboard/LogoutButton.module.scss";
import sprite from "../../assets/icons/settings.svg";
import { PropTypes } from "prop-types";

const timeout = 5000;

const LogoutButton = ({ handleLogout }) => {
	const [isTempLocked, setIsTempLocked] = useState(false);

	const logoutHandler = (e) => {
		setIsTempLocked(true);
		handleLogout(e);
	};

	// lock the 'logout' button after click...
	// ...this prevents duplicate clicks & duplicate 'Logout' requests
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		let timerID;
		if (isTempLocked) {
			timerID = setTimeout(() => {
				setIsTempLocked(false);
			}, timeout);
		}

		return () => {
			isMounted = false;
			clearTimeout(timerID);
		};
	}, [isTempLocked]);

	return (
		<button
			type="button"
			className={styles.LogoutButton}
			onClick={logoutHandler}
			disabled={isTempLocked}
		>
			<svg className={styles.LogoutButton_icon}>
				<use xlinkHref={`${sprite}#icon-logout`}></use>
			</svg>
			<span className={styles.LogoutButton_text}>Logout</span>
		</button>
	);
};

export default LogoutButton;

LogoutButton.defaultProps = {};

LogoutButton.propTypes = {
	handleLogout: PropTypes.func.isRequired,
};
